import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./singledropzone.css";

function SingleImageDropZone({
  getRootProps,
  getInputProps,
  isDragActive,
  images,
  uploadType,
}) {
  return (
    <>
      <div
        {...getRootProps()}
        className={`dropzone ${isDragActive ? "active" : ""}`}
      >
        <input {...getInputProps()} />
        <ul>
          <li>
            {uploadType === "FOOD" ? (
              <LazyLoadImage
                className="loadImage"
                src={
                  images && images.preview
                    ? images.preview
                    : `${process.env.REACT_APP_API_URL}/uploads/foods/${images}`
                }
                effect="blur"
              />
            ) : (
              <LazyLoadImage
                className="loadImage"
                src={
                  images && images.preview
                    ? images.preview
                    : `${process.env.REACT_APP_API_URL}/uploads/guests/${images}`
                }
                effect="blur"
              />
            )}
          </li>
        </ul>
        {!isDragActive ||
          (!images && (
            <>
              <p>Drop the file here...</p>{" "}
              <p>Drag 'n' drop an image here, or click to select an image</p>
            </>
          ))}
      </div>
    </>
  );
}

export function FileUploadDropZone({
  getRootProps,
  getInputProps,
  isDragActive,
  file,
}) {
  return (
    <>
      <div
        {...getRootProps()}
        className={`dropzone ${isDragActive ? "active" : ""}`}
      >
        <input {...getInputProps()} />
        {!file && <p style={{ width: "100%" }}>Drop the files here ...</p>}
        <ul
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <li>
            {file && file && (
              <LazyLoadImage
                className="fileImage"
                src={`${process.env.PUBLIC_URL}/excel-icon.png`}
                effect="blur"
              />
            )}
          </li>
          <li style={{ marginTop: "20px" }}>
            {file && file && `${file.path}`}
          </li>
        </ul>
      </div>
    </>
  );
}

export default SingleImageDropZone;

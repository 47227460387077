import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  Tooltip,
  IconButton,
  Button,
  Avatar,
  useMediaQuery,
} from "@mui/material";
import DataTable from "../../../DataTable";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import CollapsedBreadcrumbs from "../../Include/Breadcrumb";
import ExcellIcon from "../../../../Assets/img/Exellicon.png";
import SwipableDrawer from "../../Include/SwipableDrawer";
import EditRoadOutlinedIcon from "@mui/icons-material/EditRoadOutlined";
import dayjs from "dayjs";
import Reportsfilter from "./Filter_Swipable_Drawer";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import handleExcelConversion from "./ExcellsFormat";
import DropDownDataTable from "./DropDownDataTable";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAllBookedRoomReport,
  GetAllFoodReport,
  GetAllRoomCancelReport,
  GetAllUserReport,
  POSTFOODORDErREPORT,
  PostBookedRoomDateAPI,
  PostCancelRoomDateAPI,
  PostUserReport,
  PostUserReportWithHistory,
} from "../../../../store/Slice/ReportSlice/ReportSlice";
import { useFormik } from "formik";
import placeholderImage from "../../../../Assets/img/room-placeholder.jpg";
import GuestDropDownTable from "./GuestDropDownTable";
import { CustomSnackBar } from "../../../../Model/Model";
const BreadCrumbLink = [
  { id: 1, name: "Home", link: "/" },
  { id: 2, name: "Reports", link: "/reports" },
];
const Reports = () => {
  const Token = Cookies.get("Token");
  const dispatch = useDispatch();
  const IsMobile = useMediaQuery("(min-width:900px)");
  const { BookedRoomReport, CancelRoomReport, FoodReport, UserReport } =
    useSelector((state) => state.ReportStore);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  // const [handlegridrow, sethandlegridrow] = useState(null);

  const columnsBookedRooms = [
    {
      field: "serialNumber",
      headerName: "Sr.No",
      width: 0,
      headerAlign: "center",
      align: "center",
      visible: true,
    },
    // {
    //   field: "SingleImages",
    //   headerName: "Image",
    //   align: "center",
    //   headerAlign: "center",
    //   visible: true,
    //   renderCell: (param) => {
    //     const image =
    //       param.row && param.row?.room && param.row?.room?.images
    //         ? `${process.env.REACT_APP_API_URL}/uploads/rooms/${
    //             param.row?.room?.images.split(",")[0]
    //           }`
    //         : placeholderImage;

    //     return (
    //       <LazyLoadImage
    //         effect="blur"
    //         // onClick={handleRowClick}
    //         src={image}
    //         style={{
    //           height: "40px",
    //           width: "70px",
    //           borderRadius: "10px",
    //           cursor: "pointer",
    //           marginBottom: "10px",
    //           marginTop: "5px",
    //         }}
    //       />
    //     );
    //   },
    // },

    {
      field: "BookingData",
      headerName: "Booking Date",
      width: 150,
      headerAlign: "center",
      visible: true,
      align: "center",
      renderCell: (param) => {
        const BookingDate = param.row?.booking_date
          ? new Date(param.row?.booking_date).toLocaleDateString()
          : "";
        return <div>{BookingDate}</div>;
      },
    },

    {
      field: "Name",
      headerName: "Name",
      width: 100,
      headerAlign: "center",
      visible: true,
      align: "center",
      renderCell: (param) => {
        const name =
          param.row && param.row?.guest && param.row?.guest?.fullname
            ? param.row?.guest?.fullname
            : "";
        return <div>{name}</div>;
      },
    },

    {
      field: "email",
      headerName: "Email",
      width: 100,
      headerAlign: "center",
      visible: true,
      align: "center",
      renderCell: (param) => {
        const Email =
          param.row && param.row?.guest && param.row?.guest?.email
            ? param.row?.guest?.email
            : "";
        return <div>{Email}</div>;
      },
    },
    {
      field: "Mobile",
      headerName: "Mobile",
      width: 150,
      headerAlign: "center",
      visible: true,
      align: "center",
      renderCell: (param) => {
        const Mobile =
          param.row && param.row?.guest && param.row?.guest?.phone_number
            ? param.row?.guest?.phone_number
            : "";
        return <div>{Mobile}</div>;
      },
    },

    {
      field: "Room_No",
      headerName: "Room No",
      width: 130,
      headerAlign: "center",
      visible: true,
      align: "center",
      renderCell: (param) => {
        const roomno =
          param.row && param.row?.room && param.row?.room?.room_no
            ? param.row?.room?.room_no
            : "";

        return <div variant="h6">{roomno}</div>;
      },
    },
    {
      field: "Price",
      headerName: "Price",
      width: 100,
      headerAlign: "center",
      visible: true,
      align: "center",
      renderCell: (param) => {
        const Price =
          param.row && param.row?.room && param.row?.room?.perdayprice
            ? param.row?.room?.perdayprice
            : "";

        return <div variant="h6">₹ {Price}</div>;
      },
    },
    {
      field: "check_in",
      headerName: "Check IN",
      visible: true,
      align: "center",
      headerAlign: "center",
      width: 190,
      renderCell: (param) =>
        param.row?.check_in ? (
          <div>
            {new Intl.DateTimeFormat("en-GB", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            }).format(new Date(param.row?.check_in))}
          </div>
        ) : null,
    },

    {
      field: "check_out",
      headerName: "Check out",
      visible: true,
      align: "center",
      headerAlign: "center",
      width: 190,
      renderCell: (param) =>
        // <div>{new Date(param.row.check_in).toLocaleDateString()}</div>
        param.row?.check_in ? (
          <div>
            {new Intl.DateTimeFormat("en-GB", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            }).format(new Date(param.row?.check_out))}
          </div>
        ) : null,
    },

    {
      field: "cgst",
      headerName: "CGST",
      width: 80,
      headerAlign: "center",
      visible: true,
      align: "center",
    },

    {
      field: "sgst",
      headerName: "SGST",
      width: 80,
      headerAlign: "center",
      visible: true,
      align: "center",
    },

    {
      field: "igst",
      headerName: "igst",
      width: 80,
      headerAlign: "center",
      visible: true,
      align: "center",
    },

    {
      field: "taxable_price",
      headerName: "Taxable Price",
      width: 80,
      headerAlign: "center",
      visible: true,
      align: "center",
    },

    {
      field: "total_days",
      headerName: "Total Days",
      width: 80,
      headerAlign: "center",
      visible: true,
      align: "center",
    },

    {
      field: "discount",
      headerName: "Discount",
      width: 140,
      headerAlign: "center",
      visible: true,
      align: "center",
    },

    {
      field: "adv_payment",
      headerName: "Advance Payment",
      width: 140,
      headerAlign: "center",
      visible: true,
      align: "center",
    },
    {
      field: "payment_type",
      headerName: "Payment Type",
      width: 120,
      headerAlign: "center",
      visible: true,
      align: "center",
    },

    {
      field: "total_price",
      headerName: "Total Price",
      width: 80,
      headerAlign: "center",
      visible: true,
      align: "center",
    },

    {
      field: "payment_status",
      headerName: "Payment Status",
      width: 140,
      headerAlign: "center",
      visible: true,
      align: "center",
      renderCell: (param) => {
        const Pstatus = param.row?.payment_status;
        return (
          <Button
            variant="outlined"
            color={Pstatus === "BOOKED" ? "success" : "error"}
            size="small"
          >
            {Pstatus}
          </Button>
        );
      },
    },

    {
      field: "status",
      headerName: "Status",
      // width:"300px",
      headerAlign: "center",
      visible: true,
      renderCell: (param) => {
        const status = param.row?.status;
        return (
          <Button
            variant="outlined"
            color={status === "BOOKED" ? "success" : "error"}
            size="small"
          >
            {param.row?.status}
          </Button>
        );
      },
    },
  ];

  // const [columnsCancelRooms, setcolumnsCancelRooms] = useState([
  //   {
  //     field: "serialNumber",
  //     headerName: "Sr.No.",
  //     width: 0,
  //     headerAlign: "center",
  //     align: "center",
  //     visible: true,
  //   },
  //   {
  //     field: "BookingData",
  //     headerName: "Booking Date",
  //     width: 100,
  //     headerAlign: "center",
  //     visible: true,
  //     align: "center",
  //     renderCell: (param) => {
  //       const BookingDate = param.row?.booking_date
  //         ? new Date(param.row?.booking_date).toLocaleDateString()
  //         : "";
  //       return <div>{BookingDate}</div>;
  //     },
  //   },
  //   {
  //     field: "SingleImages",
  //     headerName: "Images",
  //     align: "center",
  //     headerAlign: "center",
  //     visible: true,
  //     renderCell: (param) => {
  //       const image =
  //         param.row && param.row?.room && param.row?.room?.images
  //           ? `${process.env.REACT_APP_API_URL}/uploads/rooms/${
  //               param.row?.room?.images.split(",")[0]
  //             }`
  //           : placeholderImage;
  //       return (
  //         <LazyLoadImage
  //           effect="blur"
  //           onClick={handleRowClick}
  //           src={image}
  //           style={{
  //             height: "40px",
  //             width: "70px",
  //             borderRadius: "10px",
  //             cursor: "pointer",
  //             marginBottom: "10px",
  //             marginTop: "5px",
  //           }}
  //         />
  //       );
  //     },
  //   },
  //   {
  //     field: "Room_No",
  //     headerName: "Room No",
  //     width: 100,
  //     headerAlign: "center",
  //     visible: true,
  //     align: "center",
  //     renderCell: (param) => {
  //       const roomno =
  //         param.row && param.row?.room && param.row?.room?.room_no
  //           ? param.row?.room?.room_no
  //           : "";

  //       return (
  //         <div variant="h6" onClick={handleRowClick}>
  //           {roomno}
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     field: "Price",
  //     headerName: "Price",
  //     width: 60,
  //     headerAlign: "center",
  //     visible: true,
  //     align: "center",
  //     renderCell: (param) => {
  //       const Price =
  //         param.row && param.row?.room && param.row?.room?.perdayprice
  //           ? param.row?.room?.perdayprice
  //           : "";

  //       return (
  //         <div variant="h6" onClick={handleRowClick}>
  //           ₹ {Price}
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     field: "floor_No",
  //     headerName: "Floor No",
  //     width: 60,
  //     headerAlign: "center",
  //     visible: true,
  //     align: "center",
  //     renderCell: (param) => {
  //       const Floor =
  //         param.row && param.row?.room && param.row?.room?.floor_no
  //           ? param.row?.room?.floor_no
  //           : "";

  //       return (
  //         <div variant="h6" onClick={handleRowClick}>
  //           {Floor}
  //         </div>
  //       );
  //     },
  //   },

  //   {
  //     field: "checkIn",
  //     headerName: "Check In Date",
  //     width: 100,
  //     headerAlign: "center",
  //     visible: true,
  //     align: "center",
  //     renderCell: (param) => {
  //       const CheckIn = param.row?.check_in
  //         ? new Date(param.row?.check_in).toLocaleDateString()
  //         : "";
  //       return <div>{CheckIn}</div>;
  //     },
  //   },
  //   {
  //     field: "checkOut",
  //     headerName: "Check Out Date",
  //     width: 100,
  //     headerAlign: "center",
  //     visible: true,
  //     align: "center",
  //     renderCell: (param) => {
  //       const CheckOUT = param.row?.check_out
  //         ? new Date(param.row?.check_out).toLocaleDateString()
  //         : "";
  //       return <div>{CheckOUT}</div>;
  //     },
  //   },
  //   {
  //     field: "status",
  //     headerName: "Status",
  //     // width:"300px",
  //     headerAlign: "center",
  //     visible: true,
  //     renderCell: (param) => (
  //       <Button variant="outlined" color="error" size="small">
  //         {param.row?.status}
  //       </Button>
  //     ),
  //   },
  //   {
  //     field: "adv_payment",
  //     headerName: "Advance Payment",
  //     width: 120,
  //     headerAlign: "center",
  //     visible: true,
  //     align: "center",
  //   },
  //   {
  //     field: "cgst",
  //     headerName: "CGST",
  //     width: 80,
  //     headerAlign: "center",
  //     visible: true,
  //     align: "center",
  //   },
  //   {
  //     field: "sgst",
  //     headerName: "SGST",
  //     width: 80,
  //     headerAlign: "center",
  //     visible: true,
  //     align: "center",
  //   },
  //   {
  //     field: "igst",
  //     headerName: "igst",
  //     width: 80,
  //     headerAlign: "center",
  //     visible: true,
  //     align: "center",
  //   },

  //   {
  //     field: "payment_status",
  //     headerName: "Payment Status",
  //     width: 100,
  //     headerAlign: "center",
  //     visible: true,
  //     align: "center",
  //   },

  //   {
  //     field: "payment_type",
  //     headerName: "Payment Type",
  //     width: 80,
  //     headerAlign: "center",
  //     visible: true,
  //     align: "center",
  //   },

  //   {
  //     field: "taxable_price",
  //     headerName: "Taxable Price",
  //     width: 80,
  //     headerAlign: "center",
  //     visible: true,
  //     align: "center",
  //   },

  //   {
  //     field: "total_days",
  //     headerName: "Total Days",
  //     width: 80,
  //     headerAlign: "center",
  //     visible: true,
  //     align: "center",
  //   },

  //   {
  //     field: "total_price",
  //     headerName: "Total Price",
  //     width: 80,
  //     headerAlign: "center",
  //     visible: true,
  //     align: "center",
  //   },

  //   {
  //     field: "name",
  //     headerName: "Name",
  //     width: 100,
  //     headerAlign: "center",
  //     visible: true,
  //     align: "center",
  //     renderCell: (param) => {
  //       const name =
  //         param.row && param.row?.guest && param.row?.guest?.fullname
  //           ? param.row?.guest?.fullname
  //           : "";
  //       return <div>{name}</div>;
  //     },
  //   },
  //   {
  //     field: "email",
  //     headerName: "Email",
  //     width: 100,
  //     headerAlign: "center",
  //     visible: true,
  //     align: "center",
  //     renderCell: (param) => {
  //       const Email =
  //         param.row && param.row?.guest && param.row?.guest?.email
  //           ? param.row?.guest?.email
  //           : "";
  //       return <div>{Email}</div>;
  //     },
  //   },
  //   {
  //     field: "Mobile",
  //     headerName: "Mobile",
  //     width: 150,
  //     headerAlign: "center",
  //     visible: true,
  //     align: "center",
  //     renderCell: (param) => {
  //       const Mobile =
  //         param.row && param.row?.guest && param.row?.guest?.phone_number
  //           ? param.row?.guest?.phone_number
  //           : "";
  //       return <div>{Mobile}</div>;
  //     },
  //   },
  // ]);

  const columnsUser = [
    {
      field: "#",
      headerName: "#",
      width: "25px",
      headerAlign: "center",
      align: "center",
      visible: true,
    },
    {
      field: "serialNumber",
      headerName: "Sr.No.",
      width: "40px",
      headerAlign: "center",
      align: "center",
      visible: true,
    },
    {
      field: "created_at",
      headerName: "Date",
      visible: true,
      align: "center",
      headerAlign: "center",
      width: "70px",
      renderCell: (param) => (
        // <div>{new Date(param.row.check_in).toLocaleDateString()}</div>
        <div>
          {new Intl.DateTimeFormat("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          }).format(new Date(param.row?.created_at))}
        </div>
      ),
    },
    {
      field: "fullname",
      headerName: "Full Name",
      width: "120px",
      headerAlign: "center",
      align: "center",
      visible: true,
      renderCell: (param) => <div>{param.row?.fullname}</div>,
    },
    {
      field: "phone_number",
      headerName: "Mobile",
      width: "70px",
      headerAlign: "center",
      align: "center",
      visible: true,
    },
    {
      field: "address",
      headerName: "Address",
      width: "105px",
      headerAlign: "center",
      align: "center",
      visible: true,
    },
    {
      field: "email",
      headerName: "Email",
      width: "155px",
      headerAlign: "center",
      align: "center",
      visible: true,
    },
    {
      headerName: "GST Number",
      field: "gst_number",
      width: "120px",
      headerAlign: "center",
      align: "center",
      visible: true,
    },
    {
      field: "document",
      headerName: "Document",
      width: "90px",
      headerAlign: "center",
      align: "center",
      visible: true,
      renderCell: (param) => {
        const imageUrl =
          param.row && param.row?.document_images
            ? `${process.env.REACT_APP_API_URL}/uploads/guests/${param.row?.document_images}`
            : placeholderImage;

        return (
          <LazyLoadImage
            effect="blur"
            src={imageUrl}
            style={{
              height: "40px",
              width: "70px",
              borderRadius: "10px",
              cursor: "pointer",
              marginBottom: "10px",
              marginTop: "5px",
            }}
          />
        );
      },
    },
  ];

  // const handleRowClick = () => sethandlegridrow((previous) => !previous);

  // const [BillingModelStatus, setBillingModelStatus] = useState(false);
  // const handleBillingModelOpen = () => {
  //   setBillingModelStatus(true);
  // };

  // const handleBillingModelClose = () => setBillingModelStatus(false);

  const [Swipeopen, setSwipeopen] = useState(false);
  const [ReportSwipeopen, setReportSwipeopen] = useState(false);
  const HandleSwipeOpen = () => setSwipeopen(true);
  const HandleSwipeClose = () => setSwipeopen(false);

  // const HandleReportSwipeOpen = () => setReportSwipeopen(true);
  const HandleReportSwipeClose = () => setReportSwipeopen(false);

  const ReportColumns = [
    { id: 1, name: "Booked Rooms", visible: false },
    { id: 3, name: "Room Cancel", visible: false },
    { id: 6, name: "Food Order Report", visible: false },
    { id: 5, name: "User", visible: false },
    // { id: 6, name: "All Booking ", visible: false },
  ];

  const [selectedReport, setSelectedReport] = useState(1);

  useEffect(() => {
    const FetchaBookedRoomData = async () => {
      try {
        await dispatch(GetAllBookedRoomReport({ Token }));
      } catch (error) {
        return error;
      }
    };

    const FetchaCancelRoomData = async () => {
      try {
        await dispatch(GetAllRoomCancelReport({ Token }));
      } catch (error) {
        return error;
      }
    };

    const FetchaFoodReport = async () => {
      try {
        await dispatch(GetAllFoodReport({ Token }));
      } catch (error) {
        return error;
        // console.error(error);
      }
    };

    const FetchaUserReport = async () => {
      try {
        await dispatch(GetAllUserReport({ Token }));
      } catch (error) {
        return error;
        // console.error(error);
      }
    };

    if (selectedReport === 1) {
      FetchaBookedRoomData();
    } else if (selectedReport === 3) {
      FetchaCancelRoomData();
    } else if (selectedReport === 6) {
      FetchaFoodReport();
    } else if (selectedReport === 5) {
      FetchaUserReport();
    }
  }, [selectedReport, dispatch, Token, selectedReport]);

  const [BookedRoomRecord, setBookedRoomRecord] = useState([]);
  const [CancelRoomRecord, setCancelRoomRecord] = useState([]);
  const [FoodRoomRecord, setFoodRoomRecord] = useState([]);
  const [UserRecord, setUserRecord] = useState([]);

  const datawithSerialNumberBookedRoomReport = useMemo(() => {
    return BookedRoomReport.map((item, index) => ({
      ...item,
      serialNumber: index + 1,
    }));
  }, [BookedRoomReport]);

  useEffect(() => {
    setBookedRoomRecord(datawithSerialNumberBookedRoomReport);
  }, [datawithSerialNumberBookedRoomReport]);

  // Cancel Report Data Handle
  const datawithSerialNumberCancelRoomReport = useMemo(() => {
    return CancelRoomReport.map((item, index) => ({
      ...item,
      serialNumber: index + 1,
    }));
  }, [CancelRoomReport]);

  useEffect(() => {
    setCancelRoomRecord(datawithSerialNumberCancelRoomReport);
  }, [selectedReport, datawithSerialNumberCancelRoomReport]);

  const datawithSerialNumberfoodReport = useMemo(() => {
    return FoodReport.map((item, index) => ({
      ...item,
      serialNumber: index + 1,
    }));
  }, [FoodReport]);

  useEffect(() => {
    setFoodRoomRecord(datawithSerialNumberfoodReport);
  }, [datawithSerialNumberfoodReport, selectedReport]);

  const datawithSerialNumberUserReport = useMemo(() => {
    return UserReport.map((item, index) => ({
      ...item,
      serialNumber: index + 1,
    }));
  }, [UserReport, selectedReport]);

  useEffect(() => {
    setUserRecord(datawithSerialNumberUserReport);
  }, [UserReport, selectedReport]);

  const handleToggleReport = (reportId) => setSelectedReport(reportId);
  const [DataTableColumn, setDataTableColumn] = useState([]);
  const [DataTablerow, setDataTablerow] = useState([]);
  const [filterstatus, setfilterstatus] = useState(true);
  const [userfilterstatus, usersetfilterstatus] = useState(true);

  useEffect(() => {
    // console.log("FFFFFFFFFFFFFFFFFFFFFFFFF",userfilterstatus)
    if (selectedReport === 1) {
      setDataTableColumn(columnsBookedRooms);
      setDataTablerow(BookedRoomRecord);
      // setDataTablerow(rowsBooksRooms);
    }

    if (!userfilterstatus) {
      console.log("user Filter status");
      setDataTableColumn(columnsUser);
      setDataTablerow(UserRecord);
      // setDataTablerow(rowsUser);
    }
  }, [
    selectedReport,
    UserRecord,
    CancelRoomRecord,
    FoodRoomRecord,
    BookedRoomRecord,
    userfilterstatus,
  ]);

  const handleToggleColumn = (field) => {
    setDataTableColumn((prevColumns) =>
      prevColumns.map((col) =>
        col.field === field ? { ...col, visible: !col.visible } : col
      )
    );
  };

  const initialValues = {
    from: null,
    to: null,
  };
  const [buttonClicked, setButtonClicked] = useState(null);

  const { values, setFieldValue, handleBlur, handleSubmit } = useFormik({
    initialValues,
    onSubmit: async (values) => {
      try {
        switch (buttonClicked) {
          case "bookedRoom":
            await dispatch(PostBookedRoomDateAPI({ Token, values }));
            // setfilterstatus(false)
            setfilterstatus(true);
            usersetfilterstatus(true);
            setToastOpen(true);
            setToastMessage(`Searching for Booked Reservation`);
            break;
          case "cancelRoom":
            await dispatch(PostCancelRoomDateAPI({ Token, values }));
            // setfilterstatus(false)
            setfilterstatus(true);
            usersetfilterstatus(true);
            setToastOpen(true);
            setToastMessage(`Searching for Cancelled Reservation`);
            break;
          case "foodOrder":
            await dispatch(POSTFOODORDErREPORT({ Token, values }));
            setfilterstatus(false);
            usersetfilterstatus(true);
            setToastOpen(true);
            setToastMessage(`Searching for Room services`);
            break;

          case "user":
            await dispatch(PostUserReportWithHistory({ Token, values }));
            setfilterstatus(false);
            usersetfilterstatus(true);
            setToastOpen(true);
            setToastMessage(`Searching for Guests`);
            // setfilterstatus(true);
            // usersetfilterstatus(false);
            break;
          default:
            console.log("No button clicked", values);
        }
      } catch (error) {
        return error;
      }
    },
  });

  const handleButtonClick = (buttonType) => {
    setButtonClicked(buttonType);
    handleSubmit();
  };

  return (
    <>
      <Box sx={{ padding: "20px", width: "100%" }}>
        <Stack
          direction={IsMobile ? "row" : "column"}
          justifyContent={"space-between"}
        >
          <Typography variant="h6">Reports</Typography>
          <CollapsedBreadcrumbs data={BreadCrumbLink} />
        </Stack>
        <Grid container>
          <Grid item xs={12}>
            <Paper
              sx={{ padding: "10px", marginTop: "20px", borderRadius: "10px" }}
            >
              <Grid container>
                <Grid
                  item
                  lg={10}
                  md={10}
                  sm={12}
                  xs={12}
                  mt={IsMobile ? 0 : 2}
                >
                  <form
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Stack
                      direction={IsMobile ? "row" : "column"}
                      gap={3}
                      width={"100%"}
                    >
                      <Grid container sx={{ width: "100%" }}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Stack
                            direction={IsMobile ? "row" : "column"}
                            gap={2}
                            width={"100%"}
                            px={2}
                          >
                            <Stack direction={"column"} width={"100%"}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                  format="DD/MM/YYYY hh:mm A"
                                  label="From"
                                  name="from"
                                  sx={{ flex: 1 }}
                                  value={
                                    values.from ? dayjs(values.from) : null
                                  }
                                  onChange={(value) => {
                                    const formattedDate = value
                                      ? dayjs(value).toISOString()
                                      : null;
                                    setFieldValue("from", formattedDate);
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} sx={{ flex: 1 }} />
                                  )}
                                  onBlur={handleBlur}
                                />
                              </LocalizationProvider>
                            </Stack>
                            <Stack direction={"column"} width={"100%"}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                  label="To"
                                  name="to"
                                  format="DD/MM/YYYY hh:mm A"
                                  sx={{ flex: 1 }}
                                  value={values.to ? dayjs(values.to) : null}
                                  onChange={(value) => {
                                    const formattedDate = value
                                      ? dayjs(value).toISOString()
                                      : null;
                                    setFieldValue("to", formattedDate);
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} sx={{ flex: 1 }} />
                                  )}
                                  onBlur={handleBlur}
                                />
                              </LocalizationProvider>
                            </Stack>
                          </Stack>
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          md={6}
                          sm={12}
                          xs={12}
                          mt={IsMobile ? 0 : 2}
                        >
                          {values.from && values.to && (
                            <Stack
                              direction={IsMobile ? "row" : "column"}
                              sx={{ width: "100%" }}
                              gap={2}
                              px={2}
                            >
                              <Button
                                variant="contained"
                                size="small"
                                sx={{ height: "50px" }}
                                color="secondary"
                                onClick={() => handleButtonClick("bookedRoom")}
                              >
                                Booked Room
                              </Button>
                              <Button
                                variant="contained"
                                size="small"
                                sx={{ height: "50px" }}
                                color="error"
                                onClick={() => handleButtonClick("cancelRoom")}
                              >
                                Cancel Room
                              </Button>
                              <Button
                                variant="contained"
                                size="small"
                                sx={{ height: "50px" }}
                                color="success"
                                onClick={() => handleButtonClick("foodOrder")}
                              >
                                Room Service
                              </Button>
                              <Button
                                variant="contained"
                                size="small"
                                sx={{
                                  height: "50px",
                                  backgroundColor: "#82625e",
                                  "&:hover": {
                                    backgroundColor: "#5f4845",
                                  },
                                }}
                                onClick={() => handleButtonClick("user")}
                              >
                                Guest
                              </Button>
                            </Stack>
                          )}
                        </Grid>
                      </Grid>
                    </Stack>
                  </form>
                </Grid>

                <Grid item lg={2} md={2} sm={12} xs={12} mt={IsMobile ? 0 : 2}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* <Avatar sx={{ background: "#EB554A" }}>
                        <Tooltip title="Report Filteration">
                          <IconButton onClick={HandleReportSwipeOpen}>
                            <AssessmentIcon sx={{ color: "#fff" }} />
                          </IconButton>
                        </Tooltip>
                      </Avatar> */}

                    <Avatar sx={{ background: "#6970E2" }}>
                      <Tooltip title="Table Edit">
                        <IconButton onClick={HandleSwipeOpen}>
                          <EditRoadOutlinedIcon sx={{ color: "#fff" }} />
                        </IconButton>
                      </Tooltip>
                    </Avatar>

                    <Avatar
                      src={ExcellIcon}
                      onClick={() => {
                        handleExcelConversion(DataTablerow);
                      }}
                    ></Avatar>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Box sx={{ marginTop: "40px", paddingLeft: "10px" }}>
          <Grid container spacing={3}>
            <Grid item lg={12} xs={12} mt={-3}>
              <Paper sx={{ overflow: "auto" }}>
                {buttonClicked === "foodOrder" ? (
                  <>
                    <DropDownDataTable data={DataTablerow} />
                  </>
                ) : buttonClicked === "user" ? (
                  <>
                    <GuestDropDownTable
                      data={UserRecord}
                      column={columnsUser}
                    />
                  </>
                ) : (
                  <>
                    <DataTable columns={DataTableColumn} rows={DataTablerow} />
                  </>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Box>

        {/* {BillingModelStatus && (
          <SlectBilling
            open={BillingModelStatus}
            handleClose={handleBillingModelClose}
          />
        )} */}

        <SwipableDrawer
          open={Swipeopen}
          close={HandleSwipeClose}
          columns={DataTableColumn}
          handleToggleColumn={handleToggleColumn}
        />

        <Reportsfilter
          open={ReportSwipeopen}
          close={HandleReportSwipeClose}
          columns={ReportColumns}
          handleToggleReport={handleToggleReport}
          selectedReport={selectedReport}
        />

        <CustomSnackBar
          open={toastOpen}
          setOpen={setToastOpen}
          message={toastMessage}
        />
      </Box>
    </>
  );
};
export default Reports;

import { createSlice } from "@reduxjs/toolkit";

const DrawerSlice=createSlice({
    name:"DrawerOpenAndClose",
    initialState:false,
    status:null,
    reducers:{
        DrawerToggle: (state, action) => {
            state.status=action.payload.status
          },
       
    }
})
export default DrawerSlice.reducer
export const {DrawerToggle}=DrawerSlice.actions
import React, { useEffect, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Box,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  Tooltip,
  IconButton,
  Button,
  Avatar,
  useMediaQuery,
} from "@mui/material";
import DataTable from "../../../DataTable";
import "react-lazy-load-image-component/src/effects/blur.css";
import CollapsedBreadcrumbs from "../../Include/Breadcrumb";
import ExcellIcon from "../../../../Assets/img/Exellicon.png";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterMenu from "../Rooms/FilterMenu";
import EditRoadOutlinedIcon from "@mui/icons-material/EditRoadOutlined";
import SwipableDrawer from "../../Include/SwipableDrawer";
import handleExcelConversion from "../Reports/ExcellsFormat";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Cookies from "js-cookie";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import UploadIcon from "@mui/icons-material/Upload";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import AddCardIcon from "@mui/icons-material/AddCard";
import BusinessIcon from "@mui/icons-material/Business";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import dayjs from "dayjs";
import handleFilter from "../../../Searching";
import {
  GetAllBookingRecord,
  GetAllBookingRecordPOST,
} from "../../../../store/Slice/BookingRecordSlice/BookingRecordSlice";
import { MySwal } from "../../../../Model/Alert/SuccessAlert";
import { CancelBookApi } from "../../../../store/Slice/Booking/BookingSlice";
import { CustomSnackBar, EditBookingModel } from "../../../../Model/Model";
import { bookingAvailability_Schema } from "../../../../schema/Schema";
import BookingDropDownView from "./BookingDropDownView";
import {
  GstRoomInvoicePayment_Status,
  OrderInvoicePayment_Status,
} from "../../../../store/Slice/BillingSlice/BillingSlice";
import BasicTabs from "../Rooms/Details/Room_User_Detail";

const BreadCrumbLink = [
  { id: 1, name: "Home", link: "/" },
  { id: 1, name: "Booking Record", link: "/booking-record" },
];

const Bookingrecord = () => {
  const Token = Cookies.get("Token");
  const IsMobile = useMediaQuery("(min-width:900px)");
  const dispatch = useDispatch();
  const { BookingRecordData } = useSelector(
    (state) => state.BookingrecordStore
  );
  const [handlegridrow, sethandlegridrow] = useState(null);
  const [filterMenuOPen, setfilterMenuOPen] = useState(null);
  const [filterid, setfilterid] = useState(1);
  const [AllRoomRecord, setAllRoomRecord] = useState([]);
  const [toastOpen, setToastOpen] = useState(false);

  useEffect(() => {
    const FetchData = async () => {
      try {
        await dispatch(GetAllBookingRecord({ Token }));
      } catch (error) {
        return error;
      }
    };
    FetchData();
  }, [dispatch, Token]);

  const datawithSerialNumber = useMemo(() => {
    if (BookingRecordData && BookingRecordData.length > 0) {
      return BookingRecordData.map((item, index) => ({
        ...item,
        serialNumber: index + 1,
      }));
    } else {
      return [];
    }
  }, [BookingRecordData]);

  useEffect(() => {
    setAllRoomRecord(datawithSerialNumber);
  }, [datawithSerialNumber]);
  const [ExcelCovertData, setExcelCovertData] = useState([]);
  useEffect(() => {
    if (AllRoomRecord) {
      const excelldata = AllRoomRecord.map(
        ({
          id,
          user_id,
          room_id,
          created_at,
          updated_at,
          invoice_num,
          serialNumber,
          guestmaster,
          roommaster,
          ...rest
        }) => {
          return { serialNumber, ...guestmaster, ...roommaster, ...rest };
        }
      );
      setExcelCovertData(excelldata);
    }
  }, [AllRoomRecord]);

  // %%%%%%%%%%%%%%% AVIALABLE ROOM API HANDLE DATA %%%%%%%%%%%%%%

  const [EditBookingData, setEditBookingData] = useState([]);
  const [EditModelOpen, setEditModelOpen] = useState(false);
  const handleEditModelOpen = (data) => {
    setEditModelOpen(true);
    setEditBookingData(data);
  };

  const handleEditModelClose = () => {
    setEditModelOpen(false);
  };

  const HandleBookingCancel = async (values) => {
    try {
      // console.log("Bookekksjds",values)
      const { id } = values;
      MySwal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Cancel it!",
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#dc3545",
        cancelButtonText: "No!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await dispatch(CancelBookApi({ Token, id }));
          await dispatch(GetAllBookingRecord({ Token }));
        } else if (result.dismiss === MySwal.DismissReason.cancel) {
          MySwal.fire({
            title: "Cancelled",
            text: "Your data is safe.",
            icon: "error",
            confirmButtonText: "OK",
            confirmButtonColor: "#dc3545",
          });
        }
      });
      // let data = filterDate;
    } catch (error) {
      return error;
    }
  };

  const HandleBookingStatusPaid = async (values) => {
    try {
      // console.log("Bookekksjds",values)
      const { id } = values;
      MySwal.fire({
        title: "Do you want to save the changes?",
        text: "Once you generate the bill, payment status will be paid!",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "With GST",
        confirmButtonColor: "#28a745",
        denyButtonColor: "#dc3545",
        denyButtonText: `Without GST`,
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        let value = {
          gst_status: null,
        };
        if (result.isConfirmed) {
          // with gst
          value.gst_status = true;
          await dispatch(GstRoomInvoicePayment_Status({ Token, value, id }));
          await dispatch(GetAllBookingRecord({ Token }));
        } else if (result.isDenied) {
          // without gst
          value.gst_status = false;
          await dispatch(GstRoomInvoicePayment_Status({ Token, value, id }));
          await dispatch(GetAllBookingRecord({ Token }));
        }
      });
    } catch (error) {
      return error;
    }
  };

  const HandleRoomServiceStatusPaid = async (values) => {
    try {
      // console.log("Bookekksjds",values)
      const { id } = values;
      MySwal.fire({
        title: "Are you sure?",
        text: "Once you generate the bill, payment status will be paid!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, generate it!",
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#dc3545",
        cancelButtonText: "No, cancel!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await dispatch(OrderInvoicePayment_Status({ Token, id }));
          await dispatch(GetAllBookingRecord({ Token }));
        } else if (result.dismiss === MySwal.DismissReason.cancel) {
          MySwal.fire({
            title: "Cancelled",
            text: "Your data is safe.",
            icon: "error",
            confirmButtonText: "OK",
            confirmButtonColor: "#dc3545",
          });
        }
      });
    } catch (error) {
      return error;
    }
  };

  const columnsRooms = useMemo(
    () => [
      {
        field: "#",
        headerName: "#",
        width: "20px",
        headerAlign: "center",
        align: "center",
        visible: true,
      },
      {
        field: "serialNumber",
        headerName: "SR.No",
        width: "40px",
        headerAlign: "center",
        align: "center",
        visible: true,
      },

      {
        field: "Action",
        headerName: "Action",
        width: "110px",
        headerAlign: "center",
        align: "center",
        visible: true,
        renderCell: (param) => (
          <div>
            {param.row?.status === "BOOKED" && (
              <>
                <IconButton
                  onClick={() => {
                    handleEditModelOpen(param.row);
                  }}
                >
                  <BorderColorOutlinedIcon sx={{ color: "#6d75eb" }} />
                </IconButton>
                {!param.row?.arrival_time && (
                  <Button
                    onClick={() => {
                      HandleBookingCancel(param.row);
                    }}
                    variant="outlined"
                    size="small"
                    color="error"
                  >
                    Cancel Book
                  </Button>
                )}
              </>
            )}
          </div>
        ),
      },

      {
        field: "invoice_num",
        headerName: "Invoice No",
        width: "75px",
        headerAlign: "center",
        align: "center",
        visible: true,
        renderCell: (param) => {
          return (
            <NavLink
              to={`/room-invoice/${param.row?.id}`}
              // style={{ textDecoration: "none", color: "#333" }}
            >
              <div>{param.row?.invoice_num}</div>
            </NavLink>
          );
        },
      },

      {
        field: "booking_date",
        headerName: "Booking Date",
        visible: true,
        textAlign: "center",
        headerAlign: "center",
        width: "95px",
        renderCell: (param) => (
          // <div>{new Date(param.row.check_in).toLocaleDateString()}</div>
          <div>
            {new Intl.DateTimeFormat("en-GB", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            }).format(new Date(param.row?.booking_date))}
          </div>
        ),
      },

      {
        field: "fullname",
        headerName: "Name",
        width: "150px",
        headerAlign: "center",
        align: "center",
        visible: true,
        renderCell: (param) => {
          const name =
            param.row &&
            param.row?.guestmaster &&
            param.row?.guestmaster?.fullname
              ? param.row?.guestmaster?.fullname
              : "";
          return (
            <NavLink to={`/room-invoice/${param.row?.id}`}>
              <div>{name}</div>
            </NavLink>
          );
        },
      },

      {
        field: "Number",
        headerName: "Number",
        width: "125px",
        headerAlign: "center",
        align: "center",
        visible: true,
        renderCell: (param) => {
          const PNumber =
            param.row &&
            param.row?.guestmaster &&
            param.row?.guestmaster?.phone_number
              ? param.row?.guestmaster?.phone_number
              : "";
          return <div variant="h6"> {PNumber} </div>;
        },
      },

      {
        field: "address",
        headerName: "City",
        width: "90px",
        headerAlign: "center",
        align: "center",
        visible: true,
        renderCell: (param) => {
          const city =
            param.row &&
            param.row?.guestmaster &&
            param.row?.guestmaster?.address
              ? param.row?.guestmaster?.address
              : "";
          return <div variant="h6"> {city} </div>;
        },
      },

      {
        field: "status",
        headerName: "Status",
        width: "90px",
        headerAlign: "center",
        align: "center",
        visible: true,
        renderCell: (param) => (
          <div>
            {param.row?.status === "BOOKED" && (
              <Button variant="outlined" size="small" color="success">
                {param.row?.status}
              </Button>
            )}
            {param.row?.status === "COMPLETED" && (
              <Button variant="outlined" size="small" color="warning">
                {param.row?.status}
              </Button>
            )}
            {param.row?.status === "CANCELLED" && (
              <Button variant="outlined" size="small" color="error">
                {param.row?.status}
              </Button>
            )}
          </div>
        ),
      },

      {
        field: "remaining_amount",
        headerName: "Remaining",
        width: "70px",
        headerAlign: "center",
        align: "center",
        visible: true,
      },

      {
        field: "payment_status",
        headerName: "Payment Status",
        width: "110px",
        headerAlign: "center",
        align: "center",
        visible: true,
        renderCell: (param) => (
          <div>
            {param.row?.payment_status === "PAID" && (
              <Button variant="outlined" size="small" color="success">
                {param.row?.payment_status}
              </Button>
            )}
            {param.row?.payment_status === "PARTIAL" && (
              <Button variant="outlined" size="small" color="info">
                {param.row.payment_status}
              </Button>
            )}

            {param.row?.payment_status === "UNPAID" && (
              <Button variant="outlined" color="error" size="small">
                {param.row?.payment_status}
              </Button>
            )}
          </div>
        ),
      },

      {
        field: "Room Number",
        headerName: "Room No",
        width: "65px",
        headerAlign: "center",
        align: "center",
        visible: true,
        renderCell: (param) => {
          const RNumber =
            param.row && param.row?.roommaster && param.row?.roommaster?.room_no
              ? param.row?.roommaster?.room_no
              : "";
          return <div variant="h6"> {RNumber} </div>;
        },
      },

      {
        field: "person",
        headerName: "Person",
        width: "125px",
        headerAlign: "center",
        align: "center",
        visible: true,
      },

      {
        field: "check_in",
        headerName: "Check In",
        visible: true,
        width: "85px",
        headerAlign: "center",
        align: "center",
        renderCell: (param) => (
          // <div>{new Date(param.row.check_in).toLocaleDateString()}</div>
          <div>
            {new Intl.DateTimeFormat("en-GB", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            }).format(new Date(param.row?.check_in))}
          </div>
        ),
      },

      {
        field: "arrival_time",
        headerName: "Arrival Time",
        visible: true,
        width: "85px",
        headerAlign: "center",
        align: "center",
        renderCell: (param) => (
          <div>
            {param.row?.arrival_time
              ? new Intl.DateTimeFormat("en-GB", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                }).format(new Date(param.row?.arrival_time))
              : null}
          </div>
        ),
      },

      {
        field: "check_out",
        headerName: "Check Out",
        visible: true,
        width: "85px",
        headerAlign: "center",
        align: "center",
        renderCell: (param) => (
          <div>
            {new Intl.DateTimeFormat("en-GB", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            }).format(new Date(param.row?.check_out))}
          </div>
        ),
      },

      {
        field: "total_days",
        headerName: "Total  Days",
        width: "125px",
        headerAlign: "center",
        align: "center",
        visible: true,
      },

      {
        field: "taxable_price",
        headerName: "Price",
        width: "125px",
        headerAlign: "center",
        align: "center",
        visible: true,
      },

      {
        field: "cgst",
        headerName: "CGST",
        width: "125px",
        headerAlign: "center",
        align: "center",
        visible: true,
      },

      {
        field: "sgst",
        headerName: "SGST",
        width: "125px",
        headerAlign: "center",
        align: "center",
        visible: true,
      },

      {
        field: "igst",
        headerName: "IGST",
        width: "125px",
        headerAlign: "center",
        align: "center",
        visible: true,
      },

      {
        field: "discount",
        headerName: "Discount",
        width: "60px",
        headerAlign: "center",
        align: "center",
        visible: true,
      },

      {
        field: "total_price",
        headerName: "Total Price",
        width: "125px",
        headerAlign: "center",
        align: "center",
        visible: true,
      },

      {
        field: "adv_payment",
        headerName: "Advance Payment",
        width: "125px",
        headerAlign: "center",
        align: "center",
        visible: true,
      },

      {
        field: "payment_type",
        headerName: "Payment Type",
        width: "125px",
        headerAlign: "center",
        align: "center",
        visible: true,
      },

      {
        field: "cancel_date",
        headerName: "Cancel Date",
        width: "85px",
        headerAlign: "center",
        align: "center",
        visible: true,
        renderCell: (param) => {
          const cancelDate = param.row?.cancel_date;
          return (
            <div>
              {cancelDate
                ? new Intl.DateTimeFormat("en-GB", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                  }).format(new Date(cancelDate))
                : "No Cancel Date"}
            </div>
          );
        },
      },
    ],
    []
  );

  const [DataTableColumn, setDataTableColumn] = useState([]);
  const [DataTablerow, setDataTablerow] = useState([]);

  useEffect(() => {
    if (filterid === 1) {
      // AvailabelRoom()
      const AvialableData = AllRoomRecord;
      setDataTableColumn(columnsRooms);
      setDataTablerow(AvialableData);
    } else if (filterid === 2) {
      // AvailabelRoom()
      const AvialableData = AllRoomRecord.filter(
        (room) => room.status === "BOOKED"
      );
      setDataTableColumn(columnsRooms);
      setDataTablerow(AvialableData);
    } else if (filterid === 3) {
      const BookedRoom = AllRoomRecord.filter(
        (room) => room.status === "CANCELLED"
      );
      setDataTableColumn(columnsRooms);
      setDataTablerow(BookedRoom);
    } else if (filterid === 4) {
      const BookedRoom = AllRoomRecord.filter(
        (room) => room.payment_status === "PAID"
      );
      console.log("%%%%%%%%%%", DataTablerow);
      setDataTableColumn(columnsRooms);
      setDataTablerow(BookedRoom);
    } else if (filterid === 5) {
      const BookedRoom = AllRoomRecord.filter(
        (room) => room.payment_status === "UNPAID"
      );
      setDataTableColumn(columnsRooms);
      setDataTablerow(BookedRoom);
    }
  }, [filterid, AllRoomRecord]);

  const handleToggleColumn = (field) => {
    // console.log("DataTableColumn", field);
    setDataTableColumn((prevColumns) =>
      prevColumns.map((col) =>
        col.field === field ? { ...col, visible: !col.visible } : col
      )
    );
  };

  // const handleRowClick = () =>  sethandlegridrow((previous) => !previous);

  // Filter
  const FilterDropMenu = [
    { id: 1, name: "All" },
    { id: 2, name: "Booked" },
    { id: 3, name: "Cancel" },
    { id: 4, name: "Paid" },
    { id: 5, name: "Un-Paid" },
  ];

  const handleFilterMenuOpen = (event) =>
    setfilterMenuOPen(event.currentTarget);
  const handleFilterMenuClose = () => setfilterMenuOPen(null);

  const [DataTableDrawer, setDataTableDrawer] = useState(false);
  const handleDrawerOpen = () => setDataTableDrawer(true);
  const handleDrawerClose = () => setDataTableDrawer(false);

  // const [CardTabView, setCardTabView] = useState(true);
  // const HandleViwerCard = () => setCardTabView(true);
  // const HandleViwerTab = () => setCardTabView(false);

  const initialValues = {
    check_in: null,
    check_out: null,
  };

  const { errors, values, handleBlur, handleSubmit, touched, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema: bookingAvailability_Schema,
      onSubmit: async (values) => {
        try {
          const data = {
            check_in: values.check_in,
            check_out: values.check_out,
          };

          await dispatch(GetAllBookingRecordPOST({ Token, data }));
          setToastOpen(true);
          console.log("values", data);
        } catch (error) {
          console.log("erroro", error);

          return error;
        }
      },
    });

  // const [UserData, setUserData] = useState({});
  // const [Roomdata, setRoomdata] = useState({});
  // const handleRowClick = (userdata) => {
  //   const {
  //     fullname,
  //     email,
  //     phone_number,
  //     address,
  //     document,
  //     created_at,
  //     document_images,
  //     persons,
  //   } = userdata;
  //   setUserData({
  //     Name: fullname,
  //     Email: email,
  //     "Mobile No": phone_number,
  //     Address: address,
  //     Documents: document,
  //     Image: images1,
  //     Document: document,
  //     "Document Images": document_images,
  //     Persons: persons,
  //     "Created Date": new Date(created_at).toDateString(),
  //   });
  //   sethandlegridrow((previos) => !previos);
  // };

  // const [otherdispalynone, setotherdispalynone] = useState(false);
  // const TabClose = () => {
  //   setotherdispalynone((previous) => !previous);
  //   sethandlegridrow((previos) => !previos);
  // };

  const [UserData, setUserData] = useState({});
  const handleRowClick = (userdata) => {
    const { extra_person_doc, guestmaster, ...rest } = userdata;
    setUserData({
      Name: guestmaster?.fullname,
      Email: guestmaster?.email,
      "Mobile No": guestmaster?.phone_number,
      Address: guestmaster?.address,
      Documents: guestmaster?.document,
      "Created Date": new Date(guestmaster?.created_at).toDateString(),
      document_images: guestmaster?.document_images,
      extra_person_doc: extra_person_doc,
    });

    sethandlegridrow((previos) => !previos);
  };

  const icons = [
    <AutorenewIcon />,
    <PersonOutlineIcon />,
    <MailOutlineIcon />,
    <LocalPhoneIcon />,
    <AddCardIcon />,
    <AddCardIcon />,
    <AddCardIcon />,
    <BusinessIcon />,
  ];

  const keys_serach = [
    "serialNumber",
    "room_no",
    "invoice_no",
    "floor_no",
    "total_days",
    "taxable_price",
    "sgst",
    "cgst",
    "igst",
    "total_price",
    "adv_payment",
    "payment_status",
    "payment_type",
    "status",
    "person",
    "guestmaster.fullname",
    "guestmaster.phone_number",
    "guestmaster.email",
  ];

  return (
    <>
      <Box sx={{ padding: "20px", width: "100%" }}>
        <Stack
          direction={IsMobile ? "row" : "column"}
          justifyContent={"space-between"}
        >
          <Typography variant="h6">Booking record</Typography>
          <CollapsedBreadcrumbs data={BreadCrumbLink} />
        </Stack>
        <Grid container>
          <Grid item xs={12}>
            <Paper
              sx={{ padding: "10px", marginTop: "20px", borderRadius: "10px" }}
            >
              <Grid container>
                <Grid item lg={3} md={3} sm={3} xs={12} mt={IsMobile ? 0 : 2}>
                  <Box
                    sx={{
                      width: { xs: "100%", sm: "" },
                    }}
                  >
                    <TextField
                      size="small"
                      placeholder="Search"
                      onChange={(event) => {
                        handleFilter({
                          event,
                          dataWithSerialNumber: datawithSerialNumber,
                          setrecord: setAllRoomRecord,
                          keys_serach,
                        });
                      }}
                      sx={{
                        textAlign: "center",
                        height: "50px",
                        bgcolor: "#dae1f3",
                        borderRadius: "5px",
                        width: { xs: "100%", sm: "230px" },
                        "& fieldset": { border: "none" },
                        "& input::placeholder": {
                          textAlign: "left",
                          display: "block",
                          width: "100%",
                        },
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item lg={7} md={6} sm={6} xs={12} mt={IsMobile ? 0 : 2}>
                  <Box
                    sx={{
                      width: { xs: "100%", sm: "100%" },
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <form
                      onSubmit={handleSubmit}
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Stack
                        direction={IsMobile ? "row" : "column"}
                        gap={3}
                        width={"100%"}
                      >
                        <Stack direction={"column"} width={"100%"}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                              label="Check In"
                              format="DD/MM/YYYY hh:mm A"
                              name="check_in"
                              sx={{ flex: 1 }}
                              value={
                                values.check_in ? dayjs(values.check_in) : null
                              }
                              onChange={(value) => {
                                const formattedDate = value
                                  ? dayjs(value).toISOString()
                                  : null;
                                setFieldValue("check_in", formattedDate);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} sx={{ flex: 1 }} />
                              )}
                              onBlur={handleBlur}
                            />
                            {touched.check_in && errors.check_in && (
                              <Typography
                                variant="caption"
                                color={"error"}
                                style={{
                                  marginTop: "3px",
                                  marginLeft: "14px",
                                  marginRight: "14px",
                                  marginBottom: "0px",
                                }}
                              >
                                {errors.check_in}
                              </Typography>
                            )}
                          </LocalizationProvider>
                        </Stack>
                        <Stack direction={"column"} width={"100%"}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                              label="Check Out"
                              format="DD/MM/YYYY hh:mm A"
                              name="check_out"
                              value={
                                values.check_out
                                  ? dayjs(values.check_out)
                                  : null
                              }
                              onChange={(value) => {
                                const formattedDate = value
                                  ? dayjs(value).toISOString()
                                  : null;
                                setFieldValue("check_out", formattedDate);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} sx={{ flex: 1 }} />
                              )}
                              onBlur={handleBlur}
                              sx={{ flex: 1 }}
                            />
                            {touched.check_out && errors.check_out && (
                              <Typography
                                variant="caption"
                                color={"error"}
                                style={{
                                  marginTop: "3px",
                                  marginLeft: "14px",
                                  marginRight: "14px",
                                  marginBottom: "0px",
                                }}
                              >
                                {errors.check_out}
                              </Typography>
                            )}
                          </LocalizationProvider>
                        </Stack>
                        <Stack direction={"column"} width={"100%"}>
                          <Button
                            variant="contained"
                            size="small"
                            color="secondary"
                            type="submit"
                            sx={{ height: "35px", mt: 1, mr: 2, ml: 2 }}
                          >
                            Search
                          </Button>
                        </Stack>
                      </Stack>
                    </form>
                  </Box>
                </Grid>

                <Grid item lg={2} md={6} sm={6} xs={12} mt={IsMobile ? 0 : 2}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Avatar sx={{ background: "#6970E2" }}>
                      <Tooltip title="Table Edit">
                        <IconButton onClick={handleDrawerOpen}>
                          <EditRoadOutlinedIcon sx={{ color: "#fff" }} />
                        </IconButton>
                      </Tooltip>
                    </Avatar>

                    <Avatar sx={{ background: "#EA82A6" }}>
                      <Tooltip title="Filters">
                        <IconButton
                          onClick={(event) => {
                            handleFilterMenuOpen(event);
                          }}
                        >
                          <FilterListIcon sx={{ color: "#fff" }} />
                        </IconButton>
                      </Tooltip>
                    </Avatar>

                    <Avatar
                      src={ExcellIcon}
                      onClick={() => {
                        handleExcelConversion(ExcelCovertData);
                      }}
                    ></Avatar>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Box sx={{ marginTop: "20px", paddingLeft: "10px" }}>
          <Grid container spacing={2}>
            {/* {otherdispalynone && (
              <Grid sm={12}>
                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
                >
                  <Button variant="contained" color="error" onClick={TabClose}>
                    Close
                  </Button>
                </Box>
              </Grid>
            )} */}

            <Grid item lg={handlegridrow ? 6 : 12} sm={12} mt={-3} xs={12}>
              <Paper sx={{ marginTop: "22px" }}>
                {/* <DataTable columns={DataTableColumn} rows={DataTablerow} /> */}
                <BookingDropDownView
                  data={DataTablerow}
                  column={columnsRooms}
                  handleEditModelOpen={handleEditModelOpen}
                  HandleBookingCancel={HandleBookingCancel}
                  HandleBookingStatusPaid={HandleBookingStatusPaid}
                  HandleRoomServiceStatusPaid={HandleRoomServiceStatusPaid}
                  handleRowClick={handleRowClick}
                  setUserData={setUserData}
                />
              </Paper>
            </Grid>

            <Grid item lg={handlegridrow ? 6 : 12} xs={handlegridrow || 12}>
              {handlegridrow && (
                <Paper sx={{ marginTop: "22px" }}>
                  <BasicTabs
                    icons={icons}
                    data={UserData}
                    tab={"guest"}
                    sethandlegridrow={sethandlegridrow}
                  />
                </Paper>
              )}
            </Grid>
          </Grid>
        </Box>

        {filterMenuOPen && (
          <FilterMenu
            open={Boolean(filterMenuOPen)}
            anchorEl={filterMenuOPen}
            handleFilterMenuClose={handleFilterMenuClose}
            FilterDropMenu={FilterDropMenu}
            setfilterid={setfilterid}
          />
        )}

        {EditModelOpen && (
          <EditBookingModel
            EditData={EditBookingData}
            open={EditModelOpen}
            handleClose={handleEditModelClose}
          />
        )}

        <SwipableDrawer
          open={DataTableDrawer}
          close={handleDrawerClose}
          columns={DataTableColumn}
          handleToggleColumn={handleToggleColumn}
        />

        <CustomSnackBar
          open={toastOpen}
          setOpen={setToastOpen}
          message={"Search has Initiated"}
        />
      </Box>
    </>
  );
};
export default Bookingrecord;

import { createTheme } from '@mui/material/styles';

const theme=createTheme({
    palette:{
        primary:{
                main: '#252525',
        }
    },

    // 1) Paper
    components:{

        MuiPaper:{
            styleOverrides:{
                root:{
                    
                    backgroundColor: '#FFFFFF',
                    // boxShadow: '1px 0px 20px rgba(0, 0, 0, 0.05)',
                    boxShadow:"1px 0px 20px rgba(0, 0, 0, 0.05)",
                     backdropFilter: 'blur(10px)',
                }
            }
        }
    },


    // 2) textField

    MuiTextField: {
        styleOverrides: {
          
          root: {
            '& .MuiFormHelperText-root': {
              color: '#da3f35',
            },
            '& fieldset': {
              borderColor: '#909090',
              transition: 'border-color 0.3s ease-in-out',
            },
            '& label': {
              color: '#909090',
            },
            '& label.Mui-focused': {
              color: '#252525',
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: 'blue',
            },
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                border:"1px solid #252525",
              },
              '&:hover fieldset': {
                borderColor: 'black',
  
              },
            },
            '& input': {
              color: '#252525',
            },
          },
        },
      },


      MuiButton: {
        styleOverrides: {
          root:{
            '&.MuiPrimary': {
              backgroundColor: 'red', // Change background color
              color: '#ffffff', // Change text color
            },

          }

        }
      },

      

    //   3) MUi Typgrapjy

    MuiTypgraphy:{
        styleOverrides: {
            h5: {
              color: '#2d3b48',
            },
            h6: {
              color: '#2d3b48',
            },
            body1: {
              color: '#2d3b48',
              fontFamily:" 'Roboto', sans-serif",
            },
            span: {
              color: '#2d3b48',
            },
          },
    },
   





  
    















})

export default theme



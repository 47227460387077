import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Box, Paper, IconButton, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import AddCardIcon from "@mui/icons-material/AddCard";
import { NavLink } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
const ProfileDetail = ({ UserData }) => {
  return (
    <Box
      sx={{
        padding: "10px",
      }}
    >
      <Box
        sx={{ width: "100%" }}
        display={"flex"}
        justifyContent={"space-evenly"}
      >
        <Box
          sx={{
            height: "200px",
            width: " 170px",
            borderRadius: "16px",
            boxShadow: "0px 15px 45px rgba(9, 0, 0, 0.1)",
            ml: { xs: 3, sm: 12 },
          }}
        >
          <LazyLoadImage
            effect="blur"
            src={
              UserData?.image
                ? `${process.env.REACT_APP_API_URL}/uploads/guests/${UserData?.image}`
                : null
            }
            style={{
              height: "100%",
              width: "100%",
              borderRadius: "16px",
              border: "1px solid #7b7b7cbb",
            }}
          />
        </Box>

        <Box sx={{ mr: { xs: -2, sm: -5 } }}>
          <Tooltip title="Close">
            <NavLink to={"/"}>
              <IconButton>
                <AddIcon
                  sx={{
                    transform: "rotate(120deg)",
                    transition: "0.5s",
                    "&:hover": {
                      transform: "rotate(360deg)",
                    },
                  }}
                />
              </IconButton>
            </NavLink>
          </Tooltip>
        </Box>
      </Box>

      <List sx={{ marginTop: "20px" }}>
        <Paper
          sx={{
            padding: "10px",
            marginBottom: "10px",
            boxShadow: "0px 20px 09px rgba(12, 0, 0, 0.1)",
          }}
        >
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <PersonOutlineIcon />
              </ListItemIcon>
              <ListItemText primary={"Name"} secondary={UserData.fullname} />
            </ListItemButton>
          </ListItem>
        </Paper>

        <Paper
          sx={{
            padding: "10px",
            marginBottom: "10px",
            boxShadow: "0px 20px 09px rgba(12, 0, 0, 0.1)",
          }}
        >
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <MailOutlineIcon />
              </ListItemIcon>
              <ListItemText primary={"Email"} secondary={UserData.email} />
            </ListItemButton>
          </ListItem>
        </Paper>

        <Paper
          sx={{
            padding: "10px",
            marginBottom: "10px",
            boxShadow: "0px 20px 09px rgba(12, 0, 0, 0.1)",
          }}
        >
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <LocalPhoneIcon />
              </ListItemIcon>
              <ListItemText
                primary={"Mobile No"}
                secondary={UserData.phone_number}
              />
            </ListItemButton>
          </ListItem>
        </Paper>

        <Paper
          sx={{
            padding: "10px",
            marginBottom: "10px",
            boxShadow: "0px 20px 09px rgba(12, 0, 0, 0.1)",
          }}
        >
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <AddCardIcon />
              </ListItemIcon>
              <ListItemText primary={"Address"} secondary={UserData.address} />
            </ListItemButton>
          </ListItem>
        </Paper>
      </List>
    </Box>
  );
};

export default ProfileDetail;

import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Stack,
  Grid,
  Divider,
  Button,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CollapsedBreadcrumbs from "../../Include/Breadcrumb";
import { LazyLoadImage } from "react-lazy-load-image-component";
import logo from "../../../../Assets/img/gotle.webp";
import { useReactToPrint } from "react-to-print";
import Cookies from "js-cookie";
import { useSelector, useDispatch } from "react-redux";
import "./print.css";
import { useParams } from "react-router-dom";
import { toWords } from "number-to-words";
import "./print.css";
import {
  BulkInvoiceApi,
  RoomInvoiceComplete_Status,
} from "../../../../store/Slice/BillingSlice/BillingSlice";
import PrintTwoToneIcon from "@mui/icons-material/PrintTwoTone";
import { MySwal } from "../../../../Model/Alert/SuccessAlert";
const BreadCrumbLink = [
  {
    id: 1,
    name: "Home",
    link: "/",
  },
  {
    id: 2,
    name: "Billing",
    link: "/billing",
  },
  {
    id: 3,
    name: "Both-Invoice",
    link: "both-invoice",
  },
];
const BothInvoice = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const Token = Cookies.get("Token");
  const [record, setrecord] = useState([]);
  const [reservationmaster, setreservationmaster] = useState({});
  const [roommaster, setroommaster] = useState({});
  const { BulkData } = useSelector((state) => state.BillingStore);

  useEffect(() => {
    const FetchData = async () => {
      try {
        await dispatch(BulkInvoiceApi({ Token, id }));
      } catch (error) {
        return error;
      }
    };
    FetchData();
  }, [Token, dispatch]);

  useEffect(() => {
    BulkData ? setrecord(BulkData) : setrecord([]);
  }, [BulkData]);

  useEffect(() => {
    if (record && record.reservationmaster) {
      let reserveData = record.reservationmaster
        ? record.reservationmaster[0]
        : {};
      setreservationmaster(reserveData);
    }
    if (record && record.roommaster) {
      let reserveData = record.roommaster ? record.roommaster[0] : {};
      setroommaster(reserveData);
    }
  }, [record]);

  // console.log("%%%%%%%%%%%",record)

  const billingRef = useRef();
  const handleBiilingPrint = useReactToPrint({
    content: () => billingRef.current,
    documentTitle: "Both Invoice",
  });
  
  const handleCheckoutPrint = () => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, check out!",
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#dc3545",
      cancelButtonText: "No, cancel!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await dispatch(RoomInvoiceComplete_Status({ Token, id }));
        // await dispatch(GetExpenseApi({ Token }));
        handleBiilingPrint();
      } else if (result.dismiss === MySwal.DismissReason.cancel) {
        MySwal.fire({
          title: "Cancelled",
          text: "Your data is safe.",
          icon: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "#dc3545",
        });
      }
    });
  };

  return (
    <Box sx={{ padding: "20px", width: "100%" }}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography variant="h6">Both Invoice</Typography>
        <CollapsedBreadcrumbs data={BreadCrumbLink} />
      </Stack>

      <Paper
        sx={{ mt: 2, marginX: "11rem", p: 2 }}
        elevation={24}
        id="paper-section"
      >
        <Paper ref={billingRef} sx={{ m: 5, border: "1px solid black", p: 2 }}>
          <Grid container>
            <Grid item sm={12} justifyContent={"center"}>
              <Box textAlign={"center"}>
                <LazyLoadImage
                  src={logo}
                  effect="blur"
                  height={"100px"}
                  width={"120px"}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                textAlign={"center"}
                sx={{ fontWeight: "700" }}
              >
                RASPBERRY BREEZE
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "400" }}
                textAlign={"center"}
              >
                SURVEY NO 23/2, OPP JAZIRA HOTEL DEVKA ROAD, DEVKA VILLAGE,
                DADRA NAGAR HAVVEL, DAMAN AND DIU, 396210 INDIA.
              </Typography>
            </Grid>
          </Grid>
          <Divider item xs={12} sx={{ marginTop: 2 }} />
          <Grid container item xs={12} sx={{ mt: 1 }}>
            <Grid container item xs={12}>
              <Grid item xs={12} justifyContent={"center"}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "400" }}
                  textAlign={"center"}
                >
                  <span style={{ fontWeight: "500" }}>Phone No : </span>
                  +91 987654210
                  <span style={{ fontWeight: "500" }}> | Email : </span>
                  Demo123@gmail.com
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "400" }}
                  textAlign={"center"}
                >
                  GSTIN :{" "}
                  <span style={{ textDecoration: "underline" }}>
                    24AAZFP0486C1Z0
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Divider sx={{ marginTop: 2 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} mt={2}>
              <Typography
                variant="h4"
                color={"secondary"}
                textAlign={"center"}
                sx={{ fontWeight: "700" }}
              >
                Tax Invoice
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ marginTop: 2 }} />

          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={10}>
              <Stack direction={"column"}>
                <Typography variant="subtitle2" mr={4}>
                  Bill To
                </Typography>
                {record ? (
                  <>
                    <Typography variant="body2" mr={4}>
                      Name: {record?.fullname ? record?.fullname : ""}
                    </Typography>
                    <Typography variant="body2">
                      Mobile No: {record?.phone_number || ""}
                    </Typography>
                    <Typography variant="body2">
                      E-mail: {record?.email || ""}
                    </Typography>
                    <Typography variant="body2">
                      Check In:{" "}
                      {reservationmaster?.check_in
                        ? new Date(
                            reservationmaster?.check_in
                          ).toLocaleDateString()
                        : ""}
                    </Typography>
                    <Typography variant="body2">
                      Check Out:{" "}
                      {reservationmaster?.check_out
                        ? new Date(
                            reservationmaster?.check_out
                          ).toLocaleDateString()
                        : ""}
                    </Typography>

                    <Typography variant="body2">
                      Room No :{" "}
                      {reservationmaster?.roommaster
                        ? reservationmaster?.roommaster?.room_no
                        : ""}
                    </Typography>
                  </>
                ) : (
                  <Typography variant="body2">
                    Guest information loading...
                  </Typography>
                )}
              </Stack>
            </Grid>
            <Grid item xs={2}>
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "end",
                  }}
                >
                  {record ? (
                    <>
                      <Typography variant="body2" mr={4}>
                        Invoice No: {record?.invoice_num || ""}
                      </Typography>
                      <Typography variant="body2">
                        Date:
                        {reservationmaster?.booking_date
                          ? new Date(
                              reservationmaster?.booking_date
                            ).toLocaleDateString()
                          : ""}
                      </Typography>
                      <Typography variant="body2">
                        Room No : {roommaster?.room_no}
                      </Typography>
                      <Typography variant="body2">
                        Room Payment Status : {roommaster?.payment_status}
                      </Typography>
                    </>
                  ) : (
                    <Typography variant="body2">
                      Reservation information loading...
                    </Typography>
                  )}
                </Box>
              </Box>
            </Grid>

            <Grid item sm={12}>
              <Stack mt={5}>
                <Grid container mb={2}>
                  <Grid item xs={12}>
                    {/* <Box height={"100%"}> */}
                    <TableContainer component={Box} height={"100%"}>
                      <Table>
                        <TableHead className="theader">
                          <TableRow>
                            <TableCell
                              scope="col"
                              style={{
                                border: "1px solid black",
                                fontWeight: 400,
                              }}
                            >
                              <Typography
                                variant="subtitle"
                                sx={{ fontWeight: 400 }}
                              >
                                Invoice No
                              </Typography>
                            </TableCell>
                            <TableCell
                              scope="col"
                              style={{
                                border: "1px solid black",
                                fontWeight: 400,
                              }}
                            >
                              <Typography
                                variant="subtitle"
                                sx={{ fontWeight: 400 }}
                              >
                                Order Date
                              </Typography>
                            </TableCell>
                            <TableCell
                              scope="col"
                              style={{
                                border: "1px solid black",
                                fontWeight: 400,
                              }}
                            >
                              <Typography
                                variant="subtitle"
                                sx={{ fontWeight: 400 }}
                              >
                                Sub Total
                              </Typography>
                            </TableCell>

                            <TableCell
                              scope="col"
                              style={{
                                border: "1px solid black",
                                fontWeight: 400,
                              }}
                            >
                              <Typography
                                variant="subtitle"
                                sx={{ fontWeight: 400 }}
                              >
                                CGST
                              </Typography>
                            </TableCell>

                            <TableCell
                              scope="col"
                              style={{
                                border: "1px solid black",
                                fontWeight: 400,
                              }}
                            >
                              <Typography
                                variant="subtitle"
                                sx={{ fontWeight: 400 }}
                              >
                                SGST
                              </Typography>
                            </TableCell>

                            <TableCell
                              scope="col"
                              style={{
                                border: "1px solid black",
                                fontWeight: 400,
                              }}
                            >
                              <Typography
                                variant="subtitle"
                                sx={{ fontWeight: 400 }}
                              >
                                IGST
                              </Typography>
                            </TableCell>

                            <TableCell
                              scope="col"
                              style={{
                                border: "1px solid black",
                                fontWeight: 400,
                              }}
                            >
                              <Typography
                                variant="subtitle"
                                sx={{ fontWeight: 400 }}
                              >
                                Discount
                              </Typography>
                            </TableCell>

                            <TableCell
                              scope="col"
                              style={{
                                border: "1px solid black",
                                fontWeight: 400,
                              }}
                            >
                              <Typography
                                variant="subtitle"
                                sx={{ fontWeight: 400 }}
                              >
                                Total
                              </Typography>
                            </TableCell>

                            <TableCell
                              scope="col"
                              style={{
                                border: "1px solid black",
                                fontWeight: 400,
                              }}
                            >
                              <Typography
                                variant="subtitle"
                                sx={{ fontWeight: 400 }}
                              >
                                Payment Status
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {reservationmaster &&
                            reservationmaster?.roomservicemaster &&
                            reservationmaster?.roomservicemaster?.length > 0 &&
                            reservationmaster?.roomservicemaster.map(
                              (item, index) => (
                                <TableRow>
                                  <TableCell
                                    style={{ border: "1px solid black" }}
                                  >
                                    <Typography variant="body2">
                                      {item?.invoice_num || ""}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    style={{ border: "1px solid black" }}
                                  >
                                    {" "}
                                    <Typography variant="body2">
                                      {item?.order_date
                                        ? new Date(
                                            item?.order_date
                                          ).toLocaleDateString()
                                        : ""}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    style={{ border: "1px solid black" }}
                                  >
                                    {" "}
                                    <Typography variant="body2">
                                      {item?.sub_total || "0"}
                                    </Typography>
                                  </TableCell>
                                  {/* <TableCell style={{ border: "1px solid black" }}> <Typography variant="body2" >₹1600</Typography></TableCell> */}
                                  <TableCell
                                    style={{ border: "1px solid black" }}
                                  >
                                    {" "}
                                    <Typography variant="body2">
                                      {item?.cgst || "0"}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    style={{ border: "1px solid black" }}
                                  >
                                    {" "}
                                    <Typography variant="body2">
                                      {item?.sgst || "0"}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    style={{ border: "1px solid black" }}
                                  >
                                    {" "}
                                    <Typography variant="body2">
                                      {item?.igst || "0"}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    style={{ border: "1px solid black" }}
                                  >
                                    {" "}
                                    <Typography variant="body2">
                                      {item?.discount || "0"}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    style={{ border: "1px solid black" }}
                                  >
                                    {" "}
                                    <Typography variant="body2">
                                      {item?.total || "0"}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    style={{ border: "1px solid black" }}
                                  >
                                    {" "}
                                    <Typography variant="body2">
                                      {item?.payment_status || ""}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                        </TableBody>
                      </Table>
                      {/* </Box> */}
                    </TableContainer>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6}>
                    <Box
                      border={"1px solid black"}
                      borderRight={"none"}
                      height={"100%"}
                      p={2}
                    >
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="subtitle2">
                          Invoice Amount In Words :-{" "}
                        </Typography>
                        <Typography variant="body2">
                          {record.grandTotal
                            ? toWords(record?.grandTotal)
                            : toWords(0)}
                        </Typography>
                      </Box>

                      <Box sx={{ mt: 2 }}>
                        <Typography
                          variant="subtitle2"
                          sx={{ fontSize: "17px" }}
                        >
                          Terms and Conditions
                        </Typography>
                        <Typography variant="body2">
                          Thanks for doing business with us! Any complaint must
                          be made within 7 days of receiving the invoice.
                          Payment must be made within 30 days from the date of
                          invoice.
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <TableContainer component={Box} height={"100%"}>
                      <Table height={"100%"}>
                        <TableBody>
                          <TableRow>
                            <TableCell scope="row" className="trowHeader">
                              {" "}
                              <Typography variant="body2">
                                Room Total
                              </Typography>
                            </TableCell>
                            <TableCell
                              style={{
                                border: "1px solid black",
                                width: "5%",
                              }}
                            >
                              {" "}
                              <Typography
                                variant="body2"
                                display={"flex"}
                                justifyContent={"end"}
                                align={"right"}
                                sx={{ fontSize: "15px" }}
                              >
                                {record?.roomTotal || ""}
                              </Typography>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell scope="row" className="trowHeader">
                              {" "}
                              <Typography variant="body2">
                                Remaining Room Amount
                              </Typography>
                            </TableCell>
                            <TableCell
                              style={{
                                border: "1px solid black",
                                width: "5%",
                              }}
                            >
                              {" "}
                              <Typography
                                variant="body2"
                                display={"flex"}
                                justifyContent={"end"}
                                align={"right"}
                                sx={{ fontSize: "15px" }}
                              >
                                {record?.remainingRoomAmount || "0"}
                              </Typography>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell scope="row" className="theader">
                              {" "}
                              <Typography variant="subtitle2">
                                Room Service Total
                              </Typography>
                            </TableCell>
                            <TableCell className="trowHeader">
                              {" "}
                              <Typography
                                variant="body2"
                                display={"flex"}
                                justifyContent={"end"}
                                align={"right"}
                                sx={{ fontSize: "15px" }}
                              >
                                {record?.roomServiceTotal || "0"}
                              </Typography>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell scope="row" className="trowHeader">
                              {" "}
                              <Typography variant="body2">
                                Reamining Room Service Amount
                              </Typography>
                            </TableCell>
                            <TableCell
                              style={{
                                border: "1px solid black",
                                width: "5%",
                              }}
                            >
                              {" "}
                              <Typography
                                variant="body2"
                                display={"flex"}
                                justifyContent={"end"}
                                align={"right"}
                                sx={{ fontSize: "15px" }}
                              >
                                {record?.remainingRoomServiceAmount || "0"}
                              </Typography>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell scope="row" className="trowHeader">
                              {" "}
                              <Typography variant="body2">
                                Reamining Amount
                              </Typography>
                            </TableCell>
                            <TableCell
                              style={{
                                border: "1px solid black",
                                width: "5%",
                              }}
                            >
                              {" "}
                              <Typography
                                variant="body2"
                                display={"flex"}
                                justifyContent={"end"}
                                align={"right"}
                                sx={{ fontSize: "15px" }}
                              >
                                {record?.remainingAmount || "0"}
                              </Typography>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell scope="row" className="theader">
                              {" "}
                              <Typography variant="subtitle2">
                                Grand Total
                              </Typography>
                            </TableCell>
                            <TableCell className="trowHeader">
                              {" "}
                              <Typography
                                variant="body2"
                                display={"flex"}
                                justifyContent={"end"}
                                align={"right"}
                                sx={{ fontSize: "15px" }}
                              >
                                {record?.grandTotal || "0"}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 3 }} spacing={2}>
            <Grid item xs={12}>
              <Stack direction={"column"} justifyContent={"flex-end"} gap={7}>
                <Box textAlign={"right"}>
                  <Typography variant="body">
                    For : Hotel Management (Admin)
                  </Typography>
                </Box>
                <Box textAlign={"right"}>
                  <Typography variant="body" mt={8}>
                    Authorized Signatory
                  </Typography>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Paper>
      <Stack
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        direction={"row"}
        mt={5}
        gap={2}
      >
        <Button variant="contained" onClick={handleBiilingPrint} color="info">
          <PrintTwoToneIcon /> Print
        </Button>
        {reservationmaster?.status &&
          reservationmaster?.status !== "COMPLETED" && (
            <Button
              variant="contained"
              onClick={handleCheckoutPrint}
              color="warning"
            >
              <PrintTwoToneIcon /> Check out & Print
            </Button>
          )}
      </Stack>
    </Box>
  );
};
export default BothInvoice;

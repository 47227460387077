import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  TextField,
  Tooltip,
  IconButton,
  Button,
  Avatar,
} from "@mui/material";
import DataTable from "../../../DataTable";
import "react-lazy-load-image-component/src/effects/blur.css";
import ExcellIcon from "../../../../Assets/img/Exellicon.png";
import PrintIcon from "@mui/icons-material/Print";
import SwipableDrawer from "../../Include/SwipableDrawer";
import EditRoadOutlinedIcon from "@mui/icons-material/EditRoadOutlined";
import Cookies from "js-cookie";
import { useSelector, useDispatch } from "react-redux";
import {
  GetAllBilling,
  RoomInvoicePayment_Status,
} from "../../../../store/Slice/BillingSlice/BillingSlice";
import handleExcelConversion from "../Reports/ExcellsFormat";
import { MySwal } from "../../../../Model/Alert/SuccessAlert";
import { useNavigate } from "react-router-dom";
import handleFilter from "../../../Searching";

const GenerateBilling = () => {
  const navigate = useNavigate();
  const Token = Cookies.get("Token");
  const dispatch = useDispatch();
  const { GetAllBillingData } = useSelector((state) => state.BillingStore);
  const [record, setrecord] = useState([]);

  useEffect(() => {
    const FetchData = async () => {
      try {
        await dispatch(GetAllBilling({ Token }));
      } catch (error) {
        return error;
      }
    };
    FetchData();
  }, [Token, dispatch]);

  const datawithSerialNumber = useMemo(
    () =>
      GetAllBillingData.map((item, index) => ({
        ...item,
        serialNumber: index + 1,
      })),
    [GetAllBillingData]
  );

  useEffect(() => {
    setrecord(datawithSerialNumber);
  }, [datawithSerialNumber]);

  // const [handlegridrow, sethandlegridrow] = useState(null);

  const [columnsBookedRooms, setcolumnsBookedRooms] = useState([
    {
      field: "serialNumber",
      headerName: "SR.No",
      width: 0,
      headerAlign: "center",
      align: "center",
      visible: true,
    },
    {
      field: "Action",
      headerName: "Action",
      width: 100,
      headerAlign: "center",
      visible: true,
      align: "center",
      renderCell: (param) => (
        <div>
          <Button
            variant="outlined"
            sx={{ background: "#F1F2F3" }}
            onClick={() => {
              navigate(`/room-invoice/${param.row.id}`);
            }}
          >
            <PrintIcon sx={{ fontSize: "19px", color: "#3B3F5C" }} />
          </Button>
        </div>
      ),
    },
    {
      field: "invoice_num",
      headerName: "Invoice No",
      width: 120,
      headerAlign: "center",
      align: "center",
      visible: true,
    },
    // {
    //   field: "images",
    //   headerName: "Images",
    //   align: "center",
    //   headerAlign: "center",
    //   visible: true,
    //   renderCell: (param) => {
    //     const imageUrl =
    //       param.row && param.row?.room && param.row?.room?.images
    //         ? `${process.env.REACT_APP_API_URL}/uploads/rooms/${
    //             param.row?.room?.images.split(",")[0]
    //           }`
    //         : placeholderImage;
    //     return (
    //       <LazyLoadImage
    //         effect="blur"
    //         // onClick={handleRowClick}
    //         src={imageUrl}
    //         style={{
    //           height: "40px",
    //           width: "70px",
    //           borderRadius: "10px",
    //           cursor: "pointer",
    //           marginBottom: "10px",
    //           marginTop: "5px",
    //         }}
    //       />
    //     );
    //   },
    // },

    {
      field: "Room_No",
      headerName: "Room No",
      width: 110,
      headerAlign: "center",
      visible: true,
      align: "center",
      renderCell: (param) => {
        const room_no =
          param.row.room && param.row.room.room_no
            ? param.row.room.room_no
            : "";
        return <div variant="h6">{room_no}</div>;
      },
    },

    {
      field: "check_out",
      headerName: "Check Out",
      visible: true,
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        params.row.check_out ? (
          <div>
            {new Intl.DateTimeFormat("en-GB", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            }).format(new Date(params.row.check_out))}
          </div>
        ) : null,
    },

    {
      field: "username",
      headerName: "Name",
      width: 150,
      headerAlign: "center",
      visible: true,
      align: "center",
      renderCell: (param) => {
        const name =
          param.row.guest && param.row.guest.fullname
            ? param.row.guest.fullname
            : "";
        return <div variant="h6">{name}</div>;
      },
    },

    {
      field: "phone_number",
      headerName: "Mobile",
      width: 150,
      headerAlign: "center",
      visible: true,
      align: "center",
      renderCell: (param) => {
        const Mobile =
          param.row.guest && param.row.guest.phone_number
            ? param.row.guest.phone_number
            : "";
        return <div variant="h6">{Mobile}</div>;
      },
    },

    {
      field: "Email",
      headerName: "Email",
      width: 150,
      headerAlign: "center",
      visible: true,
      align: "center",
      renderCell: (param) => {
        const email =
          param.row.guest && param.row.guest.email ? param.row.guest.email : "";
        return <div variant="h6">{email}</div>;
      },
    },

    {
      field: "status",
      headerName: "Status",
      width: 130,
      headerAlign: "center",
      align: "center",
      visible: true,
      renderCell: (param) => (
        <div>
          {param.row?.status === "BOOKED" && (
            <Button variant="outlined" size="small" color="success">
              {param.row?.status}
            </Button>
          )}
          {param.row?.status === "COMPLETED" && (
            <Button variant="outlined" size="small" color="warning">
              {param.row?.status}
            </Button>
          )}
          {param.row?.status === "CANCELLED" && (
            <Button variant="outlined" size="small" color="error">
              {param.row?.status}
            </Button>
          )}
        </div>
      ),
    },

    // { field: 'Price', headerName: 'Price', width: 120, headerAlign: 'center',visible: true, align: 'center' },
    // {
    //   field: "floo_No",
    //   headerName: "Floor No",
    //   width: 130,
    //   headerAlign: "center",
    //   visible: true,
    //   align: "center",
    //   renderCell: (param) => {
    //     const floor_no =
    //       param.row.room && param.row.room.floor_no
    //         ? param.row.room.floor_no
    //         : "";
    //     return <div variant="h6">{floor_no}</div>;
    //   },
    // },

    {
      field: "check_in",
      headerName: "Check In",
      visible: true,
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        params.row.check_in ? (
          <div>
            {new Intl.DateTimeFormat("en-GB", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            }).format(new Date(params.row.check_in))}
          </div>
        ) : null,
    },

    {
      field: "remaining_amount",
      headerName: "Remaining Amount",
      width: 100,
      headerAlign: "center",
      align: "center",
      visible: true,
    },

    {
      field: "payment_status",
      headerName: "Payment Status",
      width: 150,
      headerAlign: "center",
      align: "center",
      visible: true,
      renderCell: (param) => (
        <div>
          {param.row?.payment_status === "PAID" && (
            <Button variant="outlined" size="small" color="success">
              {param.row?.payment_status}
            </Button>
          )}
          {param.row?.payment_status === "PARTIAL" && (
            <Button variant="outlined" size="small" color="info">
              {param.row.payment_status}
            </Button>
          )}

          {param.row?.payment_status === "UNPAID" && (
            <Button variant="outlined" color="error" size="small">
              {param.row?.payment_status}
            </Button>
          )}
        </div>
      ),
    },
  ]);
  const handleToggleColumn = (field) => {
    setcolumnsBookedRooms((prevColumns) =>
      prevColumns.map(
        (col) => (col.field === field ? { ...col, visible: !col.visible } : col)
        // console.log("col",col)
      )
    );
  };

  // const handleRowClick = () => sethandlegridrow((previous) => !previous);

  // const [BillingModelStatus, setBillingModelStatus] = useState(false);
  // const [billingId, setbillingId] = useState(false);
  let handleBillingModelOpen = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "Once you generate the bill, payment status will be paid!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, generate it!",
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#dc3545",
      cancelButtonText: "No, cancel!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        //CALL API TO CHANGE STATUS
        // setBillingModelStatus(true)
        // setbillingId(id)
        dispatch(RoomInvoicePayment_Status({ Token, id }));

        navigate(`/room-invoice/${id}`);
      } else if (result.dismiss === MySwal.DismissReason.cancel) {
        MySwal.fire({
          title: "Cancelled",
          text: "Your data is safe.",
          icon: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "#dc3545",
        });
      }
    });
  };

  // const handleBillingModelClose = () => setBillingModelStatus(false);

  const [Swipeopen, setSwipeopen] = useState(false);
  const HandleSwipeOpen = () => setSwipeopen(true);
  const HandleSwipeClose = () => setSwipeopen(false);

  const keys_serach = [
    "room.room_no",
    "room.floor_no",
    "room.perdayprice",
    "guest.fullname",
    "guest.email",
    "guest.phone_number",
    "check_in",
    "check_out",
  ];

  return (
    <>
      <Box>
        <Grid container>
          <Grid item xs={12}>
            <Paper sx={{ padding: "10px", borderRadius: "10px" }}>
              <Grid container>
                <Grid item lg={9} md={9} sm={9} xs={12}>
                  <Box
                    sx={{
                      width: { xs: "100%", sm: "" },
                    }}
                  >
                    <TextField
                      label="Search"
                      size="small"
                      onChange={(event) => {
                        handleFilter({
                          event,
                          dataWithSerialNumber: datawithSerialNumber,
                          setrecord,
                          keys_serach,
                        });
                      }}
                      sx={{
                        bgcolor: "#dae1f3",
                        borderRadius: "10px",
                        width: { xs: "100%", sm: "230px" },

                        "& fieldset": { border: "none" },
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignContent: "center",
                      alignItems: "center",
                      mt: { xs: 2, sm: 0 },
                    }}
                  >
                    <Avatar sx={{ background: "#6970E2" }}>
                      <Tooltip title="Table Edit">
                        <IconButton onClick={HandleSwipeOpen}>
                          <EditRoadOutlinedIcon sx={{ color: "#fff" }} />
                        </IconButton>
                      </Tooltip>
                    </Avatar>

                    <Avatar
                      src={ExcellIcon}
                      onClick={() => {
                        handleExcelConversion(record);
                      }}
                    ></Avatar>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Box sx={{ marginTop: "40px", paddingLeft: "10px" }}>
          <Grid container spacing={3}>
            {/* <Grid item lg={handlegridrow ? 6 : 12} xs={12} mt={-3}> */}
            <Grid item lg={12} xs={12} mt={-3}>
              <Paper>
                <DataTable columns={columnsBookedRooms} rows={record} />
              </Paper>
            </Grid>

            {/* <Grid item lg={handlegridrow ? 6 : 4} xs={handlegridrow || 12}>
              {handlegridrow && (
                <Paper sx={{ borderRadius: "10px", marginTop: "-25px" }}>
                  <BasicTabs
                    handlegridrow={handlegridrow}
                    RoomsubImages={RoomsubImages}
                    MainImage={images2}
                    icons={Bookedicons}
                    data={BookedRoom_Data}
                    UserData={UserData}
                    iconsUser={iconsUser}
                    itemdata={itemdata}
                    Iccream={Iccream}
                    sethandlegridrow={sethandlegridrow}
                  />
                </Paper>
              )}
            </Grid> */}
          </Grid>
        </Box>

        {/* {BillingModelStatus && (
          <SlectBilling
            open={BillingModelStatus}
            billingId={billingId}
            handleClose={handleBillingModelClose}
          />
        )} */}
        <SwipableDrawer
          open={Swipeopen}
          close={HandleSwipeClose}
          columns={columnsBookedRooms}
          handleToggleColumn={handleToggleColumn}
        />
      </Box>
    </>
  );
};
export default GenerateBilling;

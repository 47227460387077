import { Typography, Box } from "@mui/material"
import { NavLink } from "react-router-dom"

const Footer = () => {
    return (
        <Box>
            <Typography variant="body" textAlign={'center'} >
                © Copyright 2023. All Rights Reserved by
                <NavLink to={'/'} style={{textDecoration:"none",}}> Hotel Management System &nbsp; <span style={{color:"#333"}}>Developed By</span>

                    <NavLink to={"https://msquaretec.com/"} style={{textDecoration:"none"}}> M-Square Technologies</NavLink>
                </NavLink>

            </Typography>
        </Box>
    )
}

export default Footer
// filterFunctions.js

import Fuse from "fuse.js";

const handleFilter = ({
  event,
  dataWithSerialNumber,
  setrecord,
  keys_serach,
}) => {
  const query = event.target.value.toLowerCase();
  let newdata;
  if (query.trim() === "") {
    newdata = dataWithSerialNumber;
  } else {
    const fuse = new Fuse(dataWithSerialNumber, {
      keys: keys_serach,
      //   threshold: 0.3,
    });
    newdata = fuse.search(query).map((result) => result.item);
  }

  setrecord(newdata);
};

export default handleFilter;

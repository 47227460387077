import React, { useRef, useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Stack,
  Grid,
  Divider,
  Button,
  Chip,
  useMediaQuery,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { toWords } from "number-to-words";
import CollapsedBreadcrumbs from "../../Include/Breadcrumb";
import { LazyLoadImage } from "react-lazy-load-image-component";
import logo from "../../../../Assets/img/gotle.webp";
import { useReactToPrint } from "react-to-print";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import "./print.css";
import { OrderInvoiceAPI } from "../../../../store/Slice/BillingSlice/BillingSlice";
import dayjs from "dayjs";
const BreadCrumbLink = [
  {
    id: 1,
    name: "Home",
    link: "/",
  },
  {
    id: 2,
    name: "Billing",
    link: "/billing",
  },
  {
    id: 3,
    name: "order-Invoice",
    link: "order-invoice",
  },
];
const OrderInvoice = () => {
  const Billingref = useRef();
  const IsMobile = useMediaQuery("(min-width:900px)");

  const handlePrint = useReactToPrint({
    copyStyles: true,
    documentTitle: "Order Invoice",
    content: () => Billingref.current,
  });

  const { id } = useParams();
  const dispatch = useDispatch();
  const Token = Cookies.get("Token");
  const [record, setrecord] = useState([]);
  const { OrderInvoiceData } = useSelector((state) => state.BillingStore);

  useEffect(() => {
    const FetchData = async () => {
      try {
        await dispatch(OrderInvoiceAPI({ Token, id }));
      } catch (error) {
        return error;
      }
    };
    FetchData();
  }, [Token, dispatch]);

  useEffect(() => {
    setrecord(OrderInvoiceData);
  }, [OrderInvoiceData]);

  return (
    <Box sx={{ padding: "20px", width: "100%", color: "grey" }}>
      <Stack
        direction={IsMobile ? "row" : "column"}
        justifyContent={"space-between"}
      >
        <Typography variant="h6">Order Invoice</Typography>
        <CollapsedBreadcrumbs data={BreadCrumbLink} />
      </Stack>

      <Paper
        sx={{ mt: 2, marginX: IsMobile ? "4rem" : "0px", p: 3 }}
        elevation={24}
        id="paper-section"
      >
        <Paper
          sx={{
            m: 5,
            border: "1px solid black",
            p: 2,
            backgroundColor: "#e4f5ffaa",
            color: "#474747",
          }}
          ref={Billingref}
        >
          <Grid container xs={12} sx={{ mt: 1 }}>
            <Grid item xs={5}>
              {record ? (
                <>
                  <Typography variant="h6">
                    Invoice No.{" "}
                    <b
                      style={{
                        color: "#9c27b0",
                        fontFamily: "serif",
                        fontSize: "2rem",
                      }}
                    >
                      {record?.invoice_num || ""}
                    </b>
                  </Typography>
                </>
              ) : (
                <Typography variant="h6">
                  Reservation information loading...
                </Typography>
              )}
            </Grid>
            <Grid
              container
              item
              xs={7}
              display={"flex"}
              alignContent={"end"}
              pb={1}
            >
              <Grid item xs={6}>
                {record ? (
                  <>
                    <Typography variant="h6">
                      Room No. {record?.room_no}
                    </Typography>
                  </>
                ) : (
                  <Typography variant="h6">
                    Reservation information loading...
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6}>
                {record ? (
                  <>
                    <Typography variant="h6">
                      Date :{" "}
                      {record?.order_date
                        ? dayjs(record?.order_date).format(
                            "DD/MM/YYYY, hh:mm A"
                          )
                        : ""}
                    </Typography>
                  </>
                ) : (
                  <Typography variant="h6">
                    Reservation information loading...
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={6}>
              <Stack direction={"column"}>
                <Typography variant="h6" mr={4}>
                  BILLED TO
                </Typography>
                {record ? ( // Check if guest is not null before accessing its properties
                  <>
                    <Typography
                      variant="h5"
                      mr={4}
                      sx={{ fontFamily: "serif" }}
                    >
                      <b>{record?.fullname || ""}</b>
                    </Typography>
                    <Typography variant="body2" mt={1}>
                      Mobile No
                    </Typography>
                    <Typography variant="h6" sx={{ fontFamily: "serif" }}>
                      <b>{record?.phone_number || ""}</b>
                    </Typography>
                    <Typography variant="body2" mt={1}>
                      E-mail
                    </Typography>
                    <Typography variant="h6" sx={{ fontFamily: "serif" }}>
                      <b>{record?.email || ""}</b>
                    </Typography>
                  </>
                ) : (
                  <Typography variant="body2">
                    Guest information loading...
                  </Typography>
                )}
              </Stack>
            </Grid>
            <Grid item xs={6} display={"flex"} justifyContent={"end"}>
              <Stack direction={"column"}>
                {record ? ( // Check if record is not null before accessing its properties
                  <>
                    <Typography variant="body2" mt={1} textAlign={"right"}>
                      Check-In
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ fontFamily: "serif" }}
                      textAlign={"right"}
                    >
                      <b>
                        {record?.check_in
                          ? dayjs(record?.check_in).format(
                              "DD/MM/YYYY, hh:mm A"
                            )
                          : ""}
                      </b>
                    </Typography>

                    <Typography variant="body2" mt={1} textAlign={"right"}>
                      Check-out
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ fontFamily: "serif" }}
                      textAlign={"right"}
                    >
                      <b>
                        {record?.check_out
                          ? dayjs(record?.check_out).format(
                              "DD/MM/YYYY, hh:mm A"
                            )
                          : ""}
                      </b>
                    </Typography>

                    <Typography variant="body2" mt={1} textAlign={"right"}>
                      Payment Status
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ fontFamily: "serif" }}
                      textAlign={"right"}
                    >
                      {record &&
                        record?.payment_status &&
                        record?.payment_status === "PAID" && (
                          <Chip
                            variant="outlined"
                            label={record?.payment_status}
                            color="success"
                            sx={{
                              fontSize: "1rem",
                              fontWeight: 700,
                            }}
                          />
                        )}

                      {record &&
                        record?.payment_status &&
                        record?.payment_status === "PARTIAL" && (
                          <Chip
                            sx={{
                              fontSize: "1rem",
                              fontWeight: 700,
                            }}
                            variant="outlined"
                            label={record?.payment_status}
                            color="info"
                          />
                        )}

                      {record &&
                        record?.payment_status &&
                        record?.payment_status === "UNPAID" && (
                          <Chip
                            variant="outlined"
                            sx={{
                              fontSize: "1rem",
                              fontWeight: 700,
                            }}
                            label={record?.payment_status}
                            color="error"
                          />
                        )}
                    </Typography>
                  </>
                ) : (
                  <Typography variant="body2">
                    Guest information loading...
                  </Typography>
                )}
              </Stack>
            </Grid>
            <Grid item sm={12}>
              <Stack mt={5}>
                <Grid container mb={2}>
                  <Grid item xs={12}>
                    <TableContainer component={Box} height={"100%"}>
                      {/* <Box height={"100%"}> */}
                      <Table>
                        <TableHead className="theader">
                          <TableRow>
                            <TableCell scope="col" className="theader">
                              <Typography
                                variant="subtitle"
                                sx={{ fontWeight: 400 }}
                              >
                                Invoice No
                              </Typography>
                            </TableCell>
                            <TableCell scope="col" className="theader">
                              <Typography
                                variant="subtitle"
                                sx={{ fontWeight: 400 }}
                              >
                                Food
                              </Typography>
                            </TableCell>
                            <TableCell scope="col" className="theader">
                              <Typography
                                variant="subtitle"
                                sx={{ fontWeight: 400 }}
                              >
                                Quantity
                              </Typography>
                            </TableCell>
                            <TableCell scope="col" className="theader">
                              <Typography
                                variant="subtitle"
                                sx={{ fontWeight: 400 }}
                              >
                                Tax Type
                              </Typography>
                            </TableCell>
                            <TableCell scope="col" className="theader">
                              <Typography
                                variant="subtitle"
                                sx={{ fontWeight: 400 }}
                              >
                                Price
                              </Typography>
                            </TableCell>

                            <TableCell scope="col" className="theader">
                              <Typography
                                variant="subtitle"
                                sx={{ fontWeight: 400 }}
                              >
                                CGST
                              </Typography>
                            </TableCell>

                            <TableCell scope="col" className="theader">
                              <Typography
                                variant="subtitle"
                                sx={{ fontWeight: 400 }}
                              >
                                SGST
                              </Typography>
                            </TableCell>

                            <TableCell scope="col" className="theader">
                              <Typography
                                variant="subtitle"
                                sx={{ fontWeight: 400 }}
                              >
                                IGST
                              </Typography>
                            </TableCell>

                            <TableCell scope="col" className="theader">
                              <Typography
                                variant="subtitle"
                                sx={{ fontWeight: 400 }}
                              >
                                Total
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {record &&
                          record?.field &&
                          record?.field.length > 0 ? (
                            record?.field.map((item, index) => (
                              <TableRow key={index}>
                                <TableCell sx={{ border: "1px solid black" }}>
                                  <Typography variant="body2">
                                    {record?.invoice_num || ""}
                                  </Typography>
                                </TableCell>
                                <TableCell sx={{ border: "1px solid black" }}>
                                  <Typography variant="body2">
                                    {item?.item_name || ""}
                                  </Typography>
                                </TableCell>
                                <TableCell sx={{ border: "1px solid black" }}>
                                  {" "}
                                  <Typography variant="body2">
                                    {item?.quantity || ""}
                                  </Typography>
                                </TableCell>
                                <TableCell sx={{ border: "1px solid black" }}>
                                  {" "}
                                  <Typography variant="body2">
                                    {item?.tax_type || "NONE"}
                                  </Typography>
                                </TableCell>
                                <TableCell sx={{ border: "1px solid black" }}>
                                  {" "}
                                  <Typography variant="body2">
                                    {item?.price || ""}
                                  </Typography>
                                </TableCell>
                                <TableCell sx={{ border: "1px solid black" }}>
                                  {" "}
                                  <Typography variant="body2">
                                    {item?.cgst || "0"}
                                  </Typography>
                                </TableCell>
                                <TableCell sx={{ border: "1px solid black" }}>
                                  {" "}
                                  <Typography variant="body2">
                                    {item.sgst || "0"}
                                  </Typography>
                                </TableCell>
                                <TableCell sx={{ border: "1px solid black" }}>
                                  {" "}
                                  <Typography variant="body2">
                                    {item?.igst || "0"}
                                  </Typography>
                                </TableCell>
                                <TableCell sx={{ border: "1px solid black" }}>
                                  {" "}
                                  <Typography variant="body2">
                                    {item?.total || "0"}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <Typography variant="body2">
                              Order information loading...
                            </Typography>
                          )}
                        </TableBody>
                      </Table>
                      {/* </Box> */}
                    </TableContainer>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6}>
                    <Box
                      border={"1px solid black"}
                      borderRight={"none"}
                      height={"100%"}
                      p={2}
                    >
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="subtitle2">
                          Invoice Amount In Words :-{" "}
                        </Typography>
                        <Typography variant="body2">
                          {record?.total ? toWords(record?.total) : toWords(0)}
                        </Typography>
                      </Box>

                      <Box sx={{ mt: 2 }}>
                        <Typography
                          variant="subtitle2"
                          sx={{ fontSize: "17px" }}
                        >
                          Terms and Conditions
                        </Typography>
                        <Typography variant="body2">
                          Thanks for doing business with us! Any complaint must
                          be made within 7 days of receiving the invoice.
                          Payment must be made within 30 days from the date of
                          invoice.
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <TableContainer component={Box} height={"100%"}>
                      <Table height={"100%"}>
                        <TableBody>
                          <TableRow>
                            <TableCell scope="row" className="trowHeader">
                              {" "}
                              <Typography variant="body2">Sub Total</Typography>
                            </TableCell>
                            <TableCell
                              style={{
                                border: "1px solid black",
                                width: "5%",
                              }}
                            >
                              {" "}
                              <Typography
                                variant="body2"
                                display={"flex"}
                                justifyContent={"end"}
                                align={"right"}
                                sx={{ fontSize: "15px" }}
                              >
                                {record?.sub_total || "0"}
                              </Typography>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell scope="row" className="trowHeader">
                              {" "}
                              <Typography variant="body2">
                                CGST {/*2.5%*/}
                              </Typography>
                            </TableCell>
                            <TableCell
                              style={{
                                border: "1px solid black",
                                width: "5%",
                              }}
                            >
                              {" "}
                              <Typography
                                variant="body2"
                                display={"flex"}
                                justifyContent={"end"}
                                align={"right"}
                                sx={{ fontSize: "15px" }}
                              >
                                {record?.cgst || "0"}
                              </Typography>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell scope="row" className="trowHeader">
                              {" "}
                              <Typography variant="body2">
                                SGST {/*2.5%*/}
                              </Typography>
                            </TableCell>
                            <TableCell
                              style={{
                                border: "1px solid black",
                                width: "5%",
                              }}
                            >
                              {" "}
                              <Typography
                                variant="body2"
                                display={"flex"}
                                justifyContent={"end"}
                                align={"right"}
                                sx={{ fontSize: "15px" }}
                              >
                                {record?.sgst || "0"}
                              </Typography>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell scope="row" className="trowHeader">
                              {" "}
                              <Typography variant="body2">
                                IGST {/*5%*/}
                              </Typography>
                            </TableCell>
                            <TableCell
                              style={{
                                border: "1px solid black",
                                width: "5%",
                              }}
                            >
                              {" "}
                              <Typography
                                variant="body2"
                                display={"flex"}
                                justifyContent={"end"}
                                align={"right"}
                                sx={{ fontSize: "15px" }}
                              >
                                {record?.igst || "0"}
                              </Typography>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell scope="row" className="trowHeader">
                              {" "}
                              <Typography variant="body2">Discount</Typography>
                            </TableCell>
                            <TableCell
                              style={{
                                border: "1px solid black",
                                width: "5%",
                              }}
                            >
                              {" "}
                              <Typography
                                variant="body2"
                                display={"flex"}
                                justifyContent={"end"}
                                align={"right"}
                                sx={{ fontSize: "15px" }}
                              >
                                {record?.discount || "0"}
                              </Typography>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell scope="row" className="theader">
                              {" "}
                              <Typography variant="subtitle2">Total</Typography>
                            </TableCell>
                            <TableCell className="trowHeader">
                              {" "}
                              <Typography
                                variant="body2"
                                display={"flex"}
                                justifyContent={"end"}
                                align={"right"}
                                sx={{ fontSize: "15px" }}
                              >
                                {record?.total || "0"}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
          </Grid>

          <Divider sx={{ marginTop: 2 }} />
          <Grid container sx={{ mt: 2 }} spacing={2}>
            <Grid item xs={12}>
              <Stack direction={"column"} justifyContent={"flex-start"} gap={5}>
                <Box textAlign={"left"}>
                  <Typography
                    variant="h5"
                    sx={{ fontFamily: "serif", fontWeight: 700 }}
                  >
                    THANK YOU !
                  </Typography>
                </Box>

                <Box textAlign={"left"}>
                  <Typography variant="h6" sx={{ fontFamily: "serif" }}>
                    Customer's Signature
                  </Typography>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Paper>

      <Stack
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        mt={5}
      >
        <Button variant="contained" onClick={handlePrint} color="info">
          Print
        </Button>
      </Stack>
    </Box>
  );
};
export default OrderInvoice;

import React,{useState} from "react";
import { Paper, TextField, Box, Stack, Typography, Button } from "@mui/material";
import CollapsedBreadcrumbs from "../../Include/Breadcrumb";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Webcam from "react-webcam";


const AddRoomBoking = () => {
    const BreadCrumbLink = [
        {
            id: 1,
            name: "Home",
            link: "/"
        },
        {
            id: 1,
            name: "Booked",
            link: "/Booking"
        },
        {
            id: 1,
            name: "Add-Room-Booking",
            link: "/Add-Booking"
        },
    ]


    const [openWebCam,setOpenWebCam]=useState(false)
    const handleWebCam = () => {
        setOpenWebCam(!openWebCam);
        if( openWebCam){
            const imageSrc = webcamRef.current.getScreenshot();
            console.log(imageSrc)
        }
    };

  

   

    const webcamRef = React.useRef(null);




    return (
        <Box sx={{ padding: "20px", width: "100%" }}>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography variant="h6">Add Booking</Typography>
                <CollapsedBreadcrumbs data={BreadCrumbLink} />
            </Stack>



            <Paper sx={{ marginTop: "20px", padding: "30px" }} >

                <Box display={'flex'} flexDirection={'column'} gap={4}>

                <Stack direction={'row'} spacing={4}>
                        <TextField
                            label="Room No"
                            variant="outlined"
                            size="large"
                            name="Designation_Name"
                            type="roomno"
                            disabled
                            // value={Room_No? Room_No :""}
                            required
                            sx={{ width: "100%" }}
                        />

                        <TextField
                            label="Name"
                            variant="outlined"
                            size="large"
                            name="name"
                            type="text"
                            value={"Horeo Complex"}
                            disabled
                            required
                            sx={{ width: "100%" }}
                        />
                    </Stack>


                    <Stack direction={'row'} spacing={2}>
                        <TextField
                            label="full Name"
                            variant="outlined"
                            size="large"
                            name="fullname"
                            type="text"
                            required
                            sx={{ width: "100%" }}
                        />

                        <TextField
                            label="Email"
                            variant="outlined"
                            size="large"
                            name="email"
                            type="text"
                            required
                            sx={{ width: "100%" }}
                        />
                    </Stack>


                    <Stack direction={'row'} spacing={2}>
                        <TextField
                            label="Mobile No"
                            variant="outlined"
                            size="large"
                            name="Designation_Name"
                            type="mobile"
                            required
                            sx={{ width: "100%" }}
                        />

                        <TextField
                            label="Adhar No"
                            variant="outlined"
                            size="large"
                            name="Designation_Name"
                            type="adhar"
                            required
                            sx={{ width: "100%" }}
                        />
                    </Stack>

                   

                    <Stack direction={'row'} spacing={4}>
                        <TextField
                            variant="outlined"
                            size="large"
                            name="file"
                            type="file"
                            sx={{ flex: 1}}
                        />

<FormControl sx={{ flex: 1}}>
                            <InputLabel id="demo-simple-select-label">Select person</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Select person"

                            >
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                                <MenuItem value={5}>5</MenuItem>
                            </Select>
                        </FormControl>


                    </Stack>


                    <Stack direction={'row'} spacing={4}>
                        <TextField
                            label="Address"
                            variant="outlined"
                            size="large"
                            name="Designation_Name"
                            type="text"
                            multiline
                            rows={5}

                            sx={{ width: "100%" }}
                        />
                    </Stack>

                    <Paper>
                {openWebCam && <Webcam ref={webcamRef} style={{height:"100px",}}/>}
            </Paper>

           <Stack direction={'row'} gap={3} sx={{width:"100%",display:"flex",justifyContent:"end"}}>
           <Button variant="outlined" color="info" onClick={handleWebCam} sx={{height:"50px"}}>open Webcam</Button>
           
           </Stack>




                    <Stack direction={'row'} spacing={4}>
                        <Box sx={{ flex: 1, width: "100%" }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ flex: 1 }}>
                                <DatePicker
                                    label="Booking Date"
                                    name="Booking"
                                    sx={{ width: "100%", flex: 1 }}
                                />
                            </LocalizationProvider>

                        </Box>

                        <FormControl sx={{ flex: 1 }}>
                            <InputLabel id="demo-simple-select-label">Select Payment Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Select person"

                            >
                                <MenuItem value={'cash'}>Cash</MenuItem>
                                <MenuItem value={"UPI"}>UPI</MenuItem>
                            </Select>
                        </FormControl>



                    </Stack>


                 


                   

                    <Stack direction={'row'} spacing={4}>
                        <FormControl sx={{ flex: 1 }}>
                            <InputLabel id="demo-simple-select-label">Select status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Room Type"

                            >
                                <MenuItem value={'paid'}>paid</MenuItem>
                                <MenuItem value={'un-paid'}>un-paid</MenuItem>
                                <MenuItem value={'partial'}>partial</MenuItem>

                            </Select>
                        </FormControl>


                        <TextField
                            label="Advance Amount"
                            variant="outlined"
                            size="large"
                            name="advanceamount"
                            type="text"
                            sx={{ flex: 1 }}
                        />




                    </Stack>


                    <Stack direction={'row'} spacing={4}>
                        <Box sx={{ flex: 1, width: "100%" }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ flex: 1 }}>
                                <DateTimePicker
                                    label="Checking Date"
                                    name="Booking"
                                    sx={{ width: "100%", flex: 1 }}
                                />
                            </LocalizationProvider>

                        </Box>


                        <Box sx={{ flex: 1, width: "100%" }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ flex: 1 }}>
                                <DateTimePicker
                                    label="check-Out date "
                                    name="Booking"
                                    sx={{ width: "100%", flex: 1 }}
                                />
                            </LocalizationProvider>

                        </Box>

                    </Stack>


                    <Stack direction={'row'} spacing={4} display={'flex'} justifyContent={'center'}>
                        <Button variant="contained" color="success">Submit</Button>
                        <Button variant="contained" color="error">Reset</Button>
                    </Stack>
                </Box>
            </Paper>
        </Box>
    )
}

export default AddRoomBoking

import { Routes, Route } from "react-router-dom";
import ViewAllGuest from "./Components/Admin/Other/Guest/ViewAllGuest";
import Layout from "./Layout";
import { Box } from "@mui/material";
import AddGuest from "./Components/Admin/Other/Guest/AddGuest";
import EditGuest from "./Components/Admin/Other/Guest/EditGuest";
import AddRooms from "./Components/Admin/Other/Rooms/AddRooms";
import ViewAllRooms from "./Components/Admin/Other/Rooms/ViewAllRooms";
import EditRooms from "./Components/Admin/Other/Rooms/EditRooms";
// import ViewBooking from "./Components/Admin/Other/Booking/ViewAll_Room_Booking";
import AddRoomBoking from "./Components/Admin/Other/Booking/AddRoomBooking";
// import EditRoomBooking from "./Components/Admin/Other/Booking/EditRoomBooking";
import AddService from "./Components/Admin/Other/Room_Service/AddService";
import EditService from "./Components/Admin/Other/Room_Service/EditService";
import ViewRoomService from "./Components/Admin/Other/Room_Service/ViewService";
import AllItem from "./Components/Admin/Other/Service_Item/item";
// import GenerateBilling from "./Components/Admin/Other/Billing/GenerateBilling";
import RoomInvoice from "./Components/Admin/Other/Billing/RoomInvoice";
import OrderInvoice from "./Components/Admin/Other/Billing/OrderInvoice";
import BothInvoice from "./Components/Admin/Other/Billing/BothInvoice";
import Loginpage from "./Components/Admin/security/Login";
import ResetPage from "./Components/Admin/security/Reset";
import OTPInput from "./Components/Admin/security/OTP";
import ConfirmPd from "./Components/Admin/security/Confirm";
import DashBoard from "./Components/Admin/Dashboard/Dasboard";
import EditableDataTableGuest from "./Components/Admin/Other/Guest/EditDataTable";
import Reports from "./Components/Admin/Other/Reports/Reports";
import AdminProfile from "./Components/Admin/AdminProfile/Profilepage";
import Cookies from "js-cookie"
import { Navigate } from "react-router-dom";
import category from "./Components/Admin/Other/Service_Item/category";
import Expense from "./Components/Admin/Other/Expenses/Expenses";
import Reservation from "./Components/Admin/Include/Reservation";
import BillingTabPanel from "./Components/Admin/Other/Billing/BillingTab";
import Bookingrecord from "./Components/Admin/Other/BookingRecord/BookingRecord";
// import ImageUpload from "./Components/Admin/Other/ImageUpload";

function ProtectedRoute({ element }) {
  const token = Cookies.get('Token');
  return token ? element : <Navigate to="/login" />;
}

function ProtectedRoute2({ element }) {
  const token = Cookies.get('temp_token');
  return token ? element : <Navigate to="/reset" />;
}

function App() {
  const token = Cookies.get('Token');

  return (
    <Box sx={{ background: "#FAFAFA", height: "100vh" }}>

      <Routes>
        <Route path="/login" element={token ? <Navigate to="/" /> : <Loginpage />} />
        <Route path="/reset" element={token ? <Navigate to="/" /> : <ResetPage />} />
        <Route path="/otp" element={token ? <Navigate to="/" /> : <ProtectedRoute2 element={<OTPInput />}/>} />
        <Route path="/confirm" element={token ? <Navigate to="/" /> : <ProtectedRoute2 element={<ConfirmPd />}/>} />


        <Route path="/" element={<ProtectedRoute element={<Layout ChildComponent={DashBoard} />} />} />
        {/* Guest Route */}
        <Route path="/guest" element={<ProtectedRoute element={<Layout ChildComponent={ViewAllGuest} />} />} />
        <Route path="/guest-add" element={<ProtectedRoute element={<Layout ChildComponent={AddGuest} />} />} />
        <Route path="/guest-Edit/:id" element={<ProtectedRoute element={<Layout ChildComponent={EditGuest} />} />} />
        <Route path="/guest-Datatable-Edit" element={<ProtectedRoute element={<Layout ChildComponent={EditableDataTableGuest} />} />} />

        {/* Rooms Route */}
        <Route path="/add-rooms" element={<ProtectedRoute element={<Layout ChildComponent={AddRooms} />} />} />
        <Route path="/rooms" element={<ProtectedRoute element={<Layout ChildComponent={ViewAllRooms} />} />} />
        <Route path="/edit-rooms/:id" element={<ProtectedRoute element={<Layout ChildComponent={EditRooms} />} />} />
        <Route path="/Add-Booking" element={<ProtectedRoute element={<Layout ChildComponent={AddRoomBoking} />} />} />

        {/* Service Route */}
        <Route path="/add-service" element={<ProtectedRoute element={<Layout ChildComponent={AddService} />} />} />
        <Route path="/edit-service/:id" element={<ProtectedRoute element={<Layout ChildComponent={EditService} />} />} />
        <Route path="/room-service" element={<ProtectedRoute element={<Layout ChildComponent={ViewRoomService} />} />} />

        {/* Item Route */}
        <Route path="/Item" element={<ProtectedRoute element={<Layout ChildComponent={AllItem} />} />} />
        <Route path="/category" element={<ProtectedRoute element={<Layout ChildComponent={category} />} />} />
        <Route path="/expense" element={<ProtectedRoute element={<Layout ChildComponent={Expense} />} />} />

        {/* Billing Route */}
        <Route path="/billing" element={<ProtectedRoute element={<Layout ChildComponent={BillingTabPanel} />} />} />
        <Route path="/room-invoice/:id" element={<ProtectedRoute element={<Layout ChildComponent={RoomInvoice} />} />} />
        <Route path="/order-invoice/:id" element={<ProtectedRoute element={<Layout ChildComponent={OrderInvoice} />} />} />
        <Route path="/both-invoice/:id" element={<ProtectedRoute element={<Layout ChildComponent={BothInvoice} />} />} />

        {/* Reports Route */}
        <Route path="/reports" element={<ProtectedRoute element={<Layout ChildComponent={Reports} />} />} />

        <Route path="/profile" element={<ProtectedRoute element={<Layout ChildComponent={AdminProfile} />} />} />
        <Route path="/reservation/:id" element={<ProtectedRoute element={<Layout ChildComponent={Reservation} />} />} />

        {/* All Booking reccord */}
        <Route path="/booking-record" element={<ProtectedRoute element={<Layout ChildComponent={Bookingrecord} />} />} />
        {/* <Route path="/image-upload" element={<ProtectedRoute element={<Layout ChildComponent={ImageUpload} />} />} /> */}
      </Routes>
    </Box>
  );
}

export default App;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ErrorAlert, SuccessAlert } from "../../../Model/Alert/SuccessAlert";
const Base_url = process.env.REACT_APP_API_URL + "/expenses";

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% POST CATEGORY API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const PostExpenseApi = createAsyncThunk(
  "PostExpenseApi",
  async ({ values, Token }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.post(Base_url, values, { headers });

      if (response.data.status === true) {
        SuccessAlert(response.data.message);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data ;
    }
  }
);

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% GET ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const GetExpenseApi = createAsyncThunk(
  "GetExpenseApi",
  async ({ Token }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.get(Base_url, { headers });

      if (response.data.status === false) {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data;
    }
  }
);

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% PUT ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const PutExpenseApi = createAsyncThunk(
  "PutExpenseApi",
  async ({ Token, values, id }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };
    const jsondata = JSON.stringify(values);

    try {
      const response = await axios.put(`${Base_url}/edit/${id}`, jsondata, {
        headers,
      });
      if (response.data.status === true) {
        SuccessAlert(response.data.message);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data ;
    }
  }
);

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% DELETE ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const DeleteExpenseApi = createAsyncThunk(
  "DeleteExpenseApi",
  async ({ Token, id }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.delete(`${Base_url}/delete/${id}`, {
        headers,
      });
      if (response.data.status === true) {
        SuccessAlert(response.data.message);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data ;
    } catch (error) {
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data ;
    }
  }
);

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% SINGLE ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const SingleExpenseApi = createAsyncThunk(
  "SingleExpenseApi",
  async ({ Token, values, id }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };
    const jsondata = JSON.stringify(values);
    try {
      const response = await axios.get(`${Base_url}/${id}`, jsondata, {
        headers,
      });
      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data;
    }
  }
);

const ExpenseSlice = createSlice({
  name: "ExpenseSlice",
  initialState: {
    isLoading: false,
    status: null,
    message: "",
    ExpenseData: [],
  },
  extraReducers: (builder) => {
    //%%%%%%%%%%%%%%%%%%%%%%% POST API %%%%%%%%%%%%%%%%%%%%%%
    builder.addCase(PostExpenseApi.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(PostExpenseApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(PostExpenseApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.status = action.payload.status;
    });

    //%%%%%%%%%%%%%%%%%%%%%%% GET API %%%%%%%%%%%%%%%%%%%%%%
    builder.addCase(GetExpenseApi.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetExpenseApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetExpenseApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.status = action.payload.status;
      state.message = action.payload.message;
      state.ExpenseData = action.payload.status
        ? action.payload.result
        : [];
    });

    //%%%%%%%%%%%%%%%%%%%%%%% DELETE API %%%%%%%%%%%%%%%%%%%%%%
    builder.addCase(DeleteExpenseApi.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(DeleteExpenseApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(DeleteExpenseApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.status = action.payload.status;
    });

    //%%%%%%%%%%%%%%%%%%%%%%% PUT API %%%%%%%%%%%%%%%%%%%%%%
    builder.addCase(PutExpenseApi.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(PutExpenseApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(PutExpenseApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.status = action.payload.status;
      state.message = action.payload.message;
    });
  },
});

export default ExpenseSlice.reducer;

import React, { useState } from "react";
import {
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  Table,
  Paper,
  TableRow,
  IconButton,
  Collapse,
  Typography,
  Tooltip,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import CreditScoreTwoToneIcon from "@mui/icons-material/CreditScoreTwoTone";
import {
  DeleteRoomServiceApi,
  GetRoomServiceApi,
} from "../../../../store/Slice/RoomServiceSlice/RoomServiceSlice";
import { MySwal } from "../../../../Model/Alert/SuccessAlert";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
const DropDownView = ({ data,HandleRoomServiceStatusPaid }) => {
  const TableHeader = [
    "#",
    "SR.No",
    "Action",
    "Order Date",
    "Name",
    "Email",
    "Mobile",
    "Room No",
    "CGST",
    "SGST",
    "IGST",
    "Discount",
    "Total",
  ];

  const Token = Cookies.get("Token");
  const dispatch = useDispatch();

  const [submenuopen, setsubmenuopen] = useState(null);

  const handlesubmenuOPen = (id) => {
    setsubmenuopen((previouse) => (previouse === id ? null : id));
  };

  const handleDelete = (id) => {
    console.log("Btn Was Clicked1", id);
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#dc3545",
      cancelButtonText: "No, cancel!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        console.log("Btn Was Clicked2", id);
        await dispatch(DeleteRoomServiceApi({ Token, id }));
        await dispatch(GetRoomServiceApi({ Token }));
      } else if (result.dismiss === MySwal.DismissReason.cancel) {
        console.log("Btn Was Clicked3", id);
        MySwal.fire({
          title: "Cancelled",
          text: "Your data is safe.",
          icon: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "#dc3545",
        });
      }
    });
  };

  return (
    <>
      <TableContainer
        component={Paper}
        style={{ maxWidth: "100%", overflowX: "auto" }}
      >
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {TableHeader &&
                TableHeader.length > 0 &&
                TableHeader.map((item, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      backgroundColor: "#0288d1",
                      color: "white",
                      border: "10px solid",
                      borderRadius: 6,
                      py: 1,
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      fontSize={"15px"}
                      sx={{ textAlign: "center" }}
                    >
                      {item}
                    </Typography>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.length > 0 &&
              data.map((item, index) => (
                <React.Fragment key={item?.id}>
                  <TableRow>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handlesubmenuOPen(item?.id)}
                      >
                        {submenuopen ? (
                          <KeyboardArrowUp />
                        ) : (
                          <KeyboardArrowDown />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">
                        {item?.serialNumber}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <div>
                        <Tooltip title={"Edit Booking"} placement="top" arrow>
                          <NavLink to={`/edit-service/${item?.id}`}>
                            <IconButton
                              disabled={item?.status !== "PAID" ? false : true}
                            >
                              <BorderColorOutlinedIcon
                                sx={{ color: "#6d75eb" }}
                              />
                            </IconButton>
                          </NavLink>
                        </Tooltip>
                        <Tooltip title={"Mark as paid"} placement="left" arrow>
                          <IconButton
                            onClick={() => {
                              HandleRoomServiceStatusPaid(item);
                            }}
                            disabled={
                              item?.payment_status !== "PAID" ? false : true
                            }
                          >
                            <CreditScoreTwoToneIcon
                              sx={{
                                color: `${
                                  item?.payment_status !== "PAID"
                                    ? "#34a853"
                                    : "grey"
                                }`,
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        {/* <IconButton onClick={() => handleDelete(item?.id)}>
                          <DeleteOutlinedIcon sx={{ color: "#ff5757" }} />
                        </IconButton> */}
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">
                        {new Intl.DateTimeFormat("en-GB", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                        }).format(new Date(item?.order_date))}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">
                        {item?.fullname}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">{item?.email}</Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">
                        {item?.phone_number}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      <Typography variant="subtitle">
                        {item?.room_no}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      <Typography variant="subtitle">{item?.cgst}</Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      <Typography variant="subtitle">{item?.sgst}</Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      <Typography variant="subtitle">{item?.igst}</Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">
                        {item?.discount}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      <Typography variant="subtitle">{item?.total}</Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={TableHeader.length + 1}
                    >
                      <Collapse
                        in={submenuopen === item?.id}
                        timeout={"auto"}
                        unmountOnExit
                      >
                        <Table>
                          <TableHead>
                            <TableRow>
                              {/* <TableCell
                                sx={{
                                  backgroundColor: "#9C27B0",
                                  color: "white",
                                  border: "10px solid",
                                  borderRadius: 6,
                                  textAlign: "center",
                                }}
                              >
                                Images
                              </TableCell> */}
                              <TableCell
                                sx={{
                                  backgroundColor: "#9C27B0",
                                  color: "white",
                                  border: "10px solid",
                                  borderRadius: 6,
                                  textAlign: "center",
                                }}
                              >
                                Sr. No.
                              </TableCell>
                              <TableCell
                                sx={{
                                  backgroundColor: "#9C27B0",
                                  color: "white",
                                  border: "10px solid",
                                  borderRadius: 6,
                                  textAlign: "center",
                                }}
                              >
                                Code
                              </TableCell>
                              <TableCell
                                sx={{
                                  backgroundColor: "#9C27B0",
                                  color: "white",
                                  border: "10px solid",
                                  borderRadius: 6,
                                  textAlign: "center",
                                }}
                              >
                                Name
                              </TableCell>
                              <TableCell
                                sx={{
                                  backgroundColor: "#9C27B0",
                                  color: "white",
                                  border: "10px solid",
                                  borderRadius: 6,
                                  textAlign: "center",
                                }}
                              >
                                Quantity
                              </TableCell>
                              <TableCell
                                sx={{
                                  backgroundColor: "#9C27B0",
                                  color: "white",
                                  border: "10px solid",
                                  borderRadius: 6,
                                  textAlign: "center",
                                }}
                              >
                                Total
                              </TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {item?.room_service_items &&
                              item?.room_service_items.length > 0 &&
                              item?.room_service_items.map(
                                (foodItem, index) => (
                                  <TableRow key={index}>
                                    {/* <TableCell>
                                    <LazyLoadImage
                                      src={
                                        foodItem?.images
                                          ? `${process.env.REACT_APP_API_URL}/uploads/foods/${foodItem?.images}`
                                          : placeholderImage
                                      }
                                      effect="blur"
                                      style={{
                                        height: "40px",
                                        width: "70px",
                                        borderRadius: "10px",
                                        cursor: "pointer",
                                        marginBottom: "10px",
                                        marginTop: "5px",
                                      }}
                                    />
                                  </TableCell> */}
                                    <TableCell sx={{ textAlign: "center" }}>
                                      {+index + 1}
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>
                                      {foodItem?.item_code}
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>
                                      {foodItem?.item_name}
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>
                                      {foodItem?.quantity}
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>
                                      {foodItem?.total}
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                          </TableBody>
                        </Table>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default DropDownView;

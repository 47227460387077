import React from "react";
import Chart from "react-apexcharts";
import ReactEcharts from "echarts-for-react";

const SparklineChart = ({ color }) => {
  const chartOptions = {
    chart: {
      id: "sparkline1",
      type: "area",
      height: 70,
      sparkline: {
        enabled: true,
      },
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    series: [
      {
        name: "Sales",
        data: [38, 60, 38, 52, 36, 40, 28],
      },
    ],
    labels: ["1", "2", "3", "4", "5", "6", "7"],
    // yaxis: {
    //   min: 0,
    // },
    colors: [color],
    tooltip: {
      enabled: false,
    },
    grid: {
      show: false,
      xaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        top: 5,
        right: 0,
        left: 0,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.3,
        opacityTo: 0.05,
        stops: [100, 100],
      },
    },
  };

  return (
    <Chart
      options={chartOptions}
      series={chartOptions.series}
      type="area"
      height={70}
    />
  );
};

const BarChartExample = ({ monthlyData }) => {
 

  const months = monthlyData.map((data) => data.month);
  const total_visitors = monthlyData.map((data) => data.total_visitors);

  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    xAxis: {
      type: "category",
      data: months,
      axisLabel: {
        interval: 0,
        rotate: 45,
      },
    },
    yAxis: [
      {
        type: "value",
        name: "Number of Vistors",
        position: "top",
        offset: -6,
      },
    ],
    series: [
      {
        name: "Number of Visitors",
        type: "bar",
        stack: "total",
        emphasis: {
          focus: "series",
        },
        data: total_visitors.map((count) => (count)), 
        itemStyle: {
              color: "#7460ee",
        },
      },
    ],
  };

  return (
    <ReactEcharts
      option={option}
      style={{ height: "475px", width: "100%" }}
      className="echarts-for-react"
    />
  );
};

const MyPieChart = ({ data }) => {
  
  data = Object.keys(data).map((name) => ({ value: data[name], name }));

  const option = {
    tooltip: {
      trigger: "item",
    },
    legend: {
      top: "90%",
      left: "center",
    },
    series: [
      {
        name: "Access From",
        type: "pie",
        radius: ["40%", "70%"],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: "#fff",
          borderWidth: 2,
        },
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 40,
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
        },
        data:data
        // data: [
        //   // {}
        //   // { value: , name: 'Total Rooms' },
        //   { value: data.double, name: "Dual Rooms" },
        //   { value: data.single, name: "single Room" },
        //   // { value: 20, name: 'Other' },
        // ],
      },
    ],
  };
  return (
    <ReactEcharts
      option={option}
      style={{ height: "400px", width: "100%" }}
      className="echarts-for-react"
    />
  );
};

// export default SparklineChart
export { SparklineChart, BarChartExample, MyPieChart };

import { useEffect, useState, useMemo } from "react";
import {
  Box,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  Tooltip,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import DataTable from "../../../DataTable";
import CollapsedBreadcrumbs from "../../Include/Breadcrumb";
import { Avatar } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import UploadIcon from "@mui/icons-material/Upload";
import ExcellIcon from "../../../../Assets/img/Exellicon.png";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
// import GuestDetail from "../Guest/GuestDetail";
// import Icecream from "../../../../Assets/img/iceCream.avif";
import {
  AddExpense,
  EditExpense,
  DataUploadForm,
} from "../../../../Model/Model";
import SwipableDrawer from "../../Include/SwipableDrawer";
import EditRoadOutlinedIcon from "@mui/icons-material/EditRoadOutlined";
import handleExcelConversion from "../Reports/ExcellsFormat";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import {
  GetCategoryApi,
  DeleteCategoryApi,
} from "../../../../store/Slice/FoodCategorySlice/FoodCategorySlice";
import { MySwal } from "../../../../Model/Alert/SuccessAlert";
import handleFilter from "../../../Searching";
import {
  DeleteExpenseApi,
  GetExpenseApi,
} from "../../../../store/Slice/ExpenseSlice/ExpenseSlice";
const BreadCrumbLink = [
  {
    id: 1,
    name: "Home",
    link: "/",
  },
  {
    id: 1,
    name: "Expenses",
    link: "/expense",
  },
];
const Expense = () => {
  const Token = Cookies.get("Token");
  const dispatch = useDispatch();
  const { ExpenseData } = useSelector((state) => state.ExpenseStore);
  const [record, setrecord] = useState([]);
  const IsMobile = useMediaQuery("(min-width:900px)");

  useEffect(() => {
    const FetchData = async () => {
      try {
        await dispatch(GetExpenseApi({ Token }));
      } catch (error) {
        return error;
      }
    };
    FetchData();
  }, [Token, dispatch]);

  const datawithSerialNumber = useMemo(() => {
    // Ensure ExpenseData is an array before mapping
    return ExpenseData
      ? ExpenseData.map((item, index) => ({
          ...item,
          serialNumber: index + 1,
        }))
      : [];
  }, [ExpenseData]);

  useEffect(() => {
    setrecord(datawithSerialNumber);
  }, [datawithSerialNumber]);

  // const [handlegridrow, sethandlegridrow] = useState(null);

  const [AddModelOpen, setAddModelOpen] = useState(false);
  const handlModelopen = () => setAddModelOpen(true);
  const handlModelClose = () => setAddModelOpen(false);
  const [dataUploadModelOpen, setdataUploadModelOpen] = useState(false);
  const handlUploadModelOpen = () => setdataUploadModelOpen(true);
  const handlUploadModelClose = () => setdataUploadModelOpen(false);

  const [updatedata, setupdatedata] = useState({});
  const [EditModelOpen, setEditModelOpen] = useState(false);

  const handlModelopenEdit = (data) => {
    setEditModelOpen(true);
    setupdatedata(data);
  };
  const handlModelCloseEdit = () => setEditModelOpen(false);

  const handleDeleteExpense = async (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#dc3545",
      cancelButtonText: "No, cancel!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await dispatch(DeleteExpenseApi({ Token, id }));
        await dispatch(GetExpenseApi({ Token }));
      } else if (result.dismiss === MySwal.DismissReason.cancel) {
        MySwal.fire({
          title: "Cancelled",
          text: "Your data is safe.",
          icon: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "#dc3545",
        });
      }
    });
  };

  const [columns, setColumns] = useState([
    {
      field: "serialNumber",
      headerName: "SR.No",
      width: 0,
      headerAlign: "center",
      align: "center",
      visible: true,
      // marginLeft:"20px"
    },
    {
      field: "date",
      headerName: "Date",
      visible: true,
      align: "center",
      headerAlign: "center",
      width: 190,
      renderCell: (param) => (
        <div>
          {new Intl.DateTimeFormat("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          }).format(new Date(param.row?.date))}
        </div>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      width: 200,
      headerAlign: "center",
      align: "center",
      visible: true,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 200,
      headerAlign: "center",
      align: "center",
      visible: true,
    },

    {
      field: "Action",
      headerName: "Action",
      width: 200,
      headerAlign: "center",
      align: "center",
      visible: true,
      renderCell: (param) => (
        <div>
          <IconButton
            onClick={() => {
              handlModelopenEdit(param.row);
            }}
          >
            <BorderColorOutlinedIcon sx={{ color: "#6d75eb" }} />
          </IconButton>
          <IconButton onClick={() => handleDeleteExpense(param.row.id)}>
            <DeleteOutlinedIcon sx={{ color: "#ff5757" }} />
          </IconButton>
        </div>
      ),
    },
  ]);

  const handleToggleColumn = (field) => {
    setColumns((prevColumns) =>
      prevColumns.map(
        (col) => (col.field === field ? { ...col, visible: !col.visible } : col)
        // console.log("col",col)
      )
    );
  };

  const [Swipeopen, setSwipeopen] = useState(false);
  const HandleSwipeOpen = () => setSwipeopen(true);
  const HandleSwipeClose = () => setSwipeopen(false);

  const keys_serach = ["description", "expense_date", "amount"];

  return (
    <>
      <Box sx={{ padding: "20px", width: "100%" }}>
        <Stack
          direction={IsMobile ? "row" : "column"}
          justifyContent={"space-between"}
        >
          <Typography variant="h6">Expense</Typography>
          <CollapsedBreadcrumbs data={BreadCrumbLink} />
        </Stack>

        <Grid container>
          <Grid item xs={12}>
            <Paper
              sx={{ padding: "10px", marginTop: "20px", borderRadius: "10px" }}
            >
              <Grid container>
                <Grid item lg={9} md={9} sm={9} xs={12}>
                  <Box
                    sx={{
                      width: { xs: "100%", sm: "" },
                    }}
                  >
                    <TextField
                      label="Search"
                      size="small"
                      onChange={(event) => {
                        handleFilter({
                          event,
                          dataWithSerialNumber: datawithSerialNumber,
                          setrecord,
                          keys_serach,
                        });
                      }}
                      sx={{
                        bgcolor: "#dae1f3",
                        borderRadius: "10px",
                        width: { xs: "100%", sm: "230px" },

                        "& fieldset": { border: "none" },
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignContent: "center",
                      alignItems: "center",
                      mt: { xs: 2, sm: 0 },
                    }}
                  >
                    <Avatar sx={{ background: "#6970E2" }}>
                      <Tooltip title="Table Edit">
                        <IconButton onClick={HandleSwipeOpen}>
                          <EditRoadOutlinedIcon sx={{ color: "#fff" }} />
                        </IconButton>
                      </Tooltip>
                    </Avatar>

                    <Avatar sx={{ background: "#EA82A6" }}>
                      <Tooltip title="Add Expense">
                        <IconButton onClick={handlModelopen}>
                          <AddIcon sx={{ color: "#fff" }} />
                        </IconButton>
                      </Tooltip>
                    </Avatar>
                    <Avatar
                      src={ExcellIcon}
                      onClick={() => {
                        handleExcelConversion(record);
                      }}
                    ></Avatar>
                    <Avatar sx={{ background: "#58c2ff" }}>
                      <Tooltip title="Bulk Upload">
                        <IconButton onClick={handlUploadModelOpen}>
                          <UploadIcon sx={{ color: "#fff" }} />
                        </IconButton>
                      </Tooltip>
                    </Avatar>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Box sx={{ marginTop: "30px", paddingLeft: "10px" }}>
          <Grid container spacing={2}>
            {/* <Grid item lg={handlegridrow ? 6 : 12} xs={12} mt={-3}> */}
            <Grid item lg={12} xs={12} mt={-3}>
              <Paper sx={{ mt: 2 }}>
                <DataTable columns={columns} rows={record} />
              </Paper>
            </Grid>
            {/* </Grid> */}

            {/* <Grid item lg={handlegridrow ? 6 : 4} xs={handlegridrow || 12}>
              {handlegridrow && (
                <Paper sx={{ borderRadius: "10px" }}>
                  <GuestDetail
                    userDetails={categoryDetail}
                    icons={icons}
                    MainImage={Icecream}
                    sethandlegridrow={sethandlegridrow}
                  />
                </Paper>
              )}
            </Grid> */}
          </Grid>
        </Box>
      </Box>
      <Paper>
        {AddModelOpen && (
          <AddExpense open={AddModelOpen} handleClose={handlModelClose} />
        )}

        {dataUploadModelOpen && (
          <DataUploadForm
            open={dataUploadModelOpen}
            handleClose={handlUploadModelClose}
            uploadType={"CATEGORY"}
            demoFile={"Category_Demo.xlsx"}
          />
        )}

        {EditModelOpen && (
          <EditExpense
            open={EditModelOpen}
            handleClose={handlModelCloseEdit}
            data={updatedata}
          />
        )}

        <SwipableDrawer
          open={Swipeopen}
          close={HandleSwipeClose}
          columns={columns}
          handleToggleColumn={handleToggleColumn}
        />
      </Paper>
    </>
  );
};
export default Expense;

import * as XLSX from "xlsx";

const flattenObject = (obj, parent = "", res = {}) => {
  for (const key in obj) {
    const propName = parent ? `${parent}.${key}` : key;
    if (typeof obj[key] === "object" && obj[key] !== null) {
      flattenObject(obj[key], propName, res);
    } else {
      res[propName] = obj[key];
    }
  }
  return res;
};

const handleExcelConversion = (records) => {
  const flattenedRecords = records.map((record) => flattenObject(record));
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(flattenedRecords);
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, "Data.xlsx", { bookType: "xlsx" });
};

export const handleExcelDropdownConversion = (records) => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet([]);
  // let currentRowIndex = 1; // Start at row 1

  records.forEach((record, index) => {
    let { room_service_items, id, room_id, serialNumber, ...rest } = record;

    if (index === 0) {
      XLSX.utils.sheet_add_json(ws, [rest], {
        // skipHeader: true,
        origin: -1, // Append new rows after the last used row
      });
    } else {
      XLSX.utils.sheet_add_json(ws, [{}], {
        skipHeader: true,
        origin: -1, // Append new rows after the last used row
      });
      XLSX.utils.sheet_add_json(ws, [rest], {
        skipHeader: true,
        origin: -1, // Append new rows after the last used row
      });
    }

    room_service_items.forEach((item, i) => {
      let { id, item_id, service_id, created_at, updated_at, images, ...rest } =
        item;

      if (i === 0) {
        XLSX.utils.sheet_add_json(ws, [rest], {
          origin: { r: -1, c: 2 },
        });
      } else {
        XLSX.utils.sheet_add_json(ws, [rest], {
          skipHeader: true,
          origin: { r: -1, c: 2 },
        });
      }
    });
  });

  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, "Data.xlsx", { bookType: "xlsx" });
};

export default handleExcelConversion;

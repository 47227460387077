import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  TextField,
  Checkbox,
  Stack,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { NavLink } from "react-router-dom";
import { LoginSchema } from "../../../schema/Schema";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { LoginApi } from "../../../store/Slice/AuthSlice/AuthSlice";
import Cookies from "js-cookie";

const Loginpage = () => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const initialValues = {
    email: null,
    password: "",
    rememberMe: false,
  };

  const {
    errors,
    values,
    setFieldValue,
    setValues,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      try {
        await dispatch(LoginApi({ values }));
        const Token = Cookies.get("Token");
        if (Token) {
          if (values.rememberMe === true) {
            localStorage.setItem("email", values.email);
            localStorage.setItem("password", values.password);
            localStorage.setItem("rememberMe", values.rememberMe);
          } else {
            localStorage.removeItem("email");
            localStorage.removeItem("password");
            localStorage.removeItem("rememberMe");
          }
        }
      } catch (error) {
        return error;
      }
    },
  });
  console.log(errors);

  useEffect(() => {
    setValues({
      ...values,
      email: localStorage.getItem("email"),
      password: localStorage.getItem("password"),
      rememberMe: localStorage.getItem("rememberMe"),
    });
  }, []);

  useEffect(() => {
    const storedRememberMe = localStorage.getItem("rememberMe") === "true";
    if (storedRememberMe) {
      let email = localStorage.getItem("email");
      setFieldValue("email", email);
      let password = localStorage.getItem("password");
      setFieldValue("password", password);
    }
    setFieldValue("rememberMe", storedRememberMe);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Paper
        sx={{
          height: "430px",
          width: "450px",
          padding: "30px",
          borderRadius: "20px",
        }}
      >
        <form onSubmit={handleSubmit}>
          <Box>
            <Typography variant="h5">Login</Typography>
            <Typography variant="subtitle1" mt={2}>
              Enter Your Email and Password To Login
            </Typography>
          </Box>
          <Stack mt={4} direction={"column"} gap={4}>
            <TextField
              label="Email"
              name="email"
              size="large"
              type="email"
              value={values.email}
              onChange={handleChange}
              autoFocus
              onBlur={handleBlur}
              error={touched.email && errors.email}
              helperText={touched.email && errors.email}
            />
            <FormControl sx={{ width: "100%" }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.password && errors.password}
              />
              {touched.password && errors.password && (
                <Typography variant="caption" color="error">
                  {errors.password}
                </Typography>
              )}
            </FormControl>
          </Stack>

          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignContent={"center"}
            alignItems={"center"}
            mt={2}
          >
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="rememberMe"
                      color="secondary"
                      checked={values.rememberMe ? true : false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  }
                  label="Remember Me"
                  sx={{ m: 0 }}
                />
              </FormGroup>
            </Box>
            <NavLink to={"/Reset"}>
              <Typography
                variant="h6"
                color={"#EFC175"}
                sx={{ textDecoration: "none" }}
              >
                Forget Password ?
              </Typography>
            </NavLink>
          </Stack>

          <Stack
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            mt={2}
          >
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              sx={{ background: "#805dca", width: "100%" }}
            >
              Login
            </Button>
          </Stack>
        </form>
      </Paper>
    </Box>
  );
};

export default Loginpage;

import { createAsyncThunk,createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const Base_Url=process.env.REACT_APP_API_URL + "/booking";
const POST_Base_Url=process.env.REACT_APP_API_URL + "/all/booking";

export const GetAllBookingRecord=createAsyncThunk("GetAllBookingRecord",async({Token})=>{
    try{
        const headers={"Content-Type":"application/json","Authorization":`Bearer ${Token}`}
    const response=await axios.get(Base_Url,{headers})
    return response.data

    }catch(error){
        return error
    }
})

export const GetAllBookingRecordPOST=createAsyncThunk("GetAllBookingRecordPOST",async({Token,data})=>{
    try{
        const headers={"Content-Type":"application/json","Authorization":`Bearer ${Token}`}
    const response=await axios.post(POST_Base_Url,data,{headers})
    return response.data

    }catch(error){
        return error
    }
})

const BookingrecordSlice=createSlice({
    name:"BookingrecordSlice",
    initialState:{
        status:null,
        BookingRecordData:[],
        loading:null,
        message:""
    },
    extraReducers:(builder)=>{
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%% BOOKING GET RECORD API HANDLE %%%%%%%%%%%%%%%%%%%%%%%%%%
        builder.addCase(GetAllBookingRecord.rejected,(state,action)=>{
            state.loading=false
        })
        builder.addCase(GetAllBookingRecord.pending,(state,action)=>{
            state.loading=true
          })
          builder.addCase(GetAllBookingRecord.fulfilled,(state,action)=>{
            state.loading=true
            state.BookingRecordData=action.payload.result
          })


           // %%%%%%%%%%%%%%%%%%%%%%%%%%%%% BOOKING GET RECORD API HANDLE %%%%%%%%%%%%%%%%%%%%%%%%%%
        builder.addCase(GetAllBookingRecordPOST.rejected,(state,action)=>{
            state.loading=false
        })
        builder.addCase(GetAllBookingRecordPOST.pending,(state,action)=>{
            state.loading=true
          })
          builder.addCase(GetAllBookingRecordPOST.fulfilled,(state,action)=>{
            state.loading=true
            state.BookingRecordData=action.payload.result
          })
    }
})

export default BookingrecordSlice.reducer

import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
// import Link from '@mui/material/Link';
import { Link } from 'react-router-dom';

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

export default function CollapsedBreadcrumbs({ data }) {
  return (
    <div role="presentation" onClick={handleClick}>
      <Breadcrumbs maxItems={2} aria-label="breadcrumb">
        {
          data.map((item, index) => (
            <Link to={item.link} key={index}>{item.name}</Link>
          ))
        }
      </Breadcrumbs>
    </div>
  );
}

// <Link underline="hover" color="inherit" href={item.link} key={index}>{item.name}</Link>
import React, { useEffect, useState } from "react";
import "../ViewButton.css";
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
  MenuItem,
  Menu,
} from "@mui/material";
import { BarChartExample, MyPieChart, SparklineChart } from "./Chart";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Visibility from "@mui/icons-material/Visibility";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import placeholderImage from "../../../Assets/img/room-placeholder.jpg";
import { DasboardsDetails } from "../../../store/Slice/DashBoardSlice.js/DashboardSlice";
import DataTable from "../../DataTable";
import { CustomSnackBar } from "../../../Model/Model";

const DashBoard = () => {
  const dispatch = useDispatch();
  const Token = Cookies.get("Token");
  const {
    CardDetails,
    RecentBookings,
    RecentCheckIns,
    RecentOrders,
    RoomSales,
    Visitors,
  } = useSelector((state) => state.DashBoardStore);

  const [CardDetailsRecord, setCardDetailsRecord] = useState({});
  const [RecentBookingsRecord, setRecentBookingsRecord] = useState({});
  const [RecentCheckInsRecord, setRecentCheckInsRecord] = useState({});

  const [RecentOrdersRecord, setRecentOrdersRecord] = useState([]);
  const [RoomSalesRecord, setRoomSalesRecord] = useState({});
  const [VisitorsRecords, setVisitorsRecords] = useState([]);

  let navigate = useNavigate();

  useEffect(() => {
    const Fetchdata = async () => {
      try {
        await dispatch(DasboardsDetails({ Token }));
      } catch (error) {
        return error;
      }
    };
    Fetchdata();
  }, [dispatch, Token]);

  useEffect(() => {
    if (CardDetails) {
      setCardDetailsRecord(CardDetails);
    }
    setRecentBookingsRecord(RecentBookings || {});
    setRecentCheckInsRecord(RecentCheckIns || {});
    setRecentOrdersRecord(RecentOrders || []);
    setRoomSalesRecord(RoomSales || {});
    setVisitorsRecords(Visitors || []);
  }, [
    CardDetails,
    RecentBookings,
    RecentCheckIns,
    RecentOrders,
    RoomSales,
    Visitors,
  ]);

  const handleDoubleClick = (path) => {
    navigate(path);
  };

  const BookingCoumns = [
    {
      field: "booking_date",
      headerName: "Date",
      visible: true,
      align: "center",
      headerAlign: "center",
      width: 190,
      renderCell: (param) => (
        // <div>{new Date(param.row.check_in).toLocaleDateString()}</div>
        <div
          onDoubleClick={() =>
            handleDoubleClick(`/room-invoice/${param.row.id}`)
          }
        >
          {new Intl.DateTimeFormat("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          }).format(new Date(param.row?.booking_date))}
        </div>
      ),
    },
    {
      field: "guest_name",
      headerName: "Name",
      align: "center",
      headerAlign: "center",
      width: 120,
      visible: true,
      renderCell: (param) => {
        const RoomNo =
          param.row &&
          param.row?.guestmaster &&
          param.row?.guestmaster?.fullname
            ? param.row?.guestmaster?.fullname
            : "";
        return (
          <div
            onDoubleClick={() =>
              handleDoubleClick(`/room-invoice/${param.row?.id}`)
            }
          >
            <Typography variant="body">{RoomNo}</Typography>
          </div>
        );
      },
    },

    {
      field: "room_no",
      headerName: "Room No",
      visible: true,
      align: "center",
      headerAlign: "center",
      width: 140,
      renderCell: (param) => {
        const RoomNo =
          param.row && param.row?.roommaster && param.row?.roommaster?.room_no
            ? param.row?.roommaster?.room_no
            : "";
        return (
          <div
            onDoubleClick={() =>
              handleDoubleClick(`/room-invoice/${param.row.id}`)
            }
          >
            <Typography variant="body">{RoomNo}</Typography>
          </div>
        );
      },
    },

    {
      field: "images",
      headerName: "Image",
      align: "center",
      headerAlign: "center",
      visible: true,
      width: 100,
      renderCell: (param) => {
        const imageUrl =
          param.row && param.row?.roommaster && param.row?.roommaster?.images
            ? `${process.env.REACT_APP_API_URL}/uploads/rooms/${
                param.row?.roommaster?.images.split(",")[0]
              }`
            : placeholderImage;

        return (
          <div
            onDoubleClick={() =>
              handleDoubleClick(`/room-invoice/${param.row.id}`)
            }
          >
            <LazyLoadImage
              effect="blur"
              src={imageUrl}
              style={{
                height: "40px",
                width: "50px",
                borderRadius: "10px",
                cursor: "pointer",
                marginBottom: "10px",
                marginTop: "5px",
              }}
            />
          </div>
        );
      },
    },

    {
      field: "Action",
      headerName: "Action",
      visible: true,
      align: "center",
      headerAlign: "center",
      width: 120,
      borderRadius: "20px",
      renderCell: (param) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <div class="viewBtn">
              <NavLink to={`/room-invoice/${param.row.id}`}>
                <div class="viewBtn-wrapper">
                  <div class="text">view</div>
                  <span class="icon">
                    <Visibility />
                  </span>
                </div>
              </NavLink>
            </div>
          </div>
        );
      },
    },
  ];

  const CheckInColumns = [
    {
      field: "booking_date",
      headerName: "Date",
      visible: true,
      align: "center",
      headerAlign: "center",
      width: 190,
      renderCell: (param) => (
        // <div>{new Date(param.row.check_in).toLocaleDateString()}</div>
        <div>
          {new Intl.DateTimeFormat("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          }).format(new Date(param.row.check_in))}
        </div>
      ),
    },

    {
      field: "guest_name",
      headerName: "Name",
      visible: true,
      align: "center",
      headerAlign: "center",
      width: 120,
      renderCell: (param) => {
        const RoomNo =
          param.row && param.row.guestmaster && param.row.guestmaster.fullname
            ? param.row.guestmaster.fullname
            : "";
        return <Typography variant="body">{RoomNo}</Typography>;
      },
    },

    {
      field: "room_no",
      headerName: "Room No",
      align: "center",
      headerAlign: "center",
      width: 120,
      visible: true,
      renderCell: (param) => {
        const RoomNo =
          param.row && param.row.roommaster && param.row.roommaster.room_no
            ? param.row.roommaster.room_no
            : "";
        return <Typography variant="body">{RoomNo}</Typography>;
      },
    },

    {
      field: "images",
      headerName: "Image",
      align: "center",
      headerAlign: "center",
      width: 120,
      visible: true,
      renderCell: (param) => {
        const imageUrl =
          param.row && param.row?.roommaster && param.row?.roommaster?.images
            ? `${process.env.REACT_APP_API_URL}/uploads/rooms/${
                param.row?.roommaster?.images.split(",")[0]
              }`
            : placeholderImage;

        return (
          <LazyLoadImage
            effect="blur"
            src={imageUrl}
            style={{
              height: "40px",
              width: "50px",
              borderRadius: "10px",
              cursor: "pointer",
              marginBottom: "10px",
              marginTop: "5px",
            }}
          />
        );
      },
    },
  ];

  const recentOrderColumns = [
    {
      field: "order_date",
      width: 300,
      align: "center",
      headerAlign: "center",
      // width:140,
      headerName: "Order Date",
      visible: true,
      renderCell: (param) => (
        <div onDoubleClick={() => handleDoubleClick("/room-service")}>
          {new Intl.DateTimeFormat("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          }).format(new Date(param.row?.order_date))}
        </div>
      ),
    },

    {
      field: "guest_name",
      width: 300,
      align: "center",
      headerAlign: "center",
      headerName: "Name",
      visible: true,
      renderCell: (param) => {
        const name =
          param.row &&
          param.row?.reservationmaster &&
          param.row?.reservationmaster?.guestmaster &&
          param.row?.reservationmaster?.guestmaster?.fullname
            ? param.row.reservationmaster?.guestmaster?.fullname
            : "";
        return (
          <div onDoubleClick={() => handleDoubleClick("/room-service")}>
            <Typography variant="body">{name}</Typography>
          </div>
        );
      },
    },

    {
      field: "phone_number",
      width: 300,
      align: "center",
      headerAlign: "center",
      headerName: "Phone",
      visible: true,
      renderCell: (param) => {
        const num =
          param.row &&
          param.row?.reservationmaster &&
          param.row?.reservationmaster?.guestmaster &&
          param.row?.reservationmaster?.guestmaster?.phone_number
            ? param.row?.reservationmaster?.guestmaster?.phone_number
            : "";
        return (
          <div onDoubleClick={() => handleDoubleClick("/room-service")}>
            <Typography variant="body">{num}</Typography>
          </div>
        );
      },
    },

    {
      field: "room_no",
      // width: 220,
      headerName: "Room No",
      width: 200,
      align: "center",
      headerAlign: "center",
      visible: true,
      renderCell: (param) => {
        const room_no =
          param.row &&
          param.row?.reservationmaster &&
          param.row?.reservationmaster?.roommaster &&
          param.row?.reservationmaster?.roommaster?.room_no
            ? param.row?.reservationmaster?.roommaster?.room_no
            : "";
        return (
          <div onDoubleClick={() => handleDoubleClick("/room-service")}>
            <Typography variant="body">{room_no}</Typography>
          </div>
        );
      },
    },

    {
      field: "Action",
      width: 110,
      align: "center",
      headerAlign: "center",
      headerName: "Action",
      visible: true,
      renderCell: (param) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <div class="viewBtn">
              <NavLink to={"/room-service"}>
                <div class="viewBtn-wrapper">
                  <div class="text">view</div>
                  <span class="icon">
                    <Visibility />
                  </span>
                </div>
              </NavLink>
            </div>
          </div>
        );
      },
    },
  ];

  const [OpneMenu, setOpneMenu] = useState(null);
  const [menuType, setMenuType] = useState("");
  const [timeFrame, setTimeFrame] = useState({
    totalRental: "weekly",
    totalPendingRental: "weekly",
    totalRoomService: "weekly",
    totalIncome: "weekly",
    totalExpense: "weekly",
  });
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const handleClick = (event, type) => {
    setOpneMenu(event.currentTarget);
    setMenuType(type);
  };

  const handleClose = (selectedTimeFrame) => {
    if (selectedTimeFrame) {
      setTimeFrame((prev) => ({ ...prev, [menuType]: selectedTimeFrame }));
      setToastMessage(`${menuType} set to ${selectedTimeFrame}`);
      setToastOpen(true);
    }
    setOpneMenu(null);
    setMenuType("");
  };

  // Bar Chart
  const getMonthName = (dateString) => {
    const date = new Date(dateString);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return monthNames[date.getMonth()];
  };

  useEffect(() => {
    const transformedData = Visitors
      ? Visitors.map((item) => ({
          ...item,
          month: getMonthName(item.month),
        }))
      : [];
    setVisitorsRecords(transformedData);
  }, [Visitors]);

  return (
    <Box sx={{ padding: "20px", width: "100%" }}>
      <Grid container spacing={3}>
        <Grid container item xs={12} spacing={3}>
          <Grid item lg={4} md={12} sm={12} xs={12}>
            <Paper
              sx={{
                padding: "20px",
                borderRadius: "10px",
                transition: "0.3s",
                "&:hover": {
                  transform: "translateY(-5px)",
                },
              }}
            >
              <Stack
                direction={"row"}
                spacing={1}
                justifyContent={"space-between"}
                pb={2}
              >
                <Typography variant="h6" sx={{ color: "#00AB55" }}>
                  Total Rental
                </Typography>
                <Typography variant="h6" sx={{ color: "#00AB55" }}>
                  ₹{CardDetailsRecord.totalRental?.[timeFrame.totalRental] ?? 0}
                  <IconButton
                    sx={{ mt: -5, ml: -5 }}
                    onClick={(event) => {
                      handleClick(event, "totalRental");
                    }}
                  >
                    <MoreHorizOutlinedIcon />
                  </IconButton>
                </Typography>
              </Stack>
              <SparklineChart color={"#00AB55"} />
            </Paper>
          </Grid>

          <Grid item lg={4} md={12} sm={12} xs={12}>
            <Paper
              sx={{
                padding: "20px",
                borderRadius: "10px",
                transition: "0.3s",
                "&:hover": {
                  transform: "translateY(-5px)",
                },
              }}
            >
              <Stack
                direction={"row"}
                spacing={1}
                justifyContent={"space-between"}
                pb={2}
              >
                <Typography variant="h6" sx={{ color: "#ed4d6e" }}>
                  Total Pending Rental
                </Typography>
                <Typography variant="h6" sx={{ color: "#ed4d6e" }}>
                  ₹
                  {CardDetailsRecord.totalPendingRental?.[
                    timeFrame.totalPendingRental
                  ] ?? 0}
                  <IconButton
                    sx={{ mt: -5, ml: -5 }}
                    onClick={(event) => {
                      handleClick(event, "totalPendingRental");
                    }}
                  >
                    <MoreHorizOutlinedIcon />
                  </IconButton>
                </Typography>
              </Stack>
              <SparklineChart color={"#ed4d6e"} />
            </Paper>
          </Grid>

          <Grid item lg={4} md={12} sm={12} xs={12}>
            <Paper
              sx={{
                padding: "20px",
                borderRadius: "10px",
                transition: "0.3s",
                "&:hover": {
                  transform: "translateY(-5px)",
                },
              }}
            >
              <Stack
                direction={"row"}
                spacing={1}
                justifyContent={"space-between"}
                pb={2}
              >
                <Typography variant="h6" sx={{ color: "#FEAC68" }}>
                  Total Room Service
                </Typography>
                <Typography variant="h6" sx={{ color: "#FEAC68" }}>
                  ₹
                  {CardDetailsRecord.totalRoomService?.[
                    timeFrame.totalRoomService
                  ] ?? 0}
                  <IconButton
                    sx={{ mt: -5, ml: -5 }}
                    onClick={(event) => {
                      handleClick(event, "totalRoomService");
                    }}
                  >
                    <MoreHorizOutlinedIcon />
                  </IconButton>
                </Typography>
              </Stack>

              <SparklineChart color={"#FEAC68"} />
            </Paper>
          </Grid>
        </Grid>
        <Grid container item xs={12} display={"flex"} justifyContent={"space-evenly"}>
          <Grid item lg={5} md={12} sm={12} xs={12}>
            <Paper
              sx={{
                padding: "20px",
                borderRadius: "10px",
                transition: "0.3s",
                "&:hover": {
                  transform: "translateY(-5px)",
                },
              }}
            >
              <Stack
                direction={"row"}
                spacing={1}
                justifyContent={"space-between"}
                pb={2}
              >
                <Typography variant="h6" sx={{ color: "#6F42C1" }}>
                  Total Income
                </Typography>
                <Typography variant="h6" sx={{ color: "#6F42C1" }}>
                  ₹{CardDetailsRecord.totalIncome?.[timeFrame.totalIncome] ?? 0}
                  <IconButton
                    sx={{ mt: -5, ml: -5 }}
                    onClick={(event) => {
                      handleClick(event, "totalIncome");
                    }}
                  >
                    <MoreHorizOutlinedIcon />
                  </IconButton>
                </Typography>
              </Stack>

              <SparklineChart color={"#6F42C1"} />
            </Paper>
          </Grid>

          <Grid item lg={5} md={12} sm={12} xs={12}>
            <Paper
              sx={{
                padding: "20px",
                borderRadius: "10px",
                transition: "0.3s",
                "&:hover": {
                  transform: "translateY(-5px)",
                },
              }}
            >
              <Stack
                direction={"row"}
                spacing={1}
                justifyContent={"space-between"}
                pb={2}
              >
                <Typography variant="h6" sx={{ color: "#33C5FF" }}>
                  Total Expense
                </Typography>
                <Typography variant="h6" sx={{ color: "#33C5FF" }}>
                  ₹
                  {CardDetailsRecord.totalExpense?.[timeFrame.totalExpense] ??
                    0}
                  <IconButton
                    sx={{ mt: -5, ml: -5 }}
                    onClick={(event) => {
                      handleClick(event, "totalExpense");
                    }}
                  >
                    <MoreHorizOutlinedIcon />
                  </IconButton>
                </Typography>
              </Stack>

              <SparklineChart color={"#33C5FF"} />
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      <div style={{ marginTop: "30px" }}>
        <Grid container spacing={3}>
          <Grid item lg={6} sm={12} xs={12}>
            <Paper sx={{ padding: "20px" }}>
              <Typography variant="h6" mb={2}>
                Recent Bookings
              </Typography>
              <DataTable columns={BookingCoumns} rows={RecentBookingsRecord} />
            </Paper>
          </Grid>

          <Grid item lg={6} sm={12} xs={12}>
            <Paper sx={{ padding: "20px" }}>
              <Typography variant="h6" mb={2}>
                Recently Check In
              </Typography>
              <DataTable columns={CheckInColumns} rows={RecentCheckInsRecord} />
            </Paper>
          </Grid>

          <Grid item sm={12} lg={12} xs={12}>
            <Paper sx={{ padding: "20px", width: "100%" }}>
              <Typography variant="h6" mb={2}>
                Recent Orders
              </Typography>
              <DataTable
                columns={recentOrderColumns}
                rows={RecentOrdersRecord}
              />
            </Paper>
          </Grid>

          <Grid item lg={7} md={12} sm={12} xs={12}>
            <Paper>
              <Box display={"flex"} justifyContent={"end"}></Box>
              <BarChartExample monthlyData={VisitorsRecords} />
            </Paper>
          </Grid>
          <Grid item lg={5} sm={12} xs={12}>
            <Paper sx={{ padding: "20px" }}>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Typography variant="h6">Room Sales By Category</Typography>
              </Box>
              <MyPieChart data={RoomSalesRecord} />
            </Paper>
          </Grid>
        </Grid>
      </div>

      <Menu
        id="basic-menu"
        anchorEl={OpneMenu}
        open={Boolean(OpneMenu)}
        onClose={() => handleClose(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => handleClose("weekly")}>Weekly</MenuItem>
        <MenuItem onClick={() => handleClose("monthly")}>Monthly</MenuItem>
        <MenuItem onClick={() => handleClose("yearly")}>Yearly</MenuItem>
      </Menu>

      <CustomSnackBar
        open={toastOpen}
        setOpen={setToastOpen}
        message={toastMessage}
      />
    </Box>
  );
};

export default DashBoard;

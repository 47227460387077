import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  TextField,
  Tooltip,
  IconButton,
  Button,
  Avatar,
} from "@mui/material";
import DataTable from "../../../DataTable";
import "react-lazy-load-image-component/src/effects/blur.css";
import ExcellIcon from "../../../../Assets/img/Exellicon.png";
import images1 from "../../../../Assets/img/lobby-living-room-hotel.jpg";
import images2 from "../../../../Assets/img/luxury-chill-bedroom-hotel.jpg";
import images3 from "../../../../Assets/img/relax-area-hotel.jpg";
import images4 from "../../../../Assets/img/indoor-design-luxury-resort.jpg";
import images5 from "../../../../Assets/img/luxury-chill-bedroom-hotel.jpg";
import PrintIcon from "@mui/icons-material/Print";
import BasicTabs from "../Rooms/Details/Room_User_Detail";
import Iccream from "../../../../Assets/img/iceCream.avif";
import SwipableDrawer from "../../Include/SwipableDrawer";
import EditRoadOutlinedIcon from "@mui/icons-material/EditRoadOutlined";
import {
  BookedRoom_Data,
  Bookedicons,
  UserData,
  iconsUser,
} from "../Rooms/Data/Data";
import Cookies from "js-cookie";
import { useSelector, useDispatch } from "react-redux";
import {
  GetRoomServiceApi,
  OrderInvoicePayment_Status,
} from "../../../../store/Slice/BillingSlice/BillingSlice";
import handleExcelConversion from "../Reports/ExcellsFormat";
import { MySwal } from "../../../../Model/Alert/SuccessAlert";
import { useNavigate } from "react-router-dom";
import handleFilter from "../../../Searching";

const OrderBilling = () => {
  const navigate = useNavigate();
  const Token = Cookies.get("Token");
  const dispatch = useDispatch();
  const { AllBillingData } = useSelector((state) => state.BillingStore);
  const [record, setrecord] = useState([]);

  useEffect(() => {
    const FetchData = async () => {
      try {
        await dispatch(GetRoomServiceApi({ Token }));
      } catch (error) {
        return error;
      }
    };
    FetchData();
  }, [Token, dispatch]);

  const datawithSerialNumber = useMemo(
    () =>
      AllBillingData
        ? AllBillingData.map((item, index) => ({
            ...item,
            serialNumber: index + 1,
          }))
        : [],
    [AllBillingData]
  );

  useEffect(() => {
    setrecord(datawithSerialNumber);
  }, [datawithSerialNumber]);

  const [handlegridrow, sethandlegridrow] = useState(null);

  const [columnsBookedRooms, setcolumnsBookedRooms] = useState([
    {
      field: "serialNumber",
      headerName: "SR.No",
      width: 0,
      headerAlign: "center",
      align: "center",
      visible: true,
    },
    {
      field: "Action",
      headerName: "Action",
      width: 100,
      headerAlign: "center",
      visible: true,
      align: "center",
      renderCell: (param) => (
        <div>
          {/* <NavLink to={`/order-invoice/${param.row.id}`}> */}
          <Button
            variant="outlined"
            sx={{ background: "#F1F2F3" }}
            onClick={() => {
              navigate(`/order-invoice/${param.row.id}`);
            }}
          >
            <PrintIcon sx={{ fontSize: "19px", color: "#3B3F5C" }} />
          </Button>
          {/* </NavLink> */}
        </div>
      ),
    },
    {
      field: "invoice_num",
      headerName: "Invoce No",
      width: 150,
      headerAlign: "center",
      align: "center",
      visible: true,
    },

    {
      field: "room_no",
      headerName: "Room No",
      width: 130,
      headerAlign: "center",
      visible: true,
      align: "center",
    },
    {
      field: "order_date",
      headerName: "Order Date",
      visible: true,
      width: 220,
      renderCell: (params) =>
        params.row.order_date ? (
          <div>
            {new Intl.DateTimeFormat("en-GB", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            }).format(new Date(params.row.order_date))}
          </div>
        ) : null,
    },
    {
      field: "fullname",
      headerName: "Name",
      width: 130,
      headerAlign: "center",
      visible: true,
      align: "center",
    },

    {
      field: "phone_number",
      headerName: "Phone Number",
      width: 130,
      headerAlign: "center",
      visible: true,
      align: "center",
    },

    {
      field: "email",
      headerName: "Email",
      width: 130,
      headerAlign: "center",
      visible: true,
      align: "center",
    },

    {
      field: "payment_status",
      headerName: "Payment Status",
      width: 150,
      headerAlign: "center",
      align: "center",
      visible: true,
      renderCell: (param) => (
        <div>
          {param.row?.payment_status === "PAID" && (
            <Button variant="outlined" size="small" color="success">
              {param.row?.payment_status}
            </Button>
          )}
          {param.row?.payment_status === "PARTIAL" && (
            <Button variant="outlined" size="small" color="info">
              {param.row.payment_status}
            </Button>
          )}

          {param.row?.payment_status === "UNPAID" && (
            <Button variant="outlined" color="error" size="small">
              {param.row?.payment_status}
            </Button>
          )}
        </div>
      ),
    },

    {
      field: "cgst",
      headerName: "CGST",
      width: 130,
      headerAlign: "center",
      visible: true,
      align: "center",
    },

    {
      field: "sgst",
      headerName: "SGST",
      width: 130,
      headerAlign: "center",
      visible: true,
      align: "center",
    },

    {
      field: "igst",
      headerName: "IGST",
      width: 130,
      headerAlign: "center",
      visible: true,
      align: "center",
    },

    {
      field: "discount",
      headerName: "Discount",
      width: 130,
      headerAlign: "center",
      visible: true,
      align: "center",
    },

    {
      field: "total",
      headerName: "Total",
      width: 130,
      headerAlign: "center",
      visible: true,
      align: "center",
    },
  ]);
  const handleToggleColumn = (field) => {
    setcolumnsBookedRooms((prevColumns) =>
      prevColumns.map(
        (col) => (col.field === field ? { ...col, visible: !col.visible } : col)
        // console.log("col",col)
      )
    );
  };

  const itemdata = [
    { primary: "Code", secondary: "$123" },
    { primary: "Name", secondary: "Noodels" },
    { primary: "Price", secondary: "₹ 500" },
  ];

  const RoomsubImages = [images1, images2, images3, images4, images5];

  // const handleRowClick = () => sethandlegridrow((previous) => !previous);

  // const [BillingModelStatus, setBillingModelStatus] = useState(false);
  // const [billingId, setbillingId] = useState(false);
  const handleBillingModelOpen = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "Once you generate the bill, payment status will be paid!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, generate it!",
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#dc3545",
      cancelButtonText: "No, cancel!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        //CALL API TO CHANGE STATUS
        // setBillingModelStatus(true)
        // setbillingId(id)
        dispatch(OrderInvoicePayment_Status({ Token, id }));
        navigate(`/order-invoice/${id}`);
      } else if (result.dismiss === MySwal.DismissReason.cancel) {
        MySwal.fire({
          title: "Cancelled",
          text: "Your data is safe.",
          icon: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "#dc3545",
        });
      }
    });
  };

  // const handleBillingModelClose = () => setBillingModelStatus(false);

  const [Swipeopen, setSwipeopen] = useState(false);
  const HandleSwipeOpen = () => setSwipeopen(true);
  const HandleSwipeClose = () => setSwipeopen(false);

  const keys_serach = [
    "room.room_no",
    "room.floor_no",
    "room.perdayprice",
    "guest.fullname",
    "guest.email",
    "guest.phone_number",
    "order_date",
  ];

  return (
    <>
      <Box>
        <Grid container>
          <Grid item xs={12}>
            <Paper sx={{ padding: "10px", borderRadius: "10px" }}>
              <Grid container>
                <Grid item lg={9} md={9} sm={9} xs={12}>
                  <Box
                    sx={{
                      width: { xs: "100%", sm: "" },
                    }}
                  >
                    <TextField
                      label="Search"
                      size="small"
                      onChange={(event) => {
                        handleFilter({
                          event,
                          dataWithSerialNumber: datawithSerialNumber,
                          setrecord,
                          keys_serach,
                        });
                      }}
                      sx={{
                        bgcolor: "#dae1f3",
                        borderRadius: "10px",
                        width: { xs: "100%", sm: "230px" },

                        "& fieldset": { border: "none" },
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignContent: "center",
                      alignItems: "center",
                      mt: { xs: 2, sm: 0 },
                    }}
                  >
                    <Avatar sx={{ background: "#6970E2" }}>
                      <Tooltip title="Table Edit">
                        <IconButton onClick={HandleSwipeOpen}>
                          <EditRoadOutlinedIcon sx={{ color: "#fff" }} />
                        </IconButton>
                      </Tooltip>
                    </Avatar>

                    <Avatar
                      src={ExcellIcon}
                      onClick={() => {
                        handleExcelConversion(record);
                      }}
                    ></Avatar>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Box sx={{ marginTop: "40px", paddingLeft: "10px" }}>
          <Grid container spacing={3}>
            <Grid item lg={handlegridrow ? 6 : 12} xs={12} mt={-3}>
              <Paper>
                <DataTable columns={columnsBookedRooms} rows={record} />
              </Paper>
            </Grid>

            <Grid item lg={handlegridrow ? 6 : 4} xs={handlegridrow || 12}>
              {handlegridrow && (
                <Paper sx={{ borderRadius: "10px", marginTop: "-25px" }}>
                  <BasicTabs
                    handlegridrow={handlegridrow}
                    RoomsubImages={RoomsubImages}
                    MainImage={images2}
                    icons={Bookedicons}
                    data={BookedRoom_Data}
                    UserData={UserData}
                    iconsUser={iconsUser}
                    itemdata={itemdata}
                    Iccream={Iccream}
                    sethandlegridrow={sethandlegridrow}
                  />
                </Paper>
              )}
            </Grid>
          </Grid>
        </Box>

        {/* {BillingModelStatus && (
          <SlectBilling
            open={BillingModelStatus}
            billingId={billingId}
            handleClose={handleBillingModelClose}
          />
        )} */}
        <SwipableDrawer
          open={Swipeopen}
          close={HandleSwipeClose}
          columns={columnsBookedRooms}
          handleToggleColumn={handleToggleColumn}
        />
      </Box>
    </>
  );
};
export default OrderBilling;

import * as Yup from "yup";
import dayjs from "dayjs";

const RoomSchema = Yup.object({
  room_no: Yup.number().required("Field is required"),
  perdayprice: Yup.number().required("Field is required"),
  floor_no: Yup.string().required("Field is required"),
  bed_type: Yup.string()
    .required("Field is Requird")
    .oneOf(["SINGLE", "DOUBLE", "OTHER"], "Invalid bed type"),
  tax_type: Yup.string()
    .required("Field is Requird")
    .oneOf(["INCLUDE", "EXCLUDE", "NONE"], "Invalid bed type"),
  description: Yup.string()
    .min(10, "Minimum 10 characters")
    .required("Field is required"),
  facilities: Yup.array()
    .min(1, "Minimum 1 facility Is required")
    .required("Field is required"),
  images: Yup.array()
    .min(2, "Minimum 2 Images Is required")
    .max(5, "Maximum 5 Images Is Required")
    .required("Field is required"),
});

const GuestSchema = Yup.object({
  fullname: Yup.string().required("Field is required"),
  email: Yup.string()
    .matches(/^[a-zA-Z0-9._-]+@gmail.com$/, "Invalid email")
    .required("Field is required"),
  document: Yup.string().required("Field is required"),
  address: Yup.string().required("Field is required"),
  gst_number: Yup.string().matches(
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
    "Invalid GST number"
  ),
  phone_number: Yup.string()
    .min(10)
    .max(10)
    .required("Invalid mobile number")
    .required("Field is required"),
});

const addBookingSchema = Yup.object({
  phone_number: Yup.string().min(10).max(10).required("Invalid mobile number"),
  fullname: Yup.string().required("Field is required"),
  email: Yup.string().matches(/^[a-zA-Z0-9._-]+@gmail.com$/, "Invalid email"),
  document: Yup.string().required("Field is required"),
  // document_images: Yup.object().required("Field is required"),
  address: Yup.string().required("Field is required"),
  person: Yup.string().required("Field is required"),
  booking_date: Yup.date().required("Field is Required").min(new Date()),
  payment_type: Yup.string().required("Field is required"),
  check_in: Yup.date().required("Field is Required"),
  check_out: Yup.date().required("Field is Required"),
  total_days: Yup.number().required("Field is required"),
  taxable_price: Yup.number().required("Field is required"),
  cgst: Yup.number().required("Field is required"),
  sgst: Yup.number().required("Field is required"),
  igst: Yup.number().required("Field is required"),
  payment_status: Yup.string().required("Field is required"),
  adv_payment: Yup.number().required("Field is required"),
  remaining_amount: Yup.number().required("Field is required"),
  total_price: Yup.number().required("Field is required"),
});

const editBookingSchema = Yup.object({
  person: Yup.string().required("Field is required"),
  booking_date: Yup.date().required("Field is Required"),
  payment_type: Yup.string().required("Field is required"),
  check_in: Yup.date().required("Field is Required"),
  check_out: Yup.date().required("Field is Required"),
  total_days: Yup.number().required("Field is required"),
  taxable_price: Yup.number().required("Field is required"),
  cgst: Yup.number().required("Field is required"),
  sgst: Yup.number().required("Field is required"),
  igst: Yup.number().required("Field is required"),
  payment_status: Yup.string().required("Field is required"),
  remaining_amount: Yup.number().required("Field is required"),
  adv_payment: Yup.number().required("Field is required"),
  total_price: Yup.number().required("Field is required"),
});

let maxDate = new Date(dayjs(new Date()).add(12, "hour"));

const addRoomServiceSchema = Yup.object({
  room_id: Yup.number().required("Field is required"),
  order_date: Yup.date()
  .required("Field is Required")
    .min(new Date(), "order Date field must be bigger than current datetime")
    .max(maxDate, `order date should be less than ${maxDate}`),
  sub_total: Yup.number().required("Field is required"),
  sgst: Yup.number().required("Field is required"),
  igst: Yup.number().required("Field is required"),
  cgst: Yup.number().required("Field is required"),
  discount: Yup.number().required("Field is required"),
  total: Yup.number().required("Field is required"),
  field: Yup.array().of(
    Yup.object().shape({
      item_id: Yup.number().required("Field is required"),
      price: Yup.number().required("Field is required"),
      quantity: Yup.number().required("Field is required"),
      cgst: Yup.number().required("Field is required"),
      sgst: Yup.number().required("Field is required"),
      igst: Yup.number().required("Field is required"),
      total: Yup.number().required("Field is required"),
    })
  ),
});

const editRoomServiceSchema = Yup.object({
  room_id: Yup.number().required("Field is required"),
  order_date: Yup.date().required("Field is Required"),
  sub_total: Yup.number().required("Field is required"),
  sgst: Yup.number().required("Field is required"),
  igst: Yup.number().required("Field is required"),
  cgst: Yup.number().required("Field is required"),
  discount: Yup.number().required("Field is required"),
  total: Yup.number().required("Field is required"),
  field: Yup.array().of(
    Yup.object().shape({
      item_id: Yup.number().required("Field is required"),
      price: Yup.number().required("Field is required"),
      quantity: Yup.number().required("Field is required"),
      cgst: Yup.number().required("Field is required"),
      sgst: Yup.number().required("Field is required"),
      igst: Yup.number().required("Field is required"),
      total: Yup.number().required("Field is required"),
    })
  ),
});

const FoodItemSchema = Yup.object({
  item_name: Yup.string().required("Field is required"),
  item_code: Yup.number().required("Field is required"),
  price: Yup.number().required("Field is required"),
  // tax_type: Yup.string().required("Field is required"),
  category_id: Yup.string().required("Field is required"),
  images: Yup.mixed()
  .test("fileOrString", "Field is required", function (value) {
      if (!value) return this.createError({ message: "Field is required" });
      
      if (typeof value === "string") {
        if (value.trim() === "") {
          return this.createError({ message: "Invalid string value" });
        }
      } else if (typeof value === "object") {
      } else {
        return this.createError({ message: "Invalid image type" });
      }
      
      return true;
    })
    .required("Field is required"),
  });
  
  const FoodCategorySchema = Yup.object({
    name: Yup.string().required("Field is required"),
});

const ExpenseSchema = Yup.object({
  date: Yup.date().required("Field is Required"),
  description: Yup.string().required("Field is required"),
  amount: Yup.number().required("Field is required"),
});

const AdmiProfileEditSchema = Yup.object({
  fullname: Yup.string().required("Field is required"),
  email: Yup.string().required("Field is required"),
  phone_number: Yup.string().min(10).max(10).required("Field is required "),
  address: Yup.string().min(3).required("Field is required"),
  gst_number: Yup.string().matches(
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
    "Invalid GST number"
  ),
  default_checkin: Yup.date(),
  default_checkout: Yup.date().min(
    Yup.ref("default_checkin"),
    "can not be bigger than check_in time"
  ),
});

const LoginSchema = Yup.object({
  email: Yup.string()
  .matches(/^[a-zA-Z0-9._-]+@gmail.com$/, "Invalid Email")
  .required("This Field is Required"),
  password: Yup.string().required("This Field is required"),
});

const EmailSchema = Yup.object({
  email: Yup.string()
    .matches(/^[a-zA-Z0-9._-]+@gmail.com$/, "Invalid Email")
    .required("This Field is Required"),
});

const OtpSchema = Yup.object({
  otp: Yup.array()
  .of(
    Yup.string()
        .matches(/^[0-9]$/, "Must be a digit")
        .required("Required")
      )
    .required("OTP is required")
    .min(6, "Must be exactly 6 digits")
    .max(6, "Must be exactly 6 digits"),
});

const ConfirmPassword_Schema = Yup.object({
  new_password: Yup.string().required("Filed Is Required"),
  confirm_password: Yup.string()
  .oneOf([Yup.ref("new_password"), null], "Passwords must match")
  .required("Confirm password is required"),
});

const bookingAvailability_Schema = Yup.object({
  check_in: Yup.date().required("Filed Is Required"),
  check_out: Yup.date()
    .min(Yup.ref("check_in"), "can not be bigger than check_in")
    .required("Filed Is Required"),
  });
  
  const fileUploadSchema = Yup.object({
    excelFile: Yup.mixed()
    .test("file", "Field is required", function (value) {
      if (!value) return this.createError({ message: "Field is required" });

      if (typeof value === "object") {
      } else {
        return this.createError({ message: "Invalid File type" });
      }
      
      return true;
    })
    .required("Field is required"),
});

const imageUploadSchema = Yup.object({
  module: Yup.string()
    .required("Field is Requird")
    .oneOf(["ROOM", "FOOD", "GUEST"], "Invalid module selected"),
    images: Yup.mixed()
    .test("file", "Field is required", function (value) {
      if (!value) return this.createError({ message: "Field is required" });

      if (typeof value === "object") {
      } else {
        return this.createError({ message: "Invalid File type" });
      }
      
      return true;
    })
    .required("Field is required"),
  });
  
export {
  RoomSchema,
  GuestSchema,
  fileUploadSchema,
  addBookingSchema,
  editBookingSchema,
  addRoomServiceSchema,
  editRoomServiceSchema,
  FoodItemSchema,
  AdmiProfileEditSchema,
  LoginSchema,
  FoodCategorySchema,
  EmailSchema,
  OtpSchema,
  ConfirmPassword_Schema,
  bookingAvailability_Schema,
  imageUploadSchema,
  ExpenseSchema,
};

import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import GenerateBilling from "./GenerateBilling";
import { Typography, Stack, Paper, useMediaQuery } from "@mui/material";
import CollapsedBreadcrumbs from "../../Include/Breadcrumb";
import OrderBilling from "./OrderBilling";
import BulkInvoice from "./BulkInvoice";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 4 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BillingTabPanel() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const BreadCrumbLink = [
    {
      id: 1,
      name: "Home",
      link: "/",
    },
    {
      id: 1,
      name: "Billing",
      link: "/billing",
    },
  ];

  const IsMobile = useMediaQuery("(min-width:900px)");

  return (
    <Box sx={{ padding: "20px", width: "100%" }}>
      <Stack
        direction={IsMobile ? "row" : "column"}
        justifyContent={"space-between"}
      >
        <Typography variant="h6">Billing</Typography>
        <CollapsedBreadcrumbs data={BreadCrumbLink} />
      </Stack>

      <Paper sx={{ borderBottom: 1, borderColor: "divider", mt: 2 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons={false}
          aria-label="basic tabs example"
          sx={{ gap: 50 }}
        >
          {/* <Tab label="Generate Bulk Invoice" {...a11yProps(0)} /> */}
          <Tab label=" Generate Room Invoice " {...a11yProps(0)} />
          <Tab label="Generate Order Invoice " {...a11yProps(1)} />
        </Tabs>
      </Paper>
      {/* <CustomTabPanel value={value} index={0}>
        <BulkInvoice />
      </CustomTabPanel> */}
      <CustomTabPanel value={value} index={0}>
        <GenerateBilling />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <OrderBilling />
      </CustomTabPanel>
    </Box>
  );
}

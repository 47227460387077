import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Grid,
  Stack,
  Button,
  IconButton,
  Tooltip,
  Chip,
} from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import KingBedTwoToneIcon from "@mui/icons-material/KingBedTwoTone";
import placeholderImage from "../../../Assets/img/room-placeholder.jpg";
import { FACILITIES } from "../Other/Rooms/Data/Data";
import "./RoomCards.css";
import Pagination from "@mui/material/Pagination";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { AddBookingModel } from "../../../Model/Model";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { NavLink } from "react-router-dom";

const RoomCard = ({
  data,
  sethandlegridrow,
  handlegridrow,
  filterDate,
  setfilterDate,
  setRoomdata,
  setotherdispalynone,
  otherdispalynone,
}) => {
  const TruncateDesccription = (description, maxlength) => {
    if (description) {
      if (description.length <= maxlength) {
        return description;
      }
      return description.substring(0, maxlength) + "...";
    } else {
      return null;
    }
  };

  let h6Style = {
    fontFamily: "'roboto',sans-serif",
    color: "#41525a",
    "&:hover": {
      color: "grey",
      textDecoration: "underline",
      cursor: "pointer",
    },
  };
  const rowDirection = { display: "flex", gap: "10px" };

  const ColorFunc = (status) => {
    if (typeof status !== "string") {
      return {}; // return a default style or handle the case as needed
    }

    if (status === "AVAILABLE" || status === "Booked") {
      return { backgroundColor: "rgb(2, 136, 209)", color: "#fff" };
    } else if (status === "UNAVAILABLE" || status === "Cancel") {
      return { backgroundColor: "#CC2A41", color: "#fff" };
    }
    return {}; // default case
  };

  const [translationValuesHouse, setTranslationValuesHouse] = useState([]);

  const setActiveImageSliderHome = (cardIndex, imageIndex) => {
    const newTranslationValues = [...translationValuesHouse];
    newTranslationValues[cardIndex] = `-${imageIndex * 100}%`;
    setTranslationValuesHouse(newTranslationValues);
  };

  const [page, setpage] = useState(1);
  const perPageItem = 8;

  // const count = Math.ceil(data.length / perPageItem);
  // const displayData = data.slice((page - 1) * perPageItem, page * perPageItem);
  const displayData = data;
  // const handleChange = (e, value) => setpage(value);

  const [BookingModelOpen, setBookingModelOpen] = useState(false);
  const [RoomData, setRoomData] = useState({});
  const handleBookingModel = (data) => {
    setBookingModelOpen(true);
    setRoomData(data);
  };

  const handleBookingModelclose = () => setBookingModelOpen(false);

  const [menuOpen, setmenuOpen] = useState(null);
  const [roomid, setroomid] = useState(null);

  const handleClick = (event, id) => {
    setmenuOpen(event.currentTarget);
    setroomid(id);
  };

  const handleClose = () => setmenuOpen(null);

  // const [EditBooking, setEditBooking] = useState(false);
  // const [EditBookingData, setEditBookingData] = useState([]);
  // const handleEditModelOpen = (data) => {
  //   setEditBooking(data);
  //   setEditBookingData(data);
  // };

  // const handleEditModelClose = () => setEditBooking(false);

  // const [otherdispalynone,setotherdispalynone]=useState(false)
  const [singledata, setsingledata] = useState([]);
  const HandleImageClick = (data) => {
    const {
      room_no,
      bed_type,
      bookingStatus,
      description,
      facilities,
      floor_no,
      images,
      perdayprice,
    } = data;
    // if (data.guest) {
    //   const { fullname, email, phone_number, address, document, created_at } =
    //     data.guest;

    //   setUserData({
    //     Name: fullname,
    //     Email: email,
    //     "Mobile No": phone_number,
    //     Address: address,
    //     Documents: document,
    //     "Created Date": new Date(created_at).toDateString(),
    //   });

    //   setRoomdata({
    //     "Room No": room_no,
    //     "Bed Type": bed_type,
    //     status: bookingStatus,
    //     Description: description,
    //     Facilities: facilities,
    //     "Floor No": floor_no,
    //     images: images,
    //     "Per Day Rent": perdayprice,
    //   });
    // } else {
    //   const {
    //     room_no,
    //     bed_type,
    //     bookingStatus,
    //     description,
    //     facilities,
    //     floor_no,
    //     images,
    //     perdayprice,
    //   } = data;
    // }

    setRoomdata({
      "Room No": room_no,
      "Bed Type": bed_type,
      status: bookingStatus,
      Description: description,
      Facilities: facilities,
      "Floor No": floor_no,
      images: images,
      "Per Day Rent": perdayprice,
    });
    sethandlegridrow((previos) => !previos);
    setotherdispalynone((previous) => !previous);
    const singleroomdata = displayData.filter((val) => val.id === data.id);

    const arrayFacilities = FACILITIES.filter((facility, i) => {
      if (
        singleroomdata[0]?.facilities &&
        singleroomdata[0]?.facilities.includes(facility.id.toString())
      ) {
        return facility;
      }
    });
    singleroomdata[0].facilities = arrayFacilities;
    setsingledata(singleroomdata);
  };

  // const HandleBookingCancel = async (values) => {
  //   try {
  //     const { id } = values.reservation;
  //     let data = filterDate;
  //     await dispatch(CancelBookApi({ Token, id }));
  //     await dispatch(GetRoomApi({ Token, data }));
  //   } catch (error) {
  //     return error;
  //   }
  // };

  // const handleRoomDelete = async (id) => {
  //   try {
  //     MySwal.fire({
  //       title: "Are you sure?",
  //       text: "You won't be able to revert this!",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonText: "Yes, delete it!",
  //       confirmButtonColor: "#28a745",
  //       cancelButtonColor: "#dc3545",
  //       cancelButtonText: "No, cancel!",
  //     }).then(async (result) => {
  //       if (result.isConfirmed) {
  //         await dispatch(DeleteRoomApi({ Token, id }));
  //         await dispatch(GetRoomApi({ Token, data: filterDate }));
  //       } else if (result.dismiss === MySwal.DismissReason.cancel) {
  //         MySwal.fire({
  //           title: "Cancelled",
  //           text: "Your data is safe.",
  //           icon: "error",
  //           confirmButtonText: "OK",
  //           confirmButtonColor: "#dc3545",
  //         });
  //       }
  //     });
  //   } catch (error) {
  //     return error;
  //   }
  //   handleClose();
  // };

  return (
    <Box sx={{ height: "auto", overflow: "hidden" }}>
      {/* {!handlegridrow && (
        <Stack direction={"row"} justifyContent={"end"} mb={3}>
          <Pagination
            count={count}
            page={page}
            onChange={handleChange}
            variant="outlined"
            shape="rounded"
            color="info"
          />
        </Stack>
      )} */}

      <Grid container spacing={2}>
        {!otherdispalynone && displayData && displayData.length > 0 ? (
          displayData.map((item, index) => (
            <Grid item lg={handlegridrow ? 12 : 3}>
              <Paper>
                <Box sx={{ position: "relative" }}>
                  <Box
                    sx={{
                      position: "absolute",
                      width: "100%",
                      zIndex: 1,
                      m: 1,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* <Button
                      variant="contained"
                      color={ColorFunc(item.bookingStatus)}>
                      {item.bookingStatus}
                    </Button> */}

                    <Button
                      className="alt status_btn"
                      style={ColorFunc(item.bookingStatus)}
                    >
                      {typeof item.bookingStatus === "string"
                        ? item.bookingStatus
                        : "Unknown Status"}
                    </Button>

                    <Tooltip title="Edit">
                      <IconButton
                        onClick={(event) => {
                          handleClick(event, item.id);
                        }}
                      >
                        <MoreVertIcon sx={{ color: "#fff" }} />
                      </IconButton>
                    </Tooltip>
                  </Box>

                  {item?.images && item?.images.length > 0 ? (
                    <div class="stacked-image">
                      {item?.images.map((image, imageIndex) => (
                        <div
                          key={imageIndex}
                          className="item-image"
                          onClick={() => {
                            HandleImageClick(item);
                          }}
                          style={{
                            height: "250px",
                            transform: `translateX(${translationValuesHouse[index]})`,
                          }}
                        >
                          <LazyLoadImage
                            key={imageIndex}
                            src={
                              image
                                ? `${process.env.REACT_APP_API_URL}/uploads/rooms/${image}`
                                : placeholderImage
                            }
                            className="item-image"
                            effect="blur"
                            height={"100%"}
                            width={"100%"}
                          />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div class="stacked-image">
                      <div
                        className="item-image"
                        onClick={() => {
                          HandleImageClick(item);
                        }}
                        style={{
                          height: "250px",
                        }}
                      >
                        <LazyLoadImage
                          src={placeholderImage}
                          className="item-image"
                          effect="blur"
                          height={"100%"}
                          width={"100%"}
                        />
                      </div>
                    </div>
                  )}
                </Box>
                <div className="sliderbtn">
                  {/* <ul style={{display:"flex",justifyContent:"start"}}> */}
                  <ul>
                    {item.images.map((btn, btnIndex) => (
                      <li>
                        <div
                          className="slider-btn"
                          onClick={() =>
                            setActiveImageSliderHome(index, btnIndex)
                          }
                        ></div>
                      </li>
                    ))}
                  </ul>
                </div>

                <Box sx={{ p: 2, mt: -3 }}>
                  <Stack
                    direction={{ xs: "columns", sm: "row" }}
                    justifyContent={"space-between"}
                    mt={1}
                  >
                    <Typography
                      variant="h6"
                      sx={h6Style}
                      onClick={() => HandleImageClick(item)}
                    >
                      Room No. {item.room_no}
                    </Typography>

                    {/* <Box display={'flex'} justifyContent={'flex-end'}> */}
                    <Button
                      sx={{
                        mt: "3px",
                        transition: "0.5s",
                        "&:hover": {
                          background: "#656CD9",
                          color: "#fff",
                          transform: "translateY(-5px)",
                        },
                      }}
                      disabled={item.bookingStatus === "UNAVAILABLE"}
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        handleBookingModel(item);
                      }}
                    >
                      Book
                    </Button>
                  </Stack>

                  <Box sx={{ mb: 1, mt: "1px" }}>
                    <Typography
                      variant="subtitle"
                      sx={{
                        fontFamily: "'roboto',sans-serif",
                        color: "#EB554A",
                        mt: -2,
                        mb: 1,
                        fontSize: "18px",
                      }}
                    >
                      ₹{item.perdayprice} / Night
                    </Typography>
                  </Box>

                  <Typography
                    variant="subtitle"
                    sx={{
                      fontFamily: "'roboto',sans-serif",
                      color: "#738395",
                    }}
                  >
                    {TruncateDesccription(item.description, 150)}
                  </Typography>

                  <Box
                    mt={4}
                    justifyContent={"center"}
                    alignItems={"center"}
                    display={"flex"}
                    gap={2}
                    flexDirection={{ xs: "column", sm: "row" }}
                    flexWrap={"wrap"}
                  >
                    {handlegridrow && (
                      <>
                        <Stack sx={rowDirection}>
                          <Typography variant="body">
                            <KingBedTwoToneIcon />
                            {/* <img
                            alt="Bed Icon"
                            src={BedIcon}
                            style={{ height: "17px" }}
                          /> */}
                            {item.bed_type}
                          </Typography>
                        </Stack>

                        {item &&
                          item?.facilities &&
                          item?.facilities.map(({ id, icon, label }) => (
                            <Stack key={id} sx={rowDirection}>
                              <Typography variant="body">
                                {icon}
                                {label}
                              </Typography>
                            </Stack>
                          ))}
                      </>
                    )}
                  </Box>

                  {item.bookingStatus === "UNAVAILABLE" && (
                    <Box
                      mt={2}
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      <NavLink to={`/reservation/${item.id}`}>
                        <Button
                          variant="outlined"
                          color="secondary"
                          size="small"
                          // onClick={() => {
                          //   handleEditModelOpen(item);
                          // }}
                        >
                          Booking Edit
                        </Button>
                      </NavLink>
                    </Box>
                  )}
                </Box>
              </Paper>
            </Grid>
          ))
        ) : (
          <>
            {otherdispalynone &&
              singledata &&
              singledata.length > 0 &&
              singledata.map((item, index) => (
                <Grid item lg={handlegridrow ? 12 : 3} key={index}>
                  <Paper>
                    <Box sx={{ position: "relative" }}>
                      <Box
                        sx={{
                          position: "absolute",
                          width: "100%",
                          zIndex: 1,
                          m: 1,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          className="alt status_btn"
                          style={ColorFunc(item.bookingStatus)}
                        >
                          {typeof item.bookingStatus === "string"
                            ? item.bookingStatus
                            : "Unknown Status"}
                        </Button>
                        <Tooltip title="Edit">
                          <IconButton
                            onClick={(event) => {
                              handleClick(event, item.id);
                            }}
                          >
                            <MoreVertIcon sx={{ color: "#fff" }} />
                          </IconButton>
                        </Tooltip>
                      </Box>

                      {item.images && item.images.length > 0 ? (
                        <div className="stacked-image">
                          {item.images.map((image, imageIndex) => (
                            <div
                              key={imageIndex}
                              className="item-image"
                              onClick={() => {
                                HandleImageClick(item);
                              }}
                              style={{
                                height: "250px",
                                transform: `translateX(${translationValuesHouse[index]})`,
                              }}
                            >
                              <LazyLoadImage
                                key={imageIndex}
                                src={
                                  image
                                    ? `${process.env.REACT_APP_API_URL}/uploads/rooms/${image}`
                                    : placeholderImage
                                }
                                className="item-image"
                                effect="blur"
                                height={"100%"}
                                width={"100%"}
                              />
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="stacked-image">
                          <div
                            className="item-image"
                            onClick={() => {
                              HandleImageClick(item);
                            }}
                            style={{
                              height: "250px",
                            }}
                          >
                            <LazyLoadImage
                              src={placeholderImage}
                              className="item-image"
                              effect="blur"
                              height={"100%"}
                              width={"100%"}
                            />
                          </div>
                        </div>
                      )}
                    </Box>
                    <div className="sliderbtn">
                      <ul>
                        {item.images.map((btn, btnIndex) => (
                          <li key={btnIndex}>
                            <div
                              className="slider-btn"
                              onClick={() =>
                                setActiveImageSliderHome(index, btnIndex)
                              }
                            ></div>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <Box sx={{ p: 2, mt: -3 }}>
                      <Stack
                        direction={{ xs: "columns", sm: "row" }}
                        justifyContent={"space-between"}
                        gap={1}
                        mt={1}
                      >
                        <Typography variant="h6" sx={h6Style}>
                          {item.room_no} Horeo Complex
                        </Typography>
                        <Button
                          sx={{
                            mt: "3px",
                            transition: "0.5s",
                            "&:hover": {
                              background: "#656CD9",
                              color: "#fff",
                              transform: "translateY(-5px)",
                            },
                          }}
                          disabled={item.bookingStatus === "UNAVAILABLE"}
                          variant="outlined"
                          color="secondary"
                          onClick={() => {
                            handleBookingModel(item);
                          }}
                        >
                          Book
                        </Button>
                      </Stack>
                      <Stack
                        direction={{ xs: "columns", sm: "row" }}
                        // justifyContent={"space-between"}
                        mt={2}
                        mb={1}
                      >
                        <Box sx={{ mb: 1, mt: "1px" }}>
                          <Typography
                            variant="subtitle"
                            sx={{
                              fontFamily: "'roboto',sans-serif",
                              color: "#EB554A",
                              mt: -2,
                              mb: 1,
                              fontSize: "18px",
                            }}
                          >
                            ₹{item.perdayprice} / Night
                          </Typography>
                        </Box>
                      </Stack>
                      <Stack
                        direction={{ xs: "columns", sm: "row" }}
                        // justifyContent={"space-between"}
                        mt={2}
                        mb={1}
                      >
                        <Typography
                          variant="subtitle"
                          sx={{
                            fontFamily: "'roboto',sans-serif",
                            color: "#738395",
                          }}
                        >
                          {TruncateDesccription(item.description, 150)}
                        </Typography>
                      </Stack>
                      {handlegridrow && (
                        <Box
                          mt={4}
                          justifyContent={"start"}
                          display={"flex"}
                          alignItems={"center"}
                          gap={2}
                          flexDirection={{ xs: "column", sm: "row" }}
                          sx={{ flexWrap: "wrap" }}
                        >
                          <Stack>
                            <Typography variant="body" sx={rowDirection}>
                              <KingBedTwoToneIcon />
                              {/* <img
                            alt="Bed Icon"
                            src={BedIcon}
                            style={{ height: "17px" }}
                          /> */}
                              {item.bed_type}
                            </Typography>
                          </Stack>
                          {item &&
                            item?.facilities &&
                            item?.facilities.map(({ id, icon, label }) => (
                              <Stack key={id}>
                                <Typography variant="body" sx={rowDirection}>
                                  {icon}
                                  {label}
                                </Typography>
                              </Stack>
                            ))}
                        </Box>
                      )}
                      {item.bookingStatus === "UNAVAILABLE" && (
                        <Box
                          mt={2}
                          display={"flex"}
                          justifyContent={"space-between"}
                        >
                          <NavLink to={`/reservation/${item.id}`}>
                            <Button
                              variant="outlined"
                              color="secondary"
                              size="small"
                            >
                              Booking Edit
                            </Button>
                          </NavLink>
                        </Box>
                      )}
                    </Box>
                  </Paper>
                </Grid>
              ))}
          </>
        )}
      </Grid>
      {BookingModelOpen && (
        <AddBookingModel
          RoomNo={RoomData}
          open={BookingModelOpen}
          handleBookingModelclose={handleBookingModelclose}
          filterDate={filterDate}
          setfilterDate={setfilterDate}
        />
      )}
      {/* {!handlegridrow && (
        <Stack direction={"row"} justifyContent={"end"} mt={3}>
          <Pagination
            count={count}
            page={page}
            onChange={handleChange}
            variant="outlined"
            shape="rounded"
            color="info"
          />
        </Stack>
      )} */}
      <Menu
        id="basic-menu"
        anchorEl={menuOpen}
        open={Boolean(menuOpen)}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose}>
          <NavLink
            to={`/edit-rooms/${roomid}`}
            style={{ textDecoration: "none" }}
          >
            <Typography variant="body" color={"secondary"}>
              Edit
            </Typography>
          </NavLink>
        </MenuItem>
        {/* <MenuItem
          onClick={() => {
            handleRoomDelete(roomid);
          }}
        >
          <Typography variant="body" color={"error"}>
            Delete
          </Typography>
        </MenuItem> */}
      </Menu>
    </Box>
  );
};

export default RoomCard;

import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Box, Paper, IconButton, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { GUEST_ICONS } from "../Rooms/Data/Data";
import { LazyLoadImage } from "react-lazy-load-image-component";

const GuestDetail = ({ data, sethandlegridrow }) => {
  const handleclose = () => {
    sethandlegridrow((previos) => !previos);
  };

  const placeHolderImage =
    "https://img.freepik.com/free-photo/young-bearded-man-with-striped-shirt_273609-5677.jpg?t=st=1715858901~exp=1715862501~hmac=6b7580e9bc96b16c776bef9f7222ee3588860ccd86d1360d4fa6ac3bb3d284d6&w=996";

  const handleSubImageClick = (ImageUrl) => {
    setFullImage(`${process.env.REACT_APP_API_URL}/uploads/guests/${ImageUrl}`);
  };
  // const handleclose = () => sethandlegridrow((previos) => !previos);
  const [FullImage, setFullImage] = useState(null);

  useEffect(() => {
    if (data && data?.document_images) {
      const image = data?.document_images;
      setFullImage(`${process.env.REACT_APP_API_URL}/uploads/guests/${image}`);
    } else {
      setFullImage(placeHolderImage);
    }
  }, [data]);

  return (
    <Box
      sx={{
        padding: "10px",
        height: "440px",
        overflow: "auto",
        "::-webkit-scrollbar": {
          width: "0px",
        },
      }}
    >
      <Box
        sx={{ width: "100%" }}
        display={"flex"}
        justifyContent={"space-evenly"}
      >
        <Box
          sx={{
            height: "200px",
            width: " 170px",
            borderRadius: "16px",
            boxShadow: "0px 15px 45px rgba(9, 0, 0, 0.1)",
            ml: { xs: 3, sm: 10 },
          }}
        >
          {FullImage ? (
            <LazyLoadImage
              src={FullImage}
              effect="blur"
              height="100%"
              width="100%"
              style={{
                height: "100%",
                width: "100%",
                borderRadius: "16px",
              }}
            />
          ) : (
            <img
              alt="demo profile pic"
              src={
                "https://img.freepik.com/free-photo/young-bearded-man-with-striped-shirt_273609-5677.jpg?t=st=1715858901~exp=1715862501~hmac=6b7580e9bc96b16c776bef9f7222ee3588860ccd86d1360d4fa6ac3bb3d284d6&w=996"
              }
              style={{ height: "100%", width: "100%", borderRadius: "16px" }}
            />
          )}
        </Box>

        <Box sx={{ mr: { xs: -2, sm: -5 } }}>
          <Tooltip title="Close" onClick={handleclose}>
            <IconButton>
              <AddIcon
                sx={{
                  transform: "rotate(120deg)",
                  transition: "0.5s",
                  "&:hover": {
                    transform: "rotate(360deg)",
                  },
                }}
              />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "15px",
          overflow: "auto",
          "::-webkit-scrollbar": {
            width: "0px",
          },
        }}
      >
        {data?.document_images && (
          <LazyLoadImage
            effect="blur"
            src={`${process.env.REACT_APP_API_URL}/uploads/guests/${data?.document_images}`}
            onClick={() => {
              handleSubImageClick(data?.document_images);
            }}
            style={{
              height: "70px",
              width: "70px",
              borderRadius: "16px",
              cursor: "pointer",
              boxShadow: "0px 120px 49px rgba(12, 0, 0, 0.1)",
            }}
          />
        )}
        {data &&
          data?.extra_person_doc &&
          data?.extra_person_doc.map((item, index) => {
            const image = item
              ? `${process.env.REACT_APP_API_URL}/uploads/guests/${item}`
              : placeHolderImage;
            return (
              <LazyLoadImage
                key={index}
                effect="blur"
                src={image}
                onClick={() => {
                  handleSubImageClick(item);
                }}
                style={{
                  height: "70px",
                  width: "70px",
                  borderRadius: "16px",
                  cursor: "pointer",
                  boxShadow: "0px 120px 49px rgba(12, 0, 0, 0.1)",
                }}
              />
            );
          })}
      </Box>
      <List sx={{ marginTop: "20px" }}>
        {data &&
          Object.entries(data).map(([key, value], index) => {
            if (key !== "document_images" && key !== "extra_person_doc") {
              return (
                <Paper
                  key={index}
                  sx={{
                    padding: "10px",
                    marginBottom: "10px",
                    boxShadow: "0px 20px 09px rgba(12, 0, 0, 0.1)",
                  }}
                >
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemIcon>{GUEST_ICONS[index]}</ListItemIcon>
                      <ListItemText
                        primary={key ? key : ""}
                        secondary={value ? value : ""}
                      />
                    </ListItemButton>
                  </ListItem>
                </Paper>
              );
            }
          })}
      </List>
    </Box>
  );
};

export default GuestDetail;

import React, { useRef, useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Stack,
  Grid,
  Divider,
  Button,
  Chip,
  useMediaQuery,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { toWords } from "number-to-words";
import CollapsedBreadcrumbs from "../../Include/Breadcrumb";
import { LazyLoadImage } from "react-lazy-load-image-component";
import logo from "../../../../Assets/img/gotle.webp";
import { useReactToPrint } from "react-to-print";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import "./print.css";
import {
  RoomInvoiceAPI,
  RoomInvoiceComplete_Status,
} from "../../../../store/Slice/BillingSlice/BillingSlice";
import PrintTwoToneIcon from "@mui/icons-material/PrintTwoTone";
import { MySwal } from "../../../../Model/Alert/SuccessAlert";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
const BreadCrumbLink = [
  {
    id: 1,
    name: "Home",
    link: "/",
  },
  {
    id: 2,
    name: "Billing",
    link: "/billing",
  },
  {
    id: 3,
    name: "Room-Invoice",
    link: "room-invoice",
  },
];
const RoomInvoice = () => {
  const { id } = useParams();
  const Token = Cookies.get("Token");
  const IsMobile = useMediaQuery("(min-width:900px)");
  const dispatch = useDispatch();
  const { RoomInvoiceData } = useSelector((state) => state.BillingStore);
  const [record, setrecord] = useState([]);
  const [guest, setGuest] = useState(null);
  const [reservation, setReservation] = useState(null);
  const [room, setRoom] = useState(null);

  useEffect(() => {
    const FetchData = async () => {
      try {
        await dispatch(RoomInvoiceAPI({ Token, id }));
      } catch (error) {
        return error;
      }
    };
    FetchData();
  }, [Token, dispatch]);

  useEffect(() => {
    setrecord(RoomInvoiceData);
  }, [RoomInvoiceData]);

  useEffect(() => {
    console.log(record);
    if (record) {
      setGuest(record.guest);
      setReservation(record);
      setRoom(record.room);
    }
  }, [record]);

  const Billingref = useRef();
  const handlePrint = useReactToPrint({
    copyStyles: true,
    documentTitle: "Room Invoice",
    content: () => Billingref.current,
  });

  const handleCheckoutPrint = () => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, check out!",
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#dc3545",
      cancelButtonText: "No, cancel!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await dispatch(RoomInvoiceComplete_Status({ Token, id }));
        // await dispatch(GetExpenseApi({ Token }));
        handlePrint();
      } else if (result.dismiss === MySwal.DismissReason.cancel) {
        MySwal.fire({
          title: "Cancelled",
          text: "Your data is safe.",
          icon: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "#dc3545",
        });
      }
    });
  };

  return (
    <Box sx={{ padding: "20px", width: "100%", color: "grey" }}>
      <Stack
        direction={IsMobile ? "row" : "column"}
        justifyContent={"space-between"}
      >
        <Typography variant="h6">Room Invoice</Typography>
        <CollapsedBreadcrumbs data={BreadCrumbLink} />
      </Stack>

      <Paper
        sx={{ mt: 2, marginX: IsMobile ? "4rem" : "0px", p: 2 }}
        elevation={24}
        id="paper-section"
      >
        <Paper
          ref={Billingref}
          sx={{
            m: 5,
            border: "1px solid black",
            p: 2,
            backgroundColor: "#e4f5ffaa",
            color: "#474747",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "400" }}
                textAlign={"center"}
                display={"flex"}
                justifyContent={"start"}
                alignContent={"start"}
              >
                GSTIN : 24AAZFP0486C1Z0
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="h4"
                color={"secondary"}
                textAlign={"center"}
                display={"flex"}
                alignContent={"start"}
                justifyContent={"center"}
                sx={{ fontWeight: "700" }}
              >
                <span style={{ textDecoration: "underline", color: "#9c27b0" }}>
                  Tax Invoice
                </span>
              </Typography>
            </Grid>
            <Grid item xs={4} justifyContent={"center"}>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "400" }}
                textAlign={"center"}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"end"}
                alignContent={"start"}
              >
                <div
                  style={{ fontWeight: "500", width: "100%", textAlign: "end" }}
                >
                  Email : Demo123@gmail.com{" "}
                </div>
                <div
                  style={{ fontWeight: "500", width: "100%", textAlign: "end" }}
                >
                  Phone No : +91 987654210{" "}
                </div>
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ marginTop: 2 }} />
          <Grid container>
            {/* <Grid item sm={12} justifyContent={"center"}>
              <Box textAlign={"center"}>
                <LazyLoadImage
                  src={logo}
                  effect="blur"
                  height={"70px"}
                  width={"90px"}
                />
              </Box>
            </Grid> */}
            <Grid item xs={12}>
              <Typography
                variant="h4"
                textAlign={"center"}
                sx={{ fontWeight: "700", fontFamily: "serif" }}
              >
                HOTEL RAJ PALACE
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "400" }}
                textAlign={"center"}
              >
                NEAR BUS STAND , <b>MERTA CITY</b> - 341510 (RAJ.)
              </Typography>
            </Grid>
          </Grid>
          <Divider xs={12} sx={{ marginTop: 2 }} />
          <Grid container xs={12} sx={{ mt: 1 }}>
            <Grid item xs={5}>
              {reservation ? (
                <>
                  <Typography variant="h6">
                    Invoice No.{" "}
                    <b
                      style={{
                        color: "#9c27b0",
                        fontFamily: "serif",
                        fontSize: "2rem",
                      }}
                    >
                      {reservation?.invoice_num || ""}
                    </b>
                  </Typography>
                </>
              ) : (
                <Typography variant="h6">
                  Reservation information loading...
                </Typography>
              )}
            </Grid>
            <Grid
              container
              item
              xs={7}
              display={"flex"}
              alignContent={"end"}
              pb={1}
            >
              <Grid item xs={6}>
                {room ? (
                  <>
                    <Typography variant="h6">
                      Room No. {room?.room_no}
                    </Typography>
                  </>
                ) : (
                  <Typography variant="h6">
                    Reservation information loading...
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6}>
                {reservation ? (
                  <>
                    <Typography variant="h6">
                      Date :{" "}
                      {reservation?.booking_date
                        ? dayjs(reservation?.booking_date).format(
                            "DD/MM/YYYY, hh:mm A"
                          )
                        : ""}
                    </Typography>
                  </>
                ) : (
                  <Typography variant="h6">
                    Reservation information loading...
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={6}>
              <Stack direction={"column"}>
                <Typography variant="h6" mr={4}>
                  BILLED TO
                </Typography>
                {guest ? ( // Check if guest is not null before accessing its properties
                  <>
                    <Typography
                      variant="h5"
                      mr={4}
                      sx={{ fontFamily: "serif" }}
                    >
                      <b>{guest?.fullname || ""}</b>
                    </Typography>
                    <Typography variant="body2" mt={1}>
                      Mobile No
                    </Typography>
                    <Typography variant="h6" sx={{ fontFamily: "serif" }}>
                      <b>{guest?.phone_number || ""}</b>
                    </Typography>
                    <Typography variant="body2" mt={1}>
                      E-mail
                    </Typography>
                    <Typography variant="h6" sx={{ fontFamily: "serif" }}>
                      <b>{guest?.email || ""}</b>
                    </Typography>
                  </>
                ) : (
                  <Typography variant="body2">
                    Guest information loading...
                  </Typography>
                )}
              </Stack>
            </Grid>
            <Grid item xs={6} display={"flex"} justifyContent={"end"}>
              <Stack direction={"column"}>
                {reservation ? ( // Check if guest is not null before accessing its properties
                  <>
                    <Typography variant="body2" mt={1} textAlign={"right"}>
                      Check-In
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ fontFamily: "serif" }}
                      textAlign={"right"}
                    >
                      <b>
                        {reservation?.check_in
                          ? dayjs(reservation?.check_in).format(
                              "DD/MM/YYYY, hh:mm A"
                            )
                          : ""}
                      </b>
                    </Typography>

                    <Typography variant="body2" mt={1} textAlign={"right"}>
                      Check-out
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ fontFamily: "serif" }}
                      textAlign={"right"}
                    >
                      <b>
                        {reservation?.check_out
                          ? dayjs(reservation?.check_out).format(
                              "DD/MM/YYYY, hh:mm A"
                            )
                          : ""}
                      </b>
                    </Typography>
                  </>
                ) : (
                  <Typography variant="body2">
                    Guest information loading...
                  </Typography>
                )}
              </Stack>
            </Grid>
            {/* <Grid item lg={2}>
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "end",
                  }}
                >
                  {reservation ? (
                    <>
                      <Typography variant="body2" mr={3}>
                        Invoice No : {reservation?.invoice_num || ""}
                      </Typography>
                      <Typography variant="body2">
                        Date :{" "}
                        {reservation?.booking_date
                          ? new Date(
                              reservation?.booking_date
                            ).toLocaleDateString()
                          : ""}
                      </Typography>
                    </>
                  ) : (
                    <Typography variant="body2">
                      Reservation information loading...
                    </Typography>
                  )}
                </Box>
              </Box>
            </Grid> */}
            <Grid item sm={12}>
              <Stack mt={5}>
                <Grid container mb={2}>
                  <Grid item xs={12}>
                    <TableContainer
                      component={Box}
                      height={"100%"}
                      width={"100%"}
                    >
                      <Table>
                        <TableHead className="theader">
                          <TableRow>
                            <TableCell
                              scope="col"
                              style={{
                                border: "1px solid black",
                                fontWeight: 400,
                                textAlign: "center",
                                padding: "2px",
                              }}
                              colSpan={2}
                            >
                              <Typography
                                variant="h4"
                                sx={{ fontFamily: "serif", fontWeight: 700 }}
                              >
                                Reservation Details
                              </Typography>
                            </TableCell>
                            {/* <TableCell
                              scope="col"
                              style={{ border: "1px solid black" }}
                            >
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: 400 }}
                              >
                                Amount
                              </Typography>
                            </TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {room ? (
                            <>
                              {/* <TableRow>
                                <TableCell
                                  scope="row"
                                  className="trowHeader"
                                  style={{
                                    width: "90%",
                                    border: "1px solid black",
                                    fontWeight: 400,
                                  }}
                                >
                                  <Typography variant="body2">
                                    Room No
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  style={{ border: "1px solid black" }}
                                >
                                  <Typography variant="body2">
                                    {room?.room_no || ""}
                                  </Typography>
                                </TableCell>
                              </TableRow> */}

                              <TableRow>
                                <TableCell
                                  scope="row"
                                  className="trowHeader"
                                  style={{
                                    width: "95%",
                                    border: "1px solid black",
                                    fontWeight: 400,
                                  }}
                                >
                                  <Typography variant="body2">
                                    Bed Type
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  style={{ border: "1px solid black" }}
                                >
                                  <Typography variant="body2">
                                    {room?.bed_type || ""}
                                  </Typography>
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell scope="row" className="trowHeader">
                                  <Typography variant="body2">
                                    Per day Rent
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  style={{ border: "1px solid black" }}
                                >
                                  <Typography variant="body2">
                                    {room?.perdayprice || ""}
                                  </Typography>
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell scope="row" className="trowHeader">
                                  <Typography variant="body2">
                                    Payment Status
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  style={{ border: "1px solid black" }}
                                >
                                  {reservation &&
                                    reservation?.payment_status &&
                                    reservation?.payment_status === "PAID" && (
                                      <Chip
                                        variant="outlined"
                                        label={reservation?.payment_status}
                                        color="success"
                                        sx={{
                                          fontSize: "1rem",
                                          fontWeight: 700,
                                        }}
                                      />
                                    )}

                                  {reservation &&
                                    reservation?.payment_status &&
                                    reservation?.payment_status ===
                                      "PARTIAL" && (
                                      <Chip
                                        sx={{
                                          fontSize: "1rem",
                                          fontWeight: 700,
                                        }}
                                        variant="outlined"
                                        label={reservation?.payment_status}
                                        color="info"
                                      />
                                    )}

                                  {reservation &&
                                    reservation?.payment_status &&
                                    reservation?.payment_status ===
                                      "UNPAID" && (
                                      <Chip
                                        variant="outlined"
                                        sx={{
                                          fontSize: "1rem",
                                          fontWeight: 700,
                                        }}
                                        label={reservation?.payment_status}
                                        color="error"
                                      />
                                    )}
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell scope="row" className="trowHeader">
                                  <Typography variant="body2">
                                    Total Days
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  style={{ border: "1px solid black" }}
                                >
                                  <Typography variant="body2">
                                    {reservation
                                      ? reservation?.total_days
                                      : "-"}
                                  </Typography>
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell scope="row" className="trowHeader">
                                  <Typography variant="body2">
                                    Advance Amount
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  style={{ border: "1px solid black" }}
                                >
                                  <Typography variant="body2">
                                    {reservation
                                      ? reservation?.adv_payment
                                      : ""}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </>
                          ) : (
                            <Typography variant="body2">
                              Room information loading...
                            </Typography>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6}>
                    <Box
                      border={"1px solid black"}
                      borderRight={"none"}
                      height={"100%"}
                      p={2}
                    >
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="subtitle2">
                          Invoice Amount In Words
                        </Typography>
                        <Typography variant="body2">
                          {reservation && reservation?.taxable_price
                            ? toWords(reservation?.taxable_price)
                            : toWords(0)}
                        </Typography>
                      </Box>

                      <Box sx={{ mt: 2 }}>
                        <Typography
                          variant="subtitle2"
                          sx={{ fontSize: "17px" }}
                        >
                          Terms and Conditions
                        </Typography>
                        <Typography variant="body2">
                          Thanks for doing business with us! Any complaint must
                          be made within 7 days of receiving the invoice.
                          Payment must be made within 30 days from the date of
                          invoice.
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box height={"100%"}>
                      <Table height={"100%"}>
                        <TableBody>
                          <TableRow>
                            <TableCell scope="row" className="trowHeader">
                              <Typography variant="body2">Sub Total</Typography>
                            </TableCell>
                            <TableCell
                              style={{
                                border: "1px solid black",
                                width: "5%",
                              }}
                            >
                              <Typography
                                variant="body2"
                                display={"flex"}
                                justifyContent={"end"}
                                align={"right"}
                                sx={{ fontSize: "15px" }}
                              >
                                {reservation ? reservation?.total_price : "0"}
                              </Typography>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell className="trowHeader">
                              <Typography variant="body2">CGST 6%</Typography>
                            </TableCell>
                            <TableCell
                              style={{
                                border: "1px solid black",
                              }}
                            >
                              <Typography
                                variant="body2"
                                display={"flex"}
                                justifyContent={"end"}
                                align={"right"}
                                sx={{ fontSize: "15px" }}
                              >
                                {reservation ? reservation?.cgst : "0"}
                              </Typography>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell className="trowHeader">
                              <Typography variant="body2">SGST 6%</Typography>
                            </TableCell>
                            <TableCell
                              style={{
                                border: "1px solid black",
                              }}
                            >
                              <Typography
                                variant="body2"
                                display={"flex"}
                                justifyContent={"end"}
                                align={"right"}
                                sx={{ fontSize: "15px" }}
                              >
                                {reservation ? reservation?.sgst : "0"}
                              </Typography>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell className="trowHeader">
                              <Typography variant="body2">IGST 12%</Typography>
                            </TableCell>
                            <TableCell
                              style={{
                                border: "1px solid black",
                              }}
                            >
                              <Typography
                                variant="body2"
                                display={"flex"}
                                justifyContent={"end"}
                                align={"right"}
                                sx={{ fontSize: "15px" }}
                              >
                                {reservation ? reservation?.igst : "0"}
                              </Typography>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell className="theader">
                              <Typography variant="subtitle2">Total</Typography>
                            </TableCell>
                            <TableCell className="trowHeader">
                              <Typography
                                variant="subtitle2"
                                display={"flex"}
                                justifyContent={"end"}
                                align={"right"}
                                sx={{ fontSize: "15px" }}
                              >
                                {reservation ? reservation?.taxable_price : "0"}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
          </Grid>

          <Divider sx={{ marginTop: 2 }} />
          <Grid container sx={{ mt: 2 }} spacing={2}>
            <Grid item xs={6}>
              <Stack direction={"column"} justifyContent={"flex-start"} gap={5}>
                <Box textAlign={"left"}>
                  <Typography
                    variant="h5"
                    sx={{ fontFamily: "serif", fontWeight: 700 }}
                  >
                    THANK YOU !
                  </Typography>
                </Box>

                <Box textAlign={"left"}>
                  <Typography variant="h6" sx={{ fontFamily: "serif" }}>
                    Customer's Signature
                  </Typography>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack direction={"column"} justifyContent={"flex-end"} gap={5}>
                <Box textAlign={"right"}>
                  <Typography
                    variant="h5"
                    sx={{ fontFamily: "serif", fontWeight: 700 }}
                  >
                    For : HOTEL RAJ PALACE
                  </Typography>
                </Box>

                <Box textAlign={"right"}>
                  <Typography variant="h6" sx={{ fontFamily: "serif" }}>
                    Manager / Reciptionist
                  </Typography>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Paper>

      <Stack
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        direction={"row"}
        mt={5}
        gap={2}
      >
        <Button variant="contained" onClick={handlePrint} color="info">
          Print
        </Button>
        {/* {reservation?.status && reservation?.status !== "COMPLETED" && (
          <Button
            variant="contained"
            onClick={handleCheckoutPrint}
            disabled
            color="warning"
          >
            <PrintTwoToneIcon /> Check out & Print
          </Button>
        )} */}
      </Stack>
    </Box>
  );
};
export default RoomInvoice;

import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import AddCardIcon from "@mui/icons-material/AddCard";
import BusinessIcon from "@mui/icons-material/Business";
import ScheduleIcon from "@mui/icons-material/Schedule";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import FreeBreakfastTwoToneIcon from "@mui/icons-material/FreeBreakfastTwoTone";
import BathtubTwoToneIcon from "@mui/icons-material/BathtubTwoTone";
import ShowerTwoToneIcon from "@mui/icons-material/ShowerTwoTone";
import WifiTwoToneIcon from "@mui/icons-material/WifiTwoTone";
import FamilyRestroomTwoToneIcon from "@mui/icons-material/FamilyRestroomTwoTone";
import TvTwoToneIcon from "@mui/icons-material/TvTwoTone";
import CleaningServicesTwoToneIcon from "@mui/icons-material/CleaningServicesTwoTone";
import SmokeFreeTwoToneIcon from "@mui/icons-material/SmokeFreeTwoTone";
import AcUnitTwoToneIcon from "@mui/icons-material/AcUnitTwoTone";
import PetsTwoToneIcon from "@mui/icons-material/PetsTwoTone";
import LocalParkingTwoToneIcon from "@mui/icons-material/LocalParkingTwoTone";
import ElectricalServicesTwoToneIcon from "@mui/icons-material/ElectricalServicesTwoTone";
import Filter2Icon from "@mui/icons-material/Filter2";
import KingBedTwoToneIcon from "@mui/icons-material/KingBedTwoTone";
import { MdEventAvailable } from "react-icons/md";
import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import StarsTwoToneIcon from "@mui/icons-material/StarsTwoTone";
import { BsSortNumericUpAlt } from "react-icons/bs";
import PaymentsTwoToneIcon from "@mui/icons-material/PaymentsTwoTone";
import BadgeTwoToneIcon from "@mui/icons-material/BadgeTwoTone";
import FaceTwoToneIcon from "@mui/icons-material/FaceTwoTone";
import ForwardToInboxTwoToneIcon from "@mui/icons-material/ForwardToInboxTwoTone";
import PhoneIphoneTwoToneIcon from '@mui/icons-material/PhoneIphoneTwoTone';
import LocationCityTwoToneIcon from '@mui/icons-material/LocationCityTwoTone';
import AlarmTwoToneIcon from '@mui/icons-material/AlarmTwoTone';

const FACILITIES = [
  {
    id: 1,
    icon: <FreeBreakfastTwoToneIcon />,
    label: "Breakfast",
  },
  {
    id: 2,
    icon: <BathtubTwoToneIcon />,
    label: "Private bathroom",
  },
  {
    id: 3,
    icon: <WifiTwoToneIcon />,
    label: "Free wifi",
  },
  {
    id: 4,
    icon: <FamilyRestroomTwoToneIcon />,
    label: "Family rooms",
  },
  {
    id: 5,
    icon: <CleaningServicesTwoToneIcon />,
    label: "Room service",
  },
  {
    id: 6,
    icon: <ShowerTwoToneIcon />,
    label: "Shower",
  },
  {
    id: 7,
    icon: <SmokeFreeTwoToneIcon />,
    label: "Non Smoking Rooms",
  },
  {
    id: 8,
    icon: <AcUnitTwoToneIcon />,
    label: "Air Conditioning",
  },
  {
    id: 9,
    icon: <TvTwoToneIcon />,
    label: "TV",
  },
  {
    id: 10,
    icon: <PetsTwoToneIcon />,
    label: "Pet Friendly",
  },
  {
    id: 11,
    icon: <LocalParkingTwoToneIcon />,
    label: "Free on-site parking",
  },
  {
    id: 12,
    icon: <LocalParkingTwoToneIcon />,
    label: "Private parking",
  },
  {
    id: 13,
    icon: <ElectricalServicesTwoToneIcon />,
    label: "Cabel channels",
  },
];

const ROOM_ICONS = [
  <Filter2Icon />,
  <KingBedTwoToneIcon />,
  <MdEventAvailable fontSize={"25px"} />,
  <DescriptionTwoToneIcon />,
  <StarsTwoToneIcon />,
  <BsSortNumericUpAlt fontSize={"25px"} />,
  null,
  <PaymentsTwoToneIcon />,
];

const GUEST_ICONS = [
  <FaceTwoToneIcon />,
  <ForwardToInboxTwoToneIcon />,
  <PhoneIphoneTwoToneIcon />,
  <LocationCityTwoToneIcon />,
  <BadgeTwoToneIcon />,
  <AlarmTwoToneIcon />,
];

const data = [
  { primary: "Room No", secondary: "111" },
  { primary: "Price", secondary: "₹876555" },
  { primary: "Floor No", secondary: "17" },
  { primary: "Bed Type", secondary: "Double" },
  { primary: "Facility", secondary: "Tv,Phone,A.c,Free Services" },
  {
    primary: "Bed Type",
    secondary:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Non, ipsa.",
  },
  {
    primary: "Address",
    secondary: "315, Halima Residency gabheni Road Unn Surat 394210",
  },
];

const BookedRoom_Data = [
  { primary: "Room No", secondary: "111" },
  { primary: "Price", secondary: "₹876555" },
  { primary: "Status", secondary: "Booked" },
  { primary: "Booking Date", secondary: "27-5-2024" },
  { primary: "Check In Date", secondary: "30-5-2024" },
  { primary: "Check Out Date", secondary: "30-5-2024" },
  { primary: "Floor No", secondary: "17" },
  { primary: "Bed Type", secondary: "Double" },
  { primary: "Facility", secondary: "Tv,Phone,A.c,Free Services" },
  {
    primary: "Bed Type",
    secondary:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Non, ipsa.",
  },
  {
    primary: "Address",
    secondary: "315, Halima Residency gabheni Road Unn Surat 394210",
  },
];

const CancelRoom_Data = [
  { primary: "Room No", secondary: "111" },
  { primary: "Price", secondary: "₹876555" },
  { primary: "Status", secondary: "cancel" },
  { primary: "Booking Date", secondary: "27-5-2024" },
  { primary: "Cancel Date", secondary: "28-5-2024" },
  { primary: "Floor No", secondary: "17" },
  { primary: "Bed Type", secondary: "Double" },
  { primary: "Facility", secondary: "Tv,Phone,A.c,Free Services" },
  {
    primary: "Bed Type",
    secondary:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Non, ipsa.",
  },
  {
    primary: "Address",
    secondary: "315, Halima Residency gabheni Road Unn Surat 394210",
  },
];

const icons = [
  <PersonOutlineIcon />,
  <MailOutlineIcon />,
  <LocalPhoneIcon />,
  <AddCardIcon />,
  <AddCardIcon />,
  <AddCardIcon />,
  <BusinessIcon />,
];

const Bookedicons = [
  <PersonOutlineIcon />,
  <MailOutlineIcon />,
  <ScheduleIcon />,
  <ScheduleIcon />,
  <AutorenewIcon />,
  <LocalPhoneIcon />,
  <AddCardIcon />,
  <AddCardIcon />,
  <AddCardIcon />,
  <BusinessIcon />,
];

const Cancelicons = [
  <PersonOutlineIcon />,
  <MailOutlineIcon />,
  <ScheduleIcon />,
  <ScheduleIcon />,
  <AutorenewIcon />,
  <LocalPhoneIcon />,
  <AddCardIcon />,
  <AddCardIcon />,
  <AddCardIcon />,
  <BusinessIcon />,
];

//UserData
const UserData = [
  { primary: "status", secondary: "Active" },
  { primary: "Name", secondary: "Demo User" },
  { primary: "Email", secondary: "DemoUser123@gmail.com" },
  { primary: "Mobile", secondary: "8796541230" },
  { primary: "Adhar-No", secondary: "8796541230123" },
  {
    primary: "Address",
    secondary:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus enim consequuntur amet magni velit cumque.",
  },
];

const iconsUser = [
  <AutorenewIcon />,
  <PersonOutlineIcon />,
  <MailOutlineIcon />,
  <LocalPhoneIcon />,
  <AddCardIcon />,
  <AddCardIcon />,
  <AddCardIcon />,
  <BusinessIcon />,
];

const Room_Service_Data = [
  { primary: "Food Code", secondary: "111" },
  { primary: "Status", secondary: "cancel" },
  { primary: "Food Name", secondary: "Noodels" },
  { primary: "Price", secondary: "₹876555" },
  { primary: "Quantity", secondary: "5" },
  { primary: "Discount", secondary: "₹50" },
  { primary: "Total", secondary: "₹500" },
  { primary: "Booking Date", secondary: "27-5-2024 10:00" },
  { primary: "Room No", secondary: "17" },
  { primary: "Mobile", secondary: "8796541230" },
];

export {
  FACILITIES,
  ROOM_ICONS,
  GUEST_ICONS,
  data,
  BookedRoom_Data,
  CancelRoom_Data,
  icons,
  Bookedicons,
  Cancelicons,
  UserData,
  iconsUser,
  Room_Service_Data,
};

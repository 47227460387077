import React, { useState, useCallback, useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import axios from "axios";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Modal as BaseModal } from "@mui/base/Modal";
import Fade from "@mui/material/Fade";
import {
  Alert,
  Autocomplete,
  Avatar,
  Divider,
  IconButton,
  Snackbar,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Stack, TextField } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import SingleImageDropZone, {
  FileUploadDropZone,
} from "../Components/Admin/AdminProfile/SingleImageDropzone";

import { DateTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SimpleDialog from "../Components/Admin/Other/Guest/Webcam/webcam";
import { useFormik } from "formik";
import dayjs from "dayjs";
import {
  ExpenseSchema,
  FoodCategorySchema,
  addBookingSchema,
  editBookingSchema,
  fileUploadSchema,
} from "../schema/Schema";
import { FoodItemSchema } from "../schema/Schema";
import { useDispatch, useSelector } from "react-redux";
import {
  GetFoodApi,
  PostBulkFoodApi,
  PostFoodApi,
  PutFoodApi,
} from "../store/Slice/FoodSlice/FoodSlice";
import {
  PostCategoryApi,
  PutCategoryApi,
  GetCategoryApi,
  PostBulkCategoryApi,
} from "../store/Slice/FoodCategorySlice/FoodCategorySlice";
import Cookies from "js-cookie";
import {
  PostBookingAPI,
  PutBookingAPI,
} from "../store/Slice/Booking/BookingSlice";
import {
  GetAllGuestData,
  GetSingleGuestData,
  PostBulkGuestData,
} from "../store/Slice/GuestSlice/GuestDataTableSlice";
import { MySwal } from "./Alert/SuccessAlert";
import {
  AddBulkRoomApi,
  GetRoomApi,
} from "../store/Slice/RoomsSlice/RoomSlice";
import { Get_ReservationApi_BYID } from "../store/Slice/ReservationSlice/ReservationSlice";
import { GetAllBookingRecord } from "../store/Slice/BookingRecordSlice/BookingRecordSlice";
import {
  GetExpenseApi,
  PostExpenseApi,
  PutExpenseApi,
} from "../store/Slice/ExpenseSlice/ExpenseSlice";
import { LazyLoadImage } from "react-lazy-load-image-component";
const Base_url = process.env.REACT_APP_API_URL + "/check/itemcode/fooditems";

const numberInputOnWheelPreventChange = (e) => {
  // Prevent the input value change
  e.target.blur();

  // Refocus immediately, on the next tick (after the current function is done)
  setTimeout(() => {
    e.target.focus();
  }, 0);
};

export default function AddItem({ handleClose, handleCategoryOpen, open }) {
  const IsMobile = useMediaQuery("(min-width:600px)");
  const Token = Cookies.get("Token");
  const dispatch = useDispatch();
  const { FoodCategoryData } = useSelector((state) => state.FoodCategoryStore);
  const [Status, setStatus] = useState(true);
  const [Message, setMessage] = useState("");

  useEffect(() => {
    const FetchData = async () => {
      try {
        await dispatch(GetCategoryApi({ Token }));
      } catch (error) {
        return error;
      }
    };
    FetchData();
  }, [dispatch, Token]);

  const initialValues = {
    item_code: "",
    item_name: "",
    category_id: "",
    price: "",
    // tax_type: "",
    images: null,
  };
  const {
    errors,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: FoodItemSchema,
    onSubmit: async (values) => {
      try {
        values.images = image.file;
        const formdata = new FormData();
        Object.entries(values).forEach(([key, val]) => {
          formdata.append(key, val); // Include the file name
        });

        await dispatch(PostFoodApi({ Token, formdata }));
        await dispatch(GetFoodApi({ Token }));
      } catch (error) {
        return error;
      }
      handleClose();
    },
  });

  const [image, setImage] = useState(null);

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      const preview = URL.createObjectURL(file);
      setImage({ file, preview });
    }

    if (rejectedFiles.length) {
      // Handle rejected files if needed
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [],
    }, // Accept any image file
    maxFiles: 1, // Limit to only one file
  });

  useEffect(() => {
    setFieldValue("images", image);
  }, [image]);

  const handleItemCodeChange = async (e) => {
    handleChange(e);
    setStatus(true);
    setTimeout(async () => {
      try {
        const headers = {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        };

        const response = await axios.post(
          Base_url,
          { item_code: e.target.value },
          { headers }
        );
        if (response.data.status === true) {
          setStatus(true);
          setMessage(response.data.message);
        }
      } catch (error) {
        setStatus(false);
        setMessage(error.response.data.message);
      }
    }, 500);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: StyledBackdrop }}
      >
        <Fade in={open}>
          <ModalContent sx={style}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: IsMobile ? 2 : 20,
                height: "600px",
                overflow: "auto",
                "::-webkit-scrollbar": {
                  width: "0px",
                },
              }}
            >
              <Typography
                variant="h6"
                sx={{ textAlign: "center", textTransform: "capitalize" }}
              >
                Add Item
              </Typography>
              <form
                onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: IsMobile ? 2 : 1,
                }}
              >
                <Stack direction={"column"} spacing={2} sx={{ marginTop: 1 }}>
                  <Stack
                    direction={"row"}
                    spacing={2}
                    sx={{ marginTop: 1 }}
                    justifyContent={"center"}
                    alignContent={"center"}
                    alignItems={"center"}
                  >
                    <FormControl
                      fullWidth
                      error={touched.category_id && errors.category_id}
                      helperText={touched.category_id && errors.category_id}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Category
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="category_id"
                        name="category_id"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.category_id}
                      >
                        {FoodCategoryData &&
                          FoodCategoryData.length > 0 &&
                          FoodCategoryData.map((item, index) => (
                            <MenuItem key={index} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    {touched.category_id && errors.category_id && (
                      <Typography
                        variant="caption"
                        color={"error"}
                        style={{
                          marginTop: "3px",
                          marginLeft: "14px",
                          marginRight: "14px",
                          marginBottom: "0px",
                        }}
                      >
                        {errors.category_id}
                      </Typography>
                    )}
                    <Avatar sx={{ background: "#EA82A6" }}>
                      <Tooltip title="Add Category">
                        <IconButton onClick={handleCategoryOpen}>
                          <AddIcon sx={{ color: "#fff" }} />
                        </IconButton>
                      </Tooltip>
                    </Avatar>
                  </Stack>
                  <TextField
                    label="Code"
                    variant="outlined"
                    size="large"
                    name="item_code"
                    type="text"
                    sx={{ width: "100%" }}
                    value={values.item_code}
                    onChange={handleItemCodeChange}
                    onBlur={handleBlur}
                    error={touched.item_code && errors.item_code}
                    helperText={touched.item_code && errors.item_code}
                  />
                  {Status === false && (
                    <Typography
                      variant="caption"
                      color={"error"}
                      style={{
                        marginTop: "3px",
                        marginLeft: "14px",
                        marginRight: "14px",
                        marginBottom: "0px",
                      }}
                    >
                      {Message}
                    </Typography>
                  )}

                  <TextField
                    label=" Name"
                    variant="outlined"
                    size="large"
                    name="item_name"
                    type="text"
                    sx={{ width: "100%" }}
                    value={values.item_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.item_name && errors.item_name}
                    helperText={touched.item_name && errors.item_name}
                  />

                  <TextField
                    label="price"
                    variant="outlined"
                    size="large"
                    name="price"
                    type="number"
                    onWheel={numberInputOnWheelPreventChange}
                    sx={{ width: "100%" }}
                    value={values.price}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.price && errors.price}
                    helperText={touched.price && errors.price}
                  />

                  {/* <FormControl
                    fullWidth
                    error={touched.tax_type && errors.tax_type}
                    helperText={touched.tax_type && errors.tax_type}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Tax Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="tax_type"
                      name="tax_type"
                      value={values.tax_type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <MenuItem value={"INCLUDE"}>INCLUDE</MenuItem>
                      <MenuItem value={"EXCLUDE"}>EXCLUDE</MenuItem>
                      <MenuItem value={"NONE"}>NONE</MenuItem>
                    </Select>
                  </FormControl>
                  {touched.tax_type && errors.tax_type && (
                    <Typography
                      variant="caption"
                      color={"error"}
                      style={{
                        marginTop: "3px",
                        marginLeft: "14px",
                        marginRight: "14px",
                        marginBottom: "0px",
                      }}
                    >
                      {errors.tax_type}
                    </Typography>
                  )} */}

                  <SingleImageDropZone
                    getRootProps={getRootProps}
                    getInputProps={getInputProps}
                    isDragActive={isDragActive}
                    images={image}
                    uploadType={"FOOD"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.images && errors.images && (
                    <Typography
                      variant="caption"
                      color={"error"}
                      style={{
                        marginTop: "3px",
                        marginLeft: "14px",
                        marginRight: "14px",
                        marginBottom: "0px",
                      }}
                    >
                      {errors.images}
                    </Typography>
                  )}
                </Stack>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 5,
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant={"contained"}
                    type="submit"
                    color="success"
                    disabled={Status === false}
                  >
                    submit
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="error"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </Box>
          </ModalContent>
        </Fade>
      </Modal>
    </div>
  );
}

export function EditItem({ handleClose, open, data }) {
  const IsMobile = useMediaQuery("(min-width:600px)");
  const dispatch = useDispatch();
  const Token = Cookies.get("Token");
  const { FoodCategoryData } = useSelector((state) => state.FoodCategoryStore);
  const { id } = data;
  const [Status, setStatus] = useState(true);
  const [Message, setMessage] = useState("");

  useEffect(() => {
    const FetchData = async () => {
      try {
        await dispatch(GetCategoryApi({ Token }));
      } catch (error) {
        return error;
      }
    };
    FetchData();
  }, [dispatch, Token]);

  const [image, setImage] = useState(() => data.images || null);

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      const preview = URL.createObjectURL(file);
      setImage({ file, preview });
    }

    if (rejectedFiles.length) {
      // Handle rejected files if needed
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [],
    }, // Accept any image file
    maxFiles: 1, // Limit to only one file
  });

  const initialValues = {
    item_code: "",
    item_name: "",
    price: "",
    // tax_type: "",
    category_id: "",
    images: null,
  };
  const {
    errors,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues,
    validationSchema: FoodItemSchema,
    onSubmit: async (values) => {
      try {
        values.images = image.file ? image.file : values.images;
        MySwal.fire({
          title: "Do you want to save the changes?",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Save",
          denyButtonText: `Don't save`,
        }).then(async (result) => {
          if (result.isConfirmed) {
            values.images = image.file;
            values.images = image.file;
            const formdata = new FormData();
            Object.entries(values).forEach(([key, val]) => {
              formdata.append(key, val); // Include the file name
            });
            await dispatch(PutFoodApi({ Token, formdata, id }));
            await dispatch(GetFoodApi({ Token }));
          } else if (result.isDenied) {
            MySwal.fire("Changes are not saved", "", "info");
          }
        });
      } catch (error) {
        return error;
      }

      handleClose();
    },
  });

  useEffect(() => {
    setValues({
      ...values,
      item_code: data.item_code ? data.item_code : "",
      item_name: data.item_name ? data.item_name : "",
      price: data.price ? data.price : "",
      // tax_type: data.tax_type ? data.tax_type : "",
      category_id: data.category_id ? data.category_id : "",
      images: data.images ? data.images : "",
    });
  }, [data]);

  console.info("values", values);

  useEffect(() => {
    setImage(data.images || null);
  }, [data]);

  useEffect(() => {
    console.info("image :>>", image);
    setFieldValue("images", image);
  }, [image]);

  const handleItemCodeChange = async (e) => {
    handleChange(e);
    setStatus(true);
    setTimeout(async () => {
      try {
        const headers = {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        };

        const response = await axios.post(
          Base_url,
          { item_code: e.target.value, item_id: id },
          { headers }
        );
        if (response.data.status === true) {
          setStatus(true);
          setMessage(response.data.message);
        }
      } catch (error) {
        setStatus(false);
        setMessage(error.response.data.message);
      }
    }, 500);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: StyledBackdrop }}
      >
        <Fade in={open}>
          <ModalContent sx={style}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: IsMobile ? 30 : 20,
              }}
            >
              <Typography
                variant="h6"
                sx={{ textAlign: "center", textTransform: "capitalize" }}
              >
                Edit Food Items
              </Typography>
              <form
                onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: IsMobile ? 2 : 1,
                }}
              >
                <Stack direction={"column"} spacing={2} sx={{ marginTop: 1 }}>
                  <FormControl
                    fullWidth
                    error={touched.category_id && errors.category_id}
                    helperText={touched.category_id && errors.category_id}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Category
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="category_id"
                      name="category_id"
                      value={values.category_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {FoodCategoryData &&
                        FoodCategoryData.length > 0 &&
                        FoodCategoryData.map((item, index) => (
                          <MenuItem key={index} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {touched.category_id && errors.category_id && (
                    <Typography
                      variant="caption"
                      color={"error"}
                      style={{
                        marginTop: "3px",
                        marginLeft: "14px",
                        marginRight: "14px",
                        marginBottom: "0px",
                      }}
                    >
                      {errors.category_id}
                    </Typography>
                  )}

                  <TextField
                    label="Code"
                    variant="outlined"
                    size="large"
                    name="item_code"
                    type="text"
                    sx={{ width: "100%" }}
                    value={values.item_code}
                    onChange={handleItemCodeChange}
                    onBlur={handleBlur}
                    error={touched.item_code && errors.item_code}
                    helperText={touched.item_code && errors.item_code}
                  />
                  {Status === false && (
                    <Typography
                      variant="caption"
                      color={"error"}
                      style={{
                        marginTop: "3px",
                        marginLeft: "14px",
                        marginRight: "14px",
                        marginBottom: "0px",
                      }}
                    >
                      {Message}
                    </Typography>
                  )}

                  <TextField
                    label=" Name"
                    variant="outlined"
                    size="large"
                    name="item_name"
                    type="text"
                    sx={{ width: "100%" }}
                    value={values.item_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.item_name && errors.item_name}
                    helperText={touched.item_name && errors.item_name}
                  />

                  <TextField
                    label="price"
                    variant="outlined"
                    size="large"
                    name="price"
                    type="text"
                    sx={{ width: "100%" }}
                    value={values.price}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.price && errors.price}
                    helperText={touched.price && errors.price}
                  />

                  {/* <FormControl
                    fullWidth
                    error={touched.tax_type && errors.tax_type}
                    helperText={touched.tax_type && errors.tax_type}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Tax Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="tax_type"
                      value={values.tax_type}
                      name="tax_type"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <MenuItem value={"INCLUDE"}>INCLUDE</MenuItem>
                      <MenuItem value={"EXCLUDE"}>EXCLUDE</MenuItem>
                      <MenuItem value={"NONE"}>NONE</MenuItem>
                    </Select>
                  </FormControl>
                  {touched.tax_type && errors.tax_type && (
                    <Typography
                      variant="caption"
                      color={"error"}
                      style={{
                        marginTop: "3px",
                        marginLeft: "14px",
                        marginRight: "14px",
                        marginBottom: "0px",
                      }}
                    >
                      {errors.tax_type}
                    </Typography>
                  )} */}

                  <SingleImageDropZone
                    getRootProps={getRootProps}
                    getInputProps={getInputProps}
                    isDragActive={isDragActive}
                    images={image}
                    uploadType={"FOOD"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.images && errors.images && (
                    <Typography
                      variant="caption"
                      color={"error"}
                      style={{
                        marginTop: "3px",
                        marginLeft: "14px",
                        marginRight: "14px",
                        marginBottom: "0px",
                      }}
                    >
                      {errors.images}
                    </Typography>
                  )}
                </Stack>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 5,
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant={"contained"}
                    type="submit"
                    color="success"
                    disabled={Status === false}
                  >
                    submit
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="error"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          </ModalContent>
        </Fade>
      </Modal>
    </div>
  );
}

// Add Booking Model

export function AddBookingModel({
  // handleClose,
  // setfilterDate,
  // handleBookingModelclose,
  // setfilterDate,
  open,
  RoomNo,
  handleBookingModelclose,
  filterDate,
}) {
  const IsMobile = useMediaQuery("(min-width:600px)");
  const dispatch = useDispatch();
  const Token = Cookies.get("Token");
  const userId = Cookies.get("userid");
  let checkIn = Cookies.get("checkin");
  let checkOut = Cookies.get("checkout");

  const { id, perdayprice, room_no, tax_type } = RoomNo;
  const { GuestDatatableData } = useSelector((state) => state.UserStore);
  const [record, setrecord] = useState([]);

  useEffect(() => {
    const FetchData = async () => {
      try {
        await dispatch(GetAllGuestData({ Token }));
      } catch (error) {
        return error;
      }
    };
    FetchData();
  }, [Token, dispatch]);

  const datawithSerialNumber = useMemo(
    () =>
      GuestDatatableData.map((item, index) => ({
        ...item,
        serialNumber: index + 1,
      })),
    [GuestDatatableData]
  );

  const RemoveAdminData = useMemo(
    () => datawithSerialNumber.filter((val) => val.id !== userId),
    [datawithSerialNumber, userId]
  );

  useEffect(() => {
    setrecord(RemoveAdminData);
  }, [RemoveAdminData]);

  // console.log("Remove Admin Data",record)

  //Image Uplodaing
  // const [images, setImages] = useState([]);
  // const [rejectedImages, setrejectedImages] = useState([]);
  // const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
  //   if (acceptedFiles.length) {
  //     setImages((previous) => [
  //       ...previous,
  //       ...acceptedFiles.map((item) =>
  //         Object.assign(item, { Preview: URL.createObjectURL(item) })
  //       ),
  //     ]);
  //   }

  //   if (rejectedFiles.length) {
  //     setrejectedImages((previous) => [...previous, ...rejectedFiles]);
  //   }
  // }, []);
  // const { getRootProps, getInputProps, isDragActive } = useDropzone({
  //   onDrop,
  //   accept: {
  //     "image/*": [],
  //   },
  // });

  const initialValues = {
    user_id: null,
    fullname: "",
    email: "",
    phone_number: "",
    document: "",
    document_images: null,
    extra_person_doc: null,
    address: "",
    person: null,
    booking_date: dayjs(new Date()),
    payment_type: "",
    check_in: null,
    check_out: null,
    total_days: null,
    total_price: "",
    remaining_amount: "",
    payment_status: "",
    adv_payment: 0,
    cgst: "",
    sgst: "",
    igst: "",
    taxable_price: "",
    discount: 0,
    // password: "123demo",
    // tax_type: "",
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    touched,
    setValues,
  } = useFormik({
    initialValues,
    validationSchema: addBookingSchema,
    onSubmit: async (values) => {
      handleBookingModelclose();
      try {
        if (values.user_id === null) {
          delete values.user_id;
        }
        values.document_images = selectedvalue;
        values.extra_person_doc = [...extraPersonSelectedvalue];

        const formdata = new FormData();

        for (let key in values) {
          if (key === "extra_person_doc") {
            values.extra_person_doc.forEach((image, index) => {
              formdata.append(`extra_person_doc`, image);
            });
          } else {
            formdata.append(key, values[key]);
          }
        }

        console.info("formdata", ...formdata);
        await dispatch(PostBookingAPI({ formdata, Token, id }));

        const checkIn = new Date(values.check_in).toLocaleString();
        const checkOut = new Date(values.check_out).toLocaleString();

        const data = {
          check_in: checkIn,
          check_out: checkOut,
        };

        await dispatch(GetRoomApi({ Token, data }));
      } catch (error) {
        console.error("Error occurred during form submission:", error);
        return error;
      }
    },
  });

  const [openWebCam, setOpenWebCam] = useState(false);
  const [webCamMode, setWebCamMode] = useState("SINGLE");
  const handleWebCamOpen = (mode) => {
    setOpenWebCam(true);
    setWebCamMode(mode);
  };
  const handlewebcamClose = () => {
    setOpenWebCam(false);
  };

  const [selectedvalue, setselectedvalue] = useState(null);
  const [displayValue, setDisplayValue] = useState(null);

  useEffect(() => {
    if (selectedvalue && typeof selectedvalue === "object") {
      const imageUrl = URL.createObjectURL(selectedvalue);
      setDisplayValue(imageUrl);
      setFieldValue("document_images", selectedvalue);
      return () => {
        URL.revokeObjectURL(imageUrl);
      };
    } else {
      setDisplayValue(selectedvalue);
      setFieldValue("document_images", selectedvalue);
      if (selectedvalue) {
        var file = dataURLtoFile(selectedvalue, "document.jpg");
        setFieldValue("document_images", selectedvalue);
      }
    }
  }, [selectedvalue]);

  const [extraPersonSelectedvalue, setExtraPersonSelectedvalue] = useState([]);
  const [extraPersonDisplayValue, setExtraPersonDisplayValue] = useState([]);

  useEffect(() => {
    setExtraPersonDisplayValue([]);
    extraPersonSelectedvalue.forEach((item) => {
      if (item && typeof item === "object") {
        const imageUrl = URL.createObjectURL(item);
        setExtraPersonDisplayValue((prev) => [...prev, imageUrl]);
        return () => {
          URL.revokeObjectURL(imageUrl);
        };
      } else {
        setExtraPersonDisplayValue((prev) => [...prev, item]);
        if (item) {
          var file = dataURLtoFile(item, "document.jpg");
        }
      }
    });
    setFieldValue("extra_person_doc", [...extraPersonSelectedvalue]);
  }, [extraPersonSelectedvalue]);

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  //Usage example:

  useEffect(() => {
    let startDate = new Date(values.check_in);
    let endDate = new Date(values.check_out);

    if (!isNaN(startDate) && !isNaN(endDate)) {
      if (endDate < startDate) {
        endDate.setDate(endDate.getDate() + 1);
      }

      let difference = endDate.getTime() - startDate.getTime();

      let total_days = Math.floor(difference / (1000 * 60 * 60 * 24));

      if (total_days === 0) {
        total_days += 1;
      } else {
        total_days = Math.ceil(difference / (1000 * 60 * 60 * 24));
      }
      
      endDate = dayjs(endDate).format("HH:mm");
      checkOut = dayjs(checkOut).format("HH:mm");
      // Compare the times
      const isendDateGreater = dayjs(endDate, "HH:mm").isAfter(
        dayjs(checkOut, "HH:mm")
      );
      if (isendDateGreater) {
        total_days += 1;
      }

      setFieldValue("total_days", total_days);

      let taxable_price = +total_days * +perdayprice;
      setFieldValue("taxable_price", taxable_price);

      // let total = taxable_price,
      //   cgst,
      //   sgst,
      //   igst;
      // if (tax_type === "INCLUDE") {
      //   cgst = Math.ceil((taxable_price * 6) / 100);
      //   sgst = Math.ceil((taxable_price * 6) / 100);
      //   igst = 0;
      //   setFieldValue("cgst", cgst.toFixed(2));
      //   setFieldValue("sgst", sgst.toFixed(2));
      //   setFieldValue("igst", igst.toFixed(2));
      //   // setFieldValue("total_price", total.toFixed(2));
      // } else if (tax_type === "EXCLUDE") {
      //   cgst = Math.ceil((taxable_price * 6) / 100);
      //   sgst = Math.ceil((taxable_price * 6) / 100);
      //   igst = 0;
      //   total += cgst + sgst + igst;
      //   setFieldValue("cgst", cgst.toFixed(2));
      //   setFieldValue("sgst", sgst.toFixed(2));
      //   setFieldValue("igst", igst.toFixed(2));
      //   // setFieldValue("total_price", total.toFixed(2));
      // } else {
      //   cgst = 0;
      //   sgst = 0;
      //   igst = 0;
      //   total += cgst + sgst + igst;
      //   setFieldValue("cgst", cgst.toFixed(2));
      //   setFieldValue("sgst", sgst.toFixed(2));
      //   setFieldValue("igst", igst.toFixed(2));
      //   // setFieldValue("total_price", total.toFixed(2));
      // }

      // setFieldValue("remaining_amount", total.toFixed(2));
      // setFieldValue("total_price", total.toFixed(2));
    }
  }, [values.check_in, values.check_out, perdayprice,checkOut]);

  useEffect(() => {
    let taxable_price = +values.taxable_price;
    let total = 0,
      cgst,
      sgst,
      igst;
    if (tax_type === "INCLUDE") {
      cgst = Math.ceil((taxable_price * 6) / 100);
      sgst = Math.ceil((taxable_price * 6) / 100);
      igst = 0;
      setFieldValue("cgst", cgst.toFixed(2));
      setFieldValue("sgst", sgst.toFixed(2));
      setFieldValue("igst", igst.toFixed(2));
      // setFieldValue("total_price", total.toFixed(2));
    } else if (tax_type === "EXCLUDE") {
      cgst = Math.ceil((taxable_price * 6) / 100);
      sgst = Math.ceil((taxable_price * 6) / 100);
      igst = 0;
      total += taxable_price + cgst + sgst + igst;
      setFieldValue("cgst", cgst.toFixed(2));
      setFieldValue("sgst", sgst.toFixed(2));
      setFieldValue("igst", igst.toFixed(2));
      // setFieldValue("total_price", total.toFixed(2));
    } else {
      cgst = 0;
      sgst = 0;
      igst = 0;
      total += taxable_price + cgst + sgst + igst;
      setFieldValue("cgst", cgst.toFixed(2));
      setFieldValue("sgst", sgst.toFixed(2));
      setFieldValue("igst", igst.toFixed(2));
      // setFieldValue("total_price", total.toFixed(2));
    }

    let advance_payment = +values.adv_payment || 0;
    let discount = +values.discount || 0;

    let total_price = total - discount;
    let remaining_amount = total_price - advance_payment;

    console.log('total_price :>>', total_price)

    setFieldValue("remaining_amount", remaining_amount.toFixed(2));
    setFieldValue("total_price", total_price.toFixed(2));
  }, [values.taxable_price, values.adv_payment, values.discount, tax_type]);

  const [checkNewuser, setcheckNewuser] = useState(false);
  const { SpecificGuestdata } = useSelector((state) => state.UserStore);
  const [singleguestData, setsingleguestData] = useState({});
  useEffect(() => {
    setsingleguestData(SpecificGuestdata);
  }, [SpecificGuestdata]);

  // const [Modelstatus, setModelstatus] = useState(false);

  useEffect(() => {
    setValues({
      ...values,
      fullname:
        checkNewuser && singleguestData.fullname
          ? singleguestData.fullname
          : null,
      email:
        checkNewuser && singleguestData.email ? singleguestData.email : null,
      phone_number:
        checkNewuser && singleguestData.phone_number
          ? singleguestData.phone_number
          : null,
      document:
        checkNewuser && singleguestData.document
          ? singleguestData.document
          : null,
      // document_images:singleguestData.fullname ?singleguestData.fullname :null,
      address:
        checkNewuser && singleguestData.address
          ? singleguestData.address
          : null,
      check_in: filterDate.check_in ? filterDate.check_in : null,
      check_out: filterDate.check_out ? filterDate.check_out : null,
    });
  }, [singleguestData, filterDate, checkNewuser]);
  // console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%",singleguestData)
  const ModelClose = () => {
    setcheckNewuser(false);
    handleBookingModelclose();
  };

  const handleremovefile = (event, index) => {
    event.stopPropagation();

    setExtraPersonSelectedvalue((previous) =>
      previous.filter((_, i) => i !== index)
    );
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={ModelClose}
        closeAfterTransition
        slots={{ backdrop: StyledBackdrop }}
      >
        <Fade in={open}>
          <ModalContent sx={style}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: IsMobile ? 2 : 20,
                height: "600px",
                overflow: "auto",
                "::-webkit-scrollbar": {
                  width: "0px",
                },
              }}
            >
              <form
                onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: IsMobile ? 2 : 1,
                }}
              >
                <Box display={"flex"} flexDirection={"column"} gap={3} mt={2}>
                  <Stack
                    direction={"row"}
                    spacing={2}
                    justifyContent={"flex-end"}
                  >
                    <Typography variant="h6">Room No:- {room_no} </Typography>
                  </Stack>

                  <Stack direction={"row"} spacing={3}>
                    <Autocomplete
                      sx={{ width: "100%" }}
                      id="free-solo-demo"
                      freeSolo
                      options={record.map((option) => option.phone_number)}
                      onChange={(event, newValue) => {
                        const selectedUser = record.find(
                          (user) => user.phone_number === newValue
                        );
                        if (selectedUser) {
                          const id = selectedUser.id;
                          dispatch(GetSingleGuestData({ Token, id }));
                          setFieldValue("user_id", id);
                          setcheckNewuser(true);
                        } else {
                          setFieldValue("user_id", null);
                          setcheckNewuser(false);
                          setsingleguestData({});
                          setFieldValue("fullname", "");
                          setFieldValue("email", "");
                          setFieldValue("document", "");
                          setFieldValue("address", "");
                        }
                        setFieldValue("phone_number", newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Mobile No *"
                          onBlur={(event) => {
                            const newValue = event.target.value;
                            const selectedUser = record.find(
                              (user) => user.phone_number === newValue
                            );
                            if (!selectedUser) {
                              // console.log("newValue", newValue);
                              setFieldValue("phone_number", newValue);
                            }
                            handleBlur(event); // ensure Formik's blur handler is called
                          }}
                          helperText={
                            touched.phone_number && errors.phone_number
                          }
                          error={touched.phone_number && errors.phone_number}
                        />
                      )}
                    />

                    <TextField
                      label="full Name"
                      variant="outlined"
                      size="large"
                      name="fullname"
                      type="text"
                      // disabled={singleguestData.fullname ? true : false}
                      sx={{ width: "100%" }}
                      value={values.fullname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.fullname && errors.fullname}
                      error={touched.fullname && errors.fullname}
                      InputLabelProps={{ shrink: !!values.fullname }}
                    />
                  </Stack>

                  <Stack direction={"row"} spacing={3}>
                    <TextField
                      label="Email"
                      variant="outlined"
                      size="large"
                      name="email"
                      helperText={touched.email && errors.email}
                      error={touched.email && errors.email}
                      // disabled={singleguestData.email ? true : false}
                      type="text"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      sx={{ flex: 1 }}
                      InputLabelProps={{ shrink: !!values.email }}
                    />

                    {/* <TextField
                      label="Mobile No"
                      variant="outlined"
                      size="large"
                      name="phone_number"
                      type="text"
                      required
                      value={values.phone_number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      sx={{ flex: 1 }}
                      helperText={touched.phone_number && errors.phone_number}
                      error={touched.phone_number && errors.phone_number}
                    /> */}

                    <FormControl
                      sx={{ flex: 1 }}
                      helperText={touched.document && errors.document}
                      error={touched.document && errors.document}
                      InputLabelProps={{ shrink: !!values.document }}
                    >
                      <InputLabel
                        id="demo-simple-select-label"
                        shrink={!!values.document}
                      >
                        Document
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Document"
                        name="document"
                        value={values.document}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <MenuItem value={"Adhar Card"}>Adhar Card</MenuItem>
                        <MenuItem value={"Pan Card"}>Pan Card</MenuItem>
                        <MenuItem value={"Driving License"}>
                          Driving License
                        </MenuItem>
                        <MenuItem value={"Voting Card"}>Voting Card</MenuItem>
                      </Select>
                      {touched.document && errors.document && (
                        <Typography
                          variant="caption"
                          color={"error"}
                          style={{
                            marginTop: "3px",
                            marginLeft: "14px",
                            marginRight: "14px",
                            marginBottom: "0px",
                          }}
                        >
                          {errors.document}
                        </Typography>
                      )}
                    </FormControl>
                  </Stack>

                  {!checkNewuser && (
                    <Box sx={{ display: "flex", width: "100%" }}>
                      <Stack
                        direction={"row"}
                        gap={3}
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {displayValue && (
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              p: 2,
                              border: "1px dashed #333",
                            }}
                          >
                            <img
                              src={displayValue}
                              alt="Selected document"
                              style={{ height: "100px", width: "200px" }}
                            />
                          </Box>
                        )}

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            width: "100%",
                          }}
                        >
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => handleWebCamOpen("SINGLE")}
                            sx={{ height: "35px" }}
                          >
                            Upload File
                          </Button>
                        </Box>
                      </Stack>
                    </Box>
                  )}

                  <Stack direction={"row"} spacing={3}>
                    <TextField
                      label="Address"
                      variant="outlined"
                      size="large"
                      name="address"
                      // disabled={singleguestData.address ? true : false}
                      value={values.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="text"
                      multiline
                      rows={1}
                      sx={{ flex: 1 }}
                      helperText={touched.address && errors.address}
                      error={touched.address && errors.address}
                      InputLabelProps={{ shrink: !!values.address }}
                    />

                    <FormControl
                      sx={{ flex: 1 }}
                      helperText={touched.person && errors.person}
                      error={touched.person && errors.person}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Person
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Person"
                        name="person"
                        value={values.person}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        {[1, 2, 3, 4, 5].map((val, i) => (
                          <MenuItem key={val} value={val}>
                            {val}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.person && errors.person && (
                        <Typography
                          variant="caption"
                          color={"error"}
                          style={{
                            marginTop: "3px",
                            marginLeft: "14px",
                            marginRight: "14px",
                            marginBottom: "0px",
                          }}
                        >
                          {errors.person}
                        </Typography>
                      )}
                    </FormControl>
                  </Stack>

                  {values.person && values.person !== 1 && (
                    <>
                      <Stack
                        direction={"row"}
                        gap={3}
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            width: "100%",
                          }}
                        >
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => handleWebCamOpen("MULTIPLE")}
                            sx={{ height: "35px" }}
                          >
                            Upload File
                          </Button>
                        </Box>
                      </Stack>
                      <Stack
                        direction={"row"}
                        gap={3}
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        {extraPersonDisplayValue &&
                          extraPersonDisplayValue.length !== 0 && (
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexWrap: "wrap",
                                p: 2,
                                border: "1px dashed #333",
                              }}
                            >
                              <ul
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  flexWrap: "wrap",
                                  gap: "10px",
                                  padding: "0px",
                                }}
                              >
                                {extraPersonDisplayValue.map((item, index) => (
                                  <li
                                    key={index}
                                    style={{
                                      display: "flex",
                                      justifyContent: "start",
                                      alignContent: "start",
                                      alignItems: "start",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    <LazyLoadImage
                                      src={item}
                                      effect="blur"
                                      height={"100px"}
                                      width={"150px"}
                                      style={{
                                        borderRadius: "10px",
                                        border: "1px solid #7b7b7cbb",
                                      }}
                                    />
                                    <IconButton
                                      sx={{
                                        marginLeft: "-40px",
                                        marginTop: "-5px",
                                      }}
                                      onClick={(event) => {
                                        handleremovefile(event, index);
                                      }}
                                    >
                                      <DeleteOutlinedIcon
                                        sx={{
                                          color: "#ff5757",
                                          fontSize: "29px",
                                        }}
                                      />
                                    </IconButton>
                                  </li>
                                ))}
                              </ul>
                              {/* {extraPersonDisplayValue.map((item) => (
                                <img
                                  src={item}
                                  alt="Selected document"
                                  style={{ height: "100px", width: "200px" }}
                                />
                              ))} */}
                            </Box>
                          )}
                      </Stack>
                    </>
                  )}

                  <Stack direction={"row"} spacing={3}>
                    <Box sx={{ flex: 1, width: "100%" }}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        sx={{ flex: 1 }}
                      >
                        <DateTimePicker
                          label="Booking Date"
                          name="booking_date"
                          format="DD/MM/YYYY hh:mm A"
                          value={
                            values.booking_date
                              ? dayjs(values.booking_date)
                              : dayjs(new Date())
                          }
                          onChange={(value) => {
                            const formattedDate = value
                              ? dayjs(value).toISOString()
                              : null;
                            setFieldValue("booking_date", formattedDate);
                          }}
                          disabled
                          renderInput={(params) => <TextField {...params} />}
                          onBlur={handleBlur}
                          sx={{ width: "100%", flex: 1 }}
                          // disablePast
                          views={["year", "month", "day", "hours", "minutes"]}
                        />
                      </LocalizationProvider>

                      {touched.booking_date && errors.booking_date && (
                        <Typography
                          variant="caption"
                          color={"error"}
                          style={{
                            marginTop: "3px",
                            marginLeft: "14px",
                            marginRight: "14px",
                            marginBottom: "0px",
                          }}
                        >
                          {errors.booking_date}
                        </Typography>
                      )}
                    </Box>

                    <FormControl
                      sx={{ flex: 1 }}
                      helperText={touched.payment_type && errors.payment_type}
                      error={touched.payment_type && errors.payment_type}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Payment Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select person"
                        name="payment_type"
                        value={values.payment_type}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <MenuItem value={"cash"}>Cash</MenuItem>
                        <MenuItem value={"UPI"}>UPI</MenuItem>
                      </Select>
                      {touched.payment_type && errors.payment_type && (
                        <Typography
                          variant="caption"
                          color={"error"}
                          style={{
                            marginTop: "3px",
                            marginLeft: "14px",
                            marginRight: "14px",
                            marginBottom: "0px",
                          }}
                        >
                          {errors.payment_type}
                        </Typography>
                      )}
                    </FormControl>
                  </Stack>

                  <Stack direction={"row"} spacing={3}>
                    <Box sx={{ flex: 1, width: "100%" }}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        sx={{ flex: 1 }}
                      >
                        <DateTimePicker
                          // disablePast
                          format="DD/MM/YYYY hh:mm A"
                          views={["year", "month", "day", "hours", "minutes"]}
                          label="Check In"
                          name="check_in"
                          sx={{ width: "100%", flex: 1 }}
                          value={
                            values.check_in ? dayjs(values.check_in) : null
                          }
                          onChange={(value) => {
                            const formatedDate = value
                              ? dayjs(value).toISOString()
                              : null;
                            setFieldValue("check_in", formatedDate);
                          }}
                          onBlur={handleBlur}
                        />
                      </LocalizationProvider>

                      {touched.check_in && errors.check_in && (
                        <Typography
                          variant="caption"
                          color={"error"}
                          style={{
                            marginTop: "3px",
                            marginLeft: "14px",
                            marginRight: "14px",
                            marginBottom: "0px",
                          }}
                        >
                          {errors.check_in}
                        </Typography>
                      )}
                    </Box>

                    <Box sx={{ flex: 1, width: "100%" }}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        sx={{ flex: 1 }}
                      >
                        <DateTimePicker
                          // disablePast
                          format="DD/MM/YYYY hh:mm A"
                          views={["year", "month", "day", "hours", "minutes"]}
                          label="Check Out"
                          name="check_out"
                          value={
                            values.check_out ? dayjs(values.check_out) : null
                          }
                          onChange={(value) => {
                            const formatedDate = value
                              ? dayjs(value).toISOString()
                              : null;
                            setFieldValue("check_out", formatedDate);
                          }}
                          sx={{ width: "100%", flex: 1 }}
                        />
                      </LocalizationProvider>
                      {touched.check_out && errors.check_out && (
                        <Typography
                          variant="caption"
                          color={"error"}
                          style={{
                            marginTop: "3px",
                            marginLeft: "14px",
                            marginRight: "14px",
                            marginBottom: "0px",
                          }}
                        >
                          {errors.check_out}
                        </Typography>
                      )}
                    </Box>
                  </Stack>

                  <Stack direction={"row"} spacing={3}>
                    <TextField
                      label="Total Days"
                      variant="outlined"
                      size="large"
                      name="total_days"
                      type="text"
                      disabled
                      sx={{ width: "100%" }}
                      value={values.total_days}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.total_days && errors.total_days}
                      error={touched.total_days && errors.total_days}
                    />

                    <TextField
                      label="Taxable Price"
                      variant="outlined"
                      size="large"
                      name="taxable_price"
                      type="number"
                      sx={{ width: "100%" }}
                      onWheel={numberInputOnWheelPreventChange}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.taxable_price}
                      helperText={touched.taxable_price && errors.taxable_price}
                      error={touched.taxable_price && errors.taxable_price}
                    />
                  </Stack>

                  <Stack direction={"row"} spacing={3}>
                    <TextField
                      label="CGST"
                      variant="outlined"
                      name="cgst"
                      size="large"
                      type="number"
                      onWheel={numberInputOnWheelPreventChange}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      sx={{ width: "100%" }}
                      disabled
                      value={values.cgst}
                      helperText={touched.cgst && errors.cgst}
                      error={touched.cgst && errors.cgst}
                    />

                    <TextField
                      label="SGST"
                      disabled
                      variant="outlined"
                      name="sgst"
                      size="large"
                      type="number"
                      onWheel={numberInputOnWheelPreventChange}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      sx={{ width: "100%" }}
                      value={values.sgst}
                      helperText={touched.sgst && errors.sgst}
                      error={touched.sgst && errors.sgst}
                    />
                  </Stack>
                  <Stack direction={"row"} spacing={3}>
                    <TextField
                      label="IGST"
                      variant="outlined"
                      name="igst"
                      size="large"
                      type="number"
                      onWheel={numberInputOnWheelPreventChange}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      sx={{ width: "48%" }}
                      value={values.igst}
                      helperText={touched.igst && errors.igst}
                      error={touched.igst && errors.igst}
                      disabled
                    />

                    <TextField
                      label="Discount"
                      variant="outlined"
                      size="large"
                      name="discount"
                      value={values.discount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="number"
                      onWheel={numberInputOnWheelPreventChange}
                      sx={{ flex: 1 }}
                      helperText={touched.discount && errors.discount}
                      error={touched.discount && errors.discount}
                    />
                  </Stack>
                  <Divider />

                  <Stack direction={"row"} spacing={3} mb={3}>
                    <FormControl
                      sx={{ flex: 1 }}
                      helperText={
                        touched.payment_status && errors.payment_status
                      }
                      error={touched.payment_status && errors.payment_status}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Payment Status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Payment Status"
                        name="payment_status"
                        value={values.payment_status}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <MenuItem value={"PAID"}>PAID</MenuItem>
                        <MenuItem value={"UNPAID"}>UNPAID</MenuItem>
                        <MenuItem value={"PARTIAL"}>PARTIAL</MenuItem>
                      </Select>
                      {touched.payment_status && errors.payment_status && (
                        <Typography
                          variant="caption"
                          color={"error"}
                          style={{
                            marginTop: "3px",
                            marginLeft: "14px",
                            marginRight: "14px",
                            marginBottom: "0px",
                          }}
                        >
                          {errors.payment_status}
                        </Typography>
                      )}
                    </FormControl>

                    <TextField
                      label="Advance Payment"
                      name="adv_payment"
                      variant="outlined"
                      size="large"
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      sx={{ flex: 1 }}
                      value={values.adv_payment}
                      helperText={touched.adv_payment && errors.adv_payment}
                      error={touched.adv_payment && errors.adv_payment}
                      onWheel={numberInputOnWheelPreventChange}
                    />
                  </Stack>

                  <Stack direction={"row"} spacing={3}>
                    <TextField
                      label="Remaining Amount"
                      name="remaining_amount"
                      variant="outlined"
                      size="large"
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      sx={{ width: "100%" }}
                      value={values.remaining_amount}
                      disabled
                      helperText={
                        touched.remaining_amount && errors.remaining_amount
                      }
                      error={
                        touched.remaining_amount && errors.remaining_amount
                      }
                      onWheel={numberInputOnWheelPreventChange}
                    />

                    <TextField
                      label="Total"
                      name="total_price"
                      variant="outlined"
                      size="large"
                      type="number"
                      disabled
                      onChange={handleChange}
                      onBlur={handleBlur}
                      sx={{ width: "100%" }}
                      value={values.total_price}
                      helperText={touched.total_price && errors.total_price}
                      error={touched.total_price && errors.total_price}
                      onWheel={numberInputOnWheelPreventChange}
                    />
                  </Stack>
                </Box>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 20,
                    justifyContent: "center",
                    marginTop: 25,
                  }}
                >
                  <Button variant={"contained"} type="submit" color="success">
                    submit
                  </Button>
                  <Button
                    variant="contained"
                    type="button"
                    color="error"
                    onClick={ModelClose}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </Box>

            {openWebCam && webCamMode === "SINGLE" && (
              <SimpleDialog
                mode={webCamMode}
                open={openWebCam}
                handleClose={handlewebcamClose}
                setselectedvalue={setselectedvalue}
                selectedvalue={selectedvalue}
              />
            )}

            {openWebCam && webCamMode === "MULTIPLE" && (
              <SimpleDialog
                mode={webCamMode}
                open={openWebCam}
                handleClose={handlewebcamClose}
                setselectedvalue={setExtraPersonSelectedvalue}
                selectedvalue={extraPersonSelectedvalue}
              />
            )}
          </ModalContent>
        </Fade>
      </Modal>
    </div>
  );
}

// Edit Booking Model
export function EditBookingModel({ handleClose, open, EditData }) {
  const IsMobile = useMediaQuery("(min-width:600px)");
  const dispatch = useDispatch();
  const Token = Cookies.get("Token");
  let checkIn = Cookies.get("checkin");
  let checkOut = Cookies.get("checkout");

  const { perdayprice, room_no, tax_type } = EditData.roommaster;
  const { id, room_id } = EditData;

  // const { GuestDatatableData } = useSelector((state) => state.UserStore);
  // const [record, setrecord] = useState([]);

  // const datawithSerialNumber = useMemo(
  //   () =>
  //     GuestDatatableData.map((item, index) => ({
  //       ...item,
  //       serialNumber: index + 1,
  //     })),
  //   [GuestDatatableData]
  // );

  // const RemoveAdminData = useMemo(
  //   () => datawithSerialNumber.filter((val) => val.id != id),
  //   [datawithSerialNumber]
  // );

  // useEffect(() => {
  //   setrecord(RemoveAdminData);
  // }, [RemoveAdminData]);

  //Image Uplodaing
  // const [images, setImages] = useState([]);
  // const [rejectedImages, setrejectedImages] = useState([]);
  // const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
  //   if (acceptedFiles.length) {
  //     setImages((previous) => [
  //       ...previous,
  //       ...acceptedFiles.map((item) =>
  //         Object.assign(item, { Preview: URL.createObjectURL(item) })
  //       ),
  //     ]);
  //   }

  //   if (rejectedFiles.length) {
  //     setrejectedImages((previous) => [...previous, ...rejectedFiles]);
  //   }
  // }, []);
  // const { getRootProps, getInputProps, isDragActive } = useDropzone({
  //   onDrop,
  //   accept: {
  //     "image/*": [],
  //   },
  // });

  // const handleWebCamOpen = () => setOpenWebCam(true);
  // const handlewebcamClose = () => setOpenWebCam(false);
  // const [Status, setStatus] = useState(null);

  // const { status, message, timestamp } = useSelector(
  //   (state) => state.BookingStore
  // );

  const initialValues = {
    booking_date: null,
    payment_type: "",
    extra_person_doc: [],
    check_in: null,
    check_out: null,
    total_days: 0,
    total_price: 0,
    payment_status: "",
    adv_payment: 0,
    cgst: 0,
    sgst: 0,
    igst: 0,
    taxable_price: 0,
    arrival_time: null,
    remaining_amount: "",
    person: "",
    discount: 0,
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    touched,
    setValues,
  } = useFormik({
    initialValues,
    validationSchema: editBookingSchema,
    onSubmit: async (values) => {
      try {
        values.extra_person_doc = [...extraPersonSelectedvalue];
        const formdata = new FormData();

        for (let key in values) {
          if (key === "extra_person_doc") {
            values.extra_person_doc.forEach((image, index) => {
              formdata.append(`extra_person_doc`, image);
            });
          } else {
            formdata.append(key, values[key]);
          }
        }

        console.log("...formdata", ...formdata);

        MySwal.fire({
          title: "Do you want to save the changes?",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Save",
          denyButtonText: `Don't save`,
        }).then(async (result) => {
          if (result.isConfirmed) {
            await dispatch(PutBookingAPI({ formdata, Token, id }));
            await dispatch(Get_ReservationApi_BYID({ Token, id: room_id }));
            await dispatch(GetAllBookingRecord({ Token }));
          } else if (result.isDenied) {
            MySwal.fire("Changes are not saved", "", "info");
          }
        });
        handleClose();
      } catch (error) {
        return error;
      }
    },
  });

  const [openWebCam, setOpenWebCam] = useState(false);
  const handleWebCamOpen = (mode) => {
    setOpenWebCam(true);
  };
  const handlewebcamClose = () => {
    setOpenWebCam(false);
  };

  const [extraPersonSelectedvalue, setExtraPersonSelectedvalue] = useState([]);
  const [extraPersonDisplayValue, setExtraPersonDisplayValue] = useState([]);

  useEffect(() => {
    setExtraPersonDisplayValue([]);
    extraPersonSelectedvalue.forEach((item) => {
      if (item && typeof item === "object") {
        const imageUrl = URL.createObjectURL(item);
        setExtraPersonDisplayValue((prev) => [...prev, imageUrl]);
        setFieldValue("extra_person_doc", item);
        return () => {
          URL.revokeObjectURL(imageUrl);
        };
      } else {
        setExtraPersonDisplayValue((prev) => [...prev, item]);
        if (item.startsWith("blob")) {
          var file = dataURLtoFile(item, "document.jpg");
        }
      }
    });
    setFieldValue("extra_person_doc", [...extraPersonSelectedvalue]);
  }, [extraPersonSelectedvalue]);

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  useEffect(() => {
    let startDate = new Date(values.check_in);
    let endDate = new Date(values.check_out);

    if (!isNaN(startDate) && !isNaN(endDate)) {
      if (endDate < startDate) {
        endDate.setDate(endDate.getDate() + 1);
      }

      let difference = endDate.getTime() - startDate.getTime();

      let total_days = Math.floor(difference / (1000 * 60 * 60 * 24));

      if (total_days === 0) {
        total_days += 1;
      } else {
        total_days = Math.ceil(difference / (1000 * 60 * 60 * 24));
      }
      
      endDate = dayjs(endDate).format("HH:mm");
      checkOut = dayjs(checkOut).format("HH:mm");
      // Compare the times
      const isendDateGreater = dayjs(endDate, "HH:mm").isAfter(
        dayjs(checkOut, "HH:mm")
      );
      if (isendDateGreater) {
        total_days += 1;
      }

      setFieldValue("total_days", total_days);

      let taxable_price = +total_days * +perdayprice;
      setFieldValue("taxable_price", taxable_price);

      // let total = taxable_price,
      //   cgst,
      //   sgst,
      //   igst;
      // if (tax_type === "INCLUDE") {
      //   cgst = Math.ceil((taxable_price * 6) / 100);
      //   sgst = Math.ceil((taxable_price * 6) / 100);
      //   igst = 0;
      //   setFieldValue("cgst", cgst.toFixed(2));
      //   setFieldValue("sgst", sgst.toFixed(2));
      //   setFieldValue("igst", igst.toFixed(2));
      //   // setFieldValue("total_price", total.toFixed(2));
      // } else if (tax_type === "EXCLUDE") {
      //   cgst = Math.ceil((taxable_price * 6) / 100);
      //   sgst = Math.ceil((taxable_price * 6) / 100);
      //   igst = 0;
      //   total += cgst + sgst + igst;
      //   setFieldValue("cgst", cgst.toFixed(2));
      //   setFieldValue("sgst", sgst.toFixed(2));
      //   setFieldValue("igst", igst.toFixed(2));
      //   // setFieldValue("total_price", total.toFixed(2));
      // } else {
      //   cgst = 0;
      //   sgst = 0;
      //   igst = 0;
      //   total += cgst + sgst + igst;
      //   setFieldValue("cgst", cgst.toFixed(2));
      //   setFieldValue("sgst", sgst.toFixed(2));
      //   setFieldValue("igst", igst.toFixed(2));
      //   // setFieldValue("total_price", total.toFixed(2));
      // }

      // setFieldValue("remaining_amount", total.toFixed(2));
      // setFieldValue("total_price", total.toFixed(2));
    }
  }, [values.check_in, values.check_out, perdayprice]);

  useEffect(() => {
    let taxable_price = +values.taxable_price;
    let total = 0,
      cgst,
      sgst,
      igst;
    if (tax_type === "INCLUDE") {
      cgst = Math.ceil((taxable_price * 6) / 100);
      sgst = Math.ceil((taxable_price * 6) / 100);
      igst = 0;
      setFieldValue("cgst", cgst.toFixed(2));
      setFieldValue("sgst", sgst.toFixed(2));
      setFieldValue("igst", igst.toFixed(2));
      // setFieldValue("total_price", total.toFixed(2));
    } else if (tax_type === "EXCLUDE") {
      cgst = Math.ceil((taxable_price * 6) / 100);
      sgst = Math.ceil((taxable_price * 6) / 100);
      igst = 0;
      total += taxable_price + cgst + sgst + igst;
      setFieldValue("cgst", cgst.toFixed(2));
      setFieldValue("sgst", sgst.toFixed(2));
      setFieldValue("igst", igst.toFixed(2));
      // setFieldValue("total_price", total.toFixed(2));
    } else {
      cgst = 0;
      sgst = 0;
      igst = 0;
      total += taxable_price + cgst + sgst + igst;
      setFieldValue("cgst", cgst.toFixed(2));
      setFieldValue("sgst", sgst.toFixed(2));
      setFieldValue("igst", igst.toFixed(2));
      // setFieldValue("total_price", total.toFixed(2));
    }

    let advance_payment = +values.adv_payment || 0;
    let discount = +values.discount || 0;

    let total_price = total - discount;
    let remaining_amount = total_price - advance_payment;

    setFieldValue("remaining_amount", remaining_amount.toFixed(2));
    setFieldValue("total_price", total_price.toFixed(2));
  }, [values.taxable_price, values.adv_payment, values.discount, tax_type]);

  const handleremovefile = (event, index) => {
    event.stopPropagation();

    setExtraPersonSelectedvalue((previous) =>
      previous.filter((_, i) => i !== index)
    );
  };

  // const [checkNewuser, setcheckNewuser] = useState(false);

  // const { SpecificGuestdata } = useSelector((state) => state.UserStore);
  // const [singleguestData, setsingleguestData] = useState({});
  // useEffect(() => {
  //   // console.log("singleguestDatasingleguestDatasingleguestData",singleguestData)
  //   setsingleguestData(SpecificGuestdata);
  // });

  useEffect(() => {
    if (EditData || EditData) {
      const Booking = EditData
        ? dayjs(EditData.booking_date).toISOString()
        : null;
      const CheckIn = EditData ? dayjs(EditData.check_in).toISOString() : null;
      const Checkout = EditData
        ? dayjs(EditData.check_out).toISOString()
        : null;
      const ArrivalTime =
        EditData && EditData?.arrival_time
          ? dayjs(EditData.arrival_time).toISOString()
          : dayjs(new Date()).toISOString();

      setValues((prevValues) => ({
        ...prevValues,
        booking_date: Booking,
        payment_type: EditData.payment_type,
        person: EditData.person,
        check_in: CheckIn,
        check_out: Checkout,
        total_days: EditData.total_days,
        taxable_price: EditData.taxable_price || 0,
        cgst: EditData.cgst,
        sgst: EditData.sgst,
        igst: EditData.igst,
        payment_status: EditData.payment_status,
        adv_payment: EditData.adv_payment,
        discount: EditData.discount,
        remaining_amount: EditData.remaining_amount,
        total_price: EditData.total_price,
        arrival_time: ArrivalTime,
      }));

      if (EditData?.extra_person_doc) {
        let arrayImages = EditData?.extra_person_doc
          ? EditData.extra_person_doc
          : [];
        setExtraPersonSelectedvalue(arrayImages);
      }
    }
  }, [EditData]);

  console.log("extraPersonSelectedvalue :>>", extraPersonSelectedvalue);
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: StyledBackdrop }}
      >
        <Fade in={open}>
          <ModalContent sx={style}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: IsMobile ? 2 : 20,
                height: "550px",
                overflow: "auto",
                "::-webkit-scrollbar": {
                  width: "0px",
                },
              }}
            >
              <form
                onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: IsMobile ? 2 : 1,
                }}
              >
                <Box display={"flex"} flexDirection={"column"} gap={3} mt={2}>
                  <Stack
                    direction={"row"}
                    spacing={2}
                    justifyContent={"flex-end"}
                  >
                    <Typography variant="h6">
                      Edit Room No:- {room_no}{" "}
                    </Typography>
                  </Stack>

                  <Stack direction={"row"} spacing={3}>
                    <Box sx={{ flex: 1, width: "100%" }}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        sx={{ flex: 1 }}
                      >
                        <DateTimePicker
                          label="Booking Date"
                          name="booking_date"
                          format="DD/MM/YYYY hh:mm A"
                          value={
                            values.booking_date
                              ? dayjs(values.booking_date)
                              : dayjs(new Date())
                          }
                          onChange={(value) => {
                            const formattedDate = value
                              ? dayjs(value).toISOString()
                              : null;
                            setFieldValue("booking_date", formattedDate);
                          }}
                          onBlur={handleBlur}
                          sx={{ width: "100%", flex: 1 }}
                          views={["year", "month", "day", "hours", "minutes"]}
                        />
                      </LocalizationProvider>
                      {touched.booking_date && errors.booking_date && (
                        <Typography
                          variant="caption"
                          color={"error"}
                          style={{
                            marginTop: "3px",
                            marginLeft: "14px",
                            marginRight: "14px",
                            marginBottom: "0px",
                          }}
                        >
                          {errors.booking_date}
                        </Typography>
                      )}
                    </Box>

                    <FormControl
                      sx={{ flex: 1 }}
                      helperText={touched.payment_type && errors.payment_type}
                      error={touched.payment_type && errors.payment_type}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Payment Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select person"
                        name="payment_type"
                        value={values.payment_type}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <MenuItem value={"cash"}>Cash</MenuItem>
                        <MenuItem value={"UPI"}>UPI</MenuItem>
                      </Select>
                      {touched.payment_type && errors.payment_type && (
                        <Typography
                          variant="caption"
                          color={"error"}
                          style={{
                            marginTop: "3px",
                            marginLeft: "14px",
                            marginRight: "14px",
                            marginBottom: "0px",
                          }}
                        >
                          {errors.payment_type}
                        </Typography>
                      )}
                    </FormControl>
                  </Stack>

                  <Stack direction={"row"} spacing={3}>
                    <Box sx={{ flex: 1, width: "100%" }}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        sx={{ flex: 1 }}
                      >
                        <DateTimePicker
                          label="Check In"
                          format="DD/MM/YYYY hh:mm A"
                          name="check_in"
                          sx={{ width: "100%", flex: 1 }}
                          value={
                            values.check_in ? dayjs(values.check_in) : null
                          }
                          onChange={(value) => {
                            const formatedDate = value
                              ? dayjs(value).toISOString()
                              : null;
                            setFieldValue("check_in", formatedDate);
                          }}
                          onBlur={handleBlur}
                          views={["year", "month", "day", "hours", "minutes"]}
                        />
                      </LocalizationProvider>

                      {touched.check_in && errors.check_in && (
                        <Typography
                          variant="caption"
                          color={"error"}
                          style={{
                            marginTop: "3px",
                            marginLeft: "14px",
                            marginRight: "14px",
                            marginBottom: "0px",
                          }}
                        >
                          {errors.check_in}
                        </Typography>
                      )}
                    </Box>

                    <Box sx={{ flex: 1, width: "100%" }}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        sx={{ flex: 1 }}
                      >
                        <DateTimePicker
                          label="Check Out"
                          name="check_out"
                          format="DD/MM/YYYY hh:mm A"
                          value={
                            values.check_out ? dayjs(values.check_out) : null
                          }
                          onChange={(value) => {
                            const formatedDate = value
                              ? dayjs(value).toISOString()
                              : null;
                            setFieldValue("check_out", formatedDate);
                          }}
                          sx={{ width: "100%", flex: 1 }}
                          views={["year", "month", "day", "hours", "minutes"]}
                        />
                      </LocalizationProvider>
                      {touched.check_out && errors.check_out && (
                        <Typography
                          variant="caption"
                          color={"error"}
                          style={{
                            marginTop: "3px",
                            marginLeft: "14px",
                            marginRight: "14px",
                            marginBottom: "0px",
                          }}
                        >
                          {errors.check_out}
                        </Typography>
                      )}
                    </Box>
                  </Stack>

                  <Stack direction={"row"} spacing={3}>
                    <FormControl
                      sx={{ width: "48%" }}
                      helperText={touched.person && errors.person}
                      error={touched.person && errors.person}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Person
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Person"
                        name="person"
                        value={values.person}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        {[1, 2, 3, 4, 5].map((val, i) => (
                          <MenuItem key={val} value={val}>
                            {val}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.person && errors.person && (
                        <Typography
                          variant="caption"
                          color={"error"}
                          style={{
                            marginTop: "3px",
                            marginLeft: "14px",
                            marginRight: "14px",
                            marginBottom: "0px",
                          }}
                        >
                          {errors.person}
                        </Typography>
                      )}
                    </FormControl>
                  </Stack>
                  {values.person && values.person !== 1 && (
                    <>
                      <Stack
                        direction={"row"}
                        gap={3}
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            width: "100%",
                          }}
                        >
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => handleWebCamOpen("MULTIPLE")}
                            sx={{ height: "35px" }}
                          >
                            Upload File
                          </Button>
                        </Box>
                      </Stack>
                      <Stack
                        direction={"row"}
                        gap={3}
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        {extraPersonDisplayValue &&
                          extraPersonDisplayValue.length !== 0 && (
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexWrap: "wrap",
                                p: 2,
                                border: "1px dashed #333",
                              }}
                            >
                              <ul
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  flexWrap: "wrap",
                                  gap: "10px",
                                  padding: "0px",
                                }}
                              >
                                {extraPersonDisplayValue.map((item, index) => (
                                  <li
                                    key={index}
                                    style={{
                                      display: "flex",
                                      justifyContent: "start",
                                      alignContent: "start",
                                      alignItems: "start",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    <LazyLoadImage
                                      src={
                                        item?.startsWith("blob")
                                          ? item
                                          : `${process.env.REACT_APP_API_URL}/uploads/guests/${item}`
                                      }
                                      // src={item}
                                      effect="blur"
                                      height={"100px"}
                                      width={"150px"}
                                      style={{
                                        borderRadius: "10px",
                                        border: "1px solid #7b7b7cbb",
                                      }}
                                    />
                                    <IconButton
                                      sx={{
                                        marginLeft: "-40px",
                                        marginTop: "-5px",
                                      }}
                                      onClick={(event) => {
                                        handleremovefile(event, index);
                                      }}
                                    >
                                      <DeleteOutlinedIcon
                                        sx={{
                                          color: "#ff5757",
                                          fontSize: "29px",
                                        }}
                                      />
                                    </IconButton>
                                  </li>
                                ))}
                              </ul>
                              {/* {extraPersonDisplayValue.map((item) => (
                                <img
                                  src={item}
                                  alt="Selected document"
                                  style={{ height: "100px", width: "200px" }}
                                />
                              ))} */}
                            </Box>
                          )}
                      </Stack>
                    </>
                  )}
                  <Stack direction={"row"} spacing={3}>
                    <TextField
                      label="Total Days"
                      variant="outlined"
                      size="large"
                      name="total_days"
                      type="number"
                      disabled
                      sx={{ width: "100%" }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.total_days}
                      helperText={touched.total_days && errors.total_days}
                      error={touched.total_days && errors.total_days}
                      onWheel={numberInputOnWheelPreventChange}
                    />

                    <TextField
                      label="Taxable Price"
                      variant="outlined"
                      size="large"
                      name="taxable_price"
                      type="number"
                      onWheel={numberInputOnWheelPreventChange}
                      sx={{ width: "100%" }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.taxable_price || ""}
                      helperText={touched.taxable_price && errors.taxable_price}
                      error={touched.taxable_price && errors.taxable_price}
                    />
                  </Stack>

                  <Stack direction={"row"} spacing={3}>
                    <TextField
                      label="CGST"
                      variant="outlined"
                      name="cgst"
                      size="large"
                      type="number"
                      onWheel={numberInputOnWheelPreventChange}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      sx={{ width: "100%" }}
                      disabled
                      value={values.cgst}
                      helperText={touched.cgst && errors.cgst}
                      error={touched.cgst && errors.cgst}
                    />

                    <TextField
                      label="SGST"
                      disabled
                      variant="outlined"
                      name="sgst"
                      size="large"
                      type="number"
                      onWheel={numberInputOnWheelPreventChange}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      sx={{ width: "100%" }}
                      value={values.sgst}
                      helperText={touched.sgst && errors.sgst}
                      error={touched.sgst && errors.sgst}
                    />
                  </Stack>

                  <Stack direction={"row"} spacing={3}>
                    <TextField
                      label="IGST"
                      variant="outlined"
                      name="igst"
                      size="large"
                      type="number"
                      onWheel={numberInputOnWheelPreventChange}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      sx={{ width: "48%" }}
                      value={values.igst}
                      helperText={touched.igst && errors.igst}
                      error={touched.igst && errors.igst}
                      disabled
                    />

                    <Box sx={{ flex: 1, width: "100%" }}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        sx={{ flex: 1 }}
                      >
                        <DateTimePicker
                          label="Arrival Time"
                          name="arrival_time"
                          format="DD/MM/YYYY hh:mm A"
                          sx={{ width: "100%", flex: 1 }}
                          value={
                            values.arrival_time
                              ? dayjs(values.arrival_time)
                              : null
                          }
                          onChange={(value) => {
                            const formatedDate = value
                              ? dayjs(value).toISOString()
                              : null;
                            setFieldValue("arrival_time", formatedDate);
                          }}
                          onBlur={handleBlur}
                          views={["year", "month", "day", "hours", "minutes"]}
                        />
                      </LocalizationProvider>

                      {touched.arrival_time && errors.arrival_time && (
                        <Typography
                          variant="caption"
                          color={"error"}
                          style={{
                            marginTop: "3px",
                            marginLeft: "14px",
                            marginRight: "14px",
                            marginBottom: "0px",
                          }}
                        >
                          {errors.arrival_time}
                        </Typography>
                      )}
                    </Box>
                  </Stack>
                  <Divider />

                  <Stack direction={"row"} spacing={3} mb={3}>
                    <FormControl
                      sx={{ flex: 1 }}
                      helperText={
                        touched.payment_status && errors.payment_status
                      }
                      error={touched.payment_status && errors.payment_status}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Payment Status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Payment Status"
                        name="payment_status"
                        value={values.payment_status}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled
                      >
                        <MenuItem value={"PAID"}>PAID</MenuItem>
                        <MenuItem value={"UNPAID"}>UNPAID</MenuItem>
                        <MenuItem value={"PARTIAL"}>PARTIAL</MenuItem>
                      </Select>
                      {touched.payment_status && errors.payment_status && (
                        <Typography
                          variant="caption"
                          color={"error"}
                          style={{
                            marginTop: "3px",
                            marginLeft: "14px",
                            marginRight: "14px",
                            marginBottom: "0px",
                          }}
                        >
                          {errors.payment_status}
                        </Typography>
                      )}
                    </FormControl>

                    <TextField
                      label="Advance Payment"
                      variant="outlined"
                      size="large"
                      name="adv_payment"
                      value={values.adv_payment}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onWheel={numberInputOnWheelPreventChange}
                      disabled
                      type="number"
                      sx={{ flex: 1 }}
                      helperText={touched.adv_payment && errors.adv_payment}
                      error={touched.adv_payment && errors.adv_payment}
                    />
                  </Stack>

                  <Stack direction={"row"} spacing={3}>
                    <TextField
                      label="Discount"
                      variant="outlined"
                      size="large"
                      name="discount"
                      value={values.discount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onWheel={numberInputOnWheelPreventChange}
                      type="number"
                      sx={{ flex: 1 }}
                      helperText={touched.discount && errors.discount}
                      error={touched.discount && errors.discount}
                    />

                    <TextField
                      label="Rmaining Amount"
                      name="remaining_amount"
                      variant="outlined"
                      size="large"
                      type="number"
                      onChange={handleChange}
                      onWheel={numberInputOnWheelPreventChange}
                      onBlur={handleBlur}
                      sx={{ width: "48%" }}
                      value={values.remaining_amount}
                      helperText={
                        touched.remaining_amount && errors.remaining_amount
                      }
                      error={
                        touched.remaining_amount && errors.remaining_amount
                      }
                      disabled
                    />
                  </Stack>
                  <Stack direction={"row"} spacing={3}>
                    <TextField
                      label="Total"
                      name="total_price"
                      variant="outlined"
                      size="large"
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      sx={{ width: "48%" }}
                      value={values.total_price}
                      helperText={touched.total_price && errors.total_price}
                      error={touched.total_price && errors.total_price}
                      onWheel={numberInputOnWheelPreventChange}
                      disabled
                    />
                  </Stack>
                </Box>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 20,
                    justifyContent: "center",
                    marginTop: 25,
                  }}
                >
                  <Button variant={"contained"} type="submit" color="success">
                    submit
                  </Button>
                  <Button
                    variant="contained"
                    type="button"
                    color="error"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </Box>

            {openWebCam && (
              <SimpleDialog
                mode={"MULTIPLE"}
                open={openWebCam}
                handleClose={handlewebcamClose}
                setselectedvalue={setExtraPersonSelectedvalue}
                selectedvalue={extraPersonSelectedvalue}
              />
            )}
            {/* {openWebCam && (
              <SimpleDialog
                open={openWebCam}
                handleClose={handlewebcamClose}
                setselectedvalue={setselectedvalue}
                selectedvalue={selectedvalue}
              />
            )} */}
          </ModalContent>
        </Fade>
      </Modal>
    </div>
  );
}

export function AddCategory({ handleClose, open }) {
  const IsMobile = useMediaQuery("(min-width:600px)");
  const Token = Cookies.get("Token");
  const dispatch = useDispatch();

  const initialValues = {
    name: "",
  };
  const { errors, values, handleBlur, handleChange, handleSubmit, touched } =
    useFormik({
      initialValues,
      validationSchema: FoodCategorySchema,
      onSubmit: async (values) => {
        try {
          const formdata = new FormData();
          Object.entries(values).forEach(([key, val]) => {
            formdata.append(key, val);
          });

          console.log(...formdata);
          await dispatch(PostCategoryApi({ Token, values }));
          await dispatch(GetCategoryApi({ Token }));
        } catch (error) {
          return error;
        }
        handleClose();
      },
    });

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: StyledBackdrop }}
      >
        <Fade in={open}>
          <ModalContent sx={style}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: IsMobile ? 30 : 20,
              }}
            >
              <Typography
                variant="h6"
                sx={{ textAlign: "center", textTransform: "capitalize" }}
              >
                Add Category
              </Typography>
              <form
                onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: IsMobile ? 30 : 20,
                }}
              >
                <Stack direction={"column"} spacing={2} sx={{ marginTop: 1 }}>
                  <TextField
                    label=" Category Name"
                    variant="outlined"
                    size="small"
                    name="name"
                    type="text"
                    sx={{ width: "100%" }}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.name && errors.name}
                    helperText={touched.name && errors.name}
                  />
                </Stack>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 5,
                    justifyContent: "center",
                  }}
                >
                  <Button variant={"contained"} type="submit" color="success">
                    submit
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="error"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          </ModalContent>
        </Fade>
      </Modal>
    </div>
  );
}

export function EditCategory({ handleClose, open, data }) {
  const IsMobile = useMediaQuery("(min-width:600px)");
  const dispatch = useDispatch();
  const Token = Cookies.get("Token");
  const { id } = data;
  const initialValues = {
    name: "",
  };
  const {
    errors,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setValues,
  } = useFormik({
    initialValues,
    validationSchema: FoodCategorySchema,
    onSubmit: async (values) => {
      try {
        MySwal.fire({
          title: "Do you want to save the changes?",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Save",
          denyButtonText: `Don't save`,
        }).then(async (result) => {
          if (result.isConfirmed) {
            await dispatch(PutCategoryApi({ Token, values, id }));
            await dispatch(GetCategoryApi({ Token }));
          } else if (result.isDenied) {
            MySwal.fire("Changes are not saved", "", "info");
          }
        });
      } catch (error) {
        return error;
      }
      handleClose();
    },
  });

  useEffect(() => {
    setValues({
      ...values,
      name: data.name ? data.name : "",
    });
  }, [data]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: StyledBackdrop }}
      >
        <Fade in={open}>
          <ModalContent sx={style}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: IsMobile ? 30 : 20,
              }}
            >
              <Typography
                variant="h6"
                sx={{ textAlign: "center", textTransform: "capitalize" }}
              >
                Edit Category
              </Typography>
              <form
                onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: IsMobile ? 30 : 20,
                }}
              >
                <Stack direction={"column"} spacing={2} sx={{ marginTop: 1 }}>
                  <TextField
                    label=" Name"
                    variant="outlined"
                    size="large"
                    name="name"
                    type="text"
                    sx={{ width: "100%" }}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.name && errors.name}
                    helperText={touched.name && errors.name}
                  />
                </Stack>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 5,
                    justifyContent: "center",
                  }}
                >
                  <Button variant={"contained"} type="submit" color="success">
                    submit
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="error"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          </ModalContent>
        </Fade>
      </Modal>
    </div>
  );
}

export function AddExpense({ handleClose, open }) {
  const IsMobile = useMediaQuery("(min-width:600px)");
  const Token = Cookies.get("Token");
  const dispatch = useDispatch();

  const initialValues = {
    date: dayjs(new Date()).toISOString(),
    description: "",
    amount: "",
  };
  const {
    errors,
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: ExpenseSchema,
    onSubmit: async (values) => {
      try {
        await dispatch(PostExpenseApi({ Token, values }));
        await dispatch(GetExpenseApi({ Token }));
      } catch (error) {
        return error;
      }
      handleClose();
    },
  });

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: StyledBackdrop }}
      >
        <Fade in={open}>
          <ModalContent sx={style}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: IsMobile ? 10 : 5,
              }}
            >
              <Typography
                variant="h6"
                sx={{ textAlign: "center", textTransform: "capitalize" }}
              >
                Add Expense
              </Typography>
              <form
                onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: IsMobile ? 30 : 20,
                }}
              >
                <Stack direction={"column"} spacing={2} sx={{ marginTop: 1 }}>
                  <Box sx={{ width: "100%" }}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      sx={{ flex: 1 }}
                    >
                      <DateTimePicker
                        label="Date"
                        name="date"
                        format="DD/MM/YYYY hh:mm A"
                        value={
                          values.date ? dayjs(values.date) : dayjs(new Date())
                        }
                        onChange={(value) => {
                          const formattedDate = value
                            ? dayjs(value).toISOString()
                            : null;
                          setFieldValue("date", formattedDate);
                        }}
                        onBlur={handleBlur}
                        sx={{ width: "100%", flex: 1 }}
                        views={["year", "month", "day", "hours", "minutes"]}
                      />
                    </LocalizationProvider>
                    {touched.date && errors.date && (
                      <Typography
                        variant="caption"
                        color={"error"}
                        style={{
                          marginTop: "3px",
                          marginLeft: "14px",
                          marginRight: "14px",
                          marginBottom: "0px",
                        }}
                      >
                        {errors.date}
                      </Typography>
                    )}
                  </Box>

                  <TextField
                    label="Amount"
                    variant="outlined"
                    name="amount"
                    type="number"
                    sx={{ width: "100%" }}
                    value={values.amount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.amount && errors.amount}
                    helperText={touched.amount && errors.amount}
                    onWheel={numberInputOnWheelPreventChange}
                  />

                  <TextField
                    label=" Description"
                    variant="outlined"
                    name="description"
                    type="text"
                    sx={{ width: "100%" }}
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.description && errors.description}
                    helperText={touched.description && errors.description}
                  />
                </Stack>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 5,
                    justifyContent: "center",
                  }}
                >
                  <Button variant={"contained"} type="submit" color="success">
                    submit
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="error"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          </ModalContent>
        </Fade>
      </Modal>
    </div>
  );
}

export function EditExpense({ handleClose, open, data }) {
  const IsMobile = useMediaQuery("(min-width:600px)");
  const dispatch = useDispatch();
  const Token = Cookies.get("Token");
  const { id } = data;
  const initialValues = {
    date: "",
    description: "",
    amount: "",
  };
  const {
    errors,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    touched,
    setValues,
  } = useFormik({
    initialValues,
    validationSchema: ExpenseSchema,
    onSubmit: async (values) => {
      try {
        MySwal.fire({
          title: "Do you want to save the changes?",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Save",
          denyButtonText: `Don't save`,
        }).then(async (result) => {
          if (result.isConfirmed) {
            await dispatch(PutExpenseApi({ Token, values, id }));
            await dispatch(GetExpenseApi({ Token }));
          } else if (result.isDenied) {
            MySwal.fire("Changes are not saved", "", "info");
          }
        });
      } catch (error) {
        return error;
      }
      handleClose();
    },
  });

  useEffect(() => {
    const expenseDate =
      data && data?.date ? dayjs(data?.date).toISOString() : null;
    setValues({
      ...values,
      description: data.description ? data.description : "",
      amount: data.amount ? data.amount : "",
      date: expenseDate,
    });
  }, [data]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: StyledBackdrop }}
      >
        <Fade in={open}>
          <ModalContent sx={style}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: IsMobile ? 10 : 5,
              }}
            >
              <Typography
                variant="h6"
                sx={{ textAlign: "center", textTransform: "capitalize" }}
              >
                Edit Expense
              </Typography>
              <form
                onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: IsMobile ? 30 : 20,
                }}
              >
                <Stack direction={"column"} spacing={2} sx={{ marginTop: 1 }}>
                  <Box sx={{ width: "100%" }}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      sx={{ flex: 1 }}
                    >
                      <DateTimePicker
                        label="Date"
                        name="date"
                        format="DD/MM/YYYY hh:mm A"
                        value={
                          values.date ? dayjs(values.date) : dayjs(new Date())
                        }
                        onChange={(value) => {
                          const formattedDate = value
                            ? dayjs(value).toISOString()
                            : null;
                          setFieldValue("date", formattedDate);
                        }}
                        onBlur={handleBlur}
                        sx={{ width: "100%", flex: 1 }}
                        views={["year", "month", "day", "hours", "minutes"]}
                      />
                    </LocalizationProvider>
                    {touched.date && errors.date && (
                      <Typography
                        variant="caption"
                        color={"error"}
                        style={{
                          marginTop: "3px",
                          marginLeft: "14px",
                          marginRight: "14px",
                          marginBottom: "0px",
                        }}
                      >
                        {errors.date}
                      </Typography>
                    )}
                  </Box>

                  <TextField
                    label="Amount"
                    variant="outlined"
                    name="amount"
                    type="number"
                    sx={{ width: "100%" }}
                    value={values.amount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.amount && errors.amount}
                    helperText={touched.amount && errors.amount}
                    onWheel={numberInputOnWheelPreventChange}
                  />

                  <TextField
                    label=" Description"
                    variant="outlined"
                    name="description"
                    type="text"
                    sx={{ width: "100%" }}
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.description && errors.description}
                    helperText={touched.description && errors.description}
                  />
                </Stack>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 5,
                    justifyContent: "center",
                  }}
                >
                  <Button variant={"contained"} type="submit" color="success">
                    submit
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="error"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          </ModalContent>
        </Fade>
      </Modal>
    </div>
  );
}

export function SlectBilling({ handleClose, open, billingId }) {
  const IsMobile = useMediaQuery("(min-width:600px)");

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: StyledBackdrop }}
      >
        <Fade in={open}>
          <ModalContent sx={style}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: IsMobile ? 30 : 20,
              }}
            >
              <Typography
                variant="h6"
                sx={{ textAlign: "center", textTransform: "capitalize" }}
              >
                Select Billing
              </Typography>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: IsMobile ? 30 : 20,
                }}
              >
                <Stack
                  direction={"row"}
                  spacing={2}
                  sx={{ marginTop: 1 }}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <NavLink to={`/room-invoice/${billingId}`}>
                    <Button
                      variant={"contained"}
                      type="button"
                      sx={{
                        background: "#655CC9",
                        color: "#fff",
                        "&:hover": {
                          background: "#655CC9",
                        },
                      }}
                    >
                      {" "}
                      Room Invoice
                    </Button>
                  </NavLink>

                  <NavLink to={`/order-invoice/${billingId}`}>
                    <Button variant={"contained"} type="button" color="success">
                      {" "}
                      Order Invoice
                    </Button>
                  </NavLink>
                  <NavLink to={`/both-invoice/${billingId}`}>
                    <Button variant="contained" type="button" color="error">
                      {" "}
                      Both Invoice{" "}
                    </Button>
                  </NavLink>
                </Stack>
              </Box>
            </div>
          </ModalContent>
        </Fade>
      </Modal>
    </div>
  );
}

export function DataUploadForm({ handleClose, open, demoFile, uploadType }) {
  const Token = Cookies.get("Token");
  const dispatch = useDispatch();
  const IsMobile = useMediaQuery("(min-width:600px)");
  const steps = [
    "Download Demo Excel File",
    "Upload the file with data filled in it",
  ];

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const initialValues = {
    excelFile: null,
  };
  const { errors, handleSubmit, touched, setFieldValue } = useFormik({
    initialValues,
    validationSchema: fileUploadSchema,
    onSubmit: async (values) => {
      try {
        values.excelFile = file;
        const formdata = new FormData();
        Object.entries(values).forEach(([key, val]) => {
          formdata.append(key, val); // Include the file name
        });

        console.log("values :>>", values);
        console.log("formdata :>>", ...formdata);

        switch (uploadType) {
          case "CATEGORY":
            await dispatch(PostBulkCategoryApi({ Token, formdata }));
            await dispatch(GetCategoryApi({ Token }));
            break;
          case "FOOD":
            await dispatch(PostBulkFoodApi({ Token, formdata }));
            await dispatch(GetFoodApi({ Token }));
            break;
          case "GUEST":
            await dispatch(PostBulkGuestData({ Token, formdata }));
            await dispatch(GetAllGuestData({ Token }));
            break;
          case "ROOM":
            const checkIn = new Date().toLocaleDateString();
            const checkOut = new Date();
            checkOut.setDate(checkOut.getDate() + 7);
            const data = {
              check_in: checkIn,
              check_out: checkOut.toLocaleDateString(),
            };
            await dispatch(AddBulkRoomApi({ Token, formdata }));
            await dispatch(GetRoomApi({ Token, data }));
            break;
          default:
            console.log("invalid module for bulk upload");
        }
      } catch (error) {
        return error;
      }
      handleClose();
    },
  });

  const [file, setFile] = useState(null);
  const [rejectedError, setRejectedError] = useState(null);

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setRejectedError(null);
      console.info("file :>>", file);
      setFile(file);
    }

    if (rejectedFiles.length) {
      setFile(null);
      setRejectedError("only xlsx files are allowed");
      // Handle rejected files if needed
    }
  }, []);

  let maxSize = 5 * 1024 * 1024; //5mb max size

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/vnd.ms-excel": [".xls", ".xlsx"],
    }, // Accept any image file
    maxFiles: 1, // Limit to only one file,
    maxSize: maxSize,
  });

  useEffect(() => {
    console.info("image :>>", file);
    setFieldValue("excelFile", file);
  }, [file]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: StyledBackdrop }}
      >
        <Fade in={open}>
          <ModalContent sx={style}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: IsMobile ? 30 : 20,
                justifyContent: "space-between",
              }}
            >
              <Stack direction={"row"}>
                <Box
                  sx={{ width: "100%" }}
                  display={"flex"}
                  justifyContent={"flex-start"}
                  alignContent={"start"}
                  alignItems={"start"}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      textAlign: "center",
                      textTransform: "capitalize",
                    }}
                  >
                    {uploadType === "CATEGORY" && "Add Category"}
                    {uploadType === "FOOD" && "Add Food"}
                    {uploadType === "GUEST" && "Add Guest"}
                    {uploadType === "ROOM" && "Add Room"}
                  </Typography>
                </Box>
                <Box
                  sx={{ width: "100%" }}
                  display={"flex"}
                  justifyContent={"flex-end"}
                  alignContent={"end"}
                  alignItems={"end"}
                >
                  <Tooltip title="Close">
                    <IconButton onClick={handleClose}>
                      <AddIcon
                        sx={{
                          transform: "rotate(135deg)",
                          transition: "0.5s",
                          "&:hover": {
                            transform: "rotate(360deg)",
                          },
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Stack>
            </div>

            {activeStep !== steps.length && (
              <>
                <Typography sx={{ mb: 1 }}>Step {activeStep + 1}</Typography>
              </>
            )}

            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                return (
                  <Step key={index}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>

            {activeStep === 0 && uploadType === "ROOM" && (
              <>
                <Typography variant="h6">Note : </Typography>
                <ul>
                  <li>
                    <Typography variant="subtitle2">
                      Room number must be unique
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      Bed type must be either SINGLE , DOUBLE or OTHER
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      Tax type must be either INCLUDE , EXCLUDE or NONE
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      Above field values must be in capital
                    </Typography>
                  </li>
                </ul>
              </>
            )}
            {activeStep === 0 && uploadType === "FOOD" && (
              <>
                <Typography variant="h6">Note : </Typography>
                <ul>
                  <li>
                    <Typography variant="subtitle2">
                      Item code must be unique.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      Category id must be from existing categories.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      Tax type must be either INCLUDE , EXCLUDE or NONE and must
                      be in capital
                    </Typography>
                  </li>
                </ul>
              </>
            )}

            {activeStep === 0 && (
              <Button
                variant="outlined"
                color="success"
                href={`${process.env.PUBLIC_URL}/${demoFile}`}
                download={"Demo.xlsx"}
                sx={{
                  "&:hover": {
                    textDecoration: "none",
                  },
                }}
              >
                Download
              </Button>
            )}
            {activeStep === steps.length - 1 && (
              <>
                <form
                  onSubmit={handleSubmit}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: IsMobile ? 2 : 1,
                  }}
                >
                  <FileUploadDropZone
                    getRootProps={getRootProps}
                    getInputProps={getInputProps}
                    isDragActive={isDragActive}
                    file={file}
                  />
                  {touched.excelFile && errors.excelFile && (
                    <Typography
                      variant="caption"
                      color={"error"}
                      style={{
                        marginTop: "3px",
                        marginLeft: "14px",
                        marginRight: "14px",
                        marginBottom: "0px",
                      }}
                    >
                      {errors.excelFile}
                    </Typography>
                  )}
                  {rejectedError && (
                    <Typography
                      variant="caption"
                      color={"error"}
                      style={{
                        marginTop: "3px",
                        marginLeft: "14px",
                        marginRight: "14px",
                        marginBottom: "0px",
                      }}
                    >
                      {rejectedError}
                    </Typography>
                  )}
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />

                    {activeStep === steps.length - 1 ? (
                      <Button type="submit">Finish</Button>
                    ) : (
                      <Button onClick={handleNext}>Next</Button>
                    )}
                  </Box>
                </form>
              </>
            )}
            {activeStep !== steps.length - 1 && (
              <>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />

                  <Button onClick={handleNext}>Next</Button>
                </Box>
              </>
            )}
          </ModalContent>
        </Fade>
      </Modal>
    </div>
  );
}

export function CustomSnackBar({ open, setOpen, message }) {
  // const [open, setOpen] = useState(false);

  // const handleClick = () => {
  //   setOpen(true);
  // };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

const Backdrop = React.forwardRef((props, ref) => {
  const { open, ...other } = props;
  return (
    <Fade in={open}>
      <div ref={ref} {...other} />
    </Fade>
  );
});

Backdrop.propTypes = {
  open: PropTypes.bool,
};

// const blue = {
//   200: "#99CCFF",
//   300: "#66B2FF",
//   400: "#3399FF",
//   500: "#007FFF",
//   600: "#0072E5",
//   700: "#0066CC",
// };

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: "600px",
};

const ModalContent = styled(Box)(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
     background-color:#ffffff;
    border-radius: 8px;
    // border: 1px solid ${
      theme.palette.mode === "dark" ? grey[700] : grey[200]
    };
    box-shadow: 0px 4px 12px ${
      theme.palette.mode === "dark" ? "rgba(0,0,0, 0.50)" : "rgba(0,0,0, 0.20)"
    };
    padding: 1rem;
    color: ${theme.palette.mode === "dark" ? grey[50] : grey[900]};
    font-family: IBM Plex Sans, sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
  
  
    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-right: 0.5rem;
    }
  
    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === "dark" ? grey[400] : grey[800]};
    }
    `
);

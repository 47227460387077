import React, { useState } from "react";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Webcam from "react-webcam";
import { Box } from "@mui/material";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";

export default function SimpleDialog({
  open,
  handleClose,
  setselectedvalue,
  mode,
}) {
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const webcamRef = React.useRef(null);
  const [OpenCamera, setOpenCamera] = useState(false);
  const handleWebCamOpen = () => {
    setOpenCamera(!OpenCamera);
    if (OpenCamera) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (mode === "SINGLE") {
        setselectedvalue(imageSrc);
      } else {
        setselectedvalue((prev) => [...prev, imageSrc]);
      }
      handleClose();
    }
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Choose From</DialogTitle>
      <List sx={{ pt: 0 }}>
        <ListItem disableGutters>
          <ListItemButton>
            <ListItemText>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                color="secondary"
                tabIndex={-1}
                sx={{ width: "100%" }}
                startIcon={<CloudUploadIcon />}
              >
                Upload file
                <VisuallyHiddenInput
                  type="file"
                  multiple={mode === "SINGLE" ? false : true}
                  onChange={(event) => {
                    if (mode === "SINGLE") {
                      const SelectedImage = event.target.files[0];
                      if (SelectedImage) {
                        setselectedvalue(SelectedImage);
                      }
                    } else {
                      const SelectedImage = event.target.files;
                      setselectedvalue((prev) => [...prev, ...SelectedImage]);
                    }
                    handleClose();
                  }}
                />
              </Button>
            </ListItemText>
          </ListItemButton>
        </ListItem>

        <ListItem disableGutters>
          <ListItemButton
            autoFocus
            // onClick={handleListItemClick}
          >
            <ListItemText>
              <Box sx={{ width: "100%" }}>
                {OpenCamera && (
                  <Webcam ref={webcamRef} style={{ height: "200px" }} />
                )}
              </Box>
              <Button
                variant="contained"
                color="info"
                onClick={handleWebCamOpen}
                sx={{ width: "100%" }}
                startIcon={<CameraAltOutlinedIcon />}
              >
                Capture Image
              </Button>
            </ListItemText>
          </ListItemButton>
        </ListItem>
      </List>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          color="error"
          onClick={handleClose}
          size="small"
          sx={{ mb: 1 }}
        >
          Close
        </Button>
      </Box>
    </Dialog>
  );
}

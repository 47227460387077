import {
  Box,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  Tooltip,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import DataTable from "../../../DataTable";
import { useEffect, useMemo, useState } from "react";
import CollapsedBreadcrumbs from "../../Include/Breadcrumb";
import placeholderImage from "../../../../Assets/img/room-placeholder.jpg";
import { Avatar } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ExcellIcon from "../../../../Assets/img/Exellicon.png";
import { DataUploadForm } from "../../../../Model/Model";
import { NavLink } from "react-router-dom";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import UploadIcon from "@mui/icons-material/Upload";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import AddCardIcon from "@mui/icons-material/AddCard";
import BusinessIcon from "@mui/icons-material/Business";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import AddIcon from "@mui/icons-material/Add";
import EditRoadOutlinedIcon from "@mui/icons-material/EditRoadOutlined";
import SwipableDrawer from "../../Include/SwipableDrawer";
import handleExcelConversion from "../Reports/ExcellsFormat";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import {
  GetAllGuestData,
} from "../../../../store/Slice/GuestSlice/GuestDataTableSlice";
import handleFilter from "../../../Searching";
import BasicTabs from "../Rooms/Details/Room_User_Detail";
const BreadCrumbLink = [
  { id: 1, name: "Home", link: "/" },
  { id: 1, name: "Guests" },
];
const ViewAllGuest = () => {
  const Token = Cookies.get("Token");
  const id = Cookies.get("userid");
  const dispatch = useDispatch();
  const { GuestDatatableData } = useSelector((state) => state.UserStore);
  const [record, setrecord] = useState([]);
  const IsMobile = useMediaQuery("(min-width:900px)");

  useEffect(() => {
    const FetchData = async () => {
      try {
        await dispatch(GetAllGuestData({ Token }));
      } catch (error) {
        return error;
      }
    };
    FetchData();
  }, [Token, dispatch]);

  const datawithSerialNumber = useMemo(
    () =>
      GuestDatatableData.map((item, index) => ({
        ...item,
        serialNumber: index + 1,
      })),
    [GuestDatatableData]
  );

  const RemoveAdminData = useMemo(
    () => datawithSerialNumber.filter((val) => val.id !== id),
    [datawithSerialNumber]
  );

  useEffect(() => {
    setrecord(RemoveAdminData);
  }, [RemoveAdminData]);

  const [ExcelCovertData, setExcelCovertData] = useState([]);
  useEffect(() => {
    if (record) {
      const excelldata = record.map(
        ({
          id,
          password,
          role_id,
          document_images,
          created_at,
          updated_at,
          serialNumber,
          image,
          ...rest
        }) => {
          return { serialNumber, ...rest };
        }
      );
      setExcelCovertData(excelldata);
    }
  }, [record]);

  const [handlegridrow, sethandlegridrow] = useState(null);

  const [columns, setColumns] = useState([
    {
      field: "serialNumber",
      headerName: "SR.No",
      width: 0,
      headerAlign: "center",
      align: "center",
      visible: true,
    },

    {
      field: "created_at",
      headerName: "Date",
      visible: true,
      align: "center",
      headerAlign: "center",
      width: 190,
      renderCell: (param) => (
        // <div>{new Date(param.row.check_in).toLocaleDateString()}</div>
        <div>
          {new Intl.DateTimeFormat("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          }).format(new Date(param.row.created_at))}
        </div>
      ),
    },

    // {
    //   field: "image",
    //   headerName: "images",
    //   width: 0,
    //   headerAlign: "center",
    //   align: "center",
    //   visible: true,
    //   renderCell: (param) => (
    //     // <IconButton  >
    //     <Avatar
    //       onClick={() => {
    //         handleRowClick(param.row);
    //       }}
    //       sx={{ mt: 1 }}
    //       src={`${process.env.REACT_APP_API_URL}/uploads/guests/${param.row.image}`}
    //     />
    //     // </IconButton>
    //   ),
    // },
    {
      field: "fullname",
      headerName: "Name",
      width: 220,
      headerAlign: "center",
      align: "center",
      visible: true,
      renderCell: (param) => (
        <div
          onClick={() => {
            handleRowClick(param.row);
          }}
        >
          {param.row.fullname}
        </div>
      ),
    },

    {
      field: "phone_number",
      headerName: "Mobile",
      width: 200,
      headerAlign: "center",
      align: "center",
      visible: true,
    },

    {
      field: "address",
      headerName: "Address",
      width: 220,
      headerAlign: "center",
      align: "center",
      visible: true,
    },

    {
      field: "email",
      headerName: "Email",
      width: 220,
      headerAlign: "center",
      align: "center",
      visible: true,
    },

    {
      headerName: "GST Number",
      field: "gst_number",
      width: 220,
      headerAlign: "center",
      align: "center",
      visible: true,
    },

    // {
    //     field: "images",
    //     headerName: "Images",
    //     align: "center",
    //     visible: true,
    //     headerAlign: "center",
    //     renderCell: (param) => (
    //       <LazyLoadImage
    //         effect="blur"
    //         onClick={handleRowClick}
    //         src={param.row.images}
    //         style={{
    //           height: "40px",
    //           width: "70px",
    //           borderRadius: "10px",
    //           cursor: "pointer",
    //           marginBottom: "10px",
    //           marginTop: "5px",
    //         }}
    //       />
    //     ),
    //   },
    {
      field: "document",
      headerName: "Document",
      width: 220,
      headerAlign: "center",
      align: "center",
      visible: true,
      renderCell: (param) => {
        const imageUrl =
          param.row && param.row?.document_images
            ? `${process.env.REACT_APP_API_URL}/uploads/guests/${param.row?.document_images}`
            : placeholderImage;

        return (
          <LazyLoadImage
            effect="blur"
            src={imageUrl}
            style={{
              height: "40px",
              width: "70px",
              borderRadius: "10px",
              cursor: "pointer",
              marginBottom: "10px",
              marginTop: "5px",
            }}
          />
        );
      },
    },

    {
      field: "Action",
      headerName: "Action",
      width: 200,
      headerAlign: "center",
      align: "center",
      visible: true,
      renderCell: (param) => (
        <div>
          <NavLink to={`/guest-Edit/${param.row.id}`}>
            <IconButton>
              <BorderColorOutlinedIcon sx={{ color: "#6d75eb" }} />
            </IconButton>
          </NavLink>

          {/* <IconButton
            onClick={() => {
              handleDeletUser(param.row.id);
            }}
          >
            <DeleteOutlinedIcon sx={{ color: "#ff5757" }} />
          </IconButton> */}
        </div>
      ),
    },
  ]);

  const handleToggleColumn = (field) => {
    setColumns((prevColumns) =>
      prevColumns.map((col) =>
        col.field === field ? { ...col, visible: !col.visible } : col
      )
    );
  };

  // const handleDeletUser = async (id) => {
  //   try {
  //     MySwal.fire({
  //       title: "Are you sure?",
  //       text: "You won't be able to revert this!",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonText: "Yes, delete it!",
  //       confirmButtonColor: "#28a745",
  //       cancelButtonColor: "#dc3545",
  //       cancelButtonText: "No, cancel!",
  //     }).then(async (result) => {
  //       if (result.isConfirmed) {
  //         await dispatch(DeletGuestData({ Token, id }));
  //         await dispatch(GetAllGuestData({ Token }));
  //       } else if (result.dismiss === MySwal.DismissReason.cancel) {
  //         MySwal.fire({
  //           title: "Cancelled",
  //           text: "Your data is safe.",
  //           icon: "error",
  //           confirmButtonText: "OK",
  //           confirmButtonColor: "#dc3545",
  //         });
  //       }
  //     });
  //   } catch (error) {
  //     throw error;
  //   }
  // };

  const [UserData, setUserData] = useState({});
  const handleRowClick = (userdata) => {
    const { fullname, email, phone_number, address, document, created_at } =
      userdata;
    setUserData({
      Name: fullname,
      Email: email,
      "Mobile No": phone_number,
      Address: address,
      Documents: document,
      "Created Date": new Date(created_at).toDateString(),
    });
    sethandlegridrow((previos) => !previos);
  };

  const icons = [
    <AutorenewIcon />,
    <PersonOutlineIcon />,
    <MailOutlineIcon />,
    <LocalPhoneIcon />,
    <AddCardIcon />,
    <AddCardIcon />,
    <AddCardIcon />,
    <BusinessIcon />,
  ];

  const [Swipeopen, setSwipeopen] = useState(false);
  const HandleSwipeOpen = () => setSwipeopen(true);
  const HandleSwipeClose = () => setSwipeopen(false);

  const [dataUploadModelOpen, setdataUploadModelOpen] = useState(false);
  const handlUploadModelOpen = () => setdataUploadModelOpen(true);
  const handlUploadModelClose = () => setdataUploadModelOpen(false);

  const keys_serach = [
    "name",
    "serialNumber",
    "email",
    "phone_number",
    "address",
  ];

  return (
    <>
      <Box sx={{ padding: "20px", width: "100%" }}>
        <Stack
          direction={IsMobile ? "row" : "column"}
          justifyContent={"space-between"}
        >
          <Typography variant="h6">View Guest</Typography>
          <CollapsedBreadcrumbs data={BreadCrumbLink} />
        </Stack>

        <Grid container>
          <Grid item xs={12}>
            <Paper
              sx={{ padding: "10px", marginTop: "20px", borderRadius: "10px" }}
            >
              <Grid container>
                <Grid item lg={9} md={9} sm={9} xs={12}>
                  <Box
                    sx={{
                      width: { xs: "100%", sm: "" },
                    }}
                  >
                    <TextField
                      label="Search"
                      size="small"
                      onChange={(event) => {
                        handleFilter({
                          event,
                          dataWithSerialNumber: RemoveAdminData,
                          setrecord,
                          keys_serach,
                        });
                      }}
                      sx={{
                        bgcolor: "#dae1f3",
                        borderRadius: "10px",
                        width: { xs: "100%", sm: "230px" },

                        "& fieldset": { border: "none" },
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignContent: "center",
                      alignItems: "center",
                      mt: { xs: 2, sm: 0 },
                    }}
                  >
                    <Avatar sx={{ background: "#EA82A6" }}>
                      <Tooltip title="Add Guest">
                        <NavLink to={"/guest-add"}>
                          <IconButton>
                            <AddIcon sx={{ color: "#fff" }} />
                          </IconButton>
                        </NavLink>
                      </Tooltip>
                    </Avatar>
                    <Avatar sx={{ background: "#6970E2" }}>
                      <Tooltip title="Table Edit">
                        <IconButton onClick={HandleSwipeOpen}>
                          <EditRoadOutlinedIcon sx={{ color: "#fff" }} />
                        </IconButton>
                      </Tooltip>
                    </Avatar>
                    <Avatar
                      src={ExcellIcon}
                      onClick={() => {
                        handleExcelConversion(ExcelCovertData);
                      }}
                    ></Avatar>
                    <Avatar sx={{ background: "#58c2ff" }}>
                      <Tooltip title="Bulk Upload">
                        <IconButton onClick={handlUploadModelOpen}>
                          <UploadIcon sx={{ color: "#fff" }} />
                        </IconButton>
                      </Tooltip>
                    </Avatar>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Box sx={{ marginTop: "20px", paddingLeft: "10px" }}>
          <Grid container spacing={2}>
            <Grid item lg={handlegridrow ? 6 : 12} sm={12} mt={-3} xs={12}>
              <Paper sx={{ marginTop: "22px" }}>
                <DataTable columns={columns} rows={record} />
              </Paper>
            </Grid>

            <Grid item lg={handlegridrow ? 6 : 12} xs={handlegridrow || 12}>
              {handlegridrow && (
                <Paper sx={{ marginTop: "22px" }}>
                  <BasicTabs
                    icons={icons}
                    data={UserData}
                    tab={"guest"}
                    sethandlegridrow={sethandlegridrow}
                  />
                </Paper>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Paper>
        {dataUploadModelOpen && (
          <DataUploadForm
            open={dataUploadModelOpen}
            handleClose={handlUploadModelClose}
            uploadType={"GUEST"}
            demoFile={"Guest_Demo.xlsx"}
          />
        )}
        <SwipableDrawer
          open={Swipeopen}
          close={HandleSwipeClose}
          columns={columns}
          handleToggleColumn={handleToggleColumn}
        />
      </Paper>
    </>
  );
};
export default ViewAllGuest;

import { Box, Grid, Paper, Stack, Typography} from "@mui/material";
import React, { useEffect, useState } from "react";
import CollapsedBreadcrumbs from "../Include/Breadcrumb";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import AddCardIcon from "@mui/icons-material/AddCard";
import BusinessIcon from "@mui/icons-material/Business";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import EditAdminProfile from "./EditProfile";
import ProfileDetail from "./ProfileDetail";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { GetApiAdmin } from "../../../store/Slice/AdminProfileSlice/AdminSlice";

const AdminProfile = () => {
  const BreadCrumbLink = [
    {id: 1,name: "Home",link: "/",},
    {id: 1,name: "Admin-Profile",link: "/profile",},
  ];

  const id = Cookies.get("userid");
  const Token = Cookies.get("Token");

  const dispatch = useDispatch();
  useEffect(() => {
    const FetchData = async () => {
      try {
        await dispatch(GetApiAdmin({ Token, id }));
      } catch (error) {
        return error;
      }
    };

    FetchData();
  }, [dispatch, id, Token]);

  const { AdminData } = useSelector((state) => state.AdminStore);
  const [records, setrecords] = useState({});

  useEffect(() => {
    setrecords(AdminData);
  }, [AdminData]);

  // const [handlegridrow, sethandlegridrow] = useState(null);

  // const handleRowClick = () => {
  //   sethandlegridrow((previos) => !previos);
  // };

  const icons = [
    <AutorenewIcon />,
    <PersonOutlineIcon />,
    <MailOutlineIcon />,
    <LocalPhoneIcon />,
    <AddCardIcon />,
    <AddCardIcon />,
    <AddCardIcon />,
    <BusinessIcon />,
  ];

  return (
    <>
      <Box sx={{ padding: "20px", width: "100%" }}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography variant="h6">Admin Profile</Typography>
          <CollapsedBreadcrumbs data={BreadCrumbLink} />
        </Stack>
        <Box sx={{ marginTop: "40px", paddingLeft: "10px" }}>
          <Grid container spacing={2}>
            <Grid item md={6} sm={12} mt={-3} xs={12}>
              <Paper>
                <ProfileDetail UserData={records} icons={icons} />
              </Paper>
            </Grid>

            <Grid item md={6} sm={12} mt={-3} xs={12}>
              <EditAdminProfile
                AdminData={records}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Paper></Paper>
    </>
  );
};
export default AdminProfile;

import React, { useEffect, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Box,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  Tooltip,
  IconButton,
  Button,
  Avatar,
  Pagination,
  useMediaQuery,
} from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import CollapsedBreadcrumbs from "../../Include/Breadcrumb";
import AddIcon from "@mui/icons-material/Add";
import ExcellIcon from "../../../../Assets/img/Exellicon.png";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import placeholderImage from "../../../../Assets/img/room-placeholder.jpg";
import BasicTabs from "../Rooms/Details/Room_User_Detail";
import {
  data,
  Bookedicons,
  UserData,
  iconsUser,
  Room_Service_Data,
} from "../Rooms/Data/Data";
import IcreamImage from "../../../../Assets/img/iceCream.avif";
import SwipableDrawer from "../../Include/SwipableDrawer";
import EditRoadOutlinedIcon from "@mui/icons-material/EditRoadOutlined";
import { handleExcelDropdownConversion } from "../Reports/ExcellsFormat";
import DropDownView from "./DropDownView";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { GetRoomServiceApi } from "../../../../store/Slice/RoomServiceSlice/RoomServiceSlice";
import dayjs from "dayjs";
import handleFilter from "../../../Searching";
import { OrderInvoicePayment_Status } from "../../../../store/Slice/BillingSlice/BillingSlice";
import { MySwal } from "../../../../Model/Alert/SuccessAlert";
const BreadCrumbLink = [
  {
    id: 1,
    name: "Home",
    link: "/",
  },
  {
    id: 1,
    name: "Room Service",
    link: "/room-service",
  },
];

const ViewRoomService = () => {
  const Token = Cookies.get("Token");
  const dispatch = useDispatch();
  const { RoomServiceData } = useSelector((state) => state.RoomServiceStore);
  const [record, setrecord] = useState([]);
  const IsMobile = useMediaQuery("(min-width:900px)");

  useEffect(() => {
    const Fetchdata = async () => {
      try {
        await dispatch(GetRoomServiceApi({ Token }));
      } catch (error) {
        return error;
      }
    };

    Fetchdata();
  }, [dispatch, Token]);

  const datawithSerialNumber = useMemo(
    () =>
      RoomServiceData.map((item, index) => ({
        ...item,
        serialNumber: index + 1,
      })),
    [RoomServiceData]
  );

  useEffect(() => {
    setrecord(datawithSerialNumber);
  }, [datawithSerialNumber]);

  const [handlegridrow, sethandlegridrow] = useState(null);
  // const [filterMenuOPen, setfilterMenuOPen] = useState(null);
  // const [filterid, setfilterid] = useState(0);

  const [columnsBookedRooms, setcolumnsBookedRooms] = useState([
    {
      field: "serialNumber",
      headerName: "#",
      headerAlign: "center",
      visible: true,
      align: "center",
    },
    {
      field: "images",
      headerName: "Images",
      align: "center",
      visible: true,
      headerAlign: "center",
      renderCell: (param) => {
        const image =
          param.row && param.row?.images
            ? `${process.env.REACT_APP_API_URL}/uploads/foods/${param.row?.images}`
            : placeholderImage;

        return (
          <LazyLoadImage
            effect="blur"
            onClick={handleRowClick}
            src={image}
            style={{
              height: "40px",
              width: "70px",
              borderRadius: "10px",
              cursor: "pointer",
              marginBottom: "10px",
              marginTop: "5px",
            }}
          />
        );
      },
    },
    {
      field: "itemcode",
      headerName: "Food Code",
      width: 120,
      headerAlign: "center",
      visible: true,
      align: "center",
    },
    {
      field: "itemname",
      headerName: "Food Name",
      width: 130,
      headerAlign: "center",
      visible: true,
      align: "center",
    },
    {
      field: "Price",
      headerName: "Price",
      width: 130,
      headerAlign: "center",
      visible: true,
      align: "center",
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 100,
      headerAlign: "center",
      visible: true,
      align: "center",
    },
    {
      field: "discount",
      headerName: "Discount",
      width: 100,
      headerAlign: "center",
      visible: true,
      align: "center",
    },
    {
      field: "total",
      headerName: "Total",
      width: 100,
      headerAlign: "center",
      visible: true,
      align: "center",
    },
    {
      field: "BookingData",
      headerName: "Booking Date",
      width: 140,
      headerAlign: "center",
      visible: true,
      align: "center",
      renderCell: (param) => (
        <div>
          {dayjs(new Date(param.row.booking_date)).format("DD/MM/YYYY")}
        </div>
      ),
    },
    {
      field: "Room_No",
      headerName: "Room NO",
      width: 120,
      headerAlign: "center",
      visible: false,
      align: "center",
    },
    {
      field: "Mobile",
      headerName: "Mobile",
      width: 100,
      headerAlign: "center",
      visible: false,
      align: "center",
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      headerAlign: "center",
      visible: true,
      align: "center",
      renderCell: (param) => (
        <div>
          {param.row.status === "Booked" && (
            <Button variant="outlined" size="small" color="success">
              {" "}
              Booked
            </Button>
          )}

          {param.row.status === "Cancel" && (
            <Button variant="outlined" size="small" color="error">
              {" "}
              Cancel
            </Button>
          )}

          {param.row.status === "Pending" && (
            <Button variant="outlined" size="small" color="info">
              {" "}
              Pending
            </Button>
          )}
        </div>
      ),
    },
    {
      field: "Action",
      headerName: "Action",
      width: 100,
      headerAlign: "center",
      visible: true,
      align: "center",
      renderCell: (param) => (
        <div>
          <NavLink to={`/edit-service/${param.row.id}`}>
            <IconButton>
              <BorderColorOutlinedIcon sx={{ color: "#6d75eb" }} />
            </IconButton>
          </NavLink>
          {/* <IconButton>
            <DeleteOutlinedIcon sx={{ color: "#ff5757" }} />
          </IconButton> */}
        </div>
      ),
    },
  ]);

  const handleToggleColumn = (field) => {
    setcolumnsBookedRooms((prevColumns) =>
      prevColumns.map(
        (col) => (col.field === field ? { ...col, visible: !col.visible } : col)
        // console.log("col",col)
      )
    );
  };

  const HandleRoomServiceStatusPaid = async (values) => {
    try {
      const { id } = values;
      MySwal.fire({
        title: "Are you sure?",
        text: "Once you generate the bill, payment status will be paid!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, generate it!",
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#dc3545",
        cancelButtonText: "No, cancel!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await dispatch(OrderInvoicePayment_Status({ Token, id }));
          await dispatch(GetRoomServiceApi({ Token }));
        } else if (result.dismiss === MySwal.DismissReason.cancel) {
          MySwal.fire({
            title: "Cancelled",
            text: "Your data is safe.",
            icon: "error",
            confirmButtonText: "OK",
            confirmButtonColor: "#dc3545",
          });
        }
      });
    } catch (error) {
      return error;
    }
  };

  // const rows = useMemo(() => {
  //   const today = moment();

  //   if (filterid === 1) {
  //     // Previous day data
  //     return record.filter((row) =>
  //       moment(row.order_date).isSame(today.clone().subtract(1, "day"), "day")
  //     );
  //   } else if (filterid === 3) {
  //     // Previous month data
  //     return record.filter((row) =>
  //       moment(row.order_date).isSame(
  //         today.clone().subtract(1, "month"),
  //         "month"
  //       )
  //     );
  //   } else if (filterid === 4) {
  //     // Previous year data
  //     return record.filter((row) =>
  //       moment(row.order_date).isSame(today.clone().subtract(1, "year"), "year")
  //     );
  //   } else if (filterid === 5) {
  //     // This week data
  //     return record.filter((row) =>
  //       moment(row.order_date).isBetween(
  //         today.clone().startOf("week"),
  //         today.clone().endOf("week")
  //       )
  //     );
  //   } else if (filterid === 6) {
  //     // This month data
  //     return record.filter((row) =>
  //       moment(row.order_date).isSame(today, "month")
  //     );
  //   } else {
  //     // Current day data (default)
  //     return record;
  //   }
  // }, [filterid, record]);

  const handleRowClick = () => sethandlegridrow((previous) => !previous);

  // Filter
  // const FilterDropMenu = [
  //   { id: 1, name: "Previous Day" },
  //   { id: 3, name: "Previosu Month" },
  //   { id: 4, name: "previous Year" },
  // ];

  // const handleFilterMenuOpen = (event) =>
  //   setfilterMenuOPen(event.currentTarget);
  // const handleFilterMenuClose = () => setfilterMenuOPen(null);

  const [Swipeopen, setSwipeopen] = useState(false);
  const HandleSwipeOpen = () => setSwipeopen(true);
  const HandleSwipeClose = () => setSwipeopen(false);

  // const roomserviceData = [
  //   {
  //     id: 45,
  //     RoomNo: "321",
  //     FloorNo: "f2",
  //     price: "25874",
  //     BookingDate: "27-5-2002",
  //     BookingStatus: "Booked",
  //     paymentStatus: "Paid",
  //     Email: "DemoUser123@gmail.com",
  //     Mobile: "3698521470",
  //     foodItems: [
  //       {
  //         id: 1,
  //         Code: "112",
  //         name: "Shanghai",
  //         price: "250",
  //         quantity: "4",
  //         total: "1000",
  //         status: "Booked",
  //       },
  //       {
  //         id: 2,
  //         Code: "113",
  //         name: "Shanghai Manchuraian",
  //         price: "300",
  //         quantity: "4",
  //         total: "1000",
  //         status: "Booked",
  //       },
  //       {
  //         id: 3,
  //         Code: "114",
  //         name: "Panneer",
  //         price: "100",
  //         quantity: "4",
  //         total: "1500",
  //         status: "Booked",
  //       },
  //       {
  //         id: 4,
  //         Code: "115",
  //         name: "Noodles",
  //         price: "90",
  //         quantity: "4",
  //         total: "1900",
  //         status: "Booked",
  //       },
  //       {
  //         id: 5,
  //         Code: "116",
  //         name: "Viral Vadapav",
  //         price: "50",
  //         quantity: "4",
  //         total: "200",
  //         status: "Booked",
  //       },
  //     ],
  //   },
  //   {
  //     id: 1,
  //     RoomNo: "321",
  //     FloorNo: "f2",
  //     price: "25874",
  //     BookingDate: "27-5-2002",
  //     BookingStatus: "Booked",
  //     paymentStatus: "Paid",
  //     Email: "DemoUser123@gmail.com",
  //     Mobile: "3698521470",
  //     foodItems: [
  //       {
  //         id: 1,
  //         Code: "112",
  //         name: "Shanghai",
  //         price: "250",
  //         quantity: "4",
  //         total: "1000",
  //         status: "Booked",
  //       },
  //       {
  //         id: 2,
  //         Code: "113",
  //         name: "Shanghai Manchuraian",
  //         price: "300",
  //         quantity: "4",
  //         total: "1000",
  //         status: "Booked",
  //       },
  //       {
  //         id: 3,
  //         Code: "114",
  //         name: "Panneer",
  //         price: "100",
  //         quantity: "4",
  //         total: "1500",
  //         status: "Booked",
  //       },
  //       {
  //         id: 4,
  //         Code: "115",
  //         name: "Noodles",
  //         price: "90",
  //         quantity: "4",
  //         total: "1900",
  //         status: "Booked",
  //       },
  //       {
  //         id: 5,
  //         Code: "116",
  //         name: "Viral Vadapav",
  //         price: "50",
  //         quantity: "4",
  //         total: "200",
  //         status: "Booked",
  //       },
  //     ],
  //   },
  //   {
  //     id: 2,
  //     RoomNo: "321",
  //     FloorNo: "f2",
  //     price: "25874",
  //     BookingDate: "27-5-2002",
  //     BookingStatus: "Booked",
  //     paymentStatus: "Paid",
  //     Email: "DemoUser123@gmail.com",
  //     Mobile: "3698521470",
  //     foodItems: [
  //       {
  //         id: 6,
  //         Code: "112",
  //         name: "Shanghai",
  //         price: "250",
  //         quantity: "4",
  //         total: "1000",
  //         status: "Booked",
  //       },
  //       {
  //         id: 7,
  //         Code: "113",
  //         name: "Shanghai Manchuraian",
  //         price: "300",
  //         quantity: "4",
  //         total: "1000",
  //         status: "Booked",
  //       },
  //       {
  //         id: 8,
  //         Code: "114",
  //         name: "Panneer",
  //         price: "100",
  //         quantity: "4",
  //         total: "1500",
  //         status: "Booked",
  //       },
  //       {
  //         id: 9,
  //         Code: "115",
  //         name: "Noodles",
  //         price: "90",
  //         quantity: "4",
  //         total: "1900",
  //         status: "Booked",
  //       },
  //       {
  //         id: 10,
  //         Code: "116",
  //         name: "Viral Vadapav",
  //         price: "50",
  //         quantity: "4",
  //         total: "200",
  //         status: "Booked",
  //       },
  //     ],
  //   },
  //   {
  //     id: 3,
  //     RoomNo: "321",
  //     FloorNo: "f2",
  //     price: "25874",
  //     BookingDate: "27-5-2002",
  //     BookingStatus: "Booked",
  //     paymentStatus: "Paid",
  //     Email: "DemoUser123@gmail.com",
  //     Mobile: "3698521470",
  //     foodItems: [
  //       {
  //         id: 11,
  //         Code: "112",
  //         name: "Shanghai",
  //         price: "250",
  //         quantity: "4",
  //         total: "1000",
  //         status: "Booked",
  //       },
  //       {
  //         id: 12,
  //         Code: "113",
  //         name: "Shanghai Manchuraian",
  //         price: "300",
  //         quantity: "4",
  //         total: "1000",
  //         status: "Booked",
  //       },
  //       {
  //         id: 13,
  //         Code: "114",
  //         name: "Panneer",
  //         price: "100",
  //         quantity: "4",
  //         total: "1500",
  //         status: "Booked",
  //       },
  //       {
  //         id: 14,
  //         Code: "115",
  //         name: "Noodles",
  //         price: "90",
  //         quantity: "4",
  //         total: "1900",
  //         status: "Booked",
  //       },
  //       {
  //         id: 15,
  //         Code: "116",
  //         name: "Viral Vadapav",
  //         price: "50",
  //         quantity: "4",
  //         total: "200",
  //         status: "Booked",
  //       },
  //     ],
  //   },
  //   {
  //     id: 4,
  //     FloorNo: "f2",
  //     price: "25874",
  //     BookingDate: "27-5-2002",
  //     BookingStatus: "Booked",
  //     paymentStatus: "Paid",
  //     Email: "DemoUser123@gmail.com",
  //     Mobile: "3698521470",
  //     foodItems: [
  //       {
  //         id: 16,
  //         Code: "112",
  //         name: "Shanghai",
  //         price: "250",
  //         quantity: "4",
  //         total: "1000",
  //         status: "Booked",
  //       },
  //       {
  //         id: 17,
  //         Code: "113",
  //         name: "Shanghai Manchuraian",
  //         price: "300",
  //         quantity: "4",
  //         total: "1000",
  //         status: "Booked",
  //       },
  //       {
  //         id: 18,
  //         Code: "114",
  //         name: "Panneer",
  //         price: "100",
  //         quantity: "4",
  //         total: "1500",
  //         status: "Booked",
  //       },
  //       {
  //         id: 19,
  //         Code: "115",
  //         name: "Noodles",
  //         price: "90",
  //         quantity: "4",
  //         total: "1900",
  //         status: "Booked",
  //       },
  //       {
  //         id: 20,
  //         Code: "116",
  //         name: "Viral Vadapav",
  //         price: "50",
  //         quantity: "4",
  //         total: "200",
  //         status: "Booked",
  //       },
  //     ],
  //   },
  //   {
  //     id: 5,
  //     RoomNo: "321",
  //     FloorNo: "f2",
  //     price: "25874",
  //     BookingDate: "27-5-2002",
  //     BookingStatus: "Booked",
  //     paymentStatus: "Paid",
  //     Email: "DemoUser123@gmail.com",
  //     Mobile: "3698521470",
  //     foodItems: [
  //       {
  //         id: 21,
  //         Code: "112",
  //         name: "Shanghai",
  //         price: "250",
  //         quantity: "4",
  //         total: "1000",
  //         status: "Booked",
  //       },
  //       {
  //         id: 22,
  //         Code: "113",
  //         name: "Shanghai Manchuraian",
  //         price: "300",
  //         quantity: "4",
  //         total: "1000",
  //         status: "Booked",
  //       },
  //       {
  //         id: 23,
  //         Code: "114",
  //         name: "Panneer",
  //         price: "100",
  //         quantity: "4",
  //         total: "1500",
  //         status: "Booked",
  //       },
  //       {
  //         id: 24,
  //         Code: "115",
  //         name: "Noodles",
  //         price: "90",
  //         quantity: "4",
  //         total: "1900",
  //         status: "Booked",
  //       },
  //       {
  //         id: 25,
  //         Code: "116",
  //         name: "Viral Vadapav",
  //         price: "50",
  //         quantity: "4",
  //         total: "200",
  //         status: "Booked",
  //       },
  //     ],
  //   },
  // ];

  const [page, setpage] = useState(1);
  const perPageItem = 8;

  const count = Math.ceil(data.length / perPageItem);
  // const displayData = data.slice((page - 1) * perPageItem, page * perPageItem);
  const handleChange = (event, value) => setpage(value);

  const keys_serach = [
    "phone_number",
    "serialNumber",
    "fullname",
    "email",
    "cgst",
    "sgst",
    "igst",
    "total",
    "discount",
    "room_no",
    "payment_type",
    "status",
    "person",
  ];

  return (
    <>
      <Box sx={{ padding: "20px", width: "100%" }}>
        <Stack
          direction={IsMobile ? "row" : "column"}
          justifyContent={"space-between"}
        >
          <Typography variant="h6">Rooms Service</Typography>
          <CollapsedBreadcrumbs data={BreadCrumbLink} />
        </Stack>
        <Grid container>
          <Grid item xs={12}>
            <Paper
              sx={{ padding: "10px", marginTop: "20px", borderRadius: "10px" }}
            >
              <Grid container>
                <Grid item lg={9} md={9} sm={9} xs={12}>
                  <Box
                    sx={{
                      width: { xs: "100%", sm: "" },
                    }}
                  >
                    <TextField
                      label="Search"
                      size="small"
                      onChange={(event) => {
                        handleFilter({
                          event,
                          dataWithSerialNumber: datawithSerialNumber,
                          setrecord,
                          keys_serach,
                        });
                      }}
                      sx={{
                        bgcolor: "#dae1f3",
                        borderRadius: "10px",
                        width: { xs: "100%", sm: "230px" },

                        "& fieldset": { border: "none" },
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignContent: "center",
                      alignItems: "center",
                      mt: { xs: 2, sm: 0 },
                    }}
                  >
                    <Avatar sx={{ background: "#6970E2" }}>
                      <Tooltip title="Table Edit">
                        <IconButton onClick={HandleSwipeOpen}>
                          <EditRoadOutlinedIcon sx={{ color: "#fff" }} />
                        </IconButton>
                      </Tooltip>
                    </Avatar>

                    <Avatar sx={{ background: "#EA82A6" }}>
                      <Tooltip title="Add Room Service">
                        <NavLink to={"/add-service"}>
                          <IconButton>
                            <AddIcon sx={{ color: "#fff" }} />
                          </IconButton>
                        </NavLink>
                      </Tooltip>
                    </Avatar>
                    <Avatar
                      src={ExcellIcon}
                      onClick={() => {
                        handleExcelDropdownConversion(datawithSerialNumber);
                      }}
                    ></Avatar>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Box sx={{ marginTop: "35px", paddingLeft: "10px" }}>
          <Grid container spacing={3}>
            <Grid item lg={handlegridrow ? 6 : 12} xs={12} mt={-2}>
              <Paper sx={{ width: "100%" }}>
                <DropDownView data={record} HandleRoomServiceStatusPaid={HandleRoomServiceStatusPaid}/>
                {/* <DataTable columns={columnsBookedRooms} rows={rows} /> */}
              </Paper>
              <Stack direction={"row"} justifyContent={"end"} mt={3}>
                <Pagination
                  count={count}
                  page={page}
                  onChange={handleChange}
                  variant="outlined"
                  shape="rounded"
                  color="info"
                />
              </Stack>
            </Grid>

            <Grid item lg={handlegridrow ? 6 : 4} sm={handlegridrow || 12}>
              {handlegridrow && (
                <Paper sx={{ borderRadius: "10px" }}>
                  <BasicTabs
                    handlegridrow={handlegridrow}
                    // RoomsubImages={RoomsubImages}
                    MainImage={IcreamImage}
                    icons={Bookedicons}
                    data={Room_Service_Data}
                    UserData={UserData}
                    iconsUser={iconsUser}
                    sethandlegridrow={sethandlegridrow}
                    food={"Food Items"}
                  />
                </Paper>
              )}
            </Grid>
          </Grid>
        </Box>

        {/* {filterMenuOPen && (
          <FilterMenu
            open={Boolean(filterMenuOPen)}
            anchorEl={filterMenuOPen}
            handleFilterMenuClose={handleFilterMenuClose}
            FilterDropMenu={FilterDropMenu}
            setfilterid={setfilterid}
            sethandlegridrow={sethandlegridrow}
          />
        )} */}

        <SwipableDrawer
          open={Swipeopen}
          close={HandleSwipeClose}
          columns={columnsBookedRooms}
          handleToggleColumn={handleToggleColumn}
        />
      </Box>
    </>
  );
};
export default ViewRoomService;

import React, { useState, useEffect, useCallback } from "react";
import {
  Paper,
  TextField,
  Box,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import { useFormik } from "formik";
import { AdmiProfileEditSchema } from "../../../schema/Schema";
import SingleImageDropZone from "./SingleImageDropzone";
import { useDropzone } from "react-dropzone";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { EditAdminProfileApi } from "../../../store/Slice/GuestSlice/GuestDataTableSlice";
import { GetApiAdmin } from "../../../store/Slice/AdminProfileSlice/AdminSlice";
import { NavLink } from "react-router-dom";
import { MySwal } from "../../../Model/Alert/SuccessAlert";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
const EditAdminProfilePage = ({ AdminData }) => {
  const [data, setdata] = useState({});
  useEffect(() => {
    setdata(AdminData);
  }, [AdminData]);

  const { id } = data;
  const Token = Cookies.get("Token");
  const dispatch = useDispatch();
  const initialValues = {
    fullname: "",
    email: "",
    phone_number: "",
    address: "",
    images: null,
    gst_number: "",
    default_checkin: null,
    default_checkout: null,
  };

  const {
    errors,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: AdmiProfileEditSchema,
    onSubmit: async (values) => {
      try {
        values.images = image.file ? image.file : values.images;
        const formdata = new FormData();
        Object.entries(values).forEach(([key, val]) => {
          formdata.append(key, val);
        });
        MySwal.fire({
          title: "Do you want to save the changes?",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Save",
          denyButtonText: `Don't save`,
        }).then(async (result) => {
          if (result.isConfirmed) {
            await dispatch(EditAdminProfileApi({ Token, formdata, id }));
            await dispatch(GetApiAdmin({ Token, id }));
          } else if (result.isDenied) {
            MySwal.fire("Changes are not saved", "", "info");
          }
        });
      } catch (error) {
        return error;
      }
    },
  });
  // console.log(errors);

  useEffect(() => {
    // Ensure data is defined and handle empty fields gracefully
    if (data) {
      setValues((prevValues) => ({
        ...prevValues,
        fullname: data?.fullname || "",
        email: data?.email || "",
        phone_number: data?.phone_number || "",
        address: data?.address || "",
        images: data?.image || null,
        gst_number: data?.gst_number || null,
        default_checkin: data?.default_checkin || null,
        default_checkout: data?.default_checkout || null,
      }));
    }
  }, [data]);

  const [image, setImage] = useState(() => data.image || null);

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      const preview = URL.createObjectURL(file);
      setImage({ file, preview });
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "image/*": [] },
    maxFiles: 1,
  });

  useEffect(() => {
    setImage(data.image || null);
  }, [data]);

  return (
    <Box sx={{ padding: "20px", width: "100%" }}>
      <Paper sx={{ mt: -2, paddingX: "20px", paddingY: "10px" }}>
        <Typography variant="h5" mb={2}>
          Edit Profile
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box display={"flex"} flexDirection={"column"} gap={3.5}>
            <Stack direction={"row"} spacing={4}>
              <TextField
                label="Full Name"
                variant="outlined"
                size="large"
                name="fullname"
                type="text"
                sx={{ width: "100%" }}
                value={values.fullname}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.fullname && errors.fullname}
                error={touched.fullname && errors.fullname}
              />
            </Stack>

            <Stack direction={"row"} spacing={4}>
              <TextField
                label="Email"
                variant="outlined"
                size="large"
                name="email"
                type="email"
                sx={{ width: "100%" }}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.email && errors.email}
                error={touched.email && errors.email}
              />
            </Stack>

            <Stack direction={"row"} spacing={4}>
              <TextField
                label="Mobile"
                variant="outlined"
                size="large"
                name="phone_number"
                type="text"
                sx={{ width: "100%" }}
                value={values.phone_number}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.phone_number && errors.phone_number}
                error={touched.phone_number && errors.phone_number}
              />
            </Stack>

            <Stack direction={"column"} spacing={4}>
              <SingleImageDropZone
                getRootProps={getRootProps}
                getInputProps={getInputProps}
                isDragActive={isDragActive}
                images={image}
                onChange={handleChange}
                onBlur={handleBlur}
                setImages={setImage}
                uploadType={"GUEST"}
              />
              {touched.images && errors.images && (
                <Typography variant="body" color={"error"}>
                  {errors.images}
                </Typography>
              )}
            </Stack>

            <Stack direction={"row"} spacing={4}>
              <TextField
                label="Address"
                variant="outlined"
                size="large"
                name="address"
                type="text"
                sx={{ width: "100%" }}
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.address && errors.address}
                error={touched.address && errors.address}
              />
            </Stack>
            <Stack direction={"row"} spacing={4}>
              <TextField
                label="GST Number"
                variant="outlined"
                size="small"
                name="gst_number"
                type="text"
                sx={{ width: "100%" }}
                value={values.gst_number}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.gst_number && errors.gst_number}
                error={touched.gst_number && errors.gst_number}
                InputLabelProps={{ shrink: !!values.gst_number }}
              />
            </Stack>

            <Stack direction={"column"} spacing={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ flex: 1 }}>
                <TimePicker
                  label="Default check in time"
                  name="default_checkin"
                  sx={{ width: "100%", flex: 1 }}
                  value={
                    values.default_checkin
                      ? dayjs(values.default_checkin)
                      : null
                  }
                  onChange={(value) => {
                    const formatedDate = value
                      ? dayjs(value).toISOString()
                      : null;
                    setFieldValue("default_checkin", formatedDate);
                  }}
                  onBlur={handleBlur}
                />
              </LocalizationProvider>

              {touched.default_checkin && errors.default_checkin && (
                <Typography
                  variant="caption"
                  color={"error"}
                  style={{
                    marginTop: "3px",
                    marginLeft: "14px",
                    marginRight: "14px",
                    marginBottom: "0px",
                  }}
                >
                  {errors.default_checkin}
                </Typography>
              )}
            </Stack>

            <Stack direction={"column"} spacing={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ flex: 1 }}>
                <TimePicker
                  label="Default check out time"
                  name="default_checkout"
                  sx={{ width: "100%", flex: 1 }}
                  value={
                    values.default_checkout
                      ? dayjs(values.default_checkout)
                      : null
                  }
                  onChange={(value) => {
                    const formatedDate = value
                      ? dayjs(value).toISOString()
                      : null;
                    setFieldValue("default_checkout", formatedDate);
                  }}
                  onBlur={handleBlur}
                />
              </LocalizationProvider>

              {touched.default_checkout && errors.default_checkout && (
                <Typography
                  variant="caption"
                  color={"error"}
                  style={{
                    marginTop: "3px",
                    marginLeft: "14px",
                    marginRight: "14px",
                    marginBottom: "0px",
                  }}
                >
                  {errors.default_checkout}
                </Typography>
              )}
            </Stack>

            <Stack
              direction={"row"}
              spacing={4}
              display={"flex"}
              justifyContent={"center"}
            >
              <Button variant="contained" color="success" type="submit">
                Submit
              </Button>
              <NavLink to={"/"}>
                <Button variant="contained" color="error" type="button">
                  Cancel
                </Button>
              </NavLink>
            </Stack>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};

export default EditAdminProfilePage;

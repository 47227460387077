import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";

import Logout from "@mui/icons-material/Logout";
import { NavLink} from "react-router-dom";
import {
  ListItemButton,
  ListItemText,
  ListItemAvatar,
} from "@mui/material";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ImageIcon from "@mui/icons-material/Image";
import WorkIcon from "@mui/icons-material/Work";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import Cookies from "js-cookie";

export default function ProfileMenu({ open, anchorEl, handleClose }) {
  const username = Cookies.get("username");
  const role = Cookies.get("role");

  const handleLogout = async () => {
    await Cookies.remove("Token");
    await Cookies.remove("role");
    await Cookies.remove("userid");
    await Cookies.remove("username");
    await Cookies.remove("image");

    window.location.pathname = "/login";
  };

  return (
    <React.Fragment>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <ListItemButton>
          <ListItemIcon>👋</ListItemIcon>
          <ListItemText primary={username} secondary={role} />
        </ListItemButton>
        <Divider />

        <MenuItem onClick={handleClose} sx={{ p: 0 }}>
          <NavLink
            to={"/profile"}
            style={{
              color: "#333",
              textDecoration: "none",
              width: "100%",
              padding: "8px 16px",
              display: "flex",
              justifyContent: "start",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <ListItemIcon>
              <AccountBoxIcon fontSize="small" />
            </ListItemIcon>
            Admin Profile
          </NavLink>
        </MenuItem>

        <MenuItem onClick={handleClose} sx={{ p: 0 }}>
          <NavLink
            onClick={handleLogout}
            style={{
              textDecoration: "none",
              width: "100%",
              padding: "8px 16px",
              display: "flex",
              justifyContent: "start",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </NavLink>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export function NotificationList({ BellListopen, anchorEl, handleCloseBell }) {
  const items = [
    { primary: "Booking", secondary: "Jan 9, 2014", icon: <ImageIcon /> },
    { primary: "Transaction", secondary: "Jan 7, 2014", icon: <WorkIcon /> },
    { primary: "Order", secondary: "July 20, 2014", icon: <BeachAccessIcon /> },
  ];

  return (
    <React.Fragment>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={BellListopen}
        onClose={handleCloseBell}
        onClick={handleCloseBell}
        PaperProps={{
          elevation: 0,
          sx: {
            marginLeft: "-80px",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: -10,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        {/* <ListItemButton>
          <ListItemIcon>
          👋
          </ListItemIcon>
          <ListItemText primary={"Demo User"} secondary={"Manager"}/>
      </ListItemButton>
      <Divider /> */}

        {items.map((item, index) => (
          <MenuItem key={index} sx={{ pl: 4, pr: 4 }}>
            <ListItemAvatar>
              <Avatar>{item.icon}</Avatar>
            </ListItemAvatar>
            <ListItemText primary={item.primary} secondary={item.secondary} />
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>

    // <Menu
    //   anchorEl={anchorEl}
    //   open={Boolean(BellListopen)}
    //   onClose={handleCloseBell}

    // >
    //  <Box sx={{marginRight:"70px",border:"2px solid red"}}>
    //  {items.map((item, index) => (
    //     <MenuItem key={index}  sx={{marginRight:"70px",background:"transparent"}}>
    //       <ListItemAvatar>
    //         <Avatar>
    //           {item.icon}
    //         </Avatar>
    //       </ListItemAvatar>
    //       <ListItemText primary={item.primary} secondary={item.secondary} />
    //     </MenuItem>
    //   ))}
    //  </Box>
    // </Menu>
  );
}

import React, { useRef } from "react";
import { Box, Paper, Typography, TextField, Stack, Button } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useFormik } from "formik";
import { OtpSchema } from "../../../schema/Schema";
import Cookies from "js-cookie"
import { useDispatch } from "react-redux";
import { OTPAPI } from "../../../store/Slice/AuthSlice/AuthSlice";

const OTPInput = () => {
  const dispatch=useDispatch()
  const inputRefs = useRef(new Array(6).fill(null));
  const sec_code=Cookies.get("sec_code")
 const time_stamp=Cookies.get("time_stamp")
 const Token=Cookies.get("temp_token")
  const formik = useFormik({
    initialValues: {
      otp: new Array(6).fill("")
    },
    validationSchema: OtpSchema,
    onSubmit:async  (values) => {
    try{
        const otpValue = values.otp.join("");
        console.log("Submitted OTP:", otpValue);
        await dispatch(OTPAPI({otp:otpValue,Token,sec_code,time_stamp}))
        // navigate("/confirm")
    }catch(error){
      return error
    }
    }
  });

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value) || value === "") {
      const newOtp = [...formik.values.otp];
      newOtp[index] = value;
      formik.setFieldValue("otp", newOtp);

      // Focus the next input if the current one is filled and it's not the last input
      if (value !== "" && index < 5) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !formik.values.otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
      <Paper sx={{ height: "330px", width: "450px", padding: "30px", borderRadius: "20px" }}>
        <form onSubmit={formik.handleSubmit}>
          <Box>
            <Typography variant="h5">2-Step Verification</Typography>
            <Typography variant="subtitle1" mt={2}>Enter the code for verification</Typography>
          </Box>
          <Stack mt={4} direction={'row'} gap={1}>
            {formik.values.otp.map((data, index) => (
              <TextField
                key={index}
                value={data}
                onChange={(e) => handleChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                inputRef={el => inputRefs.current[index] = el}
                inputProps={{
                  maxLength: 1,
                  style: { textAlign: "center" }
                }}
                autoFocus={index === 0}
                sx={{ mx: 1, width: "100px" }}
                error={Boolean(formik.errors.otp && formik.touched.otp && formik.errors.otp[index])}
                helperText={formik.errors.otp && formik.touched.otp && formik.errors.otp[index] ? formik.errors.otp[index] : ""}
              />
            ))}
          </Stack>
          <Stack display={'flex'} justifyContent={'center'} alignItems={'center'} mt={6}>
            <Button type="submit" variant="contained" color="secondary" sx={{ background: "#805dca", width: "100%" }}>
                <Typography variant="body">Verify</Typography>
            </Button>
          </Stack>
          <Typography variant="subtitle1" mt={2} sx={{ textAlign: "center" }}>
            Didn't receive the code?
            <NavLink to={'/login'} style={{ textDecoration: "none" }}>
              <Typography variant="body" color={"#EFC175"} sx={{ textDecoration: "none", cursor: "pointer" }}>Resend</Typography>
            </NavLink>
          </Typography>
        </form>
      </Paper>
    </Box>
  );
};

export default OTPInput;

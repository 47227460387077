import { useEffect, useMemo, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  IconButton,
  Button,
  useMediaQuery,
} from "@mui/material";
import CollapsedBreadcrumbs from "../Include/Breadcrumb";
import DataTable from "../../DataTable";
import { NavLink, useParams } from "react-router-dom";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { EditBookingModel } from "../../../Model/Model";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { Get_ReservationApi_BYID } from "../../../store/Slice/ReservationSlice/ReservationSlice";
import { MySwal } from "../../../Model/Alert/SuccessAlert";
import { CancelBookApi } from "../../../store/Slice/Booking/BookingSlice";
import dayjs from "dayjs";
import handleFilter from "../../Searching";
import {
  GstRoomInvoicePayment_Status,
  OrderInvoicePayment_Status,
} from "../../../store/Slice/BillingSlice/BillingSlice";
import BookingDropDownView from "../Other/BookingRecord/BookingDropDownView";

function Reservation() {
  let { id } = useParams();
  const Token = Cookies.get("Token");
  const IsMobile = useMediaQuery("(min-width:900px)");

  const { Room_ResevationData } = useSelector(
    (state) => state.ReservationStore
  );
  const dispatch = useDispatch();
  const [record, setrecord] = useState([]);

  useEffect(() => {
    const FetchData = async () => {
      try {
        await dispatch(Get_ReservationApi_BYID({ Token, id }));
      } catch (error) {
        console.log("Erroro", error);
        return error;
      }
    };
    FetchData();
  }, [Token, dispatch]);

  const datawithSerialNumber = useMemo(() => {
    return Room_ResevationData
      ? Room_ResevationData.map((item, index) => ({
          ...item,
          serialNumber: index + 1,
        }))
      : [];
  }, [Room_ResevationData]);

  useEffect(() => setrecord(datawithSerialNumber), [datawithSerialNumber]);

  const BreadCrumbLink = [
    {
      id: 1,
      name: "Home",
      link: "/",
    },
    {
      id: 1,
      name: "Rooms",
      link: "/rooms",
    },
    {
      id: 1,
      name: "Edit Reservation",
    },
    {
      id: 1,
      name: record[0]?.roommaster?.room_no,
    },
  ];
  // console.log("Recoredssjsjsja", record);

  // const [AddModelOpen, setAddModelOpen] = useState(false);
  // const [handlegridrow, sethandlegridrow] = useState(null);
  // const handlModelopen = () => setAddModelOpen(true);
  // const handlModelClose = () => setAddModelOpen(false);
  // const [updatedata, setupdatedata] = useState({});

  // const [foodDetail, setfoodDetail] = useState({});
  // const [EditBooking, setEditBooking] = useState(false);
  const [EditBookingData, setEditBookingData] = useState([]);
  const [EditModelOpen, setEditModelOpen] = useState(false);
  const handleEditModelOpen = (data) => {
    setEditModelOpen(true);
    setEditBookingData(data);
  };

  const handleEditModelClose = () => {
    setEditModelOpen(false);
  };

  const HandleBookingCancel = async (values) => {
    try {
      // console.log("Bookekksjds",values)
      const { id, room_id } = values;
      MySwal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Cancel it!",
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#dc3545",
        cancelButtonText: "No!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await dispatch(CancelBookApi({ Token, id }));
          await dispatch(Get_ReservationApi_BYID({ Token, id: room_id }));
        } else if (result.dismiss === MySwal.DismissReason.cancel) {
          MySwal.fire({
            title: "Cancelled",
            text: "Your data is safe.",
            icon: "error",
            confirmButtonText: "OK",
            confirmButtonColor: "#dc3545",
          });
        }
      });
      // let data = filterDate;
    } catch (error) {
      return error;
    }
  };

  const HandleBookingStatusPaid = async (values) => {
    try {
      // console.log("Bookekksjds",values)
      const { id } = values;
      MySwal.fire({
        title: "Do you want to save the changes?",
        text: "Once you generate the bill, payment status will be paid!",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "With GST",
        confirmButtonColor: "#28a745",
        denyButtonColor: "#dc3545",
        denyButtonText: `Without GST`,
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        let value = {
          gst_status: null,
        };
        if (result.isConfirmed) {
          // with gst
          value.gst_status = true;
          await dispatch(GstRoomInvoicePayment_Status({ Token, value, id }));
          await dispatch(Get_ReservationApi_BYID({ Token, id }));
        } else if (result.isDenied) {
          // without gst
          value.gst_status = false;
          await dispatch(GstRoomInvoicePayment_Status({ Token, value, id }));
          await dispatch(Get_ReservationApi_BYID({ Token, id }));
        }
      });
    } catch (error) {
      return error;
    }
  };

  const HandleRoomServiceStatusPaid = async (values) => {
    try {
      // console.log("Bookekksjds",values)
      const { id } = values;
      MySwal.fire({
        title: "Are you sure?",
        text: "Once you generate the bill, payment status will be paid!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, generate it!",
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#dc3545",
        cancelButtonText: "No, cancel!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await dispatch(OrderInvoicePayment_Status({ Token, id }));
          await dispatch(Get_ReservationApi_BYID({ Token, id }));
        } else if (result.dismiss === MySwal.DismissReason.cancel) {
          MySwal.fire({
            title: "Cancelled",
            text: "Your data is safe.",
            icon: "error",
            confirmButtonText: "OK",
            confirmButtonColor: "#dc3545",
          });
        }
      });
    } catch (error) {
      return error;
    }
  };

  const columns = useMemo(
    () => [
      {
        field: "#",
        headerName: "#",
        width: "20px",
        headerAlign: "center",
        align: "center",
        visible: true,
        // marginLeft:"20px"
      },
      {
        field: "serialNumber",
        headerName: "SR.No",
        width: "40px",
        headerAlign: "center",
        align: "center",
        visible: true,
        // marginLeft:"20px"
      },

      {
        field: "Action",
        headerName: "Action",
        width: "110px",
        headerAlign: "center",
        align: "center",
        visible: true,
        renderCell: (param) => (
          <div>
            {param.row?.status === "BOOKED" && (
              <>
                <IconButton
                  onClick={() => {
                    handleEditModelOpen(param.row);
                  }}
                >
                  <BorderColorOutlinedIcon sx={{ color: "#6d75eb" }} />
                </IconButton>
                {!param.row?.arrival_time && (
                  <Button
                    onClick={() => {
                      HandleBookingCancel(param.row);
                    }}
                    variant="outlined"
                    size="small"
                    color="error"
                  >
                    Cancel Book
                  </Button>
                )}
              </>
            )}
          </div>
        ),
      },

      {
        field: "invoice_num",
        headerName: "Invoice No",
        width: "75px",
        headerAlign: "center",
        align: "center",
        visible: true,
        renderCell: (param) => {
          return (
            <NavLink to={`/room-invoice/${param.row?.id}`}>
              <div>{param.row?.invoice_num}</div>
            </NavLink>
          );
        },
      },

      {
        field: "booking_date",
        headerName: "Booking Date",
        width: "95px",
        align: "center",
        visible: true,
        headerAlign: "center",
        renderCell: (param) => (
          <div>
            {param.row?.booking_date
              ? dayjs(new Date(param.row?.booking_date)).format(
                  "DD/MM/YYYY hh:mm"
                )
              : ""}
          </div>
        ),
      },

      {
        field: "fullname",
        headerName: "Name",
        width: "150px",
        headerAlign: "center",
        align: "center",
        visible: true,
        renderCell: (param) => {
          const name =
            param.row &&
            param.row?.guestmaster &&
            param.row?.guestmaster?.fullname
              ? param.row?.guestmaster?.fullname
              : "";
          return (
            <NavLink to={`/room-invoice/${param.row?.id}`}>
              <div>{name}</div>
            </NavLink>
          );
        },
      },

      {
        field: "Number",
        headerName: "Number",
        width: "125px",
        headerAlign: "center",
        align: "center",
        visible: true,
        renderCell: (param) => {
          const number =
            param.row &&
            param.row?.guestmaster &&
            param.row?.guestmaster?.phone_number
              ? param.row?.guestmaster?.phone_number
              : "";
          return <div>{number} </div>;
        },
      },

      {
        field: "address",
        headerName: "City",
        width: "90px",
        headerAlign: "center",
        align: "center",
        visible: true,
        renderCell: (param) => {
          const city =
            param.row &&
            param.row?.guestmaster &&
            param.row?.guestmaster?.address
              ? param.row?.guestmaster?.address
              : "";
          return <div variant="h6"> {city} </div>;
        },
      },

      {
        field: "status",
        headerName: "Status",
        width: "90px",
        headerAlign: "center",
        align: "center",
        visible: true,
        renderCell: (param) => (
          <div>
            {param.row?.status === "COMPLETED" && (
              <Button variant="outlined" size="small" color="warning">
                {param.row?.status}
              </Button>
            )}
            {param.row?.status === "BOOKED" && (
              <Button variant="outlined" size="small" color="success">
                {param.row?.status}
              </Button>
            )}
            {param.row?.status === "CANCELLED" && (
              <Button variant="outlined" size="small" color="error">
                {param.row?.status}
              </Button>
            )}
          </div>
        ),
      },

      {
        field: "remaining_amount",
        headerName: "Remaining",
        width: "70px",
        headerAlign: "center",
        align: "center",
        visible: true,
      },

      {
        field: "payment_status",
        headerName: "Payment Status",
        width: "110px",
        headerAlign: "center",
        align: "center",
        visible: true,
        renderCell: (param) => (
          <div>
            {param.row?.payment_status === "PAID" && (
              <Button variant="outlined" size="small" color="success">
                {param.row?.payment_status}
              </Button>
            )}
            {param.row?.payment_status === "PARTIAL" && (
              <Button variant="outlined" size="small" color="info">
                {param.row?.payment_status}
              </Button>
            )}

            {param.row?.payment_status === "UNPAID" && (
              <Button variant="outlined" color="error" size="small">
                {param.row?.payment_status}
              </Button>
            )}
          </div>
        ),
      },

      {
        field: "Room No",
        headerName: "Room No",
        width: "65px",
        headerAlign: "center",
        align: "center",
        visible: true,
        renderCell: (param) => {
          const roomNo =
            param.row && param.row?.roommaster && param.row?.roommaster?.room_no
              ? param.row?.roommaster?.room_no
              : "";
          return <div>{roomNo} </div>;
        },
      },

      {
        field: "person",
        headerName: "Person",
        width: "125px",
        headerAlign: "center",
        align: "center",
        visible: true,
      },

      {
        field: "check_in",
        headerName: "Check In",
        width: "85px",
        align: "center",
        visible: true,
        headerAlign: "center",
        renderCell: (param) => (
          <div>
            {param.row?.check_in
              ? dayjs(new Date(param.row?.check_in)).format("DD/MM/YYYY hh:mm")
              : ""}
          </div>
        ),
      },

      {
        field: "arrival_time",
        headerName: "Arrival Time",
        visible: true,
        width: "85px",
        headerAlign: "center",
        align: "center",
        renderCell: (param) => (
          <div>
            {param.row?.arrival_time
              ? new Intl.DateTimeFormat("en-GB", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                }).format(new Date(param.row?.arrival_time))
              : null}
          </div>
        ),
      },

      {
        field: "check_out",
        headerName: "Check Out",
        width: "85px",
        align: "center",
        visible: true,
        headerAlign: "center",
        renderCell: (param) => (
          <div>
            {param.row?.check_out
              ? dayjs(new Date(param.row?.check_out)).format("DD/MM/YYYY hh:mm")
              : ""}
          </div>
        ),
      },

      {
        field: "total_days",
        headerName: "Total Days",
        width: "125px",
        headerAlign: "center",
        align: "center",
        visible: true,
      },

      {
        field: "taxable_price",
        headerName: "Taxable Price",
        width: "125px",
        headerAlign: "center",
        align: "center",
        visible: true,
      },

      {
        field: "cgst",
        headerName: "CGST",
        width: "125px",
        headerAlign: "center",
        align: "center",
        visible: true,
      },

      {
        field: "sgst",
        headerName: "SGST",
        width: "125px",
        headerAlign: "center",
        align: "center",
        visible: true,
      },

      {
        field: "igst",
        headerName: "IGST",
        width: "125px",
        headerAlign: "center",
        align: "center",
        visible: true,
      },

      {
        field: "discount",
        headerName: "Discount",
        width: "125px",
        headerAlign: "center",
        align: "center",
        visible: true,
      },

      {
        field: "total_price",
        headerName: "Total Price",
        width: "125px",
        headerAlign: "center",
        align: "center",
        visible: true,
      },
      {
        field: "adv_payment",
        headerName: "Advance Payment",
        width: "125px",
        headerAlign: "center",
        align: "center",
        visible: true,
      },

      {
        field: "payment_type",
        headerName: "Payment Type",
        width: "125px",
        headerAlign: "center",
        align: "center",
        visible: true,
      },

      {
        field: "cancel_date",
        headerName: "Cancel Date",
        width: "85px",
        headerAlign: "center",
        align: "center",
        visible: true,
        renderCell: (param) => {
          const cancelDate = param.row?.cancel_date;
          return (
            <div>
              {cancelDate
                ? new Intl.DateTimeFormat("en-GB", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                  }).format(new Date(cancelDate))
                : "No Cancel Date"}
            </div>
          );
        },
      },
    ],
    []
  );

  // const handleRowClick = (data) => {
  //   const { item_code, item_name, price, created_at } = data;
  //   setfoodDetail({
  //     Name: item_code,
  //     Code: item_name,
  //     "Price ": price,
  //     "Created Date": new Date(created_at).toDateString(),
  //   });
  //   sethandlegridrow((previos) => !previos);
  // };

  const keys_serach = [
    "invoice_num",
    "serialNumber",
    "total_days",
    "taxable_price",
    "cgst",
    "sgst",
    "igst",
    "total_price",
    "adv_payment",
    "payment_status",
    "payment_type",
    "status",
    "person",
  ];
  return (
    <Box sx={{ padding: "20px", width: "100%" }}>
      <Stack
        direction={IsMobile ? "row" : "column"}
        justifyContent={"space-between"}
      >
        <Typography variant="h6">Reservation</Typography>
        <CollapsedBreadcrumbs data={BreadCrumbLink} />
      </Stack>

      <Grid container>
        <Grid item xs={12}>
          <Paper
            sx={{ padding: "10px", marginTop: "20px", borderRadius: "10px" }}
          >
            <Grid container>
              <Grid
                item
                lg={6}
                md={6}
                sm={6}
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: { xs: "100%", sm: "" },
                  }}
                >
                  <TextField
                    label="Search"
                    size="small"
                    onChange={(event) => {
                      handleFilter({
                        event,
                        dataWithSerialNumber: datawithSerialNumber,
                        setrecord,
                        keys_serach,
                      });
                    }}
                    sx={{
                      bgcolor: "#dae1f3",
                      borderRadius: "10px",
                      width: { xs: "100%", sm: "230px" },

                      "& fieldset": { border: "none" },
                    }}
                  />
                </Box>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={6}
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: { xs: "start", sm: "start", md: "end" },
                  alignContent: "center",
                  alignItems: "center",
                  mt: { xs: 2, sm: 2, md: 0 },
                }}
              >
                <Typography variant="h5">
                  Room No {record[0]?.roommaster?.room_no}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Box sx={{ marginTop: "30px", paddingLeft: "10px" }}>
        <Grid container spacing={2}>
          <Grid item lg={12} xs={12} mt={-3}>
            <Paper sx={{ mt: 2 }}>
              <BookingDropDownView
                data={record}
                column={columns}
                handleEditModelOpen={handleEditModelOpen}
                HandleBookingCancel={HandleBookingCancel}
                HandleBookingStatusPaid={HandleBookingStatusPaid}
                HandleRoomServiceStatusPaid={HandleRoomServiceStatusPaid}
              />
              {/* <DataTable columns={columns} rows={record} /> */}
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Paper>
        {EditModelOpen && (
          <EditBookingModel
            EditData={EditBookingData}
            open={EditModelOpen}
            handleClose={handleEditModelClose}
          />
        )}
      </Paper>
    </Box>
  );
}

export default Reservation;

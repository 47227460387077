import React, { useEffect, useMemo, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  IconButton,
  Button,
  useMediaQuery,
} from "@mui/material";
import DataTable from "../../../DataTable";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import CollapsedBreadcrumbs from "../../Include/Breadcrumb";
import AddIcon from "@mui/icons-material/Add";
import { RiFileExcel2Line } from "react-icons/ri";
import EditIcon from "@mui/icons-material/Edit";
import UploadIcon from "@mui/icons-material/Upload";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import placeholderImage from "../../../../Assets/img/room-placeholder.jpg";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterMenu from "./FilterMenu";
import EditRoadOutlinedIcon from "@mui/icons-material/EditRoadOutlined";
import BasicTabs from "./Details/Room_User_Detail";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import TocOutlinedIcon from "@mui/icons-material/TocOutlined";
import RoomCard from "../../Include/RoomsCard";
import SwipableDrawer from "../../Include/SwipableDrawer";
import handleExcelConversion from "../Reports/ExcellsFormat";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Cookies from "js-cookie";
import { useSelector, useDispatch } from "react-redux";
import { GetRoomApi } from "../../../../store/Slice/RoomsSlice/RoomSlice";
import { useFormik } from "formik";
import dayjs from "dayjs";
import handleFilter from "../../../Searching";
import { bookingAvailability_Schema } from "../../../../schema/Schema";
import { CustomSnackBar, DataUploadForm } from "../../../../Model/Model";

const BreadCrumbLink = [
  {
    id: 1,
    name: "Home",
    link: "/",
  },
  {
    id: 1,
    name: "Rooms",
    link: "/rooms",
  },
];

const ViewAllRooms = () => {
  const Token = Cookies.get("Token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { RoomData } = useSelector((state) => state.RoomStore);
  const [handlegridrow, sethandlegridrow] = useState(null);
  const [filterMenuOPen, setfilterMenuOPen] = useState(null);
  const [filterid, setfilterid] = useState(3);
  const [AllRoomRecord, setAllRoomRecord] = useState([]);
  const IsMobile = useMediaQuery("(min-width:900px)");

  const checkIn = new Date();
  const checkOut = new Date(dayjs(new Date()).add(1, "day"));
  // checkOut.setDate(checkOut.getDate() + 7);
  const [filterDate, setfilterDate] = useState({
    check_in: checkIn,
    check_out: checkOut,
  });
  const [take, setTake] = useState(8);

  useEffect(() => {
    const FetchData = async () => {
      try {
        const data = filterDate;
        await dispatch(GetRoomApi({ Token, data }));
      } catch (error) {
        return error;
      }
    };
    FetchData();
  }, [dispatch, Token]);

  const handleScroll = () => {
    try {
      if (
        window.innerHeight + window.document.documentElement.scrollHeight + 1 >=
        window.document.documentElement.scrollTop
      ) {
        setTake((prev) => +prev + 8);
      }
    } catch (error) {
      console.log("error", error);
      return error;
    }
  };

  useEffect(() => {
    const FetchData = async () => {
      try {
        const data = filterDate;
        const params = { take: take };
        await dispatch(GetRoomApi({ Token, data, params }));
      } catch (error) {
        return error;
      }
    };
    FetchData();
  }, [take]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  const datawithSerialNumber = useMemo(() => {
    if (RoomData && RoomData.length > 0) {
      return RoomData.map((item, index) => ({
        ...item,
        serialNumber: index + 1,
      }));
    } else {
      return [];
    }
  }, [RoomData]);

  useEffect(() => {
    setAllRoomRecord(datawithSerialNumber);
  }, [datawithSerialNumber]);

  // %%%%%%%%%%%%%%% AVIALABLE ROOM API HANDLE DATA %%%%%%%%%%%%%%

  const columnsRooms = useMemo(
    () => [
      {
        field: "serialNumber",
        headerName: "S.R",
        width: 0,
        headerAlign: "center",
        align: "center",
        visible: true,
      },
      {
        field: "images",
        visible: true,
        headerName: "Images",
        align: "center",
        headerAlign: "center",
        width: 130,
        renderCell: (param) => {
          const image =
            param.row && param.row?.images && param.row?.images[0]
              ? `${process.env.REACT_APP_API_URL}/uploads/rooms/${param.row?.images[0]}`
              : placeholderImage;

          return (
            <LazyLoadImage
              effect="blur"
              onClick={() => handleRowClick(param.row)}
              src={image}
              style={{
                height: "40px",
                width: "70px",
                borderRadius: "10px",
                cursor: "pointer",
                marginBottom: "10px",
                marginTop: "5px",
              }}
            />
          );
        },
      },
      {
        field: "room_no",
        headerName: "Room No",
        width: 120,
        headerAlign: "center",
        align: "center",
        visible: true,
        renderCell: (param) => (
          <div variant="h6" onClick={handleRowClick}>
            {param.row.room_no}
          </div>
        ),
      },

      {
        field: "floor_no",
        headerName: "Floor No",
        width: 150,
        headerAlign: "center",
        align: "center",
        visible: true,
      },
      {
        field: "bed_type",
        headerName: "Bed Type",
        width: 150,
        headerAlign: "center",
        align: "center",
        visible: true,
      },

      {
        field: "perdayprice",
        headerName: "Price",
        width: 120,
        headerAlign: "center",
        align: "center",
        visible: true,
      },

      {
        field: "status",
        headerName: "Status",
        width: 200,
        headerAlign: "center",
        align: "center",
        visible: true,
        renderCell: (param) => (
          <div>
            {param.row.bookingStatus === "AVAILABLE" && (
              <Button variant="outlined" size="small" color="info">
                {" "}
                AVAILABLE
              </Button>
            )}
            {param.row.bookingStatus === "UNAVAILABLE" && (
              <Button variant="outlined" color="error" size="small">
                UNAVAILABLE
              </Button>
            )}
          </div>
        ),
      },
      {
        field: "Action",
        headerName: "Action",
        width: 200,
        headerAlign: "center",
        align: "center",
        visible: true,
        renderCell: (param) => (
          <div>
            <NavLink to={`/edit-rooms/${param.row.id}`}>
              <IconButton>
                <BorderColorOutlinedIcon sx={{ color: "#6d75eb" }} />
              </IconButton>
            </NavLink>
            {/* <IconButton>
              <DeleteOutlinedIcon sx={{ color: "#ff5757" }} />
            </IconButton> */}
          </div>
        ),
      },
    ],
    []
  );

  const [DataTableColumn, setDataTableColumn] = useState([]);
  const [DataTablerow, setDataTablerow] = useState([]);

  useEffect(() => {
    if (filterid === 1) {
      // AvailabelRoom()
      const AvialableData = AllRoomRecord.filter(
        (room) => room.bookingStatus === "AVAILABLE"
      );
      setDataTableColumn(columnsRooms);
      setDataTablerow(AvialableData);
    } else if (filterid === 2) {
      const AvialableData = AllRoomRecord.filter(
        (room) => room.bookingStatus === "UNAVAILABLE"
      );
      setDataTableColumn(columnsRooms);
      setDataTablerow(AvialableData);
    } else {
      setDataTableColumn(columnsRooms);
      setDataTablerow(AllRoomRecord);
    }
  }, [filterid, AllRoomRecord, columnsRooms]);

  const handleToggleColumn = (field) => {
    // console.log("DataTableColumn", field);
    setDataTableColumn((prevColumns) =>
      prevColumns.map((col) =>
        col.field === field ? { ...col, visible: !col.visible } : col
      )
    );
  };

  const [ExcelCovertData, setExcelCovertData] = useState([]);
  useEffect(() => {
    if (DataTablerow) {
      const excelldata = DataTablerow.map(
        ({
          id,
          images,
          user_id,
          created_at,
          updated_at,
          serialNumber,
          ...rest
        }) => {
          return {
            serialNumber,
            ...rest,
          };
        }
      );
      setExcelCovertData(excelldata);
    }
  }, [DataTablerow]);

  // const handleRowClick = () =>  sethandlegridrow((previous) => !previous);

  // Filter
  const FilterDropMenu = [
    { id: 1, name: "Available" },
    { id: 2, name: "Unavailable" },
    { id: 3, name: "All Rooms" },
  ];

  const speedDialActions = [
    {
      icon: <TocOutlinedIcon />,
      name: "Table View",
      action: () => HandleViwerTab(),
    },
    {
      icon: <GridViewOutlinedIcon />,
      name: "Card View",
      action: () => HandleViwerCard(),
    },
    {
      icon: <EditRoadOutlinedIcon />,
      name: "Table Edit",
      action: () => handleDrawerOpen(),
    },
    {
      icon: <FilterListIcon />,
      name: "Filters",
      action: (e) => handleFilterMenuOpen(e),
    },
    {
      icon: <AddIcon />,
      name: "Room Add",
      action: () => navigate("/add-rooms"),
    },
    {
      icon: <RiFileExcel2Line fontSize={"25px"} />,
      name: "Convert To Excel",
      action: () => handleExcelConversion(ExcelCovertData),
    },
    {
      icon: <UploadIcon />,
      name: "Bulk Upload",
      action: () => handlUploadModelOpen(),
    },
  ];

  // const [speedDialOpen, setSpeedDialOpen] = useState(false);
  // const handlespeedDialOpen = () => setSpeedDialOpen(true);
  // const handlespeedDialClose = () => setSpeedDialOpen(false);

  const handleFilterMenuOpen = (event) =>
    setfilterMenuOPen(event.currentTarget);
  const handleFilterMenuClose = () => setfilterMenuOPen(null);

  const [DataTableDrawer, setDataTableDrawer] = useState(false);
  const handleDrawerOpen = () => setDataTableDrawer(true);
  const handleDrawerClose = () => setDataTableDrawer(false);

  const [CardTabView, setCardTabView] = useState(true);
  const HandleViwerCard = () => setCardTabView(true);
  const HandleViwerTab = () => setCardTabView(false);
  const [toastOpen, setToastOpen] = useState(false);

  const initialValues = {
    check_in: checkIn,
    check_out: checkOut,
  };

  const { errors, values, handleBlur, handleSubmit, touched, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema: bookingAvailability_Schema,
      onSubmit: async (values) => {
        try {
          const data = {
            check_in: values.check_in,
            check_out: values.check_out,
          };
          setfilterDate(data);
          await dispatch(GetRoomApi({ Token, data }));
          setToastOpen(true);
          console.log("values", data);
        } catch (error) {
          console.log("erroro", error);

          return error;
        }
      },
    });

  // const [UserData, setUserData] = useState({});
  const [Roomdata, setRoomdata] = useState({});

  const [otherdispalynone, setotherdispalynone] = useState(false);
  const TabClose = () => {
    setotherdispalynone((previous) => !previous);
    sethandlegridrow((previos) => !previos);
  };

  const handleRowClick = (userdata) => {
    const {
      room_no,
      bed_type,
      bookingStatus,
      description,
      facilities,
      floor_no,
      images,
      perdayprice,
    } = userdata;
    // if (data.guest) {
    //   const { fullname, email, phone_number, address, document, created_at } =
    //     data.guest;

    //   setUserData({
    //     Name: fullname,
    //     Email: email,
    //     "Mobile No": phone_number,
    //     Address: address,
    //     Documents: document,
    //     "Created Date": new Date(created_at).toDateString(),
    //   });

    //   setRoomdata({
    //     "Room No": room_no,
    //     "Bed Type": bed_type,
    //     status: bookingStatus,
    //     Description: description,
    //     Facilities: facilities,
    //     "Floor No": floor_no,
    //     images: images,
    //     "Per Day Rent": perdayprice,
    //   });
    // } else {
    //   const {
    //     room_no,
    //     bed_type,
    //     bookingStatus,
    //     description,
    //     facilities,
    //     floor_no,
    //     images,
    //     perdayprice,
    //   } = data;
    // }

    setRoomdata({
      "Room No": room_no,
      "Bed Type": bed_type,
      status: bookingStatus,
      Description: description,
      Facilities: facilities,
      "Floor No": floor_no,
      images: images,
      "Per Day Rent": perdayprice,
    });
    sethandlegridrow((previos) => !previos);
    setotherdispalynone((previous) => !previous);
  };

  const [dataUploadModelOpen, setdataUploadModelOpen] = useState(false);
  const handlUploadModelOpen = () => setdataUploadModelOpen(true);
  const handlUploadModelClose = () => setdataUploadModelOpen(false);

  const keys_serach = [
    "serialNumber",
    "room_no",
    "floor_no",
    "bed_type",
    "perdayprice",
    "facilities",
  ];

  return (
    <>
      <Box sx={{ padding: "20px", width: "100%" }}>
        <Stack
          direction={IsMobile ? "row" : "column"}
          justifyContent={"space-between"}
        >
          <Typography variant="h6">Rooms</Typography>
          <CollapsedBreadcrumbs data={BreadCrumbLink} />
        </Stack>
        <Grid container>
          <Grid item xs={12}>
            <Paper
              sx={{ padding: "10px", marginTop: "20px", borderRadius: "10px" }}
            >
              <Grid container>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <Box
                    sx={{
                      width: { xs: "100%", sm: "" },
                      mt: 3,
                    }}
                  >
                    <TextField
                      size="small"
                      placeholder="Search"
                      onChange={(event) => {
                        handleFilter({
                          event,
                          dataWithSerialNumber: datawithSerialNumber,
                          setrecord: setAllRoomRecord,
                          keys_serach,
                        });
                      }}
                      sx={{
                        textAlign: "center",
                        height: "50px",
                        bgcolor: "#dae1f3",
                        borderRadius: "5px",
                        width: { xs: "100%", sm: "230px" },
                        "& fieldset": { border: "none" },
                        "& input::placeholder": {
                          textAlign: "left",
                          display: "block",
                          width: "100%",
                        },
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item lg={9} md={9} sm={12} xs={12} mt={3}>
                  <Box
                    sx={{
                      width: { xs: "100%", sm: "100%" },
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <form
                      onSubmit={handleSubmit}
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Stack
                        direction={IsMobile ? "row" : "column"}
                        gap={3}
                        width={"100%"}
                      >
                        <Stack direction={"column"} width={"100%"}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                              label="Check In"
                              format="DD/MM/YYYY hh:mm A"
                              name="check_in"
                              sx={{ flex: 1 }}
                              value={
                                values.check_in ? dayjs(values.check_in) : null
                              }
                              onChange={(value) => {
                                const formattedDate = value
                                  ? dayjs(value).toISOString()
                                  : null;
                                setFieldValue("check_in", formattedDate);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} sx={{ flex: 1 }} />
                              )}
                              onBlur={handleBlur}
                            />
                            {touched.check_in && errors.check_in && (
                              <Typography
                                variant="caption"
                                color={"error"}
                                style={{
                                  marginTop: "3px",
                                  marginLeft: "14px",
                                  marginRight: "14px",
                                  marginBottom: "0px",
                                }}
                              >
                                {errors.check_in}
                              </Typography>
                            )}
                          </LocalizationProvider>
                        </Stack>
                        <Stack direction={"column"} width={"100%"}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                              label="Check Out"
                              name="check_out"
                              format="DD/MM/YYYY hh:mm A"
                              value={
                                values.check_out
                                  ? dayjs(values.check_out)
                                  : null
                              }
                              onChange={(value) => {
                                const formattedDate = value
                                  ? dayjs(value).toISOString()
                                  : null;
                                setFieldValue("check_out", formattedDate);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} sx={{ flex: 1 }} />
                              )}
                              onBlur={handleBlur}
                              sx={{ flex: 1 }}
                            />
                            {touched.check_out && errors.check_out && (
                              <Typography
                                variant="caption"
                                color={"error"}
                                style={{
                                  marginTop: "3px",
                                  marginLeft: "14px",
                                  marginRight: "14px",
                                  marginBottom: "0px",
                                }}
                              >
                                {errors.check_out}
                              </Typography>
                            )}
                          </LocalizationProvider>
                        </Stack>
                        <Stack direction={"column"} width={"100%"}>
                          <Button
                            variant="contained"
                            size="small"
                            color="secondary"
                            type="submit"
                            sx={{ height: "35px", mt: 1, mr: 2, ml: 2 }}
                          >
                            Search
                          </Button>
                        </Stack>
                      </Stack>
                    </form>
                  </Box>
                </Grid>

                <Grid item md={12} sm={12} lg={12} xs={12}>
                  <Box
                    sx={{
                      //   float: { xs: "left", sm: "right" },
                      //   display: "flex",
                      //   flexWrap: "wrap",
                      //   gap: { xs: 5, sm: 3 },

                      mt: 1,
                    }}
                  >
                    {/* <Avatar sx={{ background: "#F5584D" }}>
                      <Tooltip title="Table View">
                        <IconButton onClick={HandleViwerTab}>
                          <TocOutlinedIcon sx={{ color: "#fff" }} />
                        </IconButton>
                      </Tooltip>
                    </Avatar>

                    <Avatar sx={{ background: "#F5584D" }}>
                      <Tooltip title="Card View">
                        <IconButton onClick={HandleViwerCard}>
                          <GridViewOutlinedIcon sx={{ color: "#fff" }} />
                        </IconButton>
                      </Tooltip>
                    </Avatar>

                    <Avatar sx={{ background: "#6970E2" }}>
                      <Tooltip title="Table Edit">
                        <IconButton onClick={handleDrawerOpen}>
                          <EditRoadOutlinedIcon sx={{ color: "#fff" }} />
                        </IconButton>
                      </Tooltip>
                    </Avatar>

                    <Avatar sx={{ background: "#6d75eb" }}>
                      <Tooltip title="Filters">
                        <IconButton
                          onClick={(event) => {
                            handleFilterMenuOpen(event);
                          }}
                        >
                          <FilterListIcon sx={{ color: "#fff" }} />
                        </IconButton>
                      </Tooltip>
                    </Avatar>

                    <Avatar sx={{ background: "#EA82A6" }}>
                      <Tooltip title="Room Add">
                        <NavLink to={"/add-rooms"}>
                          <IconButton>
                            <AddIcon sx={{ color: "#fff" }} />
                          </IconButton>
                        </NavLink>
                      </Tooltip>
                    </Avatar>

                    <Avatar
                      src={ExcellIcon}
                      onClick={() => {
                        handleExcelConversion(ExcelCovertData);
                      }}
                    ></Avatar>
                    <Avatar sx={{ background: "#58c2ff" }}>
                      <Tooltip title="Bulk Upload">
                        <IconButton onClick={handlUploadModelOpen}>
                          <UploadIcon sx={{ color: "#fff" }} />
                        </IconButton>
                      </Tooltip>
                    </Avatar> */}

                    <SpeedDial
                      ariaLabel="SpeedDial Menu"
                      sx={{
                        "& .MuiFab-root": {
                          width: 45,
                          height: 45,
                          minWidth: 45,
                        },
                        //   // "& .MuiSpeedDialIcon-root": {
                        //   //   fontSize: 25,
                        //   // },
                      }}
                      icon={<SpeedDialIcon openIcon={<EditIcon />} />}
                      direction={IsMobile ? "right" : "down"}
                      // direction={"right"},
                      // open={true}
                    >
                      {speedDialActions.map((action) => (
                        <SpeedDialAction
                          key={action.name}
                          icon={action.icon}
                          tooltipTitle={action.name}
                          onClick={action.action}
                        />
                      ))}
                    </SpeedDial>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Box sx={{ marginTop: "20px" }}>
          <Grid
            container
            spacing={2}
            alignContent={"stretch"}
            alignItems={"stretch"}
          >
            {otherdispalynone && (
              <Grid sm={12}>
                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
                >
                  <Button variant="contained" color="error" onClick={TabClose}>
                    Close
                  </Button>
                </Box>
              </Grid>
            )}

            <Grid item lg={handlegridrow ? 6 : 12} sm={12} mt={-3} xs={12}>
              {CardTabView ? (
                <Box sx={{ marginTop: "25px" }}>
                  <RoomCard
                    data={DataTablerow}
                    sethandlegridrow={sethandlegridrow}
                    handlegridrow={handlegridrow}
                    filterDate={filterDate}
                    setfilterDate={setfilterDate}
                    setRoomdata={setRoomdata}
                    setotherdispalynone={setotherdispalynone}
                    otherdispalynone={otherdispalynone}
                  />
                </Box>
              ) : (
                <Paper sx={{ marginTop: "22px" }}>
                  <DataTable columns={DataTableColumn} rows={DataTablerow} />
                </Paper>
              )}
            </Grid>

            <Grid item lg={handlegridrow ? 6 : 4} xs={handlegridrow || 12}>
              {handlegridrow && (
                <Paper
                  sx={{
                    borderRadius: "10px",
                    marginTop: "12px",
                    height: "38rem",
                  }}
                >
                  <BasicTabs
                    MainImage={placeholderImage}
                    data={Roomdata}
                    tab={"room"}
                    sethandlegridrow={sethandlegridrow}
                  />
                </Paper>
              )}
            </Grid>
          </Grid>
        </Box>

        {filterMenuOPen && (
          <FilterMenu
            open={Boolean(filterMenuOPen)}
            anchorEl={filterMenuOPen}
            handleFilterMenuClose={handleFilterMenuClose}
            FilterDropMenu={FilterDropMenu}
            setfilterid={setfilterid}
          />
        )}

        {dataUploadModelOpen && (
          <DataUploadForm
            open={dataUploadModelOpen}
            handleClose={handlUploadModelClose}
            uploadType={"ROOM"}
            demoFile={"Room_Demo.xlsx"}
          />
        )}

        <SwipableDrawer
          open={DataTableDrawer}
          close={handleDrawerClose}
          columns={DataTableColumn}
          handleToggleColumn={handleToggleColumn}
        />

        <CustomSnackBar
          open={toastOpen}
          setOpen={setToastOpen}
          message={"Search has initiated"}
        />
      </Box>
    </>
  );
};
export default ViewAllRooms;

import React, { useEffect, useMemo, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FACILITIES, ROOM_ICONS } from "../Data/Data";
import {
  Box,
  Paper,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Chip,
} from "@mui/material";
const RoomDetail = ({
  MainImage,
  data,
}) => {
  const handleSubImageClick = (ImageUrl) => {
    setFullImage(`${process.env.REACT_APP_API_URL}/uploads/rooms/${ImageUrl}`);
  };
  // const handleclose = () => sethandlegridrow((previos) => !previos);
  const [FullImage, setFullImage] = useState(null);
  console.log(data);
  useEffect(() => {
    if (data && data?.images && data?.images[0]) {
      const image = data?.images[0];
      setFullImage(`${process.env.REACT_APP_API_URL}/uploads/rooms/${image}`);
    } else {
      setFullImage(MainImage);
    }
  }, [data]);

  const arrayFacilities = useMemo(() => {
    if (!data?.Facilities) return [];

    return FACILITIES.filter((facility) =>
      data.Facilities.includes(facility.id.toString())
    );
  }, [data]);

  return (
    <Box
      sx={{
        padding: "10px",
        height: "100%",
        overflow: "auto",
        marginTop: "-14px",
        "::-webkit-scrollbar": {
          width: "0px",
        },
      }}
    >
      <Box
        sx={{ width: "100%" }}
        display={"flex"}
        justifyContent={"space-evenly"}
      >
        <Box
          sx={{
            height: "170px",
            width: "170px",
            borderRadius: "16px",
            boxShadow: "0px 15px 45px rgba(9, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "row",
            ml: { xs: 0, sm: 0 },
            position: "relative",
          }}
        >
          <LazyLoadImage
            src={FullImage}
            effect="blur"
            height="100%"
            width="100%"
            style={{
              height: "100%",
              width: "100%",
              borderRadius: "16px",
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "15px",
          overflow: "auto",
          "::-webkit-scrollbar": {
            width: "0px",
          },
        }}
      >
        {data &&
          data?.images &&
          data?.images.map((item, index) => {
            const image = item
              ? `${process.env.REACT_APP_API_URL}/uploads/rooms/${item}`
              : FullImage;
            return (
              <LazyLoadImage
                key={index}
                effect="blur"
                src={image}
                onClick={() => {
                  handleSubImageClick(item);
                }}
                style={{
                  height: "70px",
                  width: "70px",
                  borderRadius: "16px",
                  cursor: "pointer",
                  boxShadow: "0px 120px 49px rgba(12, 0, 0, 0.1)",
                }}
              />
            );
          })}
      </Box>
      <List sx={{ marginTop: "20px" }}>
        {data &&
          Object.entries(data).map(([key, value], index) =>
            key === "images" ? null : (
              <Paper
                key={index}
                sx={{
                  padding: "10px",
                  marginBottom: "10px",
                  boxShadow: "0px 20px 09px rgba(12, 0, 0, 0.1)",
                }}
              >
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>{ROOM_ICONS[index]}</ListItemIcon>
                    <ListItemText
                      primary={key ? key : ""}
                      secondary={
                        Array.isArray(value) ? (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}
                          >
                            {arrayFacilities.map((item, i) => (
                              <Chip
                                icon={item.icon}
                                label={item.label}
                                variant="outlined"
                                color="info"
                                size="small"
                                sx={{ mr: 1, mb: 1, mt: 1 }}
                              />
                            ))}
                          </Box>
                        ) : value ? (
                          value
                        ) : (
                          ""
                        )
                      }
                    />
                  </ListItemButton>
                </ListItem>
              </Paper>
            )
          )}
      </List>
    </Box>
  );
};
export default RoomDetail;

import React from "react";
import {
  Box,
  Divider,
  Drawer,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Paper,
} from "@mui/material";
import { pink } from "@mui/material/colors";
const Reportsfilter = ({
  open,
  close,
  columns,
  handleToggleReport,
  selectedReport,
}) => {
  return (
    <Drawer anchor="right" open={open} onClose={close}>
      <Box p={2} width={"350px"} textAlign={"center"} role="presentation">
        <Typography variant="h6">Reports</Typography>
        <Divider />

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            flexWrap: "wrap",
            mt: 4,
          }}
        >
          {columns.map((col) => (
            <Paper
              sx={{
                p: 1,
                width: "100%",
                boxShadow: "0px 10px 09px rgba(12, 0, 0, 0.1)",
                transition: "0.5s",
                "&:hover": {
                  transform: "translateY(-10px)",
                },
              }}
            >
              <FormControlLabel
                key={col.id}
                control={
                  <Checkbox
                    checked={selectedReport === col.id}
                    onChange={() => handleToggleReport(col.id)}
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                    }}
                  />
                }
                label={col.name}
              />
            </Paper>
          ))}
        </Box>

        <Button
          onClick={close}
          sx={{ marginTop: 5 }}
          variant="outlined"
          color="info"
        >
          Close
        </Button>
      </Box>
    </Drawer>
  );
};
export default Reportsfilter;

import React, { useState } from "react";
import {
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  Table,
  Paper,
  TableRow,
  IconButton,
  Collapse,
  Typography,
  Button,
  Tooltip,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EventBusyTwoToneIcon from "@mui/icons-material/EventBusyTwoTone";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import CreditScoreTwoToneIcon from "@mui/icons-material/CreditScoreTwoTone";
import {
  DeleteRoomServiceApi,
  GetRoomServiceApi,
} from "../../../../store/Slice/RoomServiceSlice/RoomServiceSlice";
import { MySwal } from "../../../../Model/Alert/SuccessAlert";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";

const BookingDropDownView = ({
  data,
  handleEditModelOpen,
  HandleBookingCancel,
  column,
  HandleBookingStatusPaid,
  HandleRoomServiceStatusPaid,
  handleRowClick,
  setUserData,
}) => {
  // const TableHeader = [
  //   "#",
  //   "SR.No",
  //   "Action",
  //   "Invoice No.",
  //   "Booking Date",
  //   "Name",
  //   "Mobile",
  //   "City",
  //   "Status",
  //   "Remaining",
  //   "Payment Status",
  //   "Room No.",
  //   "Person",
  //   "Check In",
  //   "Arrival",
  //   "Check Out",
  //   "Days",
  //   "Price",
  //   "CGST",
  //   "SGST",
  //   "IGST",
  //   "Discount",
  //   "Total",
  //   "Advance",
  //   "Payment Type",
  //   "Cancel",
  // ];

  const Token = Cookies.get("Token");
  const dispatch = useDispatch();

  const [submenuopen, setsubmenuopen] = useState(null);

  const handlesubmenuOPen = (id) => {
    setsubmenuopen((previouse) => (previouse === id ? null : id));
  };

  const visibleColumn = column.filter((col) => col.visible);

  const handleDelete = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#dc3545",
      cancelButtonText: "No, cancel!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        console.log("Btn Was Clicked2", id);
        await dispatch(DeleteRoomServiceApi({ Token, id }));
        await dispatch(GetRoomServiceApi({ Token }));
      } else if (result.dismiss === MySwal.DismissReason.cancel) {
        console.log("Btn Was Clicked3", id);
        MySwal.fire({
          title: "Cancelled",
          text: "Your data is safe.",
          icon: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "#dc3545",
        });
      }
    });
  };

  return (
    <>
      <TableContainer
        component={Paper}
        style={{ maxWidth: "100%", overflowX: "auto" }}
      >
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {column &&
                column.length > 0 &&
                column.map((item, index) => (
                  <TableCell
                    component="th"
                    scope="row"
                    key={index}
                    sx={{
                      backgroundColor: "#0288d1",
                      color: "white",
                      border: "10px solid",
                      borderRadius: 6,
                      py: 1,
                      // px: 10,
                      textAlign: "center",
                    }}
                    // width={"200px"}
                  >
                    <Typography
                      variant="subtitle2"
                      fontSize={"15px"}
                      sx={{ textAlign: "center", width: `${item?.width}` }}
                    >
                      {item?.headerName}
                    </Typography>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.length > 0 &&
              data.map((item, index) => (
                <React.Fragment key={item?.id}>
                  <TableRow>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handlesubmenuOPen(item?.id)}
                      >
                        {submenuopen ? (
                          <KeyboardArrowUp />
                        ) : (
                          <KeyboardArrowDown />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">
                        {item?.serialNumber}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <div>
                        {/* {item?.status === "BOOKED" && ( */}
                        <Tooltip title={"Edit Booking"} placement="top" arrow>
                          <IconButton
                            onClick={() => {
                              handleEditModelOpen(item);
                            }}
                            disabled={item?.status === "BOOKED" ? false : true}
                          >
                            <BorderColorOutlinedIcon
                              sx={{
                                color: `${
                                  item?.status === "BOOKED" ? "#6d75eb" : "grey"
                                }`,
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        {/* )} */}
                        {/* {!item?.arrival_time && item?.status === "BOOKED" && ( */}
                        <Tooltip title={"Cancel Booking"} placement="top" arrow>
                          <IconButton
                            onClick={() => {
                              HandleBookingCancel(item);
                            }}
                            disabled={
                              !item?.arrival_time && item?.status === "BOOKED"
                                ? false
                                : true
                            }
                          >
                            <EventBusyTwoToneIcon
                              sx={{
                                color: `${
                                  !item?.arrival_time &&
                                  item?.status === "BOOKED"
                                    ? "#ff5757"
                                    : "grey"
                                }`,
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        {/* )} */}

                        {/* {item?.payment_status !== "PAID" && ( */}
                        <Tooltip title={"Mark as paid"} placement="top" arrow>
                          <IconButton
                            onClick={() => {
                              HandleBookingStatusPaid(item);
                            }}
                            disabled={
                              item?.payment_status !== "PAID" &&
                              item?.status !== "CANCELLED"
                                ? false
                                : true
                            }
                          >
                            <CreditScoreTwoToneIcon
                              sx={{
                                color: `${
                                  item?.payment_status !== "PAID" &&
                                  item?.status !== "CANCELLED"
                                    ? "#34a853"
                                    : "grey"
                                }`,
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        {/* )} */}
                        {/* <Button
                          onClick={() => {
                              HandleBookingCancel(item);
                            }}
                            variant="outlined"
                            size="small"
                            color="error"
                          >
                            Cancel Book
                          </Button> */}
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <NavLink
                        to={`/both-invoice/${item?.id}`}
                        // style={{ textDecoration: "none", color: "#333" }}
                      >
                        <Typography variant="subtitle">
                          {item?.invoice_num}
                        </Typography>
                      </NavLink>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">
                        {new Intl.DateTimeFormat("en-GB", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                        }).format(new Date(item?.booking_date))}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                      onClick={() => handleRowClick(item)}
                    >
                      {/* <NavLink to={`/both-invoice/${item?.id}`}> */}
                      <Typography
                        variant="subtitle"
                        sx={{
                          color: "#027cff",
                          "&:hover": {
                          color: "#0059b8",
                            textDecoration: "underline",
                            textDecorationColor: "#027cff",
                            cursor: "pointer",
                          },
                        }}
                      >
                        {item &&
                        item?.guestmaster &&
                        item?.guestmaster?.fullname
                          ? item?.guestmaster?.fullname
                          : ""}
                      </Typography>
                      {/* </NavLink> */}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">
                        {item &&
                        item?.guestmaster &&
                        item?.guestmaster?.phone_number
                          ? item?.guestmaster?.phone_number
                          : ""}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">
                        {item && item?.guestmaster && item?.guestmaster?.address
                          ? item?.guestmaster?.address
                          : ""}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">
                        {item?.status === "BOOKED" && (
                          <Button
                            variant="outlined"
                            size="small"
                            color="success"
                          >
                            {item?.status}
                          </Button>
                        )}
                        {item?.status === "COMPLETED" && (
                          <Button
                            variant="outlined"
                            size="small"
                            color="warning"
                          >
                            {item?.status}
                          </Button>
                        )}
                        {item?.status === "CANCELLED" && (
                          <Button variant="outlined" size="small" color="error">
                            {item?.status}
                          </Button>
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">
                        {item?.remaining_amount}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">
                        {item?.payment_status === "PAID" && (
                          <Button
                            variant="outlined"
                            size="small"
                            color="success"
                          >
                            {item?.payment_status}
                          </Button>
                        )}
                        {item?.payment_status === "PARTIAL" && (
                          <Button variant="outlined" size="small" color="info">
                            {item.payment_status}
                          </Button>
                        )}

                        {item?.payment_status === "UNPAID" && (
                          <Button variant="outlined" color="error" size="small">
                            {item?.payment_status}
                          </Button>
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">
                        {item && item?.roommaster && item?.roommaster?.room_no
                          ? item?.roommaster?.room_no
                          : ""}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">{item?.person}</Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">
                        {new Intl.DateTimeFormat("en-GB", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                        }).format(new Date(item?.check_in))}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">
                        {item?.arrival_time
                          ? new Intl.DateTimeFormat("en-GB", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                              hour: "2-digit",
                              minute: "2-digit",
                            }).format(new Date(item?.arrival_time))
                          : ""}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">
                        {new Intl.DateTimeFormat("en-GB", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                        }).format(new Date(item?.check_out))}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">
                        {item?.total_days}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">
                        {item?.taxable_price}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">{item?.cgst}</Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">{item?.sgst}</Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">{item?.igst}</Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">
                        {item?.discount}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">
                        {item?.total_price}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">
                        {item?.adv_payment}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">
                        {item?.payment_type}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">
                        {item?.cancel_date
                          ? new Intl.DateTimeFormat("en-GB", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                              hour: "2-digit",
                              minute: "2-digit",
                            }).format(new Date(item?.cancel_date))
                          : ""}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={9}
                    >
                      <Collapse
                        in={submenuopen === item?.id}
                        timeout={"auto"}
                        unmountOnExit
                      >
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                sx={{
                                  py: 1,
                                  fontSize: "20px",
                                }}
                                colSpan={column?.length + 1}
                              >
                                Room Services
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  backgroundColor: "#9C27B0",
                                  color: "white",
                                  border: "10px solid",
                                  borderRadius: 6,
                                  textAlign: "center",
                                  py: 1,
                                }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  fontSize={"15px"}
                                  sx={{
                                    textAlign: "center",
                                  }}
                                >
                                  Sr. No.
                                </Typography>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  backgroundColor: "#9C27B0",
                                  color: "white",
                                  border: "10px solid",
                                  borderRadius: 6,
                                  textAlign: "center",
                                  py: 1,
                                }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  fontSize={"15px"}
                                  sx={{
                                    textAlign: "center",
                                    width: `${item?.width}`,
                                  }}
                                >
                                  Action
                                </Typography>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  backgroundColor: "#9C27B0",
                                  color: "white",
                                  border: "10px solid",
                                  borderRadius: 6,
                                  textAlign: "center",
                                  py: 1,
                                }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  fontSize={"15px"}
                                  sx={{
                                    textAlign: "center",
                                    width: `${item?.width}`,
                                  }}
                                >
                                  Invoice No.
                                </Typography>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  backgroundColor: "#9C27B0",
                                  color: "white",
                                  border: "10px solid",
                                  borderRadius: 6,
                                  textAlign: "center",
                                  py: 1,
                                }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  fontSize={"15px"}
                                  sx={{
                                    textAlign: "center",
                                    width: `${item?.width}`,
                                  }}
                                >
                                  Status
                                </Typography>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  backgroundColor: "#9C27B0",
                                  color: "white",
                                  border: "10px solid",
                                  borderRadius: 6,
                                  textAlign: "center",
                                  py: 1,
                                }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  fontSize={"15px"}
                                  sx={{
                                    textAlign: "center",
                                    width: `${item?.width}`,
                                  }}
                                >
                                  Total
                                </Typography>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  backgroundColor: "#9C27B0",
                                  color: "white",
                                  border: "10px solid",
                                  borderRadius: 6,
                                  textAlign: "center",
                                  py: 1,
                                }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  fontSize={"15px"}
                                  sx={{
                                    textAlign: "center",
                                    width: `${item?.width}`,
                                  }}
                                >
                                  Sub Total
                                </Typography>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  backgroundColor: "#9C27B0",
                                  color: "white",
                                  border: "10px solid",
                                  borderRadius: 6,
                                  textAlign: "center",
                                  py: 1,
                                }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  fontSize={"15px"}
                                  sx={{
                                    textAlign: "center",
                                    width: `${item?.width}`,
                                  }}
                                >
                                  CGST
                                </Typography>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  backgroundColor: "#9C27B0",
                                  color: "white",
                                  border: "10px solid",
                                  borderRadius: 6,
                                  textAlign: "center",
                                  py: 1,
                                }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  fontSize={"15px"}
                                  sx={{
                                    textAlign: "center",
                                    width: `${item?.width}`,
                                  }}
                                >
                                  SGST
                                </Typography>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  backgroundColor: "#9C27B0",
                                  color: "white",
                                  border: "10px solid",
                                  borderRadius: 6,
                                  textAlign: "center",
                                  py: 1,
                                }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  fontSize={"15px"}
                                  sx={{
                                    textAlign: "center",
                                    width: `${item?.width}`,
                                  }}
                                >
                                  IGST
                                </Typography>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  backgroundColor: "#9C27B0",
                                  color: "white",
                                  border: "10px solid",
                                  borderRadius: 6,
                                  textAlign: "center",
                                  py: 1,
                                }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  fontSize={"15px"}
                                  sx={{
                                    textAlign: "center",
                                    width: `${item?.width}`,
                                  }}
                                >
                                  Discount
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {item?.roomservicemaster &&
                              item?.roomservicemaster.length > 0 &&
                              item?.roomservicemaster.map(
                                (serviceItem, index) => (
                                  <TableRow key={index}>
                                    {/* <TableCell>
                                    <LazyLoadImage
                                      src={
                                        serviceItem?.images
                                          ? `${process.env.REACT_APP_API_URL}/uploads/foods/${serviceItem?.images}`
                                          : placeholderImage
                                      }
                                      effect="blur"
                                      style={{
                                        height: "40px",
                                        width: "70px",
                                        borderRadius: "10px",
                                        cursor: "pointer",
                                        marginBottom: "10px",
                                        marginTop: "5px",
                                      }}
                                    />
                                  </TableCell> */}
                                    <TableCell sx={{ textAlign: "center" }}>
                                      {+index + 1}
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>
                                      {/* {serviceItem?.payment_status !==
                                        "PAID" && ( */}
                                      <Tooltip
                                        title={"Mark as paid"}
                                        placement="left"
                                        arrow
                                      >
                                        <IconButton
                                          onClick={() => {
                                            HandleRoomServiceStatusPaid(
                                              serviceItem
                                            );
                                          }}
                                          disabled={
                                            serviceItem?.payment_status !==
                                            "PAID"
                                              ? false
                                              : true
                                          }
                                        >
                                          <CreditScoreTwoToneIcon
                                            sx={{
                                              color: `${
                                                serviceItem?.payment_status !==
                                                "PAID"
                                                  ? "#34a853"
                                                  : "grey"
                                              }`,
                                            }}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                      {/* )} */}
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>
                                      {serviceItem?.invoice_num}
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>
                                      <Typography variant="subtitle">
                                        {serviceItem?.payment_status ===
                                          "PAID" && (
                                          <Button
                                            variant="outlined"
                                            size="small"
                                            color="success"
                                          >
                                            {serviceItem?.payment_status}
                                          </Button>
                                        )}
                                        {serviceItem?.payment_status ===
                                          "UNPAID" && (
                                          <Button
                                            variant="outlined"
                                            color="error"
                                            size="small"
                                          >
                                            {serviceItem?.payment_status}
                                          </Button>
                                        )}
                                      </Typography>
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>
                                      {serviceItem?.total}
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>
                                      {serviceItem?.sub_total}
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>
                                      {serviceItem?.cgst}
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>
                                      {serviceItem?.sgst}
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>
                                      {serviceItem?.igst}
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>
                                      {serviceItem?.discount}
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                          </TableBody>
                        </Table>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default BookingDropDownView;

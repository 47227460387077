import React, { useEffect } from "react";
import {
  Paper,
  TextField,
  Box,
  Stack,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import CollapsedBreadcrumbs from "../../Include/Breadcrumb";
import { DateTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useFormik } from "formik";
import dayjs from "dayjs";
import { addRoomServiceSchema } from "../../../../schema/Schema";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { AvailableRoomApi } from "../../../../store/Slice/RoomsSlice/RoomSlice";
import { PostRoomServiceApi } from "../../../../store/Slice/RoomServiceSlice/RoomServiceSlice";
import { GetFoodApi } from "../../../../store/Slice/FoodSlice/FoodSlice";
import { NavLink } from "react-router-dom";
import { ErrorAlert } from "../../../../Model/Alert/SuccessAlert";
const BreadCrumbLink = [
  {
    id: 1,
    name: "Home",
    link: "/",
  },
  {
    id: 1,
    name: "Service",
    link: "/room-service",
  },
  {
    id: 1,
    name: "Add-Service",
    link: "/add-service",
  },
];

const numberInputOnWheelPreventChange = (e) => {
  // Prevent the input value change
  e.target.blur();

  // Refocus immediately, on the next tick (after the current function is done)
  setTimeout(() => {
    e.target.focus();
  }, 0);
};

const AddService = () => {
  const Token = Cookies.get("Token");
  const dispatch = useDispatch();
  const IsMobile = useMediaQuery("(min-width:900px)");

  const { AvailableRoomData } = useSelector((state) => state.RoomStore);
  const { FoodData } = useSelector((state) => state.FoodStore);

  const initialValues = {
    room_id: "",
    order_date: dayjs(new Date()).toISOString(),
    sub_total: "",
    cgst: "",
    sgst: "",
    igst: "",
    discount: 0,
    total: "",
    field: [
      {
        item_id: "",
        tax_type: "",
        price: "",
        quantity: "",
        cgst: "",
        sgst: "",
        igst: "",
        total: "",
      },
    ],
  };

  const {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: addRoomServiceSchema,
    onSubmit: async (values) => {
      try {
        console.log("values", values);
        let fields = values.field.map((item) => {
          let { tax_type, ...val } = item;
          return { ...val };
        });
        values = { ...values, service_items: fields };
        delete values.field;

        await dispatch(PostRoomServiceApi({ Token, values }));
      } catch (error) {
        ErrorAlert("Internal server Error");
      }
    },
  });

  const AddField = () => {
    setFieldValue("field", [
      ...values.field,
      {
        item_id: "",
        tax_type: "",
        price: "",
        quantity: "",
        cgst: "",
        sgst: "",
        igst: "",
        total: "",
      },
    ]);
  };

  const removeField = (index) => {
    const fieldsInArray = [...values.field];
    fieldsInArray.splice(index, 1);
    setFieldValue("field", fieldsInArray);
  };

  const fetchItemData = async (itemCode) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/getspecific/fooditem/${itemCode}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${Token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  };

  const handleItemCodeChange = async (event, index) => {
    const { value } = event.target;
    setFieldValue(`field[${index}].item_id`, value);

    // Fetch and update the price based on selected item code
    const fetchedData = await fetchItemData(value);
    const { result } = fetchedData;

    setFieldValue(`field[${index}].price`, result.price);
    setFieldValue(`field[${index}].tax_type`, result.tax_type);
  };

  useEffect(() => {
    values.field.forEach((item, i) => {
      let total = item.total;
      let cgst = item.cgst;
      let sgst = item.sgst;
      let igst = item.igst;

      // if (item.tax_type === "INCLUDE") {
      //   total = item.price * item.quantity;
      //   cgst = Math.ceil((total * 2.5) / 100);
      //   sgst = Math.ceil((total * 2.5) / 100);
      //   igst = 0;
      //   setFieldValue(`field[${i}].cgst`, cgst.toFixed(2));
      //   setFieldValue(`field[${i}].sgst`, sgst.toFixed(2));
      //   setFieldValue(`field[${i}].igst`, igst.toFixed(2));
      //   setFieldValue(`field[${i}].total`, total.toFixed(2));
      // } else if (item.tax_type === "EXCLUDE") {
      //   total = item.price * item.quantity;
      //   cgst = Math.ceil((total * 2.5) / 100);
      //   sgst = Math.ceil((total * 2.5) / 100);
      //   igst = 0;
      //   total += cgst + sgst + igst;
      //   setFieldValue(`field[${i}].cgst`, cgst.toFixed(2));
      //   setFieldValue(`field[${i}].sgst`, sgst.toFixed(2));
      //   setFieldValue(`field[${i}].igst`, igst.toFixed(2));
      //   setFieldValue(`field[${i}].total`, total.toFixed(2));
      // } else {
      total = item.price * item.quantity;
      cgst = 0;
      sgst = 0;
      igst = 0;
      total += cgst + sgst + igst;
      setFieldValue(`field[${i}].cgst`, cgst.toFixed(2));
      setFieldValue(`field[${i}].sgst`, sgst.toFixed(2));
      setFieldValue(`field[${i}].igst`, igst.toFixed(2));
      setFieldValue(`field[${i}].total`, total.toFixed(2));
      // }
    });
  }, [values.field]);

  useEffect(() => {
    if (values?.field) {
      let sub_total = values.field.reduce(
        (sum, item) => {
          return +sum + +item.total;
        },
        [0]
      );

      let cgst = values.field.reduce(
        (total, item) => {
          return +total + +item.cgst;
        },
        [0]
      );

      let sgst = values.field.reduce(
        (total, item) => {
          return +total + +item.sgst;
        },
        [0]
      );

      let igst = values.field.reduce(
        (total, item) => {
          return +total + +item.igst;
        },
        [0]
      );

      let discount = +values.discount;
      let total = sub_total + cgst + sgst + igst - discount;

      setFieldValue("sub_total", sub_total.toFixed(2));
      setFieldValue("cgst", cgst.toFixed(2));
      setFieldValue("sgst", sgst.toFixed(2));
      setFieldValue("igst", igst.toFixed(2));
      setFieldValue("total", total.toFixed(2));
    }
  }, [values.field, values.discount]);

  useEffect(() => {
    const FetchData = async () => {
      try {
        const data = {
          order_date: values.order_date
            ? dayjs(values.order_date).toISOString()
            : new Date().toLocaleDateString(),
        };
        await dispatch(AvailableRoomApi({ Token, data }));
        await dispatch(GetFoodApi({ Token }));
      } catch (error) {
        return error;
      }
    };
    FetchData();
  }, [dispatch, Token, values.order_date]);

  return (
    <Box sx={{ padding: "20px", width: "100%" }}>
      <Stack
        direction={IsMobile ? "row" : "column"}
        justifyContent={"space-between"}
      >
        <Typography variant="h6">Add Service</Typography>
        <CollapsedBreadcrumbs data={BreadCrumbLink} />
      </Stack>

      <Paper sx={{ marginTop: "20px", padding: "30px" }}>
        <Box display={"flex"} flexDirection={"column"} gap={4}>
          <form
            onSubmit={handleSubmit}
            style={{ display: "flex", gap: "32px", flexDirection: "column" }}
          >
            <Stack direction={IsMobile ? "row" : "column"} spacing={4}>
              <Box sx={{ width: "100%" }}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  sx={{ width: "100%" }}
                >
                  <DateTimePicker
                    label="Order Date"
                    name="order_date"
                    format="DD/MM/YYYY hh:mm A"
                    value={
                      values.order_date
                        ? dayjs(values.order_date)
                        : dayjs(new Date())
                    }
                    onChange={(value) => {
                      const formattedDate = dayjs(value).toISOString();
                      setFieldValue("order_date", formattedDate);
                    }}
                    sx={{ width: "100%", flex: 1 }}
                    // size="small"
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                {errors.order_date && touched.order_date && (
                  <Typography
                    variant="caption"
                    color={"error"}
                    style={{
                      marginTop: "3px",
                      marginLeft: "14px",
                      marginRight: "14px",
                      marginBottom: "0px",
                    }}
                  >
                    {errors.order_date}
                  </Typography>
                )}
              </Box>

              <FormControl
                sx={{ width: "100%" }}
                error={touched.room_id && errors.room_id}
                helperText={touched.room_id && errors.room_id}
              >
                <InputLabel id="demo-simple-select-label">Room No</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Room No"
                  name="room_id"
                  value={values.room_id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {AvailableRoomData &&
                    AvailableRoomData.length > 0 &&
                    AvailableRoomData.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.room_no}
                      </MenuItem>
                    ))}
                </Select>
                {touched.room_id && errors.room_id && (
                  <Typography
                    variant="caption"
                    color={"error"}
                    style={{
                      marginTop: "3px",
                      marginLeft: "14px",
                      marginRight: "14px",
                      marginBottom: "0px",
                    }}
                  >
                    {errors.room_id}
                  </Typography>
                )}
              </FormControl>
            </Stack>

            <Box
              sx={{
                display: "flex",
                justifyContent: { md: "end", sm: "center", xs: "center" },
                width: "100%",
              }}
            >
              <Button
                variant="outlined"
                color="secondary"
                onClick={AddField}
                fullWidth={IsMobile ? false : true}
              >
                Add Food
              </Button>
            </Box>

            <Stack>
              {/* {
                                tableData && tableData.length >= 1 && ( */}
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 1260 }} aria-label="Item Table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: "25%" }}>Item</TableCell>
                      <TableCell align="right">Price</TableCell>
                      <TableCell align="right">Quantity</TableCell>
                      <TableCell align="right">CGST</TableCell>
                      <TableCell align="right">SGST</TableCell>
                      <TableCell align="right">IGST</TableCell>
                      <TableCell align="right">Sub Total</TableCell>
                      <TableCell sx={{ width: "1%" }} align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.field &&
                      values.field.length > 0 &&
                      values.field.map((item, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>
                            <FormControl
                              sx={{ width: "100%" }}
                              helperText={
                                touched.field &&
                                touched.field[index] &&
                                touched.field[index]?.item_id &&
                                errors.field &&
                                errors.field[index] &&
                                errors.field[index]?.item_id
                              }
                              error={
                                touched.field &&
                                touched.field[index] &&
                                touched.field[index]?.item_id &&
                                errors.field &&
                                errors.field[index] &&
                                errors.field[index]?.item_id
                              }
                            >
                              <InputLabel id="demo-simple-select-label">
                                Item
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Item"
                                name={`field[${index}].item_id`}
                                size="small"
                                // onChange={handleChange}
                                onChange={(event) =>
                                  handleItemCodeChange(event, index)
                                }
                                onBlur={handleBlur}
                                value={item.item_id}
                              >
                                {FoodData &&
                                  FoodData.length > 0 &&
                                  FoodData.map((i, index) => (
                                    <MenuItem key={index} value={i.id}>
                                      {`${i.item_code}-${i.item_name}`}
                                    </MenuItem>
                                  ))}
                              </Select>
                              {touched.field &&
                                touched.field[index] &&
                                touched.field[index]?.item_id &&
                                errors.field &&
                                errors.field[index] &&
                                errors.field[index]?.item_id && (
                                  <Typography
                                    variant="caption"
                                    color={"error"}
                                    style={{
                                      marginTop: "3px",
                                      marginLeft: "14px",
                                      marginRight: "14px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    {errors.field[index]?.item_id}
                                  </Typography>
                                )}
                            </FormControl>
                          </TableCell>
                          <TableCell align="right">
                            <TextField
                              hidden
                              size="small"
                              name={`field[${index}].tax_type`}
                              value={item.tax_type}
                              type="hidden"
                              sx={{ width: "100%" }}
                              helperText={
                                touched.field &&
                                touched.field[index] &&
                                touched.field[index].tax_type &&
                                errors.field &&
                                errors.field[index] &&
                                errors.field[index].tax_type
                              }
                              error={
                                touched.field &&
                                touched.field[index] &&
                                touched.field[index].tax_type &&
                                errors.field &&
                                errors.field[index] &&
                                errors.field[index].tax_type
                              }
                            />

                            <TextField
                              label="₹ Price"
                              variant="outlined"
                              size="small"
                              name={`field[${index}].price`}
                              value={item.price}
                              type="number"
                              onWheel={numberInputOnWheelPreventChange}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              sx={{ width: "100%" }}
                              helperText={
                                touched.field &&
                                touched.field[index] &&
                                touched.field[index].price &&
                                errors.field &&
                                errors.field[index] &&
                                errors.field[index].price
                              }
                              error={
                                touched.field &&
                                touched.field[index] &&
                                touched.field[index].price &&
                                errors.field &&
                                errors.field[index] &&
                                errors.field[index].price
                              }
                            />
                          </TableCell>
                          <TableCell align="right">
                            <TextField
                              label="Quantity"
                              variant="outlined"
                              size="small"
                              name={`field[${index}].quantity`}
                              value={item.quantity}
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              sx={{ width: "100%" }}
                              helperText={
                                touched.field &&
                                touched.field[index] &&
                                touched.field[index].quantity &&
                                errors.field &&
                                errors.field[index] &&
                                errors.field[index].quantity
                              }
                              error={
                                touched.field &&
                                touched.field[index] &&
                                touched.field[index].quantity &&
                                errors.field &&
                                errors.field[index] &&
                                errors.field[index].quantity
                              }
                            />
                          </TableCell>
                          <TableCell align="right">
                            <TextField
                              label="CGST"
                              variant="outlined"
                              size="small"
                              name={`field[${index}].cgst`}
                              disabled={Boolean(item.cgst)}
                              value={item.cgst}
                              type="number"
                              onWheel={numberInputOnWheelPreventChange}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              sx={{ width: "100%" }}
                              // disabled={Boolean(item.price)}
                              helperText={
                                touched.field &&
                                touched.field[index] &&
                                touched.field[index].cgst &&
                                errors.field &&
                                errors.field[index] &&
                                errors.field[index].cgst
                              }
                              error={
                                touched.field &&
                                touched.field[index] &&
                                touched.field[index].cgst &&
                                errors.field &&
                                errors.field[index] &&
                                errors.field[index].cgst
                              }
                            />
                          </TableCell>
                          <TableCell align="right">
                            <TextField
                              label="SGST"
                              variant="outlined"
                              size="small"
                              name={`field[${index}].sgst`}
                              disabled={Boolean(item.sgst)}
                              value={item.sgst}
                              type="number"
                              onWheel={numberInputOnWheelPreventChange}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              sx={{ width: "100%" }}
                              // disabled={Boolean(item.price)}
                              helperText={
                                touched.field &&
                                touched.field[index] &&
                                touched.field[index].sgst &&
                                errors.field &&
                                errors.field[index] &&
                                errors.field[index].sgst
                              }
                              error={
                                touched.field &&
                                touched.field[index] &&
                                touched.field[index].sgst &&
                                errors.field &&
                                errors.field[index] &&
                                errors.field[index].sgst
                              }
                            />
                          </TableCell>
                          <TableCell align="right">
                            <TextField
                              label="IGST"
                              variant="outlined"
                              size="small"
                              name={`field[${index}].igst`}
                              value={item.igst}
                              disabled={Boolean(item.igst)}
                              type="number"
                              onWheel={numberInputOnWheelPreventChange}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              sx={{ width: "100%" }}
                              // disabled={Boolean(item.price)}
                              helperText={
                                touched.field &&
                                touched.field[index] &&
                                touched.field[index].igst &&
                                errors.field &&
                                errors.field[index] &&
                                errors.field[index].igst
                              }
                              error={
                                touched.field &&
                                touched.field[index] &&
                                touched.field[index].igst &&
                                errors.field &&
                                errors.field[index] &&
                                errors.field[index].igst
                              }
                            />
                          </TableCell>
                          <TableCell align="right">
                            <TextField
                              label="Sub Total"
                              // required
                              variant="outlined"
                              size="small"
                              name={`field[${index}].total`}
                              value={item.total}
                              disabled={Boolean(item.total)}
                              type="number"
                              onWheel={numberInputOnWheelPreventChange}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              sx={{ width: "100%" }}
                              helperText={
                                touched.field &&
                                touched.field[index] &&
                                touched.field[index].total &&
                                errors.field &&
                                errors.field[index] &&
                                errors.field[index].total
                              }
                            />
                          </TableCell>
                          <TableCell align="right">
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                width: "100%",
                              }}
                            >
                              <IconButton
                                color="secondary"
                                onClick={() => {
                                  removeField(index);
                                }}
                              >
                                <HighlightOffIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: {
                  lg: "end",
                  md: "end",
                  xs: "center",
                  sm: "center",
                },
              }}
            >
              <Stack
                direction={"column"}
                spacing={2}
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "40%",
                    lg: "40%",
                  },
                }}
              >
                <Stack direction={IsMobile ? "row" : "column"} spacing={4}>
                  <TextField
                    label="Sub Total"
                    variant="outlined"
                    size="small"
                    name="sub_total"
                    value={values.sub_total}
                    type="number"
                    onWheel={numberInputOnWheelPreventChange}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={Boolean(values.sub_total)}
                    sx={{ width: "100%" }}
                    helperText={touched.sub_total && errors.sub_total}
                    error={touched.sub_total && errors.sub_total}
                  />
                </Stack>
                <Stack direction={IsMobile ? "row" : "column"} spacing={4}>
                  <TextField
                    label="SGST"
                    variant="outlined"
                    size="small"
                    name="sgst"
                    value={values.sgst}
                    disabled={Boolean(values.sgst)}
                    type="number"
                    onWheel={numberInputOnWheelPreventChange}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={{ width: "100%" }}
                    helperText={touched.sgst && errors.sgst}
                    error={touched.sgst && errors.sgst}
                  />

                  <TextField
                    label="IGST"
                    variant="outlined"
                    size="small"
                    name="igst"
                    value={values.igst}
                    type="number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onWheel={numberInputOnWheelPreventChange}
                    sx={{ width: "100%" }}
                    disabled={Boolean(values.igst)}
                    helperText={touched.igst && errors.igst}
                    error={touched.igst && errors.igst}
                  />
                </Stack>
                <Stack direction={IsMobile ? "row" : "column"} spacing={4}>
                  <TextField
                    label="CGST"
                    variant="outlined"
                    size="small"
                    name="cgst"
                    value={values.cgst}
                    disabled={Boolean(values.cgst)}
                    type="number"
                    onChange={handleChange}
                    onWheel={numberInputOnWheelPreventChange}
                    onBlur={handleBlur}
                    sx={{ width: "100%" }}
                    helperText={touched.cgst && errors.cgst}
                    error={touched.cgst && errors.cgst}
                  />

                  <TextField
                    label="Discount"
                    variant="outlined"
                    size="small"
                    name="discount"
                    value={values.discount}
                    type="number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onWheel={numberInputOnWheelPreventChange}
                    sx={{ width: "100%" }}
                    helperText={touched.discount && errors.discount}
                    error={touched.discount && errors.discount}
                  />
                </Stack>
                <Stack direction={IsMobile ? "row" : "column"} spacing={4}>
                  <TextField
                    label="Total"
                    variant="outlined"
                    size="small"
                    name="total"
                    disabled={Boolean(values.total)}
                    value={values.total}
                    type="number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onWheel={numberInputOnWheelPreventChange}
                    sx={{ width: "100%" }}
                    helperText={touched.total && errors.total}
                    error={touched.total && errors.total}
                  />
                </Stack>
              </Stack>
            </Box>
            <Stack
              direction={IsMobile ? "row" : "column"}
              display={"flex"}
              justifyContent={"center"}
              width={"100%"}
            >
              <Stack
                direction={IsMobile ? "row" : "column"}
                gap={4}
                width={IsMobile ? "initial" : "100%"}
              >
                <Button variant="contained" color="success" type="submit">
                  Submit
                </Button>
                <NavLink to={"/room-service"}>
                  <Button
                    variant="contained"
                    fullWidth={IsMobile ? false : true}
                    color="error"
                  >
                    Cancel
                  </Button>
                </NavLink>
              </Stack>
            </Stack>
          </form>
        </Box>
      </Paper>
    </Box>
  );
};

export default AddService;

import React, { useEffect, useState } from "react";
import {
  Paper,
  TextField,
  Box,
  Stack,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import CollapsedBreadcrumbs from "../../Include/Breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import {
  GetSingleGuestData,
  PutGuestData,
} from "../../../../store/Slice/GuestSlice/GuestDataTableSlice";
import { NavLink, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { GuestSchema } from "../../../../schema/Schema";
import SimpleDialog from "./Webcam/webcam";
import { MySwal } from "../../../../Model/Alert/SuccessAlert";
const BreadCrumbLink = [
  { id: 1, name: "Home", link: "/" },
  { id: 1, name: "Guests", link: "/guest" },
  { id: 1, name: "Edit" },
];

const numberInputOnWheelPreventChange = (e) => {
  e.target.blur();
  setTimeout(() => {
    e.target.focus();
  }, 0);
};

const EditGuest = () => {
  const Token = Cookies.get("Token");
  const { id } = useParams();
  const dispatch = useDispatch();
  const IsMobile = useMediaQuery("(min-width:900px)");
  const { SpecificGuestdata } = useSelector((state) => state.UserStore);
  const [record, setrecord] = useState([]);

  useEffect(() => {
    const FetchData = async () => {
      try {
        await dispatch(GetSingleGuestData({ Token, id }));
      } catch (error) {
        return error;
      }
    };
    FetchData();
  }, [Token, dispatch]);

  useEffect(() => {
    setrecord(SpecificGuestdata);
  }, [SpecificGuestdata]);

  const initialValues = {
    fullname: "",
    address: "",
    email: "",
    phone_number: "",
    document: "",
    image: null,
    gst_number: "",
    document_images: null,
  };

  const {
    errors,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues,
    validationSchema: GuestSchema,
    onSubmit: async (values) => {
      try {
        values.image = null;
        const formdata = new FormData();
        Object.entries(values).forEach(([key, val]) => {
          formdata.append(key, val);
        });
        MySwal.fire({
          title: "Do you want to save the changes?",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Save",
          denyButtonText: `Don't save`,
        }).then(async (result) => {
          if (result.isConfirmed) {
            await dispatch(PutGuestData({ Token, formdata, id }));
          } else if (result.isDenied) {
            MySwal.fire("Changes are not saved", "", "info");
          }
        });
      } catch (error) {
        return error;
      }
    },
  });

  useEffect(() => {
    if (record) {
      setValues({
        ...values,
        fullname: record.fullname || "",
        address: record.address || "",
        email: record.email || "",
        phone_number: record.phone_number || "",
        document: record.document || "",
        gst_number: record.gst_number || "",
      });
    }
  }, [record]);

  const [openWebCam, setOpenWebCam] = useState(false);
  const [selectedvalue, setselectedvalue] = useState(null);
  const [displayValue, setDisplayValue] = useState(null);
  const [filetransfer, setfiletransfer] = useState({});

  const handleWebCamOpen = () => setOpenWebCam(true);
  const handlewebcamClose = () => setOpenWebCam(false);

  useEffect(() => {
    if (selectedvalue && typeof selectedvalue === "object") {
      const imageUrl = URL.createObjectURL(selectedvalue);
      setDisplayValue(imageUrl);
      setFieldValue("document_images", selectedvalue);
      setfiletransfer(selectedvalue);
      return () => {
        URL.revokeObjectURL(imageUrl);
      };
    } else {
      setDisplayValue(selectedvalue);
      setFieldValue("document_images", selectedvalue);
      if (selectedvalue) {
        var file = dataURLtoFile(selectedvalue, "hello.jpg");
        setfiletransfer(file);
        setFieldValue("document_images", file);
      }
    }
  }, [selectedvalue]);

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  // const [image, setImage] = useState(() => record.image || null);

  // const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
  //   if (acceptedFiles.length) {
  //     const file = acceptedFiles[0];
  //     const preview = URL.createObjectURL(file);
  //     setImage({ file, preview });
  //   }

  //   if (rejectedFiles.length) {
  //     setFieldError("excelFile", "only xlsx files are allowed");
  //   }
  // }, []);

  // const { getRootProps, getInputProps, isDragActive } = useDropzone({
  //   onDrop,
  //   accept: {
  //     "image/*": [],
  //   }, // Accept any image file
  //   maxFiles: 1, // Limit to only one file
  // });

  useEffect(() => {
    // setImage(record.image || null);

    if (selectedvalue && selectedvalue instanceof File) {
      const image = URL.createObjectURL(selectedvalue);
      setDisplayValue(image || null);
    } else if (!selectedvalue && record.document_images) {
      setDisplayValue(
        `${process.env.REACT_APP_API_URL}/uploads/guests/${record.document_images}` ||
          null
      );
    } else if (
      selectedvalue &&
      typeof selectedvalue === "string" &&
      selectedvalue.startsWith("data:image")
    ) {
      setDisplayValue(selectedvalue);
    } else {
      setDisplayValue(selectedvalue || null);
    }
    // console.log("%%%%%%%",selectedvalue)
  }, [record, selectedvalue]);

  return (
    <Box sx={{ padding: "20px", width: "100%" }}>
      <Stack direction={IsMobile?"row":"column"} justifyContent={"space-between"}>
        <Typography variant="h6">Edit Guest</Typography>
        <CollapsedBreadcrumbs data={BreadCrumbLink} />
      </Stack>

      <Paper sx={{ marginTop: "20px", padding: "30px" }}>
        <form onSubmit={handleSubmit}>
          <Box display={"flex"} flexDirection={"column"} gap={4}>
            <Stack direction={IsMobile ? "row" : "column"} spacing={4}>
              <TextField
                label="Full Name"
                variant="outlined"
                size="small"
                name="fullname"
                type="text"
                sx={{ width: "100%" }}
                value={values.fullname}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.fullname && errors.fullname}
                error={touched.fullname && errors.fullname}
                InputLabelProps={{ shrink: !!values.fullname }}
              />

              <TextField
                label="Email"
                variant="outlined"
                size="small"
                name="email"
                type="email"
                sx={{ width: "100%" }}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.email && errors.email}
                error={touched.email && errors.email}
                InputLabelProps={{ shrink: !!values.email }}
              />
            </Stack>

            <Stack direction={IsMobile ? "row" : "column"} spacing={4}>
              <TextField
                label="Mobile"
                variant="outlined"
                size="small"
                name="phone_number"
                type="text"
                onWheel={numberInputOnWheelPreventChange}
                sx={{ width: "100%" }}
                value={values.phone_number}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.phone_number && errors.phone_number}
                error={touched.phone_number && errors.phone_number}
                InputLabelProps={{ shrink: !!values.phone_number }}
              />

              <TextField
                label="Address"
                variant="outlined"
                size="small"
                name="address"
                type="text"
                sx={{ width: "100%" }}
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.address && errors.address}
                error={touched.address && errors.address}
                InputLabelProps={{ shrink: !!values.address }}
              />
            </Stack>

            <Stack direction={IsMobile ? "row" : "column"} spacing={4}>
              <TextField
                label="GST Number"
                variant="outlined"
                size="small"
                name="gst_number"
                type="text"
                sx={{ width: "100%" }}
                value={values.gst_number}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.gst_number && errors.gst_number}
                error={touched.gst_number && errors.gst_number}
                InputLabelProps={{ shrink: !!values.gst_number }}
              />

              <FormControl
                sx={{ width: "100%" }}
                helperText={touched.document && errors.document}
                error={touched.document && errors.document}
                size="small"
              >
                <InputLabel id="demo-simple-select-label">Document</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Document"
                  name="document"
                  value={values.document}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <MenuItem value={"Adhar Card"}>Adhar Card</MenuItem>
                  <MenuItem value={"Pan Card"}>Pan Card</MenuItem>
                  <MenuItem value={"Driving License"}>Driving License</MenuItem>
                  <MenuItem value={"Voting Card"}>Voting Card</MenuItem>
                </Select>
              </FormControl>
            </Stack>

            <Stack direction={IsMobile ? "row" : "column"} spacing={4}>
              <Box sx={{ width: "100%" }}>
                {displayValue && (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      p: 2,
                      border: "1px dashed #333",
                    }}
                  >
                    <img
                      src={displayValue}
                      alt="Selected Document"
                      style={{ height: "100px", width: "200px" }}
                    />
                  </Box>
                )}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: { md: "end", sm: "center", xs: "center" },
                    width: "100%",
                  }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleWebCamOpen}
                    sx={{ height: "35px", mt: 2 }}
                    fullWidth={IsMobile ? false : true}
                  >
                    Upload File
                  </Button>
                </Box>
              </Box>
            </Stack>

            {/* <Stack direction={"column"} spacing={4}>
              <SingleImageDropZone
                getRootProps={getRootProps}
                getInputProps={getInputProps}
                isDragActive={isDragActive}
                images={image}
                onChange={handleChange}
                onBlur={handleBlur}
                setImages={setImage}
                uploadType={"GUEST"}
              />
              {touched.image && errors.image && (
                <Typography variant="body" color={"error"}>
                  {errors.image}
                </Typography>
              )}
            </Stack> */}

            {/* <Stack direction={IsMobile ? "row" : "column"}  spacing={4}>
              <TextField
                variant="outlined"
                size="small"
                name="image"
                type="file"
                onChange={(event) => {
                  const images = event.target.files[0];
                  setFieldValue("image", images);
                }}
                onBlur={handleBlur}
                sx={{ width: "100%" }}
              />
            </Stack> */}

            <Stack
              direction={IsMobile ? "row" : "column"}
              display={"flex"}
              justifyContent={"center"}
              width={"100%"}
            >
              <Stack
                direction={IsMobile ? "row" : "column"}
                gap={4}
                width={IsMobile ? "initial" : "100%"}
              >
                <Button variant="contained" color="success" type="submit">
                  Submit
                </Button>
                <NavLink to={"/guest"}>
                  <Button
                    variant="contained"
                    color="error"
                    fullWidth={IsMobile ? false : true}
                  >
                    Cancel
                  </Button>
                </NavLink>
              </Stack>
            </Stack>
          </Box>
        </form>
      </Paper>

      {openWebCam && (
        <SimpleDialog
          open={openWebCam}
          mode={"SINGLE"}
          handleClose={handlewebcamClose}
          setselectedvalue={setselectedvalue}
          selectedvalue={selectedvalue}
        />
      )}
    </Box>
  );
};

export default EditGuest;

import React, { useState } from "react";
import {
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  Table,
  TableRow,
  IconButton,
  Collapse,
  Typography,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

const DropDownDataTable = ({ data }) => {
  console.log("Dtatata&&&&&&&&&&&&&&&&&&&&&&&&&%%%%%%%%%%%%%%%%55", data);
  const TableHeader = [
    "#",
    "Sr.No",
    "Order Date",
    "Email",
    "Mobile",
    "Room No",
    "Floor No",
    "CGST",
    "SGST",
    "IGST",
    "Discount",
    "SubTotal",
    "Total",
  ];

  const [submenuopen, setsubmenuopen] = useState(null);

  const handlesubmenuOPen = (id) => {
    setsubmenuopen((previouse) => (previouse === id ? null : id));
  };
  return (
    <>
      <TableContainer component={"paper"}>
        <Table>
          <TableHead>
            <TableRow>
              {TableHeader &&
                TableHeader.length > 0 &&
                TableHeader.map((item, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      backgroundColor: "#0288d1",
                      color: "white",
                      border: "10px solid",
                      borderRadius: 6,
                      py: 1,
                      textAlign: "center",
                    }}
                  >
                    <Typography variant="subtitle2" fontSize={"15px"}>
                      {item}
                    </Typography>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.length > 0 &&
              data.map((item, index) => (
                <React.Fragment key={item?.id}>
                  <TableRow>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handlesubmenuOPen(item?.id)}
                      >
                        {submenuopen ? (
                          <KeyboardArrowUp />
                        ) : (
                          <KeyboardArrowDown />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">
                        {item?.serialNumber}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      <Typography variant="subtitle">
                        {item?.created_at
                          ? new Date(item?.created_at).toLocaleString()
                          : ""}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      <Typography variant="subtitle">
                        {item?.guest && item?.guest?.email
                          ? item?.guest?.email
                          : ""}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      <Typography variant="subtitle">
                        {item?.guest && item?.guest?.phone_number
                          ? item?.guest?.phone_number
                          : ""}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">
                        {item?.room && item?.room?.room_no
                          ? item?.room?.room_no
                          : ""}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">
                        {item?.room && item?.room?.floor_no
                          ? item?.room?.floor_no
                          : ""}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      <Typography variant="subtitle">{item?.cgst}</Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      <Typography variant="subtitle">{item?.sgst}</Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      <Typography variant="subtitle">{item?.igst}</Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">
                        {item?.discount}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      <Typography variant="subtitle">
                        {item?.sub_total}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      <Typography variant="subtitle">{item?.total}</Typography>
                    </TableCell>

                    <TableCell></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={TableHeader.length + 1}
                    >
                      <Collapse
                        in={submenuopen === item?.id}
                        timeout={"auto"}
                        unmountOnExit
                      >
                        <Table>
                          <TableHead>
                            <TableRow>
                              {/* <TableCell
                                sx={{
                                  backgroundColor: "#9C27B0",
                                  color: "white",
                                  border: "10px solid",
                                  borderRadius: 6,
                                  textAlign: "center",
                                }}
                              >
                                Images
                              </TableCell> */}
                              <TableCell
                                sx={{
                                  backgroundColor: "#9C27B0",
                                  color: "white",
                                  border: "10px solid",
                                  borderRadius: 6,
                                  textAlign: "center",
                                }}
                              >
                                Sr. No.
                              </TableCell>
                              <TableCell
                                sx={{
                                  backgroundColor: "#9C27B0",
                                  color: "white",
                                  border: "10px solid",
                                  borderRadius: 6,
                                  textAlign: "center",
                                }}
                              >
                                Code
                              </TableCell>
                              <TableCell
                                sx={{
                                  backgroundColor: "#9C27B0",
                                  color: "white",
                                  border: "10px solid",
                                  borderRadius: 6,
                                  py: 1,
                                  textAlign: "center",
                                }}
                              >
                                Name
                              </TableCell>
                              <TableCell
                                sx={{
                                  backgroundColor: "#9C27B0",
                                  color: "white",
                                  border: "10px solid",
                                  borderRadius: 6,
                                  textAlign: "center",
                                }}
                              >
                                Quantity
                              </TableCell>
                              <TableCell
                                sx={{
                                  backgroundColor: "#9C27B0",
                                  color: "white",
                                  border: "10px solid",
                                  borderRadius: 6,
                                  textAlign: "center",
                                }}
                              >
                                Total
                              </TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {item?.fooditems &&
                              item?.fooditems.length > 0 &&
                              item?.fooditems.map((foodItem, index) => (
                                <TableRow key={index}>
                                  {/* <TableCell>
                                    <LazyLoadImage
                                      src={
                                        foodItem?.images
                                          ? `${process.env.REACT_APP_API_URL}/uploads/foods/${foodItem?.images}`
                                          : placeholderImage
                                      }
                                      effect="blur"
                                      style={{
                                        height: "40px",
                                        width: "70px",
                                        borderRadius: "10px",
                                        cursor: "pointer",
                                        marginBottom: "10px",
                                        marginTop: "5px",
                                      }}
                                    />
                                  </TableCell> */}
                                  <TableCell sx={{ textAlign: "center" }}>
                                    {+index + 1}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: "center" }}>
                                    {foodItem?.item_code}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: "center" }}>
                                    {foodItem?.item_name}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: "center" }}>
                                    {foodItem?.quantity}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: "center" }}>
                                    {foodItem?.total}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default DropDownDataTable;

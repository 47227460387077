import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ErrorAlert, SuccessAlert } from "../../../Model/Alert/SuccessAlert";
const Base_url = process.env.REACT_APP_API_URL + "/check/itemcode/fooditems";
const GetAllBase_url = process.env.REACT_APP_API_URL + "/getall/fooditems";
const EditBase_url = process.env.REACT_APP_API_URL + "/edit/fooditem";
const AddBase_URL = process.env.REACT_APP_API_URL + "/add/fooditems";
const Price_Url = process.env.REACT_APP_API_URL + "/fooditem/price";
const Delete_Food_Url = process.env.REACT_APP_API_URL + "/delete/fooditem";
const AddBulk_URL = process.env.REACT_APP_API_URL + "/food-item/byfile";

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% POST ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const PostFoodApi = createAsyncThunk(
  "PostFoodApi",
  async ({ formdata, Token }) => {
    const headers = {
      "Content-type": "multipart/form-data",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.post(AddBase_URL, formdata, { headers });
      if (response.data.status === true) {
        SuccessAlert(response.data.message);
      }
      // } else {
      //   ErrorAlert(response.data.message);
      // }
      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data;
    }
  }
);

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% POST BULK CATEGORY API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const PostBulkFoodApi = createAsyncThunk(
  "PostBulkFoodApi",
  async ({ formdata, Token }) => {
    const headers = {
    "Content-type": "multipart/form-data",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.post(AddBulk_URL, formdata, { headers });

      if (response.data.status === true) {
        SuccessAlert(response.data.message);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data ;
    }
  }
);

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% POST ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const CheckItemCodeExists = createAsyncThunk(
  "CheckItemCodeExists",
  async ({ value, Token }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.post(Base_url, value, { headers });
      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data;
    }
  }
);

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% GET ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const GetFoodApi = createAsyncThunk("GetFoodApi", async ({ Token }) => {
  const headers = {
    "Content-type": "multipart/form-data",
    Authorization: `Bearer ${Token}`,
  };
  try {
    const response = await axios.get(GetAllBase_url, { headers });
    if (response.data.status === false) {
      ErrorAlert(response.data.message);
    }
    return response.data;
  } catch (error) {
    if (error.response.status === 500) {
      ErrorAlert(error.response.data.message);
    }
    return error.response.data;
  }
});

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% PUT ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const PutFoodApi = createAsyncThunk(
  "PutFoodApi",
  async ({ Token, formdata, id }) => {
    try {
      const headers = {
        "Content-type": "multipart/form-data",
        Authorization: `Bearer ${Token}`,
      };
      // const jsondata = JSON.stringify(values);
      const response = await axios.put(`${EditBase_url}/${id}`, formdata, {
        headers,
      });
      if (response.data.status === true) {
        SuccessAlert(response.data.message);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data;
    }
  }
);

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% DELETE ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const DeleteFoodApi = createAsyncThunk(
  "DeleteFoodApi",
  async ({ Token, id }) => {
    const headers = {
      "Content-type": "multipart/form-data",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.delete(`${Delete_Food_Url}/${id}`, {
        headers,
      });
      if (response.data.status === true) {
        SuccessAlert(response.data.message);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data;
    }
  }
);

export const SingleFoodApi = createAsyncThunk(
  "SingleFoodApi",
  async ({ Token, values, id }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };
    const jsondata = JSON.stringify(values);
    try {
      const response = await axios.put(`${Price_Url}/${id}`, jsondata, {
        headers,
      });
      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data;
    }
  }
);

const FoodSlice = createSlice({
  name: "FoodSlice",
  initialState: {
    iserror: null,
    isLoading: false,
    message: "",
    status: null,
    FoodData: [],
  },
  extraReducers: (builder) => {
    //%%%%%%%%%%%%%%%%%%%%%%% POST API %%%%%%%%%%%%%%%%%%%%%%
    builder.addCase(PostFoodApi.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(PostFoodApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(PostFoodApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.status = action.payload.status;
    });

    //%%%%%%%%%%%%%%%%%%%%%%% POST BULK API %%%%%%%%%%%%%%%%%%%%%%
    builder.addCase(PostBulkFoodApi.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(PostBulkFoodApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(PostBulkFoodApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.status = action.payload.status;
    });

    //%%%%%%%%%%%%%%%%%%%%%%% POST API %%%%%%%%%%%%%%%%%%%%%%
    builder.addCase(CheckItemCodeExists.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(CheckItemCodeExists.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(CheckItemCodeExists.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    //%%%%%%%%%%%%%%%%%%%%%%% GET API %%%%%%%%%%%%%%%%%%%%%%
    builder.addCase(GetFoodApi.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetFoodApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetFoodApi.fulfilled, (state, action) => {
      state.isLoading = false;
      // state.message = action.payload.message;
      // state.status = action.payload.status;
      state.FoodData = action.payload.status ? action.payload.result : [];
    });

    //%%%%%%%%%%%%%%%%%%%%%%% DELETE API %%%%%%%%%%%%%%%%%%%%%%
    builder.addCase(DeleteFoodApi.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(DeleteFoodApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(DeleteFoodApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.status = action.payload.status;
    });

    //%%%%%%%%%%%%%%%%%%%%%%% PUT API %%%%%%%%%%%%%%%%%%%%%%
    builder.addCase(PutFoodApi.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(PutFoodApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(PutFoodApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.status = action.payload.status;
      state.message = action.payload.message;
      state.status = action.payload.status;
    });
  },
});
export default FoodSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ErrorAlert, SuccessAlert } from "../../../Model/Alert/SuccessAlert";
const AddBase_url = process.env.REACT_APP_API_URL + "/add/rooms";
const AddBulkBase_url = process.env.REACT_APP_API_URL + "/addrooms/byfile";
const ViewBase_url = process.env.REACT_APP_API_URL + "/get/allrooms";
const AvailabeBase_url = process.env.REACT_APP_API_URL + "/booked/rooms";
const Specific_Base_URL = process.env.REACT_APP_API_URL + "/get/speceficroom";
const Edit_Room_Detail_URL =
  process.env.REACT_APP_API_URL + "/edit/roomdetails";
const Delete_Base_URL = process.env.REACT_APP_API_URL + "/delete/room";
//%%%%%%%%%%%%%%%%%%%%%%%%%%%% POST ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const AddRoomApi = createAsyncThunk(
  "AddRoomApi",
  async ({ formdata, Token }) => {
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${Token}`,
    };

    try {
      const response = await axios.post(AddBase_url, formdata, { headers });
      if (response.data.status === true) {
        SuccessAlert(response.data.message);
        setTimeout(() => {
          window.location.pathname="/rooms";
        }, 1500);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data;
    }
  }
);

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% POST ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const AddBulkRoomApi = createAsyncThunk(
  "AddBulkRoomApi",
  async ({ formdata, Token }) => {
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${Token}`,
    };

    try {
      const response = await axios.post(AddBulkBase_url, formdata, { headers });
      if (response.data.status === true) {
        SuccessAlert(response.data.message);
        setTimeout(() => {
          window.location.pathname="/rooms";
        }, 1500);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data;
    }
  }
);

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% GET ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const GetRoomApi = createAsyncThunk(
  "GetRoomApi",
  async ({ Token, data}) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.post(`${ViewBase_url}`, data, { headers });
      if (response.data.status === false) {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data;
    }
  }
);

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% Booked ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const BookedRoomApi = createAsyncThunk(
  "BookedRoomApi",
  async ({ Token }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.get(ViewBase_url, { headers });
      if (response.data.status === false) {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data;
    }
  }
);

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% Available ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const AvailableRoomApi = createAsyncThunk(
  "AvailableRoomApi",
  async ({ Token, data }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.post(AvailabeBase_url, data, { headers });
      if (response.data.status === false) {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data;
    }
  }
);

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% Cancel ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const cancelRoomApi = createAsyncThunk(
  "cancelRoomApi",
  async ({ Token }) => {
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.get(ViewBase_url, { headers });
      if (response.data.status === false) {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data;
    }
  }
);

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% GET ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const GetSpecificeRoomApi = createAsyncThunk(
  "GetSpecificeRoomApi",
  async ({ Token, id }) => {
    const headers = {
      "Content-type": "multipart/form-data",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.get(`${Specific_Base_URL}/${id}`, {
        headers,
      });
      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data;
    }
  }
);

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% PUT ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const PutRoomApi = createAsyncThunk(
  "PutRoomApi",
  async ({ Token, formdata, id }) => {
    const headers = {
      "Content-type": "multipart/form-data",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.put(
        `${Edit_Room_Detail_URL}/${id}`,
        formdata,
        {
          headers,
        }
      );
      if (response.data.status === true) {
        SuccessAlert(response.data.message);
        setTimeout(() => {
          window.location.pathname="/rooms";
        }, 1500);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data;
    }
  }
);

//%%%%%%%%%%%%%%%%%%%%%%%%%%%% DELETE ROOM API %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
export const DeleteRoomApi = createAsyncThunk(
  "DeleteRoomApi",
  async ({ Token, id }) => {
    const headers = {
      "Content-type": "multipart/form-data",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const response = await axios.delete(`${Delete_Base_URL}/${id}`, {
        headers,
      });
      if (response.data.status === true) {
        SuccessAlert(response.data.message);
      } else {
        ErrorAlert(response.data.message);
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        ErrorAlert(error.response.data.message);
      }
      return error.response.data;
    }
  }
);

const RoomSlice = createSlice({
  name: "RoomSlice",
  initialState: {
    iserror: null,
    isLoading: false,
    status: null,
    message: "",
    RoomData: [],
    BookedRoomData: [],
    AvailableRoomData: [],
    CancelRoomData: [],
    SpecificRoomdata: {},
  },
  extraReducers: (builder) => {
    //%%%%%%%%%%%%%%%%%%%%%%% POST API %%%%%%%%%%%%%%%%%%%%%%
    builder.addCase(AddRoomApi.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(AddRoomApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(AddRoomApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.RoomData = action.payload.result;
      state.message = action.payload.message;
      state.status = action.payload.status;
    });

    //%%%%%%%%%%%%%%%%%%%%%%% POST BULK API %%%%%%%%%%%%%%%%%%%%%%
    builder.addCase(AddBulkRoomApi.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(AddBulkRoomApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(AddBulkRoomApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.RoomData = action.payload.result;
      state.message = action.payload.message;
      state.status = action.payload.status;
    });

    //%%%%%%%%%%%%%%%%%%%%%%% GET API %%%%%%%%%%%%%%%%%%%%%%
    builder.addCase(GetRoomApi.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetRoomApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetRoomApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.RoomData = action.payload.status ? action.payload.rooms : [];
      state.message = action.payload.message;
      state.status = action.payload.status;
    });

    //%%%%%%%%%%%%%%%%%%%%%%% GET Booked API %%%%%%%%%%%%%%%%%%%%%%
    builder.addCase(BookedRoomApi.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(BookedRoomApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(BookedRoomApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.BookedRoomData = action.payload.status ? action.payload.result : [];
      state.message = action.payload.message;
      state.status = action.payload.status;
    });

    //%%%%%%%%%%%%%%%%%%%%%%% GET Available Room API %%%%%%%%%%%%%%%%%%%%%%
    builder.addCase(AvailableRoomApi.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(AvailableRoomApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(AvailableRoomApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.AvailableRoomData = action.payload.status
        ? action.payload.result
        : [];
      state.message = action.payload.message;
      state.status = action.payload.status;
    });

    //%%%%%%%%%%%%%%%%%%%%%%% GET CancelRoomData API %%%%%%%%%%%%%%%%%%%%%%
    builder.addCase(cancelRoomApi.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(cancelRoomApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(cancelRoomApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.status = action.payload.status;
      state.CancelRoomData = action.payload.status ? action.payload.result : [];
    });

    //%%%%%%%%%%%%%%%%%%%%%%% GET SPECIFIC API %%%%%%%%%%%%%%%%%%%%%%
    builder.addCase(GetSpecificeRoomApi.rejected, (state, action) => {
      state.isLoading = true;
      state.SpecificRoomdata = {};
    });
    builder.addCase(GetSpecificeRoomApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(GetSpecificeRoomApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.status = action.payload.status;
      state.SpecificRoomdata = action.payload.status
        ? action.payload.result
        : {};
    });

    //%%%%%%%%%%%%%%%%%%%%%%% DELETE API %%%%%%%%%%%%%%%%%%%%%%
    builder.addCase(DeleteRoomApi.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(DeleteRoomApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(DeleteRoomApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.status = action.payload.status;
    });

    //%%%%%%%%%%%%%%%%%%%%%%% PUT API %%%%%%%%%%%%%%%%%%%%%%
    builder.addCase(PutRoomApi.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(PutRoomApi.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(PutRoomApi.fulfilled, (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.status = action.payload.status;
    });
  },
});
export default RoomSlice.reducer;

import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { IconButton } from "@mui/material";
import "./ImageDropzoneCss.css";

function ImageDropZone({
  getRootProps,
  getInputProps,
  isDragActive,
  images,
  setImages,
}) {
  console.log("%%%%%%%%%%%%%%%%%%%%% ALL IMAGE DROP ZONE", images);

  const handleremovefie = (event, name) => {
    event.stopPropagation();
    // setImages((previous) => (
    //     previous.filter((item) => item.name !== name)
    // ))

    setImages((previous) => previous.filter((_, i) => i !== name));
  };
  return (
    <>
      <div
        {...getRootProps()}
        className={`dropzone ${isDragActive ? "active" : ""}`}
      >
        <input {...getInputProps()} />
        {!images.length &&
          (isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>Drag 'n' drop some files here, or click to select files</p>
          ))}
        <ul style={{ display: "flex" }}>
          {images.map((item, index) => (
            <li
              key={index}
              style={{
                height: "100px",
                width: "150px",
                listStyle: "none",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "start",
                alignContent: "start",
                alignItems: "start",
              }}
            >
              <LazyLoadImage
                className="loadImage"
                src={
                  item.Preview
                    ? item.Preview
                    : `${process.env.REACT_APP_API_URL}/uploads/rooms/${item}`
                }
                // src={item.preview ? item.preview : item}
                // src={images && images.preview  ? images.preview : images}
                effect="blur"
                // height="200px"
                // width="200px"
                // style={{
                //     height: "200px",
                //     width: "200px",
                //     borderRadius: "16px",
                // }}
              />
              <IconButton
                sx={{
                  marginLeft: "-40px",
                  marginTop: "-5px",
                }}
                onClick={(event) => {
                  handleremovefie(event, index);
                }}
              >
                <DeleteOutlinedIcon
                  sx={{ color: "#ff5757", fontSize: "29px" }}
                />
              </IconButton>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default ImageDropZone;

import React from "react";
import { Box, Paper, Typography, TextField,  Stack, Button, } from "@mui/material";
import { useFormik } from "formik";
import { EmailSchema } from "../../../schema/Schema";
import { useDispatch } from "react-redux";
import { ResetApi } from "../../../store/Slice/AuthSlice/AuthSlice";
import { useNavigate } from "react-router-dom";
const ResetPage = () => {
  const navigate=useNavigate()
  const dispatch=useDispatch()
  const initialValues={
    email:"",
  }
  const {errors,values,touched,handleChange,handleBlur,handleSubmit}=useFormik({
    initialValues,
    validationSchema:EmailSchema,
    onSubmit:async (values)=>{
      try{
        await dispatch(ResetApi(values))
        navigate("/otp")
      }catch(error){
        return error
      }
    }
  })

  console.log(errors)


  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>

      <Paper sx={{ height: "330px", width: "450px", padding: "30px", borderRadius: "20px" }} >
        <Box>
          <Typography variant="h5">Password Reset</Typography>
          <Typography variant="subtitle1" mt={2}>Enter your email to recover your ID</Typography>
        </Box>
        <form onSubmit={handleSubmit} >
        <Stack mt={4} direction={'column'} gap={4}>

          <TextField
            label="Email"
            name="email"
            size="large"
            type="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.email && errors.email}
            error={touched.email && errors.email}
            required />
        </Stack>

        <Stack display={'flex'} justifyContent={'center'} alignItems={'center'} mt={6}>


          <Button variant="contained" color="secondary" type="submit" sx={{ background: "#805dca", width: "100%" }}>
          Confirm
          </Button>
        </Stack>
        </form>


      </Paper>

    </Box>
  )
}
export default ResetPage
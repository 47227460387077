import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export default function FilterMenu({FilterDropMenu,open,anchorEl,handleFilterMenuClose,setfilterid}) {

  const filterdata=(id)=>{
    setfilterid(id)
    handleFilterMenuClose()
  }

  return (
    <div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleFilterMenuClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{  padding:"50px" }}
      >
      {
        FilterDropMenu.map((item,index)=>(
        <MenuItem onClick={()=>filterdata(item.id)}  key={index}>{item.name}</MenuItem>
        ))
      }
        {/* <MenuItem onClick={handleClose}>Booked</MenuItem>
        <MenuItem onClick={handleClose}>Cancel</MenuItem>
        <MenuItem onClick={handleClose}>AllRooms</MenuItem> */}
      </Menu>
    </div>
  );
}

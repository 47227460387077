import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import RoomDetail from "./RoomsDetail";
import GuestDetail from "../../Guest/GuestDetail";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import Person3OutlinedIcon from "@mui/icons-material/Person3Outlined";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ height: "100%" }}
    >
      {value === index && (
        <Box
          sx={{
            p: 2,
            height: "100%",
            overflow: "auto",
            "::-webkit-scrollbar": { width: "0px" },
          }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({
  MainImage,
  data,
  tab,
  sethandlegridrow,
}) {
  const [value, setValue] = React.useState(0);
  const handleChange = (newValue) => setValue(newValue);
  // const handleClose = () => {
  //   sethandlegridrow((previos) => !previos);
  // };

  return (
    <Box
      sx={{
        width: "100%",
        marginTop: "-20px",
        height: "100%",
        overflow: "auto",
        "::-webkit-scrollbar": { width: "0px" },
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="icon tabs example"
          textColor="inherit"
          variant="fullWidth"
          sx={{
            display: "flex",
            justifyContent: "center", // Centering the tabs horizontally
            alignItems: "center",
            mt: 1,
          }}
        >
          <Tab
            label={tab === "room" ? "Room Detail" : "Guest Detail"}
            iconPosition="start"
            icon={
              tab === "room" ? (
                <HomeOutlinedIcon
                  sx={{ color: value === 0 ? "#E17D9F" : "#656CD9" }}
                />
              ) : (
                <Person3OutlinedIcon
                  sx={{ color: value === 0 ? "#E17D9F" : "#656CD9" }}
                />
              )
            }
            color="info"
            {...a11yProps(0)}
            sx={{
              flex: 1,
              color: "inherit",
            }}
          />

          {/* {UserData.length > 0 && (
            <Tab
              label="User Detail"
              iconPosition="start"
              icon={
                <Person3OutlinedIcon
                  sx={{ color: value === 1 ? "#E17D9F" : "#656CD9" }}
                />
              }
              {...a11yProps(1)}
              sx={{
                flex: 1,
                color: value === 1 ? "inherit" : "inherit",
                fontWeight: "bold",
                marginLeft: "20px",
                display: UserData ? "inherit" : "none",
              }}
            />
          )} */}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {tab === "room" ? (
          <RoomDetail
            MainImage={MainImage}
            data={data}
          />
        ) : (
          <GuestDetail
            data={data}
            sethandlegridrow={sethandlegridrow}
          />
        )}
      </CustomTabPanel>
    </Box>
  );
}

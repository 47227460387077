import { useEffect,useState, useMemo } from "react";
import {
  Box,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  Tooltip,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import DataTable from "../../../DataTable";
import CollapsedBreadcrumbs from "../../Include/Breadcrumb";
import { Avatar } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ExcellIcon from "../../../../Assets/img/Exellicon.png";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import UploadIcon from "@mui/icons-material/Upload";
import { LazyLoadImage } from "react-lazy-load-image-component";
import AddItem, {
  EditItem,
  AddCategory,
  DataUploadForm,
} from "../../../../Model/Model";
import SwipableDrawer from "../../Include/SwipableDrawer";
import EditRoadOutlinedIcon from "@mui/icons-material/EditRoadOutlined";
import handleExcelConversion from "../Reports/ExcellsFormat";
import { useDispatch, useSelector } from "react-redux";
import placeholderImage from "../../../../Assets/img/room-placeholder.jpg";
import Cookies from "js-cookie";
import {
  DeleteFoodApi,
  GetFoodApi,
} from "../../../../store/Slice/FoodSlice/FoodSlice";
import { MySwal } from "../../../../Model/Alert/SuccessAlert";
import dayjs from "dayjs";
import handleFilter from "../../../Searching";

const BreadCrumbLink = [
  {
    id: 1,
    name: "Home",
    link: "/",
  },
  {
    id: 1,
    name: "Foods-Item",
    link: "/item",
  },
];
const AllItem = () => {
  const Token = Cookies.get("Token");
  const dispatch = useDispatch();
  const { FoodData } = useSelector((state) => state.FoodStore);
  const [record, setrecord] = useState([]);
  const IsMobile = useMediaQuery("(min-width:900px)");

  useEffect(() => {
    const FetchData = async () => {
      try {
        await dispatch(GetFoodApi({ Token }));
      } catch (error) {
        return error;
      }
    };
    FetchData();
  }, [Token, dispatch]);

  const datawithSerialNumber = useMemo(() => {
    // Ensure FoodData is an array before mapping
    return FoodData
      ? FoodData.map((item, index) => ({ ...item, serialNumber: index + 1 }))
      : [];
  }, [FoodData]);

  useEffect(() => {
    setrecord(datawithSerialNumber);
  }, [datawithSerialNumber]);

  const [AddModelOpen, setAddModelOpen] = useState(false);
  const handlModelopen = () => setAddModelOpen(true);
  const handlModelClose = () => setAddModelOpen(false);
  const [updatedata, setupdatedata] = useState({});
  const [EditModelOpen, setEditModelOpen] = useState(false);

  const [AddCategoryModelOpen, setAddCategoryModelOpen] = useState(false);
  const handlModelopenCategory = () => setAddCategoryModelOpen(true);
  const handlModelCloseCategory = () => setAddCategoryModelOpen(false);
  const handlModelopenEdit = (data) => {
    setEditModelOpen(true);
    setupdatedata(data);
  };
  const handlModelCloseEdit = () => setEditModelOpen(false);

  const handleDeleteItem = async (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#dc3545",
      cancelButtonText: "No, cancel!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await dispatch(DeleteFoodApi({ Token, id }));
        await dispatch(GetFoodApi({ Token }));
      } else if (result.dismiss === MySwal.DismissReason.cancel) {
        MySwal.fire({
          title: "Cancelled",
          text: "Your data is safe.",
          icon: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "#dc3545",
        });
      }
    });
  };

  const [columns, setColumns] = useState([
    {
      field: "serialNumber",
      headerName: "SR.No",
      width: 0,
      headerAlign: "center",
      align: "center",
      visible: true,
    },

    {
      field: "created_at",
      headerName: "Date",
      width: 220,
      headerAlign: "center",
      align: "center",
      visible: true,
      renderCell: (param) => (
        <div>{dayjs(new Date(param.row?.created_at)).format("DD/MM/YYYY")}</div>
      ),
    },

    {
      field: "images",
      headerName: "Images",
      align: "center",
      visible: true,
      headerAlign: "center",
      renderCell: (param) => {
        const image =
          param.row && param.row?.images
            ? `${process.env.REACT_APP_API_URL}/uploads/foods/${param.row?.images}`
            : placeholderImage;

        return (
          <LazyLoadImage
            effect="blur"
            // onClick={() => {
            //   handleRowClick(param.row);
            // }}
            src={image}
            style={{
              height: "40px",
              width: "70px",
              borderRadius: "10px",
              cursor: "pointer",
              marginBottom: "10px",
              marginTop: "5px",
            }}
          />
        );
      },
    },
    {
      field: "item_code",
      headerName: "Code",
      width: 150,
      headerAlign: "center",
      align: "center",
      visible: true,
    },

    {
      field: "item_name",
      headerName: "Name",
      width: 200,
      headerAlign: "center",
      align: "center",
      visible: true,
    },
    {
      field: "name",
      headerName: "Category",
      width: 150,
      headerAlign: "center",
      align: "center",
      visible: true,
      renderCell: (param) => {
        return <div>{param.row?.food_category_master?.name}</div>;
      },
    },

    {
      field: "price",
      headerName: "price",
      width: 200,
      headerAlign: "center",
      align: "center",
      visible: true,
    },

    {
      field: "Action",
      headerName: "Action",
      width: 200,
      headerAlign: "center",
      align: "center",
      visible: true,
      renderCell: (param) => (
        <div>
          <IconButton
            onClick={() => {
              handlModelopenEdit(param.row);
            }}
          >
            <BorderColorOutlinedIcon sx={{ color: "#6d75eb" }} />
          </IconButton>
          {/* <IconButton
            onClick={() => {
              handleDeleteItem(param.row?.id);
            }}
          >
            <DeleteOutlinedIcon sx={{ color: "#ff5757" }} />
          </IconButton> */}
        </div>
      ),
    },
  ]);

  const handleToggleColumn = (field) => {
    setColumns((prevColumns) =>
      prevColumns.map(
        (col) => (col.field === field ? { ...col, visible: !col.visible } : col)
        // console.log("col",col)
      )
    );
  };

  const [dataUploadModelOpen, setdataUploadModelOpen] = useState(false);
  const handlUploadModelOpen = () => setdataUploadModelOpen(true);
  const handlUploadModelClose = () => setdataUploadModelOpen(false);

  // const [foodDetail, setfoodDetail] = useState({});
  // const handleRowClick = (data) => {
  //   const { food_category_master, item_code, item_name, price, created_at } =
  //     data;
  //   setfoodDetail({
  //     Category: food_category_master,
  //     Name: item_code,
  //     Code: item_name,
  //     "Price ": price,
  //     "Created Date": new Date(created_at).toDateString(),
  //   });
  //   sethandlegridrow((previos) => !previos);
  // };

  // const icons = [
  //   <AutorenewIcon />,
  //   <PersonOutlineIcon />,
  //   <MailOutlineIcon />,
  //   <LocalPhoneIcon />,
  //   <AddCardIcon />,
  //   <AddCardIcon />,
  //   <AddCardIcon />,
  //   <BusinessIcon />,
  // ];

  const [Swipeopen, setSwipeopen] = useState(false);
  const HandleSwipeOpen = () => setSwipeopen(true);
  const HandleSwipeClose = () => setSwipeopen(false);

  const keys_serach = [
    "item_name",
    "serialNumber",
    "item_code",
    "price",
    "tax_type",
  ];

  return (
    <>
      <Box sx={{ padding: "20px", width: "100%" }}>
        <Stack
          direction={IsMobile ? "row" : "column"}
          justifyContent={"space-between"}
        >
          <Typography variant="h6">Food Items</Typography>
          <CollapsedBreadcrumbs data={BreadCrumbLink} />
        </Stack>

        <Grid container>
          <Grid item xs={12}>
            <Paper
              sx={{ padding: "10px", marginTop: "20px", borderRadius: "10px" }}
            >
              <Grid container>
                <Grid item lg={9} md={9} sm={9} xs={12}>
                  <Box
                    sx={{
                      width: { xs: "100%", sm: "" },
                    }}
                  >
                    <TextField
                      label="Search"
                      size="small"
                      onChange={(event) => {
                        handleFilter({
                          event,
                          dataWithSerialNumber: datawithSerialNumber,
                          setrecord,
                          keys_serach,
                        });
                      }}
                      sx={{
                        bgcolor: "#dae1f3",
                        borderRadius: "10px",
                        width: { xs: "100%", sm: "230px" },

                        "& fieldset": { border: "none" },
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignContent: "center",
                      alignItems: "center",
                      mt: { xs: 2, sm: 0 },
                    }}
                  >
                    <Avatar sx={{ background: "#6970E2" }}>
                      <Tooltip title="Table Edit">
                        <IconButton onClick={HandleSwipeOpen}>
                          <EditRoadOutlinedIcon sx={{ color: "#fff" }} />
                        </IconButton>
                      </Tooltip>
                    </Avatar>

                    <Avatar sx={{ background: "#EA82A6" }}>
                      <Tooltip title="Add Food Item">
                        <IconButton onClick={handlModelopen}>
                          <AddIcon sx={{ color: "#fff" }} />
                        </IconButton>
                      </Tooltip>
                    </Avatar>
                    <Avatar
                      src={ExcellIcon}
                      onClick={() => {
                        handleExcelConversion(record);
                      }}
                    ></Avatar>
                    <Avatar sx={{ background: "#58c2ff" }}>
                      <Tooltip title="Bulk Upload">
                        <IconButton onClick={handlUploadModelOpen}>
                          <UploadIcon sx={{ color: "#fff" }} />
                        </IconButton>
                      </Tooltip>
                    </Avatar>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Box sx={{ marginTop: "30px", paddingLeft: "10px" }}>
          {/* Hello */}
          <Grid container spacing={2}>
            {/* <Grid item lg={handlegridrow ? 6 : 12} xs={12} mt={-3}> */}
            <Grid item lg={12} xs={12} mt={-3}>
              <Paper sx={{ mt: 2 }}>
                <DataTable columns={columns} rows={record} />
              </Paper>
            </Grid>

            {/* <Grid item lg={handlegridrow ? 6 : 4} xs={handlegridrow || 12}>
              {handlegridrow && (
                <Paper sx={{ borderRadius: "10px" }}>
                  <GuestDetail
                    userDetails={foodDetail}
                    icons={icons}
                    MainImage={Icecream}
                    sethandlegridrow={sethandlegridrow}
                  />
                </Paper>
              )}
            </Grid> */}
          </Grid>
        </Box>
      </Box>
      <Paper>
        {AddCategoryModelOpen && (
          <AddCategory
            open={AddCategoryModelOpen}
            handleClose={handlModelCloseCategory}
          />
        )}

        {AddModelOpen && (
          <AddItem
            open={AddModelOpen}
            handleClose={handlModelClose}
            handleCategoryOpen={handlModelopenCategory}
          />
        )}

        {dataUploadModelOpen && (
          <DataUploadForm
            open={dataUploadModelOpen}
            handleClose={handlUploadModelClose}
            uploadType={"FOOD"}
            demoFile={"Fooditem_Demo.xlsx"}
          />
        )}

        {EditModelOpen && (
          <EditItem
            open={EditModelOpen}
            handleClose={handlModelCloseEdit}
            data={updatedata}
          />
        )}

        <SwipableDrawer
          open={Swipeopen}
          close={HandleSwipeClose}
          columns={columns}
          handleToggleColumn={handleToggleColumn}
        />
      </Paper>
    </>
  );
};
export default AllItem;

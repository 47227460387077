import React, { useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Stack,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@mui/material";
import Cookies from "js-cookie"
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useFormik } from "formik";
import { ConfirmPassword_Schema } from "../../../schema/Schema";
import { useDispatch } from "react-redux";

import { ChangePasswordApi } from "../../../store/Slice/AuthSlice/AuthSlice";

const ConfirmPd = () => {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const Token=Cookies.get("temp_token")
  const id=Cookies.get("user_id")
  
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const initialValues = {
    new_password: "",
    confirm_password: "",
  };
  const {
    errors,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: ConfirmPassword_Schema,
    onSubmit: async (values) => {
      try {
        await dispatch(ChangePasswordApi({Token,values,id}))
      } catch (error) {
        console.log("Error");
      }
    },
  });

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Paper
        sx={{
          height: "400px",
          width: "450px",
          padding: "30px",
          borderRadius: "20px",
        }}
      >
        <Box>
          <Typography variant="h5">Confirm Password</Typography>
          <Typography variant="subtitle1" mt={2}>
            Enter your New password to login
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Stack mt={4} direction={"column"} gap={4}>
            <FormControl
              sx={{ width: "100%" }}
              variant="outlined"
              error={touched.new_password && errors.new_password}
            >
              <InputLabel htmlFor="outlined-adornment-password">
                New Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                name="new_password"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter New Password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
              {touched.new_password && errors.new_password && (
                <FormHelperText>{errors.new_password}</FormHelperText>
              )}
            </FormControl>

            <FormControl
              sx={{ width: "100%" }}
              variant="outlined"
              error={touched.confirm_password && errors.confirm_password}
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Confirm Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                name="confirm_password"
                value={values.confirm_password}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Confirm Password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />

              {touched.confirm_password && errors.confirm_password && (
                <FormHelperText>{errors.confirm_password}</FormHelperText>
              )}
            </FormControl>
          </Stack>

          <Stack
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            mt={4}
          >
            <Button
              variant="contained"
              color="secondary"
              sx={{ background: "#805dca", width: "100%" }}
              type="submit"
            >
              <Typography variant="body">Confirm</Typography>
            </Button>
          </Stack>
        </form>
      </Paper>
    </Box>
  );
};
export default ConfirmPd;

import React from "react";
import Chip from "@mui/material/Chip";

const FacilityChip = ({ id, icon, label, onDelete }) => {
  return (
    <>
      <Chip
        id={id}
        icon={icon}
        label={label}
        onDelete={() => onDelete()}
        variant="outlined"
        color="info"
        size="small"
        sx={{ mr: 1, mb: 1 }}
      />
    </>
  );
};

export default FacilityChip;

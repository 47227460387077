import React, { useState } from "react";
import {
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  Table,
  Paper,
  TableRow,
  IconButton,
  Collapse,
  Typography,
  Button,
  Tooltip,
} from "@mui/material";
import placeholderImage from "../../../../Assets/img/room-placeholder.jpg";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EventBusyTwoToneIcon from "@mui/icons-material/EventBusyTwoTone";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import CreditScoreTwoToneIcon from "@mui/icons-material/CreditScoreTwoTone";
import {
  DeleteRoomServiceApi,
  GetRoomServiceApi,
} from "../../../../store/Slice/RoomServiceSlice/RoomServiceSlice";
import { MySwal } from "../../../../Model/Alert/SuccessAlert";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { LazyLoadImage } from "react-lazy-load-image-component";

const GuestDropDownTable = ({ data, column }) => {
  const Token = Cookies.get("Token");
  const dispatch = useDispatch();

  const [submenuopen, setsubmenuopen] = useState(null);

  const handlesubmenuOPen = (id) => {
    setsubmenuopen((previouse) => (previouse === id ? null : id));
  };

  const visibleColumn = column.filter((col) => col.visible);

  const handleDelete = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#dc3545",
      cancelButtonText: "No, cancel!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        console.log("Btn Was Clicked2", id);
        await dispatch(DeleteRoomServiceApi({ Token, id }));
        await dispatch(GetRoomServiceApi({ Token }));
      } else if (result.dismiss === MySwal.DismissReason.cancel) {
        console.log("Btn Was Clicked3", id);
        MySwal.fire({
          title: "Cancelled",
          text: "Your data is safe.",
          icon: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "#dc3545",
        });
      }
    });
  };

  return (
    <>
      <TableContainer
        component={Paper}
        style={{ maxWidth: "100%", overflowX: "auto" }}
      >
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {column &&
                column.length > 0 &&
                column.map((item, index) => (
                  <TableCell
                    component="th"
                    scope="row"
                    key={index}
                    sx={{
                      backgroundColor: "#0288d1",
                      color: "white",
                      border: "10px solid",
                      borderRadius: 6,
                      py: 1,
                      // px: 10,
                      textAlign: "center",
                    }}
                    // width={"200px"}
                  >
                    <Typography
                      variant="subtitle2"
                      fontSize={"15px"}
                      sx={{ textAlign: "center", width: `${item?.width}` }}
                    >
                      {item?.headerName}
                    </Typography>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.length > 0 &&
              data.map((item, index) => (
                <React.Fragment key={item?.id}>
                  <TableRow>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handlesubmenuOPen(item?.id)}
                      >
                        {submenuopen ? (
                          <KeyboardArrowUp />
                        ) : (
                          <KeyboardArrowDown />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">
                        {item?.serialNumber}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">
                        {new Intl.DateTimeFormat("en-GB", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                        }).format(new Date(item?.created_at))}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">
                        {item?.fullname}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">
                        {item?.phone_number}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">
                        {item?.address}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">{item?.email}</Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="subtitle">
                        {item?.gst_number}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <LazyLoadImage
                        effect="blur"
                        src={
                          item && item?.document_images
                            ? `${process.env.REACT_APP_API_URL}/uploads/guests/${item?.document_images}`
                            : placeholderImage
                        }
                        style={{
                          height: "40px",
                          width: "70px",
                          borderRadius: "10px",
                          cursor: "pointer",
                          marginBottom: "10px",
                          marginTop: "5px",
                        }}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={9}
                    >
                      <Collapse
                        in={submenuopen === item?.id}
                        timeout={"auto"}
                        unmountOnExit
                      >
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                sx={{
                                  py: 1,
                                  fontSize: "20px",
                                }}
                                colSpan={column?.length + 1}
                              >
                                Past Bookings
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  backgroundColor: "#9C27B0",
                                  color: "white",
                                  border: "10px solid",
                                  borderRadius: 6,
                                  textAlign: "center",
                                  py: 1,
                                }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  fontSize={"15px"}
                                  sx={{
                                    textAlign: "center",
                                  }}
                                >
                                  Sr. No.
                                </Typography>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  backgroundColor: "#9C27B0",
                                  color: "white",
                                  border: "10px solid",
                                  borderRadius: 6,
                                  textAlign: "center",
                                  py: 1,
                                }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  fontSize={"15px"}
                                  sx={{
                                    textAlign: "center",
                                    width: `${item?.width}`,
                                  }}
                                >
                                  Date
                                </Typography>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  backgroundColor: "#9C27B0",
                                  color: "white",
                                  border: "10px solid",
                                  borderRadius: 6,
                                  textAlign: "center",
                                  py: 1,
                                }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  fontSize={"15px"}
                                  sx={{
                                    textAlign: "center",
                                    width: `${item?.width}`,
                                  }}
                                >
                                  Check In
                                </Typography>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  backgroundColor: "#9C27B0",
                                  color: "white",
                                  border: "10px solid",
                                  borderRadius: 6,
                                  textAlign: "center",
                                  py: 1,
                                }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  fontSize={"15px"}
                                  sx={{
                                    textAlign: "center",
                                    width: `${item?.width}`,
                                  }}
                                >
                                  Check Out
                                </Typography>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  backgroundColor: "#9C27B0",
                                  color: "white",
                                  border: "10px solid",
                                  borderRadius: 6,
                                  textAlign: "center",
                                  py: 1,
                                }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  fontSize={"15px"}
                                  sx={{
                                    textAlign: "center",
                                    width: `${item?.width}`,
                                  }}
                                >
                                  Room No.
                                </Typography>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  backgroundColor: "#9C27B0",
                                  color: "white",
                                  border: "10px solid",
                                  borderRadius: 6,
                                  textAlign: "center",
                                  py: 1,
                                }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  fontSize={"15px"}
                                  sx={{
                                    textAlign: "center",
                                    width: `${item?.width}`,
                                  }}
                                >
                                  Arrival
                                </Typography>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  backgroundColor: "#9C27B0",
                                  color: "white",
                                  border: "10px solid",
                                  borderRadius: 6,
                                  textAlign: "center",
                                  py: 1,
                                }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  fontSize={"15px"}
                                  sx={{
                                    textAlign: "center",
                                    width: `${item?.width}`,
                                  }}
                                >
                                  Days
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {item?.reservationmaster &&
                              item?.reservationmaster.length > 0 &&
                              item?.reservationmaster.map(
                                (serviceItem, index) => (
                                  <TableRow key={index}>
                                    {/* <TableCell>
                                    <LazyLoadImage
                                      src={
                                        serviceItem?.images
                                          ? `${process.env.REACT_APP_API_URL}/uploads/foods/${serviceItem?.images}`
                                          : placeholderImage
                                      }
                                      effect="blur"
                                      style={{
                                        height: "40px",
                                        width: "70px",
                                        borderRadius: "10px",
                                        cursor: "pointer",
                                        marginBottom: "10px",
                                        marginTop: "5px",
                                      }}
                                    />
                                  </TableCell> */}
                                    <TableCell sx={{ textAlign: "center" }}>
                                      {+index + 1}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        textAlign: "center",
                                      }}
                                    >
                                      <Typography variant="subtitle">
                                        {new Intl.DateTimeFormat("en-GB", {
                                          year: "numeric",
                                          month: "2-digit",
                                          day: "2-digit",
                                          hour: "2-digit",
                                          minute: "2-digit",
                                        }).format(
                                          new Date(serviceItem?.booking_date)
                                        )}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        textAlign: "center",
                                      }}
                                    >
                                      <Typography variant="subtitle">
                                        {new Intl.DateTimeFormat("en-GB", {
                                          year: "numeric",
                                          month: "2-digit",
                                          day: "2-digit",
                                          hour: "2-digit",
                                          minute: "2-digit",
                                        }).format(
                                          new Date(serviceItem?.check_in)
                                        )}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        textAlign: "center",
                                      }}
                                    >
                                      <Typography variant="subtitle">
                                        {new Intl.DateTimeFormat("en-GB", {
                                          year: "numeric",
                                          month: "2-digit",
                                          day: "2-digit",
                                          hour: "2-digit",
                                          minute: "2-digit",
                                        }).format(
                                          new Date(serviceItem?.check_out)
                                        )}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        textAlign: "center",
                                      }}
                                    >
                                      <Typography variant="subtitle">
                                        {serviceItem?.roommaster &&
                                        serviceItem?.roommaster?.room_no
                                          ? serviceItem?.roommaster?.room_no
                                          : ""}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        textAlign: "center",
                                      }}
                                    >
                                      <Typography variant="subtitle">
                                        {serviceItem?.arrival_time
                                          ? new Intl.DateTimeFormat("en-GB", {
                                              year: "numeric",
                                              month: "2-digit",
                                              day: "2-digit",
                                              hour: "2-digit",
                                              minute: "2-digit",
                                            }).format(
                                              new Date(
                                                serviceItem?.arrival_time
                                              )
                                            )
                                          : ""}
                                      </Typography>
                                    </TableCell>

                                    <TableCell
                                      sx={{
                                        textAlign: "center",
                                      }}
                                    >
                                      <Typography variant="subtitle">
                                        {serviceItem?.total_days}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                          </TableBody>
                        </Table>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default GuestDropDownTable;

import React, { useCallback, useState, useEffect } from "react";
import axios from "axios";
import {
  Paper,
  TextField,
  Box,
  Stack,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import CollapsedBreadcrumbs from "../../Include/Breadcrumb";
import ImageDropZone from "./ImageDropZone";
import { useDropzone } from "react-dropzone";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { NavLink, useParams } from "react-router-dom";
import {
  GetSpecificeRoomApi,
  PutRoomApi,
} from "../../../../store/Slice/RoomsSlice/RoomSlice";
import { useFormik } from "formik";
import { MySwal } from "../../../../Model/Alert/SuccessAlert";
import { RoomSchema } from "../../../../schema/Schema";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { FACILITIES } from "./Data/Data";
import FacilityChip from "./FacilityChip";
const Base_url = process.env.REACT_APP_API_URL + "/check/room-no/rooms";
const BreadCrumbLink = [
  {
    id: 1,
    name: "Home",
    link: "/",
  },
  {
    id: 2,
    name: "rooms",
    link: "/rooms",
  },
  {
    id: 3,
    name: "Edit-Rooms",
    link: "/edit-rooms",
  },
];

const numberInputOnWheelPreventChange = (e) => {
  // Prevent the input value change
  e.target.blur();

  // Refocus immediately, on the next tick (after the current function is done)
  setTimeout(() => {
    e.target.focus();
  }, 0);
};

const EditRooms = () => {
  const { id } = useParams();
  const Token = Cookies.get("Token");
  const dispatch = useDispatch();
  const { SpecificRoomdata } = useSelector((state) => state.RoomStore);
  const [record, setrecord] = useState([]);
  const IsMobile = useMediaQuery("(min-width:900px)");

  useEffect(() => {
    const FetchData = async () => {
      try {
        await dispatch(GetSpecificeRoomApi({ Token, id }));
      } catch (error) {
        return error;
      }
    };
    FetchData();
  }, [Token, dispatch]);

  useEffect(() => {
    setrecord(SpecificRoomdata);
  }, [SpecificRoomdata]);

  //Image Uplodaing
  const [images, setImages] = useState([]);

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length) {
      setImages((previous) => [
        ...previous,
        ...acceptedFiles.map((item) =>
          Object.assign(item, { Preview: URL.createObjectURL(item) })
        ),
      ]);
    }

    if (rejectedFiles.length) {
      if (rejectedFiles.length > 5) {
        setFieldError("images", "Maximum 5 images can be uploaded");
      } else {
        setFieldError(
          "images",
          "Only jpg , jpeg , webp , svg , png files are allowed"
        );
      }
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [],
    },
    maxFiles: 5,
    multiple: true,
  });

  const initialValues = {
    room_no: "",
    floor_no: "",
    bed_type: "",
    tax_type: "",
    facilities: "",
    perdayprice: "",
    description: "",
    images: [],
  };

  const {
    errors,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
    setValues,
    setFieldError,
  } = useFormik({
    initialValues,
    validationSchema: RoomSchema,
    onSubmit: async (values, action) => {
      try {
        values.images = images;

        const formdata = new FormData();
        for (let key in values) {
          if (key === "images") {
            values.images.forEach((item, index) => {
              formdata.append("images", item);
            });
          } else {
            formdata.append(key, values[key]);
          }
        }
        console.log('fromdata', ...formdata)
        MySwal.fire({
          title: "Do you want to save the changes?",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Save",
          denyButtonText: `Don't save`,
        }).then(async (result) => {
          if (result.isConfirmed) {
            await dispatch(PutRoomApi({ Token, formdata, id }));
          } else if (result.isDenied) {
            MySwal.fire("Changes are not saved", "", "info");
          }
        });
      } catch (error) {
        return error;
      }
      //
    },
  });

  useEffect(() => {
    if (record) {
      // Ensure that record is defined
      setValues({
        ...values,
        room_no: record?.room_no || "",
        floor_no: record?.floor_no || "",
        tax_type: record?.tax_type || "",
        bed_type: record?.bed_type || "",
        perdayprice: record?.perdayprice || "",
        description: record?.description || "",
      });

      if (record?.images) {
        let arrayImages = record?.images ? record?.images.split(",") : [];
        setImages(arrayImages);
      }
      if (record?.facilities) {
        let data = record?.facilities ? record?.facilities.split(",") : [];
        console.info("data :>>", data);

        let arrayFacilities = FACILITIES.filter((facility, i) => {
          if (data.includes(facility.id.toString())) {
            return facility;
          }
        });
        setSelectedFacilities(arrayFacilities);
      }
    }
  }, [record]);

  useEffect(() => {
    setFieldValue("images", images);
  }, [images]);

  const [Status, setStatus] = useState(true);
  const [Message, setMessage] = useState("");

  const handleRoomNoChange = async (e) => {
    handleChange(e);
    setStatus(true);
    setTimeout(async () => {
      try {
        const headers = {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        };

        const response = await axios.post(
          Base_url,
          { room_no: e.target.value, room_id: id },
          { headers }
        );
        if (response.data.status === true) {
          setStatus(true);
          setMessage(response.data.message);
        }
      } catch (error) {
        setStatus(false);
        setMessage(error.response.data.message);
      }
    }, 500);
  };

  const [selectedFacilities, setSelectedFacilities] = useState([]);

  const handleFacilityChange = (newValue) => {
    setSelectedFacilities(newValue);
  };

  useEffect(() => {
    setFieldValue(
      "facilities",
      selectedFacilities.map((item) => item.id)
    );
  }, [selectedFacilities]);

  const handleDelete = (Id) => () => {
    setSelectedFacilities((facilities) =>
      facilities.filter((facility) => facility.id !== Id)
    );
  };

  console.info('images', images)

  return (
    <Box sx={{ padding: "20px", width: "100%" }}>
      <Stack
        direction={IsMobile ? "row" : "column"}
        justifyContent={"space-between"}
      >
        <Typography variant="h6">Edit Rooms</Typography>
        <CollapsedBreadcrumbs data={BreadCrumbLink} />
      </Stack>

      <Paper sx={{ marginTop: "20px", padding: "30px" }}>
        <form onSubmit={handleSubmit}>
          <Box display={"flex"} flexDirection={"column"} gap={4}>
            <Stack direction={IsMobile ? "row" : "column"} spacing={4}>
              <Stack direction={"column"} sx={{ width: "100%" }}>
                <TextField
                  label="Room No"
                  variant="outlined"
                  size="large"
                  name="room_no"
                  type="text"
                  sx={{ width: "100%" }}
                  onChange={handleRoomNoChange}
                  onBlur={handleBlur}
                  value={values.room_no}
                />
                {Status === false && (
                  <Typography
                    variant="caption"
                    color={"error"}
                    style={{
                      marginTop: "3px",
                      marginLeft: "14px",
                      marginRight: "14px",
                      marginBottom: "0px",
                    }}
                  >
                    {Message}
                  </Typography>
                )}
              </Stack>

              <TextField
                label="Per Day Price"
                variant="outlined"
                size="large"
                name="perdayprice"
                type="number"
                onWheel={numberInputOnWheelPreventChange}
                sx={{ width: "100%" }}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.perdayprice}
              />
            </Stack>

            <Stack direction={IsMobile ? "row" : "column"} spacing={4}>
              <TextField
                label="Floor No"
                variant="outlined"
                size="large"
                name="floor_no"
                type="text"
                sx={{ width: "100%" }}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.floor_no}
              />

              <FormControl
                fullWidth
                error={touched.bed_type && errors.bed_type}
                helperText={touched.bed_type && errors.bed_type}
              >
                <InputLabel id="demo-simple-select-label">Bed Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="bed_type"
                  name="bed_type"
                  value={values.bed_type}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <MenuItem value={"SINGLE"}>SINGLE</MenuItem>
                  <MenuItem value={"DOUBLE"}>DOUBLE</MenuItem>
                  <MenuItem value={"OTHER"}>OTHER</MenuItem>
                </Select>
                {touched.bed_type && errors.bed_type && (
                  <Typography
                    variant="caption"
                    color={"error"}
                    style={{
                      marginTop: "3px",
                      marginLeft: "14px",
                      marginRight: "14px",
                      marginBottom: "0px",
                    }}
                  >
                    {errors.bed_type}
                  </Typography>
                )}
              </FormControl>
            </Stack>

            <Stack direction={IsMobile ? "row" : "column"} spacing={4}>
              <FormControl
                fullWidth
                error={touched.tax_type && errors.tax_type}
                helperText={touched.tax_type && errors.tax_type}
              >
                <InputLabel id="demo-simple-select-label">Tax Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="tax_type"
                  name="tax_type"
                  value={values.tax_type}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <MenuItem value={"INCLUDE"}>INCLUDE</MenuItem>
                  <MenuItem value={"EXCLUDE"}>EXCLUDE</MenuItem>
                  <MenuItem value={"NONE"}>NONE</MenuItem>
                </Select>
                {touched.tax_type && errors.tax_type && (
                  <Typography
                    variant="caption"
                    color={"error"}
                    style={{
                      marginTop: "3px",
                      marginLeft: "14px",
                      marginRight: "14px",
                      marginBottom: "0px",
                    }}
                  >
                    {errors.tax_type}
                  </Typography>
                )}
              </FormControl>

              <TextField
                id="task"
                type="text"
                label="Description"
                placeholder="Description"
                multiline
                rows={2}
                fullWidth
                sx={{ width: "100%" }}
                name="description"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
              />
            </Stack>

            <Stack direction={IsMobile ? "row" : "column"} spacing={4}>
              {/* <TextField
                type="text"
                label="facility"
                placeholder="facility"
                multiline
                rows={3}
                fullWidth
                name="facilities"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.facilities}
              /> */}

              <Autocomplete
                multiple
                disableCloseOnSelect
                sx={{ width: "100%" }}
                options={FACILITIES}
                getOptionLabel={(option) => option.label}
                value={selectedFacilities}
                onChange={(e, newValue) => handleFacilityChange(newValue)}
                onBlur={handleBlur}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <FacilityChip
                      key={option.id}
                      id={option.id}
                      icon={option.icon}
                      label={option.label}
                      onDelete={handleDelete(option.id)}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Facilities"
                    variant="outlined"
                    error={touched.facilities && errors.facilities}
                    helperText={touched.facilities && errors.facilities}
                  />
                )}
                renderOption={(props, option, { selected }) => {
                  const { key, ...optionProps } = props;
                  return (
                    <li key={key} {...optionProps}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.label}
                    </li>
                  );
                }}
              />
            </Stack>
            <Stack direction={IsMobile ? "row" : "column"} spacing={4}>
              <ImageDropZone
                getRootProps={getRootProps}
                getInputProps={getInputProps}
                isDragActive={isDragActive}
                images={images}
                setImages={setImages}
              />
            </Stack>
            {touched.images && errors.images && (
              <Typography
                variant="caption"
                color={"error"}
                style={{
                  marginTop: "3px",
                  marginLeft: "14px",
                  marginRight: "14px",
                  marginBottom: "0px",
                }}
              >
                {errors.images}
              </Typography>
            )}

            <Stack
              direction={IsMobile ? "row" : "column"}
              display={"flex"}
              justifyContent={"center"}
              width={"100%"}
            >
              <Stack
                direction={IsMobile ? "row" : "column"}
                gap={4}
                width={IsMobile ? "initial" : "100%"}
              >
                <Button
                  variant="contained"
                  color="success"
                  type="submit"
                  disabled={Status === false}
                >
                  Submit
                </Button>
                <NavLink to={"/rooms"}>
                  <Button
                    variant="contained"
                    fullWidth={IsMobile ? false : true}
                    color="error"
                  >
                    Cancel
                  </Button>
                </NavLink>
              </Stack>
            </Stack>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};

export default EditRooms;

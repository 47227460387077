import {
  Box,
  Divider,
  Drawer,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Paper,
} from "@mui/material";
import { pink } from "@mui/material/colors";
const SwipableDrawer = ({ open, close, columns, handleToggleColumn }) => {
  return (
    <Drawer anchor="right" open={open} onClose={close}>
      <Box p={2} width={"350px"} textAlign={"center"} role="presentation">
        <Typography variant="h6">Customize Data Table</Typography>
        <Divider />

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            flexWrap: "wrap",
            mt: 4,
          }}
        >
          {columns.map(
            (col) =>
              col.field !== "serialNumber" &&
              col.field !== "Action" && (
                <Paper
                  sx={{
                    p: 1,
                    width: "45%",
                    boxShadow: "0px 10px 09px rgba(12, 0, 0, 0.1)",
                    transition: "0.5s",
                    "&:hover": {
                      transform: "translateY(-10px)",
                    },
                  }}
                >
                  <FormControlLabel
                    key={col.field}
                    control={
                      <Checkbox
                        checked={col.visible}
                        onChange={() => handleToggleColumn(col.field)}
                        sx={{
                          color: pink[800],
                          "&.Mui-checked": {
                            color: pink[600],
                          },
                        }}
                      />
                    }
                    label={col.headerName}
                  />
                </Paper>
              )
          )}
        </Box>

        <Button
          onClick={close}
          sx={{ marginTop: 5 }}
          variant="outlined"
          color="info"
        >
          Close
        </Button>
      </Box>
    </Drawer>
  );
};
export default SwipableDrawer;
